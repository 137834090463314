import { Input } from "antd";
import TextBase from "../Text";
import styled from "styled-components";

const { Search: SearchAnt } = Input;

const Text = styled(TextBase)`
  font-weight: bold;
`;
const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`;
const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`;

export { Text, Container, HeaderWrapper, Search };
