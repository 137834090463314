import { Typography, Row, Col, Button } from "antd";
import { FaHistory } from "react-icons/fa";
import moment from "moment";
import { CardSummaryStyled } from "./styles";

import ProfileSummary from "../../ProfileSummary";
import Chat from "../../Chat";
const { Title } = Typography;

const SummaryPatientDetailCard = (props) => {
  const { patient, userId, setVisibleHistoryPatientDetailModal, t } = props;
  return (
    <CardSummaryStyled
      title={
        patient &&
        patient.userInformation && (
          <ProfileSummary
            firstname={patient.userInformation.firstname}
            lastname={patient.userInformation.lastname}
            profileImage={patient.userInformation.img}
          />
        )
      }
      extra={
        setVisibleHistoryPatientDetailModal && (
          <Button
            type="primary"
            icon={<FaHistory />}
            onClick={() => setVisibleHistoryPatientDetailModal(true)}
            className="d-flex justify-content-center align-items-center summary-button"
          >
            <p className="m-0 pl-2">{t("history_booking")}</p>
          </Button>
        )
      }
      style={{ height: "100%" }}
      bodyStyle={{ height: "65vh" }}
    >
      {patient && patient.userInformation && (
        <Chat
          patientId={patient.id}
          userId={userId}
          patientName={`${patient.userInformation.firstname} ${patient.userInformation.lastname}`}
          isShow={false}
          isSummary
        />
      )}
    </CardSummaryStyled>
  );
};

export default SummaryPatientDetailCard;
