import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/users";

const ROLES = [
  "doctor",
  "admin",
  "callCenter",
  "pharmacy",
  "pharmacyStore",
  "nurse",
  "physicalTherapy",
  "staff",
  "logistic",
  "ci",
  "medicalRecord",
  "covidAtHome",
  "deliveryTeam",
  "communityPharmacist",
];

async function register(credential) {
  const { data } = await http.post(apiEndpoint, credential);
  return data;
}

async function login(credential) {
  const { data } = await http.post(`${apiEndpoint}/login`, credential);
  const { id } = data;
  localStorage.setItem("token", id);
  http.setJwt(id);
  return data;
}

async function getUser(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}`);
  return data;
}

async function getProfile(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/practitioner`);
  return data;
}

async function updateProfile(id, detail) {
  const { data } = await http.patch(`practitioners/${id}`, detail);
  return data;
}

async function addPhoto(id, detail) {
  const { data } = await http.post(`practitioners/${id}/photos`, detail);
  return data;
}

async function updatePhoto(id, detail, fk) {
  const { data } = await http.put(`practitioners/${id}/photos/${fk}`, detail);
  return data;
}

async function updateSpecialties(id, detail) {
  const { data } = await http.patch(
    `practitioners/${id}/updateSpecialties`,
    detail
  );
  return data;
}

async function getOrganizationsUser(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/organizations`);
  return data;
}

async function deleteUser(id) {
  const { data } = await http.delete(`${apiEndpoint}/${id}`);
  return data;
}

async function getUserSetting(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/userSetting`);
  return data;
}

function logout() {
  localStorage.clear();
  http.clearJwt();
}

export {
  login,
  getUser,
  logout,
  register,
  getProfile,
  updateProfile,
  addPhoto,
  updatePhoto,
  updateSpecialties,
  deleteUser,
  getOrganizationsUser,
  getUserSetting,
};
