import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import { Select } from "antd";
import everLogo from "../../images/EverLogo.png";
import EverLogin from "../../images/Login.png";
import IvoryLogo from "../../images/IvoryLogo.png";

import {
  BackgroundImage,
  Bold,
  Container,
  ContainerImage,
  Content,
  ContentText,
  Error,
  Footer,
  Form,
  Header,
  HeaderContent,
  Item,
  ItemPassword,
  LoginButton,
  Logo,
  MarginForm,
  Text,
  WrapBackground,
  WrapButton,
  WrapContact,
  WrapLogo,
  WrapText,
  WrapperLanguage,
  SelectStyled,
} from "./styles";
const { Option } = Select;

const LoginForm = ({
  email,
  password,
  error,
  onSubmit,
  onChange,
  user,
  t,
  lngs,
  i18n,
}) => (
  <Container>
    <ContainerImage>
      <Header>
        <WrapLogo>
          <Logo src={IvoryLogo} />
        </WrapLogo>
        <ContentText>
          <Text>
            <Bold>Santé Pour Tous</Bold>
          </Text>
        </ContentText>
      </Header>
      <WrapBackground>
        <BackgroundImage src={EverLogin} />
      </WrapBackground>
    </ContainerImage>
    <Content>
      <WrapperLanguage>
        <SelectStyled
          defaultValue={i18n.resolvedLanguage}
          style={{ width: 120 }}
          onChange={(lng) => i18n.changeLanguage(lng)}
        >
          {Object.keys(lngs).map((lng) => (
            <Option
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
            >
              {lngs[lng].nativeName}
            </Option>
          ))}
        </SelectStyled>
      </WrapperLanguage>
      <MarginForm>
        <Form>
          <HeaderContent>
            <h2>{t("login")}</h2>
          </HeaderContent>
          <form style={{ width: "100%" }}>
            <Item
              placeholder={t("email")}
              value={email}
              onChange={(e) => onChange({ email: e.target.value })}
              required
              suffix={<UserOutlined />}
            />
            <ItemPassword
              placeholder={t("password")}
              value={password}
              required
              onChange={(e) => onChange({ password: e.target.value })}
              suffix={<KeyOutlined />}
            />
            <Error>{user.error ? t("invalid_email_or_password") : null}</Error>
          </form>
        </Form>
        <Footer>
          <WrapText>
            <h2>{t("forgot_password")}?</h2>
          </WrapText>
          <WrapButton>
            <LoginButton type="submit" onClick={onSubmit}>
              {t("login")}
            </LoginButton>
          </WrapButton>
          <WrapContact>
            <p>{t("i_found_a_login_problem")}</p>
            <h2>{t("contact_us")}</h2>
          </WrapContact>
        </Footer>
      </MarginForm>
    </Content>
  </Container>
);

export default LoginForm;
