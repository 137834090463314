import React from "react";
import GoogleMap from "../../containers/GoogleMap";
import PrescriptionOrderContainer from "../../containers/PrescriptionOrder";

const DashboardMap = (props) => {
  const {
    bookings,
    history,
    communityPharmacy,
    location,
    handleClickLocation,
    isLocation,
    handleBackLocation,
    setBookingId,
    bookingId,
  } = props;
  return (
    <>
      <GoogleMap
        bookings={bookings}
        communityPharmacy={communityPharmacy}
        location={location}
        isLocation={isLocation}
        setBookingId={setBookingId}
        bookingId={bookingId}
      />
      <PrescriptionOrderContainer
        handleBackLocation={handleBackLocation}
        handleClickLocation={handleClickLocation}
        bookings={bookings}
        history={history}
        communityPharmacy={communityPharmacy}
      />
    </>
  );
};

export default DashboardMap;
