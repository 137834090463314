import * as type from '../types';

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case type.GET_DRUG_TIME_FREQUENCY_REQUESTED:
            return {
                loading: true,
                error: null,
            };
        case type.GET_DRUG_TIME_FREQUENCY_SUCCESS:
            return {
                data: action.data,
                loading: false,
            };
        case type.GET_DRUG_TIME_FREQUENCY_FAILED:
            return {
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
