import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/Medications";

async function getMedication() {
  const { data } = await http.get(`${apiEndpoint}`);
  return data;
}

async function getMedicationWithKeyword({ keyword, page }) {
  const filter = encodeURIComponent(
    `{"where": {"or": [{"activeIngredient": {"ilike": "${keyword}%", "operation": "i"}}, {"tradeName": {"ilike": "${keyword}%", "operation": "i"}}]} }`
  );
  const { data } = await http.get(
    `${apiEndpoint}?filter=${filter}&page=${page}`
  );
  return data;
}

export { getMedication, getMedicationWithKeyword };
