import { Card, Layout, Row, Steps, Button, Modal } from "antd";
import styled from "styled-components";

const { Sider, Content, Footer } = Layout;

export const Container = styled.div`
  width: 100%;
`;

export const SummaryContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

export const SummaryContent = styled.div`
  max-width: 1440px;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const LayoutContainer = styled(Layout)`
  min-height: 100vh;
  background-color: #263240;
  .str-chat-channel {
    max-height: 80vh !important;
  }
`;

export const LayoutContent = styled(Layout)`
  background-color: #202124;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const ContentRelative = styled(Content)`
  position: relative;
`;

export const ConversationContainer = styled.div`
  position: absolute;
  inset: 16px 16px 80px;
  margin: 0;
  display: flex;
  justify-content: center;
`;

export const ConversationContent = styled.div`
  position: relative;
  background: #3c4043;
  border-radius: 8;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OwnParticipant = styled.div`
  inset: auto 16px 16px auto;
  position: absolute;
`;

export const FooterContainer = styled(Footer)``;

export const CircleButtonNormal = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #000000;
  cursor: pointer;
`;

export const CircleButtonAlert = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #da0063;
  color: #ffffff;
  cursor: pointer;
`;

export const ContainerVideo = styled.div`
  display: flex;
  justify-content: flex-end;
  width: ${(prop) => (prop.width ? `${prop.width}px` : "100%")};
  height: ${(prop) => (prop.height ? `${prop.height}px` : "100%")};
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  transform: scale(-1, 1);
  border-radius: 8px;
  ${(props) => props.isObjectFit && "object-fit: cover"}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const WrapperName = styled.div`
  margin: ${(props) => (props.isLocal ? "0 12px" : "0 16px")};
`;

export const ContainerName = styled.div`
  align-items: center;
  bottom: -0.5px;
  display: flex;
  padding: ${(props) => (props.isLocal ? "8px 0" : "12px 0")};
  position: absolute;
  width: 100%;
`;

export const Name = styled.h2`
  font-size: 0.875rem;
`;

export const FooterContent = styled(Row)`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 80px;
  justify-content: space-between;
  align-items: center;
`;

export const CircleIcon = styled.div`
  cursor: pointer;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const SiderLayout = styled(Sider)`
  flex: ${(prop) => (prop.collapsed ? "0" : "0 0 360")}px !important;
  max-width: ${(prop) => (prop.collapsed ? "0" : "360")}px !important;
  min-width: ${(prop) => (prop.collapsed ? "0" : "360")}px !important;
  width: ${(prop) => (prop.collapsed ? "0" : "360")}px !important;
  border-radius: 8px;
  background-color: #fff;
  margin: ${(prop) =>
    prop.collapsed ? "16px 0px 80px 0px" : "16px 16px 80px 0px"};
  .ant-layout-sider-trigger {
    display: none;
  }
  .ant-layout-sider-children {
    padding: 16px;
  }
`;

export const SiderTelemonitoringLayout = styled(Sider)`
  flex: ${(prop) => (prop.collapsed ? "0" : "0 0 720")}px !important;
  max-width: ${(prop) => (prop.collapsed ? "0" : "720")}px !important;
  min-width: ${(prop) => (prop.collapsed ? "0" : "720")}px !important;
  width: ${(prop) => (prop.collapsed ? "0" : "720")}px !important;
  border-radius: 8px;
  background-color: #fff;
  margin: ${(prop) =>
    prop.collapsed ? "16px 0px 80px 0px" : "16px 16px 80px 0px"};
  .ant-layout-sider-trigger {
    display: none;
  }
  .ant-layout-sider-children {
    padding: 16px;
  }
  overflow: scroll;
`;

export const StepsCard = styled("div")`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #cecece;
  padding: 12px 10px;
  border-radius: 20px;
`;

export const StepsContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SummarySteps = styled(Steps)`
  max-width: 800px;
  line-height: 0px;
  .ant-steps-item-container {
    color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #b5b5b5;
    top: -10px;
    height: 2px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #b5b5b5;
    top: -10px;
    height: 2px;
  }
`;

export const SummaryCard = styled(Card)`
  border: none !important;
  border-radius: 20px !important;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  padding: 8px;
  .str-chat-channel {
    max-height: 500px !important;
  }
`;

export const NoteBox = styled("pre")`
 width: 100%
  height: 100%;
  word-break: break-all;
  white-space: pre-wrap;
`;

export const NoteIconBox = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.95),
      rgba(255, 255, 255, 0.95)
    ),
    #00bae5;
  border-radius: 100px;
`;
