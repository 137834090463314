import * as type from "../types";

const initialState = {
  loading: false,
  error: null,
  updateData: null,
  registeredCases: {
    data: null,
    loading: false,
    isSuccess: false,
    error: null,
  },
  todayCases: {
    data: null,
    loading: false,
    isSuccess: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.UPDATE_QUESTIONAIRE_BY_ID_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.UPDATE_QUESTIONAIRE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        updateData: action.data,
      };
    case type.UPDATE_QUESTIONAIRE_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case type.GET_ALL_REGISTERED_QUESTIONAIRE_REQUESTED:
      return {
        ...state,
        registeredCases: {
          ...state.registeredCases,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.GET_ALL_REGISTERED_QUESTIONAIRE_SUCCESS:
      return {
        ...state,
        registeredCases: {
          ...state.registeredCases,
          data: action.data,
          loading: false,
          isSuccess: true,
        },
      };
    case type.GET_ALL_REGISTERED_QUESTIONAIRE_FAILED:
      return {
        ...state,
        registeredCases: {
          ...state.registeredCases,
          loading: false,
          isSuccess: false,
          error: action.error,
        },
      };
    case type.GET_ALL_NEW_CASE_QUESTIONAIRE_REQUESTED:
      return {
        ...state,
        todayCases: {
          ...state.todayCases,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.GET_ALL_NEW_CASE_QUESTIONAIRE_SUCCESS:
      return {
        ...state,
        todayCases: {
          ...state.todayCases,
          data: action.data,
          loading: false,
          isSuccess: true,
        },
        loading: false,
      };
    case type.GET_ALL_NEW_CASE_QUESTIONAIRE_FAILED:
      return {
        ...state,
        todayCases: {
          ...state.todayCases,
          loading: false,
          isSuccess: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
