import styled from "styled-components";

export const ContentWrapper = styled.div`
  flex: 1 1;
  height: 100%;
`;

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex: 1 1;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 94%;
`;

export const Title = styled.div`
  color: #595959;
  font-size: 160px;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  text-shadow: 10px 6px 8px rgb(117 117 117 / 80%);
  margin-bottom: 1.5rem;
`;

export const Description = styled.h2`
  margin-bottom: 50px;
  text-align: center;
`;
