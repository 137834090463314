import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Layout, Menu } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu as Menus } from "../../redux/actions";
import "./style.css";

const Sidebar = ({ history }) => {
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const { data, current } = menu;

  return (
    <Layout>
      
      <Menu theme="light" mode="horizontal" selectedKeys={current.value}>
        {data.map((menu) => (
          <Menu.Item
            onClick={() => {
              dispatch(Menus.setMenu(menu));
              localStorage.setItem("menu", JSON.stringify(menu));
              menu.onClick();
            }}
            key={menu.value}
          >
            <LegacyIcon type={menu.icon} />
            <span>{menu.label}</span>
          </Menu.Item>
        ))}
      </Menu>
    </Layout>
  );
};

export default Sidebar;
