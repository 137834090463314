import { Container, Input, Text } from "../styles";

const CCPassTesting = ({ t, form, disabled }) => {
  const { handleChange = () => {}, values, errors = {} } = form;
  return (
    <Container className="position-relative">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th rowSpan={2}></th>
            <th className="text-center" colSpan={2} scope="col">
              {t("inhibition")}
            </th>
          </tr>
          <tr>
            <th className="text-center">{t("before")}</th>
            <th className="text-center">{t("after")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">{t("cpass_wuhan")}</th>
            <td>
              {disabled ? (
                <Text className="text-center">
                  {values.cPassWuhanBefore || t("nothing")}
                </Text>
              ) : (
                <Input
                  placeHolder={t("input_value")}
                  value={values.cPassWuhanBefore || ""}
                  onChange={handleChange("cPassWuhanBefore")}
                />
              )}
            </td>
            <td>
              {disabled ? (
                <Text className="text-center">
                  {values.cPassWuhanAfter || t("nothing")}
                </Text>
              ) : (
                <Input
                  placeHolder={t("input_value")}
                  value={values.cPassWuhanAfter || ""}
                  onChange={handleChange("cPassWuhanAfter")}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">{t("cpass_omicron")}</th>
            <td>
              {disabled ? (
                <Text className="text-center">
                  {values.cPassOmicronBefore || t("nothing")}
                </Text>
              ) : (
                <Input
                  placeHolder={t("input_value")}
                  value={values.cPassOmicronBefore || ""}
                  onChange={handleChange("cPassOmicronBefore")}
                />
              )}
            </td>
            <td>
              {disabled ? (
                <Text className="text-center">
                  {values.cPassOmicronAfter || t("nothing")}
                </Text>
              ) : (
                <Input
                  placeHolder={t("input_value")}
                  value={values.cPassOmicronAfter || ""}
                  onChange={handleChange("cPassOmicronAfter")}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">{t("cpass_delta")}</th>
            <td>
              {disabled ? (
                <Text className="text-center">
                  {values.cPassDeltaBefore || t("nothing")}
                </Text>
              ) : (
                <Input
                  placeHolder={t("input_value")}
                  value={values.cPassDeltaBefore || ""}
                  onChange={handleChange("cPassDeltaBefore")}
                />
              )}
            </td>
            <td>
              {disabled ? (
                <Text className="text-center">
                  {values.cPassDeltaAfter || t("nothing")}
                </Text>
              ) : (
                <Input
                  placeHolder={t("input_value")}
                  value={values.cPassDeltaAfter || ""}
                  onChange={handleChange("cPassDeltaAfter")}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {_.keys(errors).length > 0 && (
        <Text color="red">{t("input_number")}</Text>
      )}
    </Container>
  );
};

export default CCPassTesting;
