export const NOTIFICATION = (orgName) => {
  return {
    approve: (language = "en") => ({
      body: `Congratulations! Your account has been approved by ${orgName}`,
      title: "Alert",
      icon: "ic_notification",
      color: "#f1c40f",
      sound: "default",
    }),
    reject: (language = "en") => ({
      body: `Sorry, your account has been rejected by ${orgName}. Please check your information before re-applying again.`,
      title: "Alert",
      icon: "ic_notification",
      color: "#f1c40f",
      sound: "default",
    }),
  };
};

export const NOTIFICATION_DATA = {
  approve: {
    service: true,
    page: "Profile",
  },
  reject: {
    service: false,
    page: "Profile",
  },
};

export const STATUS = {
  pending: { title: "pending_approval", color: "red" },
  rejected: { title: "rejected", color: "red" },
  approved: { title: "approved", color: "green" },
};
