import { CheckCircleOutlined } from "@ant-design/icons";

export const SUMMARY_STEPS = [
  { title: "patient_is_waiting", icon: <CheckCircleOutlined /> },
  { title: "video_call", icon: <CheckCircleOutlined /> },
  { title: "confirm_order", icon: <CheckCircleOutlined /> },
  { title: "delivery", icon: <CheckCircleOutlined /> },
];
// ผู้ป่วยกำลังรอ
// วิดิโอคอล
// ยืนยันรายการ
// การจัดส่ง
