import React from "react";
import { Row, Space, Spin } from "antd";
import { Container, Text, Icon, Dragger, TextArea, TextError } from "../styles";
import { IoMdCloudUpload, AiFillFileText } from "react-icons/all";

const ConsentForm = (props) => {
  const { t, getNameFromUrl, customRequest, consentForm, currentRecord } =
    props;

  return (
    <form>
      <Container>
        <Space align="center">
          <Icon component={AiFillFileText} size="30px" color="#2BA796" />
          <Text primary className="ml-2" bold size="24px">
            {t("consent_form")}
          </Text>
        </Space>
        <Dragger
          defaultFileList={
            Array.isArray(consentForm.values.fileUrls) &&
            consentForm.values.fileUrls.length > 0
              ? consentForm.values.fileUrls.map((item, index) => {
                  return {
                    uid: index.toString(),
                    name: getNameFromUrl(item),
                    status: "done",
                    response: { url: item },
                    url: item,
                  };
                })
              : []
          }
          name="file"
          customRequest={(option) =>
            customRequest({
              ...option,
              key: currentRecord.researchId,
              pathFb: "consent",
            })
          }
          multiple={false}
          maxCount={10}
          onRemove={(info) => {
            if (
              Array.isArray(consentForm.values.fileUrls) &&
              consentForm.values.fileUrls.length > 0
            ) {
              const newList = consentForm.values.fileUrls.filter(
                (item) => item !== info.response?.url
              );
              consentForm.setFieldValue("fileUrls", newList);
            }
          }}
          onChange={(info) => {
            const { status } = info.file;
            if (status === "done") {
              const fileUrls = Array.isArray(consentForm.values.fileUrls)
                ? consentForm.values.fileUrls
                : [];
              consentForm.setFieldValue("fileUrls", [
                ...fileUrls,
                info.file.response?.url,
              ]);
            }
          }}
          className="mt-5 py-4"
        >
          <Row justify="center" align="middle">
            <Icon component={IoMdCloudUpload} size="24px" color="#2BA796" />
            <Text className="mx-2" primary>
              {t("drop_file_to_upload_or_click")}
            </Text>
          </Row>
        </Dragger>
        {consentForm.errors?.fileUrls && (
          <TextError>{consentForm.errors?.fileUrls}</TextError>
        )}
      </Container>
      <Container className="mt-5">
        <Text bold>{t("note")} *</Text>
        <TextArea
          value={consentForm.values.note}
          onChange={consentForm.handleChange("note")}
          placeholder="Write note..."
          className="mt-2"
        />
      </Container>
    </form>
  );
};

export default ConsentForm;
