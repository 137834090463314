import http from "../services/httpServiceHealthCare";

const getStreamTokenUrl = "https://us-central1-ever-oma.cloudfunctions.net";

export async function getGetStreamToken(id) {
  const { data } = await http.post(
    `${getStreamTokenUrl}/getStreamToken?userId=${id}`,
    {
      userId: id,
    }
  );
  return data.result;
}
