import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/UserInfos";

async function getUserOma() {
  const filter = "filter[order]=userId%20DESC";
  const { data } = await http.get(`${apiEndpoint}?${filter}`);
  return data;
}

async function getUserOmaByHie(id) {
  const filter = `checkUserDetailByVerifiedFromHIE?patientId=${id}`;
  const { data } = await http.get(`${apiEndpoint}/${filter}`);
  return data;
}

async function getUserOmaById(id) {
  const filter = `filter[where][userId]=${id}`;
  const { data } = await http.get(`${apiEndpoint}?${filter}`);
  return data;
}

async function updateUserOma({ id, detail }) {
  const { data } = await http.patch(`${apiEndpoint}/${id}`, detail);
  return data;
}

async function getUserOmaDiagnosis(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/getDiagnosisInfo`);
  return data;
}

export {
  getUserOma,
  getUserOmaByHie,
  updateUserOma,
  getUserOmaById,
  getUserOmaDiagnosis,
};
