import { View, Document, Page, Text, Image } from "@react-pdf/renderer";
import React from "react";
import { HEADER_DEFAULT_FORMS } from "../constant";
import CheckboxOutline from "../assets/CheckboxOutline";
import Checkbox from "../assets/Checkbox";
import ArrowUp from "../assets/ArrowUp";
import styles, { TableContainer, TitlePDFFile } from "../styles";
import moment from "moment";

export default function DailyAssessmentPreview({ t, data = {} }) {
  return (
    <Document>
      <Page size="A4">
        <Text style={styles.header}> {t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <TitlePDFFile t={t} />
          <View style={styles.titleHeader}>
            <Text style={styles.dateSignedText}>
              {t("subjects_initials")}: {data.subjectsInitials || "-"}
            </Text>
            <Text style={styles.dateSignedText}>
              {t("crf_subject_id")}: {data.subjectId}
            </Text>
            <Text style={styles.timeText}>
              {t("crf_date")}: {moment(data.createdAt).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}> {t("daily_assessment")}</Text>
          </View>
          <View style={styles.checkboxContainer}>
            {data?.notPerformed ? (
              <Checkbox width={14} height={14} />
            ) : (
              <CheckboxOutline width={14} height={14} />
            )}
            <Text style={styles.checkboxLabel}>{t("crf_da_sub_title")}</Text>
          </View>
          <TableContainer>
            <View style={styles.borderBottomTable}>
              <Text style={styles.textBold}>{t("crf_da_total_nasal")}</Text>
            </View>
            <RowCompnent
              t={t}
              data={data.TNSSData ?? {}}
              index={1}
              title="Morning"
            />
            <RowCompnent t={t} index={2} title="Afternoon" />
            <RowCompnent t={t} index={3} title="Evening" />
            <View style={{ paddingLeft: 24 }}>
              <Text style={styles.textDescription}>
                {t("crf_da_total_footer")}
              </Text>
            </View>
          </TableContainer>
          <View style={[styles.row, { marginTop: 20 }]}>
            <Text style={styles.textBold}>{t("crf_comment")}:</Text>
            <Text style={[styles.textDescription, { marginLeft: 3, flex: 1 }]}>
              {data.comment || "-"}
            </Text>
          </View>
        </View>
        <View style={[styles.paddingBody, styles.row]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/30/2022
          </Text>
        </View>
        <View style={[styles.paddingFooter, styles.textCenter]}>
          <Text style={styles.textDescription}>{t("crf_page")} 1/2</Text>
        </View>
      </Page>
      <Page size="A4">
        <Text style={styles.header}>{t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <TitlePDFFile t={t} />
          <View style={[styles.titleContainer, { marginBottom: 10 }]}>
            <Text style={styles.titleText}>{t("daily_assessment_con")}</Text>
          </View>
          <Text style={[styles.textBold, { marginBottom: 10 }]}>
            {t("crf_da_sino_nasal")}
          </Text>
          <TableContainer>
            <View style={[styles.row, { height: 120 }]}>
              <View
                style={[
                  styles.colItemSNOTT,
                  {
                    flex: 1,
                    justifyContent: "center",
                    paddingLeft: 16,
                  },
                ]}
              >
                <Text style={styles.textDescription}>
                  {`1. Considering how severe the problem is when you experience it and \nhow often it happens, please rate each item below on how “bad” it \nis by circlling the number that corresponds with how you feel using\nthis scale: ->`}
                </Text>
              </View>
              <View style={[styles.colItemSNOTT]}>
                <Text style={[styles.textVertical, styles.textDescription]}>
                  {t("crf_no_problem")}
                </Text>
              </View>
              <View style={styles.colItemSNOTT}>
                <Text style={[styles.textVertical, styles.textDescription]}>
                  {t("crf_very_mild_problem")}
                </Text>
              </View>
              <View style={styles.colItemSNOTT}>
                <Text style={[styles.textVertical, styles.textDescription]}>
                  {t("crf_mild_slightly_problem")}
                </Text>
              </View>
              <View style={styles.colItemSNOTT}>
                <Text style={[styles.textVertical, styles.textDescription]}>
                  {t("crf_moderate_problem")}
                </Text>
              </View>
              <View style={styles.colItemSNOTT}>
                <Text style={[styles.textVertical, styles.textDescription]}>
                  {t("crf_severe_problem")}
                </Text>
              </View>
              <View style={styles.colItemSNOTT}>
                <Text style={[styles.textVertical, styles.textDescription]}>
                  {t("crf_problem_bad_as_can")}
                </Text>
              </View>
              <View style={styles.colItemSNOTT}></View>
              <View style={[styles.colItemSNOTT]}>
                <Text style={[styles.textVertical, styles.textDescription]}>
                  {t("crf_five_most_important")}
                </Text>
              </View>
            </View>
            {Object.keys(data?.SNOTTData ?? {}).map((key, index) => {
              const colStyle =
                index === Object.keys(data?.SNOTTData ?? {}).length - 1
                  ? styles.colLastItemSNOTT
                  : styles.colItemSNOTT;
              return (
                <View key={key} style={[styles.row]}>
                  <View
                    style={[
                      colStyle,
                      {
                        flex: 1,
                        justifyContent: "center",
                        paddingLeft: 16,
                      },
                    ]}
                  >
                    <Text style={[styles.textDescription]}>
                      {index + 1}. {t(key)}
                    </Text>
                  </View>
                  <View style={[colStyle, { justifyContent: "center" }]}>
                    {data.SNOTTData[key].value === "1" && (
                      <View style={styles.circleText} />
                    )}
                    <Text style={[styles.textDescription, styles.textCenter]}>
                      0
                    </Text>
                  </View>
                  <View style={[colStyle, { justifyContent: "center" }]}>
                    {data.SNOTTData[key].value === "2" && (
                      <View style={styles.circleText} />
                    )}
                    <Text style={[styles.textDescription, styles.textCenter]}>
                      1
                    </Text>
                  </View>
                  <View style={[colStyle, { justifyContent: "center" }]}>
                    {data.SNOTTData[key].value === "3" && (
                      <View style={styles.circleText} />
                    )}
                    <Text style={[styles.textDescription, styles.textCenter]}>
                      2
                    </Text>
                  </View>
                  <View style={[colStyle, { justifyContent: "center" }]}>
                    {data.SNOTTData[key].value === "4" && (
                      <View style={styles.circleText} />
                    )}
                    <Text style={[styles.textDescription, styles.textCenter]}>
                      3
                    </Text>
                  </View>
                  <View style={[colStyle, { justifyContent: "center" }]}>
                    <Text style={[styles.textDescription, styles.textCenter]}>
                      4
                    </Text>
                  </View>
                  <View style={[colStyle, { justifyContent: "center" }]}>
                    {data.SNOTTData[key].value === "5" && (
                      <View style={styles.circleText} />
                    )}
                    <Text style={[styles.textDescription, styles.textCenter]}>
                      5
                    </Text>
                  </View>
                  <View style={colStyle}></View>
                  <View style={[colStyle, { alignItems: "center" }]}>
                    {Array.isArray(data.affectedhealthchoice?.value) &&
                    data.affectedhealthchoice?.value.includes(
                      key.replace("snot_", "")
                    ) ? (
                      <Checkbox width={10} height={10} />
                    ) : (
                      <CheckboxOutline width={10} height={10} />
                    )}
                  </View>
                </View>
              );
            })}
          </TableContainer>
          <View style={{ marginTop: 5, flexDirection: "row" }}>
            <Text style={[styles.textDescription, { flex: 1 }]}>
              {t("crf_mark_max_five_item")}:{" "}
              {Array.isArray(data.affectedhealthchoice?.value) &&
                data.affectedhealthchoice?.value.map((item, index) => {
                  return t(`snot_${item}`) + " ";
                })}
            </Text>
            <View style={{ marginRight: 6 }}>
              <ArrowUp width={14} height={14} />
            </View>
          </View>
        </View>
        <View style={[styles.paddingBody, styles.row]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/30/2022
          </Text>
        </View>
        <View style={[styles.paddingFooter, styles.textCenter]}>
          <Text style={styles.textDescription}>{t("crf_page")} 2/2</Text>
        </View>
      </Page>
    </Document>
  );
}

const RowCompnent = ({ t, title, index, data }) => {
  return (
    <View>
      <View
        style={[styles.borderBottomTable, index !== 1 && styles.borderTopTable]}
      >
        <Text style={[styles.textBold, styles.textCenter]}>{title}</Text>
      </View>
      <View style={styles.row}>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>Symptom</Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>None</Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>Mild</Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>Moderate</Text>
        </View>
        <View style={styles.colLastItem}>
          <Text style={styles.textBold}>Severe</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textDescription}>{t("tnss_runnynose")}</Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>
            {data?.tnss_runnynose?.value === "1" ? "0" : ""}
          </Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>
            {data?.tnss_runnynose?.value === "2" ? "1" : ""}
          </Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>
            {data?.tnss_runnynose?.value === "3" ? "2" : ""}
          </Text>
        </View>
        <View style={styles.colLastItem}>
          <Text style={styles.textBold}>
            {data?.tnss_runnynose?.value === "4" ? "3" : ""}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textDescription}>{t("tnss_stuffynose")}</Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>
            {data?.tnss_stuffynose?.value === "1" ? "0" : ""}
          </Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>
            {data?.tnss_stuffynose?.value === "2" ? "1" : ""}
          </Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>
            {data?.tnss_stuffynose?.value === "3" ? "2" : ""}
          </Text>
        </View>
        <View style={styles.colLastItem}>
          <Text style={styles.textBold}>
            {data?.tnss_stuffynose?.value === "4" ? "3" : ""}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textDescription}>{t("tnss_itchynose")}</Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>
            {data?.tnss_itchynose?.value === "1" ? "0" : ""}
          </Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>
            {data?.tnss_itchynose?.value === "2" ? "1" : ""}
          </Text>
        </View>
        <View style={styles.colItemTNSS}>
          <Text style={styles.textBold}>
            {data?.tnss_itchynose?.value === "3" ? "2" : ""}
          </Text>
        </View>
        <View style={styles.colLastItem}>
          <Text style={styles.textBold}>
            {data?.tnss_itchynose?.value === "4" ? "3" : ""}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View
          style={[index === 3 ? styles.colItemTNSS : styles.colItemLastRow]}
        >
          <Text style={styles.textDescription}>{t("tnss_sneeze")}</Text>
        </View>
        <View
          style={[index === 3 ? styles.colItemTNSS : styles.colItemLastRow]}
        >
          <Text style={styles.textBold}>
            {data?.tnss_sneeze?.value === "1" ? "0" : ""}
          </Text>
        </View>
        <View
          style={[index === 3 ? styles.colItemTNSS : styles.colItemLastRow]}
        >
          <Text style={styles.textBold}>
            {data?.tnss_sneeze?.value === "2" ? "1" : ""}
          </Text>
        </View>
        <View
          style={[index === 3 ? styles.colItemTNSS : styles.colItemLastRow]}
        >
          <Text style={styles.textBold}>
            {data?.tnss_sneeze?.value === "3" ? "2" : ""}
          </Text>
        </View>
        <View
          style={[index === 3 ? styles.colLastItem : styles.colLastItemLastRow]}
        >
          <Text style={styles.textBold}>
            {data?.tnss_sneeze?.value === "4" ? "3" : ""}
          </Text>
        </View>
      </View>
    </View>
  );
};
