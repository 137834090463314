import React, { useEffect } from "react";
import {
  Container,
  ModalCustom,
  Text,
  Image,
  Input,
  HeaderContainer,
  DatePicker,
  TextVetical,
  Radio,
  DropDownWidget,
  ButtonRounded,
  Icon,
  Button,
  TextError,
} from "./style";
import {
  Row,
  Col,
  Space,
  Button as ButtonAnt,
  message,
  Spin,
  Tooltip,
} from "antd";
import PropTypes from "prop-types";
import EverLogo from "../../../images/EverLogo.png";
import { CATEGORY, DISPLAY_CATEGORY } from "./constant";
import { AiFillPlusCircle, FaCheck, FaTimes, MdEdit } from "react-icons/all";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function CtmsWithdrawnFormModal(props) {
  const {
    t,
    visible,
    setVisible,
    clinicTrial,
    researchId,
    subjectId,
    onWitdrawnSubmitHandler,
    onSubmitSeperateSectionHandler,
    onDeleteDevationsHandler,
  } = props;

  const withdrawnForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      subjectDeviationTitle: "",
      subjectDeviationInitials: "",
      subjectDeviationfilledFormDate: null,
      piName: "",
      irbNumber: "",
      ptcShortTitle: "",
      data: [],
    },
    validationSchema: Yup.object().shape({
      piName: Yup.string().required("กรุณากรอกชื่อ PI"),
    }),
    onSubmit: (values) => {
      const payload = {
        subjectDeviationInitials: values.subjectDeviationInitials,
        subjectDeviationfilledFormDate:
          values.subjectDeviationfilledFormDate ?? new Date().toISOString(),
        subjectDeviationTitle:
          values.piName + "," + values.irbNumber + "," + values.ptcShortTitle,
      };
      onWitdrawnSubmitHandler(payload);
    },
  });

  const checkCanAddmore = withdrawnForm.values.data[
    withdrawnForm.values.data.length - 1
  ]?.id
    ? false
    : true;

  useEffect(() => {
    withdrawnForm.setFieldValue(
      "data",
      Array.isArray(clinicTrial.deviationData.data) &&
        clinicTrial.deviationData.data.length > 0
        ? clinicTrial.deviationData.data.map((item) => {
            return {
              ...item,
              editable: false,
            };
          })
        : [
            {
              sequence: 1,
              id: "",
              startDate: null,
              endDate: null,
              description: "",
              category: "",
              otherCategoryReason: "",
              impactSubjectSafety: "",
              affectDataIntegrity: "",
              affectSubjectWillingness: "",
              reported: "",
              reportedDate: null,
              piInitials: "",
              piInitialsDate: null,
              editable: true,
            },
          ]
    );
  }, [clinicTrial.deviationData.data]);

  useEffect(() => {
    if (clinicTrial.deviationData.error) {
      message.error(t("error_occurred"));
    }
    if (withdrawnForm.errors.piName) {
      message.error(withdrawnForm.errors.piName, 5);
    }
  }, [clinicTrial.deviationData.error, withdrawnForm.errors.piName]);

  const seperateSectionSubmit = (index) => {
    if (researchId) {
      const submitObject = withdrawnForm.values.data[index];
      const payload = {
        sequence: submitObject.sequence,
        startDate: submitObject.startDate,
        endDate: submitObject.endDate,
        description: submitObject.description,
        category: submitObject.category,
        otherCategoryReason: submitObject.otherCategoryReason,
        impactSubjectSafety: submitObject.impactSubjectSafety,
        affectDataIntegrity: submitObject.affectDataIntegrity,
        affectSubjectWillingness: submitObject.affectSubjectWillingness,
        reported: submitObject.reported,
        reportedDate: submitObject.reportedDate,
        piInitials: submitObject.piInitials,
        piInitialsDate: submitObject.piInitialsDate,
      };
      onSubmitSeperateSectionHandler(submitObject.id, payload);
    }
  };

  const deleteSectionById = (index) => {
    const payload = withdrawnForm.values.data[index];
    if (payload.id && researchId) {
      onDeleteDevationsHandler(payload.id);
    }
  };

  const handleChangeValue = (key, index, value) => {
    const dataArray = withdrawnForm.values.data;
    if (dataArray[index]) {
      dataArray[index][key] = value;
    }
    withdrawnForm.setFieldValue("data", dataArray);
  };

  const onAddmoreSubject = () => {
    const sequence =
      withdrawnForm.values.data.length === 0
        ? 1
        : withdrawnForm.values.data[withdrawnForm.values.data.length - 1]
            .sequence + 1;
    withdrawnForm.setFieldValue("data", [
      ...withdrawnForm.values.data,
      {
        sequence,
        id: "",
        startDate: null,
        endDate: null,
        description: "",
        category: "",
        otherCategoryReason: "",
        impactSubjectSafety: "",
        affectDataIntegrity: "",
        affectSubjectWillingness: "",
        reported: "",
        reportedDate: null,
        piInitials: "",
        piInitialsDate: null,
        editable: true,
      },
    ]);
  };

  const onDeleteSubject = (index) => {
    withdrawnForm.setFieldValue(
      "data",
      withdrawnForm.values.data.filter((_, i) => i !== index)
    );
  };

  const renderNormalValue = (key, index, items) => {
    return (
      <Container
        style={{ backgroundColor: "white" }}
        className="shadow px-2 py-1"
      >
        {items.map((item) => {
          return (
            <a
              key={item.key}
              onClick={(e) => {
                e.preventDefault();
                handleChangeValue(key, index, item.key);
              }}
            >
              <Text className="py-1 my-1" key={item.key}>
                {t(item.label)}
              </Text>
            </a>
          );
        })}
      </Container>
    );
  };

  return (
    <ModalCustom
      className="pb-5"
      title={
        <Container>
          <Text className="text-uppercase" color="grey" size="14px">
            <b>{t("withdrawn_edit")}</b> {t("withdrawn_title")}
          </Text>
        </Container>
      }
      width={1300}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={[
        <Button onClick={(_) => setVisible(false)} type="default">
          <Text bold color="#2ba796">
            {t("cancel")}
          </Text>
        </Button>,
        <Button onClick={(_) => withdrawnForm.handleSubmit()} secondary="true">
          <Text bold color="white">
            {t("submit")}
          </Text>
        </Button>,
      ]}
    >
      <Spin spinning={clinicTrial.deviationData.loading}>
        <Text className="text-center" size="16px">
          {t("withdrawn_before_title")} <b>{t("withdrawn_title")}</b>
        </Text>
        <Container className="px-5 mt-3 rounded border">
          <Image className="mt-4" src={EverLogo} />
          <Row gutter={[16, 16]} className="my-4" align="middle">
            <Col span={8}>
              <Space align="center" direction="horizontal">
                <Text color="grey">{t("pi_name")}:</Text>
                <div>
                  <Input
                    value={withdrawnForm.values.piName}
                    padding="2px"
                    onChange={withdrawnForm.handleChange("piName")}
                  />
                  {withdrawnForm.errors.piName && (
                    <TextError>{withdrawnForm.errors.piName}</TextError>
                  )}
                </div>
              </Space>
            </Col>
            <Col span={8}>
              <Space align="center" direction="horizontal">
                <Text color="grey">{t("irb_number")}:</Text>
                <Input
                  value={withdrawnForm.values.irbNumber}
                  padding="2px"
                  onChange={withdrawnForm.handleChange("irbNumber")}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space align="center" direction="horizontal">
                <Text color="grey">{t("protocol_short_title")}:</Text>
                <Input
                  value={withdrawnForm.values.ptcShortTitle}
                  padding="2px"
                  onChange={withdrawnForm.handleChange("ptcShortTitle")}
                />
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="my-3" align="middle">
            <Col span={8}>
              <Space align="center" direction="horizontal">
                <Text color="grey">{t("subjects_initials")}:</Text>
                <Input
                  value={withdrawnForm.values.subjectDeviationInitials}
                  padding="2px"
                  onChange={withdrawnForm.handleChange(
                    "subjectDeviationInitials"
                  )}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space align="center" direction="horizontal">
                <Text color="grey">{t("subject_id")}:</Text>
                <Text bold>{subjectId}</Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space align="center" direction="horizontal">
                <Text color="grey">{t("date")}:</Text>
                <DatePicker
                  value={
                    withdrawnForm.values.subjectDeviationfilledFormDate
                      ? moment(
                          withdrawnForm.values.subjectDeviationfilledFormDate
                        )
                      : null
                  }
                  format="DD/MM/YYYY"
                  defaultValue={
                    withdrawnForm.values.subjectDeviationfilledFormDate
                      ? moment(
                          withdrawnForm.values.subjectDeviationfilledFormDate
                        )
                      : null
                  }
                  padding="2px"
                  width="130px"
                  onChange={(date) =>
                    withdrawnForm.setFieldValue(
                      "subjectDeviationfilledFormDate",
                      moment(date).toISOString()
                    )
                  }
                />
              </Space>
            </Col>
          </Row>
          <HeaderContainer className="my-4">
            <Text className="my-2 text-center" bold size="15px">
              {t("withdrawn_title")}
            </Text>
          </HeaderContainer>
          <Container className="my-3" style={{ overflowX: "scroll" }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    rowSpan={2}
                    style={{ width: 40 }}
                    className="text-center align-middle"
                  >
                    #
                  </th>
                  <th rowSpan={2} className="align-middle text-center">
                    <Text size="12px">{t("crf_start_date")}</Text>
                  </th>
                  <th rowSpan={2} className="align-middle text-center">
                    <Text size="12px">{t("crf_end_date")}</Text>
                  </th>
                  <th rowSpan={2} className="align-middle text-center">
                    <Text size="12px">{t("description")}</Text>
                  </th>
                  <th rowSpan={2} className="align-middle text-center">
                    <Text size="12px">{t("category")}</Text>
                    <Text size="10px">({t("see_below")})</Text>
                  </th>
                  <th colSpan={3} className="align-middle text-center">
                    <Text size="12px" bold>
                      {t("does_the_deviation")}
                    </Text>
                  </th>
                  <th rowSpan={2} className="align-middle text-center">
                    <Text size="12px">{t("report_to_irb")}</Text>
                  </th>
                  <th rowSpan={2} className="align-middle text-center">
                    <TextVetical size="12px">{t("pi_initials")}</TextVetical>
                  </th>
                  <th rowSpan={2} className="align-middle text-center">
                    <TextVetical size="12px">
                      {t("date_pi_initials")}
                    </TextVetical>
                  </th>
                  <th rowSpan={2} className="align-middle text-center">
                    <Text size="12px">{t("action")}</Text>
                  </th>
                </tr>
                <tr>
                  <th
                    style={{ whiteSpace: "pre-line" }}
                    className="align-middle text-center"
                  >
                    <Text size="10px">{t("impact_subject")}</Text>
                  </th>
                  <th
                    style={{ whiteSpace: "pre-line" }}
                    className="align-middle text-center"
                  >
                    <Text size="10px">{t("affect_data")}</Text>
                  </th>
                  <th
                    style={{ whiteSpace: "pre-line" }}
                    className="align-middle text-center"
                  >
                    <Text size="10px">{t("affect_subject")}</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {withdrawnForm.values.data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th className="text-center">{index + 1}</th>
                      <td>
                        <DatePicker
                          disabled={!item.editable}
                          value={item.startDate ? moment(item.startDate) : null}
                          format="DD/MM/YY"
                          defaultValue={
                            item.startDate ? moment(item.startDate) : null
                          }
                          padding="2px"
                          width="100px"
                          onChange={(date) =>
                            handleChangeValue(
                              "startDate",
                              index,
                              moment(date).toISOString()
                            )
                          }
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!item.editable}
                          value={item.endDate ? moment(item.endDate) : null}
                          format="DD/MM/YY"
                          defaultValue={
                            item.endDate ? moment(item.endDate) : null
                          }
                          padding="2px"
                          width="100px"
                          onChange={(date) =>
                            handleChangeValue(
                              "endDate",
                              index,
                              moment(date).toISOString()
                            )
                          }
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!item.editable}
                          value={item.description}
                          padding="2px"
                          onChange={(e) =>
                            handleChangeValue(
                              "description",
                              index,
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <Space direction="vertical">
                          <DropDownWidget
                            disabled={!item.editable}
                            width="50px"
                            value={DISPLAY_CATEGORY[item.category] ?? ""}
                            renderList={renderNormalValue(
                              "category",
                              index,
                              CATEGORY
                            )}
                          />
                          {item.category === "Other" && (
                            <Input
                              disabled={!item.editable}
                              value={item.otherCategoryReason}
                              padding="2px"
                              onChange={(e) =>
                                handleChangeValue(
                                  "otherCategoryReason",
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        </Space>
                      </td>
                      <td>
                        <Radio.Group
                          disabled={!item.editable}
                          onChange={(e) =>
                            handleChangeValue(
                              "impactSubjectSafety",
                              index,
                              e.target.value
                            )
                          }
                          value={item.impactSubjectSafety}
                        >
                          <Space className="mt-1" direction="vertical" size={0}>
                            <Radio value="yes">{t("radio_yes")}</Radio>
                            <Radio className="mt-1" value="no">
                              {t("radio_no")}
                            </Radio>
                            <Radio className="mt-1" value="not_applicable">
                              {t("not_applicable")}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </td>
                      <td>
                        <Radio.Group
                          disabled={!item.editable}
                          onChange={(e) =>
                            handleChangeValue(
                              "affectDataIntegrity",
                              index,
                              e.target.value
                            )
                          }
                          value={item.affectDataIntegrity}
                        >
                          <Space className="mt-1" direction="vertical" size={0}>
                            <Radio value="yes">{t("radio_yes")}</Radio>
                            <Radio className="mt-1" value="no">
                              {t("radio_no")}
                            </Radio>
                            <Radio className="mt-1" value="not_applicable">
                              {t("not_applicable")}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </td>
                      <td>
                        <Radio.Group
                          disabled={!item.editable}
                          onChange={(e) =>
                            handleChangeValue(
                              "affectSubjectWillingness",
                              index,
                              e.target.value
                            )
                          }
                          value={item.affectSubjectWillingness}
                        >
                          <Space className="mt-1" direction="vertical" size={0}>
                            <Radio value="yes">{t("radio_yes")}</Radio>
                            <Radio className="mt-1" value="no">
                              {t("radio_no")}
                            </Radio>
                            <Radio className="mt-1" value="not_applicable">
                              {t("not_applicable")}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </td>
                      <td>
                        <Radio.Group
                          disabled={!item.editable}
                          value={item.reported}
                        >
                          <Radio
                            className="mb-2"
                            onClick={(e) => {
                              handleChangeValue(
                                "reported",
                                index,
                                item.reported !== "not_applicable"
                                  ? "not_applicable"
                                  : ""
                              );
                            }}
                            value="not_applicable"
                          >
                            {t("not_applicable")}
                          </Radio>
                        </Radio.Group>
                        <DatePicker
                          disabled={!item.editable || item.reported}
                          value={
                            item.reportedDate ? moment(item.reportedDate) : null
                          }
                          format="DD/MM/YY"
                          defaultValue={
                            item.reportedDate ? moment(item.reportedDate) : null
                          }
                          padding="2px"
                          width="100px"
                          onChange={(date) => {
                            handleChangeValue(
                              "reportedDate",
                              index,
                              moment(date).toISOString()
                            );
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          minWidth="50px"
                          disabled={!item.editable}
                          value={item.piInitials}
                          onChange={(e) =>
                            handleChangeValue(
                              "piInitials",
                              index,
                              e.target.value
                            )
                          }
                          padding="2px"
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!item.editable}
                          value={
                            item.piInitialsDate
                              ? moment(item.piInitialsDate)
                              : null
                          }
                          format="DD/MM/YY"
                          defaultValue={
                            item.piInitialsDate
                              ? moment(item.piInitialsDate)
                              : null
                          }
                          padding="2px"
                          width="100px"
                          onChange={(date) =>
                            handleChangeValue(
                              "piInitialsDate",
                              index,
                              moment(date).toISOString()
                            )
                          }
                        />
                      </td>
                      <td className="text-center">
                        {item.editable ? (
                          <Space direction="vertical">
                            <Tooltip title={t("save")}>
                              <ButtonAnt
                                onClick={() => seperateSectionSubmit(index)}
                                type="primary"
                                shape="circle"
                                icon={<FaCheck />}
                                size="small"
                              />
                            </Tooltip>
                            <Tooltip title={t("delete")}>
                              <ButtonAnt
                                onClick={() => {
                                  deleteSectionById(index);
                                  onDeleteSubject(index);
                                }}
                                danger
                                type="primary"
                                shape="circle"
                                icon={<FaTimes />}
                                size="small"
                              />
                            </Tooltip>
                          </Space>
                        ) : (
                          <Tooltip title={t("edit")}>
                            <ButtonAnt
                              onClick={() =>
                                handleChangeValue("editable", index, true)
                              }
                              type="default"
                              shape="circle"
                              icon={<MdEdit />}
                              size="small"
                            />
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Container>
          <Container className="mb-3">
            <Tooltip title={checkCanAddmore ? <>{t("please_submit")}</> : ""}>
              <ButtonRounded
                disabled={checkCanAddmore}
                onClick={onAddmoreSubject}
              >
                <Space className="py-1" direction="horizontal" align="center">
                  <Icon
                    component={AiFillPlusCircle}
                    size="24px"
                    color="#2BA796"
                  />
                  <Text>{t("add_more")}</Text>
                </Space>
              </ButtonRounded>
            </Tooltip>
          </Container>
          <Container className="mb-3">
            <Text className="mb-5">{t("withdrawn_footter")}</Text>
            <Space direction="vertical" size={4}>
              <Text bold>{t("category")}:</Text>
              <Text>{t("cateogory_clinic_1")}</Text>
              <Text>{t("cateogory_clinic_2")}</Text>
              <Text>{t("cateogory_clinic_3")}</Text>
              <Text>{t("cateogory_clinic_4")}</Text>
              <Text>{t("cateogory_clinic_5")}</Text>
              <Text>{t("cateogory_clinic_6")}</Text>
            </Space>
          </Container>
        </Container>
      </Spin>
    </ModalCustom>
  );
}

CtmsWithdrawnFormModal.propTypes = {
  t: PropTypes.any,
  clinicTrial: PropTypes.object,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  researchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subjectId: PropTypes.any,
  onWitdrawnSubmitHandler: PropTypes.func,
  onSubmitSeperateSectionHandler: PropTypes.func,
  onDeleteDevationsHandler: PropTypes.func,
};
