export const FILTER_STATUS = {
  doctor: "DOCTOR_PENDING",
  pharmacy: "PHARMACY_PENDING",
  nurse: "NURSE_PENDING",
};

export const ROOM_TYPES = {
  scheduling: { type: "SCHEDULING" },
  covid: { type: "COVID" },
  general: { type: "GENERAL" },
};
