export const ROOMS = {
  DOCTOR: [
    {
      name: "COVID",
      path: "/round-robin/covid",
    },
    {
      name: "GENERAL",
      path: "/round-robin/general",
    },
  ],
  NURSE: [
    {
      name: "COVID",
      path: "/round-robin/covid",
    },
    {
      name: "GENERAL",
      path: "/round-robin/general",
    },
  ],
};
