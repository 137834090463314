export const CATEGORY = [
  {
    label: "cateogory_clinic_1",
    key: "contentDeviation",
  },
  {
    label: "cateogory_clinic_2",
    key: "drugAdmin",
  },
  {
    label: "cateogory_clinic_3",
    key: "enrollmentDeviation",
  },
  {
    label: "cateogory_clinic_4",
    key: "proceduralDeviation",
  },
  {
    label: "cateogory_clinic_5",
    key: "lossConfidentiality",
  },
  {
    label: "cateogory_clinic_6",
    key: "Other",
  },
];

export const DISPLAY_CATEGORY = {
  contentDeviation: "1.",
  drugAdmin: "2.",
  enrollmentDeviation: "3.",
  proceduralDeviation: "4.",
  lossConfidentiality: "5.",
  Other: "6.",
};
