import { VideoCameraOutlined } from "@ant-design/icons";
import { CircleButtonAlert, CircleButtonNormal } from "./styles";
import React from "react";
const VideoButton = (props) => {
  const { isDisabled, handleVideoButton } = props;
  const Icon = VideoCameraOutlined;
  const Button = isDisabled ? CircleButtonAlert : CircleButtonNormal;
  return (
    <Button onClick={() => handleVideoButton(!isDisabled)}>
      <Icon />
    </Button>
  );
};

export default VideoButton;
