import React, { useEffect, useState } from "react";
import Layout from "../Home";
import HistoryBooking from "../../components/HistoryBooking";
import { useDispatch, useSelector } from "react-redux";
import { Booking } from "../../redux/actions";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";

const HistoryBookingContainer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appUserOma = useSelector((state) => state.appUserOma);
  const booking = useSelector((state) => state.booking);

  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);
  const [visibleNote, setVisibleNote] = useState(false);
  const [note, setNote] = useState("");
  const [filterData, setFilterData] = useState(false);

  useEffect(() => {
    if (
      appUserOma.information !== null &&
      booking.history.isSuccess === false
    ) {
      dispatch(Booking.getBookingHistory(appUserOma.information.id));
    }
  }, [appUserOma]);

  useEffect(() => {
    if (booking.history.isSuccess) {
      setIsFinishInitialLoad(true);
    }
  }, [booking]);

  const checkAddress = (address, addressTypeId) => {
    if (!address) return "-";
    const addressInfo = address.find(
      (item) => item.addressTypeId == addressTypeId
    );
    return addressInfo;
  };

  const onSearch = (text) => {
    if (text && text.length) {
      const filter = booking.history.data.filter((data) => {
        if (data.patient && data.patient.userInformation) {
          const { cId, firstname, lastname } = data.patient.userInformation;
          const fullname = firstname && lastname && `${firstname} ${lastname}`;
          const filterData =
            (cId && cId.includes(text)) ||
            (firstname && firstname.includes(text)) ||
            (lastname && lastname.includes(text)) ||
            (fullname && fullname.includes(text));
          return filterData;
        }
      });
      setFilterData(filter);
    } else {
      setFilterData(false);
    }
  };

  return (
    <Layout
      {...props}
      render={(props) =>
        isFinishInitialLoad ? (
          <HistoryBooking
            filterData={filterData}
            checkAddress={checkAddress}
            history={booking.history}
            setVisibleNote={setVisibleNote}
            visibleNote={visibleNote}
            setNote={setNote}
            note={note}
            onSearch={onSearch}
            t={t}
          />
        ) : (
          <Loading />
        )
      }
    />
  );
};

export default HistoryBookingContainer;
