import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, ProfileImageCard, WrapContent } from "./styles";
import { Input, InputNumber, Form, Space, Select } from "antd";
import { AppUserOma, PractitionerSpecialties } from "../../../redux/actions";
import Button from "../../Button";
import firebase from "firebase";
import "./Practitioner.css";
const { TextArea } = Input;

const Practitioner = ({ practitioner, t }) => {
  const {
    id,
    photos,
    bio,
    title,
    firstName,
    lastName,
    yearsOfExperience,
    educationalBackground,
    professionalBackground,
    expertAt,
    specialty,
    openingHours,
    graduatedFrom,
    hospitalName,
  } = practitioner;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [editable, setEditable] = useState(false);
  const appUserOma = useSelector((state) => state.appUserOma);
  const specialties = useSelector((state) => state.practitionerSpecialties);
  const roleId = appUserOma.information.roles[0].id;
  const practitionerSpecialties =
    appUserOma.practitioner.practitionerSpecialties;
  const appUserId = appUserOma.practitioner.appUserId;
  const practitionerId = appUserOma.practitioner.id;
  const listOfFileType = "image/png, image/jpeg";

  useEffect(() => {
    if (!specialties.data) {
      dispatch(PractitionerSpecialties.getPractitionerSpecialties(roleId));
    }
  }, []);

  const updateData = (values) => {
    const {
      bio,
      title,
      firstName,
      lastName,
      experience,
      practitionerSpecialties,
      educational,
      professional,
      expert,
      specialty,
      openingHours,
      graduatedFrom,
      hospitalName,
    } = values;
    let data = {
      bio: bio,
      title: title,
      firstName: firstName,
      lastName: lastName,
      yearsOfExperience: experience,
      educationalBackground: educational,
      professionalBackground: professional,
      expertAt: expert,
      specialty: specialty,
      openingHours: openingHours,
      graduatedFrom,
      hospitalName,
    };
    let specialties = { practitionerSpecialtyIds: practitionerSpecialties };
    setEditable(false);
    dispatch(AppUserOma.updateProfile(id, data));
    dispatch(AppUserOma.updateSpecialties(id, specialties));
  };

  const onReset = () => {
    setEditable(false);
    form.resetFields();
  };

  const mapSpecialities = (practitionerSpecialties) => {
    let mapData = [];
    practitionerSpecialties.map((data) => mapData.push(data.id));
    return mapData;
  };

  const onUpload = async (e) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 2097152) {
        message.error("เกิดข้อผิดพลาด กรุณาอัปโหลดรูปภาพที่มีขนาดไม่เกิน 2MB");
      } else {
        const fileUpload = e.target.files[0];
        const fileName = fileUpload.name;
        const storageRef = firebase
          .storage()
          .ref(`profile-image/userId${appUserId}/${fileName}`);
        const task = storageRef.put(fileUpload);
        task.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            alert.error(error.message);
          },
          async () => {
            const image = await storageRef.getDownloadURL();
            setEditable(false);
            {
              photos.length === 0
                ? dispatch(
                    AppUserOma.addPhoto(id, {
                      category: "avatar",
                      imageUrl: image,
                    })
                  )
                : dispatch(
                    AppUserOma.updatePhoto(
                      id,
                      {
                        category: "avatar",
                        imageUrl: image,
                      },
                      photos[0].id
                    )
                  );
            }
            setTimeout(() => {
              dispatch(AppUserOma.getProfile(appUserOma.information.id));
            }, 500);
          }
        );
      }
    }
  };

  return (
    <Container
      extra={
        <Button onClick={() => setEditable(true)}>
          <EditOutlined />
        </Button>
      }
    >
      <Form
        form={form}
        name="control-hooks"
        initialValues={{
          bio: bio,
          title: title,
          firstName: firstName,
          lastName: lastName,
          experience: yearsOfExperience,
          practitionerSpecialties: mapSpecialities(practitionerSpecialties),
          openingHours: openingHours,
          specialty: specialty,
          expert: expertAt,
          educational: educationalBackground,
          professional: professionalBackground,
          graduatedFrom: graduatedFrom,
          hospitalName: hospitalName,
        }}
        onFinish={updateData}
      >
        <Row>
          <Col span={5}>
            <WrapContent>
              <h2>{t("photo")}</h2>

              {editable ? (
                <div>
                  <label htmlFor="upload-button">
                    <div className="head-image">
                      <ProfileImageCard
                        src={photos.length > 0 && photos[0].imageUrl}
                        style={{ opacity: 0.3 }}
                      />
                    </div>
                    <div className="text-on-image">
                      <UploadOutlined
                        style={{
                          fontSize: "24px",
                          color: "#12A4D3",
                          paddingLeft: 30,
                        }}
                      />
                    </div>
                  </label>
                  <input
                    type="file"
                    accept={listOfFileType}
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={(e) => onUpload(e)}
                  />
                </div>
              ) : (
                <ProfileImageCard
                  src={photos.length > 0 && photos[0].imageUrl}
                />
              )}
            </WrapContent>
          </Col>
          <Col span={19}>
            <WrapContent>
              <h2>{t("about_me")}</h2>
              {editable ? (
                <Form.Item name="bio">
                  <TextArea />
                </Form.Item>
              ) : (
                <p>{bio}</p>
              )}
            </WrapContent>
          </Col>
          <Col span={3}>
            <WrapContent>
              <h2>{t("title_position")}</h2>
              {editable ? (
                <Form.Item name="title">
                  <Input />
                </Form.Item>
              ) : (
                <p>{title}</p>
              )}
            </WrapContent>
          </Col>
          <Col span={6}>
            <WrapContent>
              <h2>{t("first_name")}</h2>
              {editable ? (
                <Form.Item name="firstName">
                  <Input />
                </Form.Item>
              ) : (
                <p>{firstName}</p>
              )}
            </WrapContent>
          </Col>
          <Col span={6}>
            <WrapContent>
              <h2>{t("last_name")}</h2>
              {editable ? (
                <Form.Item name="lastName">
                  <Input />
                </Form.Item>
              ) : (
                <p>{lastName}</p>
              )}
            </WrapContent>
          </Col>

          <Col span={9}>
            <WrapContent>
              <h2>{t("practitioner_specialties")}</h2>
              {editable ? (
                <Form.List name="practitionerSpecialties">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex" }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={name}
                            fieldKey={fieldKey}
                          >
                            <Select
                              style={{ width: 200 }}
                              placeholder="เลือก แผนก"
                            >
                              {specialties.data &&
                                specialties.data.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {t("add_field")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ) : (
                practitionerSpecialties.map((text, index) => (
                  <p key={index}>{text.name}</p>
                ))
              )}
            </WrapContent>
          </Col>
          <Col span={12}>
            <WrapContent>
              <h2>{t("experience")}</h2>
              {editable ? (
                <Form.Item name="experience">
                  <InputNumber min={0} max={50} />
                </Form.Item>
              ) : (
                <p>{yearsOfExperience}</p>
              )}
            </WrapContent>
          </Col>
          <Col span={12}>
            <WrapContent>
              <h2>{t("opening_hours")}</h2>
              {editable ? (
                <Form.List name="openingHours">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex" }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={name}
                            fieldKey={fieldKey}
                          >
                            <Input />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {t("add_field")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ) : (
                openingHours &&
                openingHours.map((text, index) => <p key={index}>{text}</p>)
              )}
            </WrapContent>
          </Col>
          <Col span={12}>
            <WrapContent>
              <h2>{t("specialty")}</h2>
              {editable ? (
                <Form.List name="specialty">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex" }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={name}
                            fieldKey={fieldKey}
                          >
                            <Input />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {t("add_field")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ) : (
                specialty &&
                specialty.length &&
                specialty.map((text, index) => <p key={index}>{text}</p>)
              )}
            </WrapContent>
          </Col>
          <Col span={12}>
            <WrapContent>
              <h2>{t("expert")}</h2>
              {editable ? (
                <Form.Item name="expert">
                  <Input />
                </Form.Item>
              ) : (
                <p>{expertAt}</p>
              )}
            </WrapContent>
          </Col>
          <Col span={24}>
            <WrapContent>
              <h2>{t("graduated_from")}</h2>
              {editable ? (
                <Form.Item name="graduatedFrom">
                  <Input />
                </Form.Item>
              ) : (
                <p>{graduatedFrom}</p>
              )}
            </WrapContent>
          </Col>
          <Col span={24}>
            <WrapContent>
              <h2>{t("hospital_name")}</h2>
              {editable ? (
                <Form.Item name="hospitalName">
                  <Input />
                </Form.Item>
              ) : (
                <p>{hospitalName}</p>
              )}
            </WrapContent>
          </Col>
          <Col span={24}>
            <WrapContent>
              <h2>{t("educational")}</h2>
              {editable ? (
                <Form.List name="educational">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex" }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={name}
                            fieldKey={fieldKey}
                          >
                            <Input style={{ width: "40vw" }} />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {t("add_field")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ) : (
                educationalBackground &&
                educationalBackground.length &&
                educationalBackground.map((text, index) => (
                  <p key={index}>{text}</p>
                ))
              )}
            </WrapContent>
          </Col>
          <Col span={24}>
            <WrapContent>
              <h2>{t("work_history")}</h2>
              {editable ? (
                <Form.List name="professional">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex" }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={name}
                            fieldKey={fieldKey}
                          >
                            <Input style={{ width: "40vw" }} />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {t("add_field")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ) : (
                professionalBackground &&
                professionalBackground.length &&
                professionalBackground.map((text, index) => (
                  <p key={index}>{text}</p>
                ))
              )}
            </WrapContent>
          </Col>
          {editable && (
            <Col style={{ marginLeft: "auto" }}>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
              <Button type="primary" danger onClick={() => onReset()}>
                {t("cancel")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Container>
  );
};

export default Practitioner;
