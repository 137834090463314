import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import LoginForm from "../../components/LoginForm";
import { AppUserOma } from "../../redux/actions";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const appUserOma = useSelector((state) => state.appUserOma);
  const user = useSelector((state) => state.appUserOma);

  const { location } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [lngs, setLngs] = useState({
    fr: { name: "French" },
  });

  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages((err, ret) => {
      let object = {};
      if (err) return;
      for (const lng in ret) {
        if (ret[lng].name !== "Thai") {
          object[lng] = { ...ret[lng], ...object[lng] };
        }
      }
      setLngs(object);
    });
  }, []);

  const handleChange = (e) => {
    if (e.email || e.email === "") {
      setEmail(e.email);
    }
    if (e.password || e.password === "") {
      setPassword(e.password);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(AppUserOma.loginAppUserOma({ email, password }));
  };

  useEffect(() => {
    if (appUserOma && appUserOma.data && appUserOma.data.id) {
      dispatch(AppUserOma.getUser(appUserOma.data.userId));
    }
  }, [appUserOma]);

  if (user.isAuthenticated) {
    return (
      <Redirect
        to={location.state ? location.state.from.pathname : "/homepage"}
      />
    );
  }

  return (
    <Container>
      <LoginForm
        email={email}
        password={password}
        onChange={handleChange}
        onSubmit={handleSubmit}
        user={user}
        t={t}
        i18n={i18n}
        lngs={lngs}
      />
    </Container>
  );
};

export default Login;
