import * as type from "../types";

const initialState = {
  loading: false,
  error: null,
  user: null,
  data: null,
  hie: null,
  diagnosis: null,
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_USER_OMA_REQUESTED:
      return {
        ...state,
        isSuccess: false,
        loading: true,
        error: null,
      };
    case type.GET_USER_OMA_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        data: action.data,
        loading: false,
      };
    case type.GET_USER_OMA_FAILED:
      return {
        ...state,
        isSuccess: false,
        loading: false,
        error: action.error,
      };
    case type.GET_USER_OMA_BY_HIE_REQUESTED:
      return {
        ...state,
        isSuccess: false,
        loading: true,
        error: null,
      };
    case type.GET_USER_OMA_BY_HIE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        hie: action.data,
        loading: false,
      };
    case type.GET_USER_OMA_BY_HIE_FAILED:
      return {
        ...state,
        isSuccess: false,
        loading: false,
        error: action.error,
      };
    case type.GET_USER_OMA_ID_REQUESTED:
      return {
        ...state,
        isSuccess: false,
        loading: true,
        error: null,
      };
    case type.GET_USER_OMA_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        user: action.data,
        loading: false,
      };
    case type.GET_USER_OMA_ID_FAILED:
      return {
        ...state,
        isSuccess: false,
        loading: false,
        error: action.error,
      };
    case type.UPDATE_USER_OMA_REQUESTED:
      return {
        ...state,
        isSuccess: false,
        loading: true,
        error: null,
      };
    case type.UPDATE_USER_OMA_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        data: state.data.map((item) =>
          item.id === action.data.id ? action.data : item
        ),
        loading: false,
      };
    case type.UPDATE_USER_OMA_FAILED:
      return {
        ...state,
        isSuccess: false,
        loading: false,
        error: action.error,
      };
    case type.GET_USER_OMA_DIAGNOSIS_REQUESTED:
      return {
        ...state,
        isSuccess: false,
        loading: true,
        error: null,
      };
    case type.GET_USER_OMA_DIAGNOSIS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        diagnosis: action.data,
        loading: false,
      };
    case type.GET_USER_OMA_DIAGNOSIS_FAILED:
      return {
        ...state,
        isSuccess: false,
        loading: false,
        diagnosis: null,
        error: action.error,
      };
    case type.CLEAR_USER_OMA:
      return {
        isSuccess: false,
        loading: false,
        error: null,
        user: null,
        data: null,
        hie: null,
        diagnosis: null,
      };
    default:
      return state;
  }
};
