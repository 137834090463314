import React from "react";
import EverLogo from "../../../images/EverLogo.png";
import { checkDateInvalid, isNumberValidate } from "../utils";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  HeaderContainer,
  TextArea,
  TextError,
  SpaceItemFlex,
} from "../styles";
import { Row, Col, Space, Checkbox, Radio, Divider } from "antd";
import { HEADER_DEFAULT_FORMS } from "../constant";
import moment from "moment";

export default function InformedConsentForm(props) {
  const { t, form = {}, editable } = props;

  const docSignedHandleChange = (key, index, e) => {
    const dataArray = form.values.docSigned;
    if (dataArray[index]) {
      dataArray[index][key] = e?.target?.value ?? e;
    }
    form.setFieldValue("dataList", dataArray);
    return {};
  };
  return (
    <Container>
      <Text className="text-center" size="16px" color="#0000008C">
        {t(HEADER_DEFAULT_FORMS)}
      </Text>
      <Container className="px-5 mt-3 rounded border">
        <Image className="mt-4" src={EverLogo} />
        <Row gutter={[16, 16]} className="my-4" align="middle">
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("subjects_initials")}:
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.subjectsInitials}
                padding="2px"
                onChange={form.handleChange("subjectsInitials")}
                maxLength={3}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_subject_id")}:
              </Text>
              <Input
                disabled
                value={form.values?.subjectId}
                padding="2px"
                onChange={form.handleChange("subjectId")}
              />
            </Space>
          </Col>
        </Row>
        <HeaderContainer>
          <Text className="my-2 text-center" bold size="16px">
            {t(form.values?.type)}
          </Text>
        </HeaderContainer>
        <Container className="my-3">
          <Row>
            <Col span={8}>
              <Space align="center" direction="horizontal">
                <Text bold color="grey">
                  Date Signed:
                </Text>
                <DatePicker
                  disabled={!editable}
                  value={checkDateInvalid(form.values?.dateSigned)}
                  padding="2px"
                  onChange={(date) =>
                    form.setFieldValue("dateSigned", moment(date).toISOString())
                  }
                />
              </Space>
            </Col>
            <Col span={16}>
              <Space align="center" direction="horizontal">
                <Text bold color="grey">
                  {t("crf_time")} :
                </Text>
                <DatePicker
                  picker="time"
                  allowClear={false}
                  disabled={!editable}
                  format="HH:mm"
                  value={checkDateInvalid(form.values?.timeSigned)}
                  padding="2px"
                  onChange={(time) => {
                    form.setFieldValue(
                      "timeSigned",
                      moment(time).toISOString()
                    );
                  }}
                />
                <Text color="grey" className="font-italic">
                  {t("crf_of_24_format")}
                </Text>
              </Space>
            </Col>
          </Row>
          <table className="table table-bordered my-3">
            <thead>
              <tr>
                <td colSpan={3}>
                  <Text color="grey" bold>
                    Documents(s) signed
                  </Text>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td className="text-center" style={{ width: 130 }}>
                  <Text>Version Date</Text>
                </td>
                <td className="text-center" style={{ width: 130 }}>
                  <Text>Approval Date</Text>
                </td>
              </tr>
              {Array.isArray(form.values?.docSigned) &&
                form.values?.docSigned.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Input
                          disabled={!editable}
                          value={item.title}
                          padding="2px"
                          onChange={docSignedHandleChange.bind(
                            this,
                            "title",
                            index
                          )}
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.versionDate)}
                          padding="2px"
                          onChange={docSignedHandleChange.bind(
                            this,
                            "versionDate",
                            index
                          )}
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.approvalDate)}
                          padding="2px"
                          onChange={docSignedHandleChange.bind(
                            this,
                            "approvalDate",
                            index
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Container className="my-4">
            <CheckBoxSection
              title="Consent Form, and related study documents, was throughly reviewed with the subject."
              value={form.values?.consentReviewSbj}
              onChange={form.handleChange("consentReviewSbj")}
            />
            <CheckBoxSection
              title="Subject had sufficient time to review the documents and ask questions."
              value={form.values?.subjectSuffient}
              onChange={form.handleChange("subjectSuffient")}
            />
            <CheckBoxSection
              title="Informed consent prior to any study related procedures."
              value={form.values?.consentPrior}
              onChange={form.handleChange("consentPrior")}
            />
            <CheckBoxSection
              title="A copy of the signed documents has been given to the subject."
              value={form.values?.copyOfTheSigned}
              onChange={form.handleChange("copyOfTheSigned")}
            />
          </Container>
          <Container className="my-4">
            <SpaceItemFlex className="mb-3">
              <Text bold color="grey">
                Name of person that obtained consent:
              </Text>
              <TextArea
                disabled={!editable}
                maxLength={100}
                value={form.values?.nameObtainConsent}
                className="h-auto"
                onChange={form.handleChange("nameObtainConsent")}
              />
            </SpaceItemFlex>
            <SpaceItemFlex className="mb-3">
              <Text bold color="grey">
                Comment:
              </Text>
              <TextArea
                disabled={!editable}
                maxLength={100}
                value={form.values?.comment}
                className="h-auto"
                onChange={form.handleChange("comment")}
              />
            </SpaceItemFlex>
          </Container>
          <HeaderContainer>
            <Text className="my-2 text-center" bold size="16px">
              Informed Consent Refused
            </Text>
          </HeaderContainer>
          <Container className="my-4">
            <Row justify="space-between" className="mb-4">
              <Space>
                <Text bold color="grey">
                  {t("crf_time")} :
                </Text>
                <DatePicker
                  picker="time"
                  allowClear={false}
                  disabled={!editable}
                  value={checkDateInvalid(form.values?.refusedTime)}
                  padding="2px"
                  format="HH:mm"
                  onChange={(time) =>
                    form.setFieldValue(
                      "refusedTime",
                      moment(time).toISOString()
                    )
                  }
                />
              </Space>
              <Space>
                <Checkbox
                  disabled={!editable}
                  checked={form.values?.notApicable}
                  className="ml-2"
                  onChange={form.handleChange("notApicable")}
                />
                <Text color="grey">Not Applicable</Text>
              </Space>
            </Row>
            <SpaceItemFlex>
              <Text bold color="grey">
                Comment:
              </Text>
              <TextArea
                disabled={!editable}
                maxLength={100}
                value={form.values?.refusedComment}
                className="h-auto"
                onChange={form.handleChange("refusedComment")}
              />
            </SpaceItemFlex>
          </Container>
          <Divider />
          <Text>
            This form should be completed for each Informed Consent process
            (i.e. if Re-consented)
          </Text>
          <Divider />
        </Container>
        <Space className="mb-4" size={25}>
          <Space>
            <Text bold color="grey">
              {t("crf_form_number")}
            </Text>
            <Input
              disabled={!editable}
              value={form.values?.formNumber}
              padding="2px"
              width="60px"
              maxLength={2}
              onChange={(e) => {
                if (isNumberValidate(e.target.value)) {
                  form.setFieldValue("formNumber", e.target.value);
                }
              }}
            />
          </Space>
          <Text color="grey">{t("crf_version_date")}: 01/28/2022</Text>
        </Space>
      </Container>
    </Container>
  );
}

const CheckBoxSection = ({ onChange, value, title }) => {
  return (
    <Container className="my-4">
      <Text bold color="grey" className="mb-2">
        {title}
      </Text>
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    </Container>
  );
};
