import * as type from "../types";

export function getUserOma() {
  return {
    type: type.GET_USER_OMA_REQUESTED,
  };
}
export function getUserOmaById(id) {
  return {
    type: type.GET_USER_OMA_ID_REQUESTED,
    id,
  };
}

export function getUserOmaByHie(id) {
  return {
    type: type.GET_USER_OMA_BY_HIE_REQUESTED,
    id,
  };
}

export function updateUserOma(id, detail) {
  return {
    type: type.UPDATE_USER_OMA_REQUESTED,
    id,
    detail,
  };
}

export function getUserOmaDiagnosis(id) {
  return {
    type: type.GET_USER_OMA_DIAGNOSIS_REQUESTED,
    id,
  };
}

export function clearUserOma() {
  return {
    type: type.CLEAR_USER_OMA,
  };
}
