import React, { useEffect, useState } from "react";

import { Card, Row, Col, Modal, DatePicker, Button } from "antd";
import { PressureText } from "./styles";
const { RangePicker } = DatePicker;

const PressureValue = (props) => {
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const { min, max, average } = props.pressure;
  const { title, filterReport, t } = props;

  const onDateChange = (value, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const handleOk = () => {
    filterReport(startDate, endDate);
    setVisible(false);
  };

  return (
    <Card
      title={
        <Row type="flex" justify="space-between">
          <Col>{title}</Col>
          {filterReport && (
            <Col>
              <Button type="primary" onClick={() => setVisible(true)}>
                {t("select_date_range")}
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      <Modal
        title={t("select_date_range")}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        okText="ยืนยัน"
        cancelText="ปิด"
      >
        <RangePicker format="DD MMM YYYY" onChange={onDateChange} />
      </Modal>
      <Row>
        <PressureText span={8} color="black">
          {t("lowest")}
        </PressureText>
        <PressureText span={8} color="black">
          {t("highest")}
        </PressureText>
        <PressureText span={8} color="black">
          {t("average")}
        </PressureText>
        <PressureText span={8} color="blue">
          {min}
        </PressureText>
        <PressureText span={8} color="red">
          {max}
        </PressureText>
        <PressureText span={8} color="black">
          {average}
        </PressureText>
      </Row>
    </Card>
  );
};

export default PressureValue;
