import http from "../services/httpServiceHealthCare";
const apiEndpoint = "/ResearchSubjects";

async function getFilterResearchObjectsWithSomeConditions(type) {
  const { data } = await http.get(
    `${apiEndpoint}/?filter[where][and][0][allocatedBy]=principleInvestigation&filter[where][and][1][study]=${type}`
  );
  return data;
}
export { getFilterResearchObjectsWithSomeConditions };
