import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { AppUserOma } from "../../redux/actions";
import { useIntercom } from "react-use-intercom";

const Logout = () => {
  const { shutdown } = useIntercom();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AppUserOma.logout());
    shutdown();
  }, []);

  return <Redirect to="/login" />;
};

export default Logout;
