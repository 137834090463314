import { Container, TextArea } from "../styles";
import { Descriptions } from "antd";

const PhysicalExamination = ({ t, form, disabled }) => {
  if (disabled) {
    const { physicalExamination = "" } = form;
    return (
      <Container>
        <Descriptions
          bordered
          labelStyle={{ fontWeight: "bold" }}
          size="small"
          column={1}
        >
          <Descriptions.Item>
            {physicalExamination || t("nothing")}
          </Descriptions.Item>
        </Descriptions>
      </Container>
    );
  }
  return (
    <Container>
      <TextArea
        value={form.values.physicalExamination}
        onChange={form.handleChange("physicalExamination")}
        className="my-3"
      />
    </Container>
  );
};

export default PhysicalExamination;
