import { put, takeEvery } from "redux-saga/effects";
import * as type from "../types";

function* getMenus(action) {
  try {
    yield put({ type: type.GET_MENUS_SUCCESS, data: action.data });
  } catch (e) {
    yield put({ type: type.GET_MENUS_FAILED, message: e.message });
  }
}

function* setMenu(action) {
  try {
    yield put({ type: type.SET_CURRENT_MENU_SUCCESS, data: action.data });
  } catch (e) {
    yield put({ type: type.SET_CURRENT_MENU_FAILED, message: e.message });
  }
}

function* menuSaga() {
  yield takeEvery(type.GET_MENUS_REQUESTED, getMenus);
  yield takeEvery(type.SET_CURRENT_MENU_REQUESTED, setMenu);
}

export default menuSaga;
