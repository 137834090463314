import React from "react";
import { Container } from "./styles";
import PatientInfo from "./patientInfo";
const GeneralInfo = ({ userInfo, isVideoCall }) => {
  return (
    <Container>
      <PatientInfo userInfo={userInfo} isVideoCall={isVideoCall} />
    </Container>
  );
};

export default GeneralInfo;
