import { call, put, takeEvery } from "redux-saga/effects";
import { Communication } from "../../api";
import * as type from "../types";

function* getToken() {
  try {
    const data = yield call(Communication.getToken);
    yield put({ type: type.GET_TOKEN_SUCCESS, token: data.token });
  } catch (e) {
    yield put({ type: type.GET_TOKEN_FAILED, message: e.message });
  }
}

function* deleteToken() {
  try {
    yield put({ type: type.DELETE_TOKEN_SUCCESS });
  } catch (e) {
    yield put({ type: type.DELETE_TOKEN_FAILED, message: e.message });
  }
}

function* communicationSaga() {
  yield takeEvery(type.GET_TOKEN_REQUESTED, getToken);
  yield takeEvery(type.DELETE_TOKEN_REQUESTED, deleteToken);
}

export default communicationSaga;
