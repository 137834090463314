export const HEADER_DEFAULT_FORMS = "crf_default_pi_name";

export const Y_OR_N = [
  {
    label: "Yes",
    key: "Y",
  },
  {
    label: "No",
    key: "N",
  },
];

export const TABLE_SERIOUS_ADVERSE_CONTENT = [
  {
    sae: "1 - Fatal (resulted in death)",
    grade: "1 - Mild",
    add: "0 - Definite",
    out: "0 - Fatal",
  },
  {
    sae: "2 - A life-threatening occurrence.)",
    grade: "2 - Moderate",
    add: "1 - Probable",
    out: "1 - Not recovered/not resolved",
  },
  {
    sae: "3 - Requires inpatient hospitalization or prolongation of existing hospitalization",
    grade: "3 - Severe",
    add: "2 - Possible",
    out: "2 - Recovered w/sequelae",
  },
  {
    sae: "4 - Results in persistent or significant disability/incapacity",
    grade: "4 - Life Threatening",
    add: "3 - Unlikely",
    out: "3 - Recovered w/o sequelae",
  },
  {
    sae: "5 - Results in congenital anomaly/birth defect",
    grade: "5 - Death (Fatal)",
    add: "4 - Unrelated",
    out: "4 - Recovering/Resolving",
  },
  {
    sae: "6 - A significant medical incident that, based upon appropriate medical judgment, may jeopardize the subject and require medical or surgical intervention to prevent one of the outcomes listed above.",
    grade: "",
    add: "",
    out: "",
  },
];
