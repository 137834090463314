import { Svg, Path } from "@react-pdf/renderer";

export default function CheckIcon({ width, height }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M11 1.08336L3.99996 8.08336L0.791626 4.87503L1.61413 4.05253L3.99996 6.43253L10.1775 0.260864L11 1.08336Z"
        fill="#27A06D"
      />
    </Svg>
  );
}
