export const ROLE_TO_STATUS = {
  doctor: "DOCTOR_COMPLETED",
  communityPharmacist: "COMMUNITY_PHARMACIST_COMPLETED",
};

export const JOIN_STATUS = {
  doctor: "DOCTOR_JOIN",
  pharmacy: "PHARMACY_JOIN",
  communityPharmacist: "COMMUNITY_PHARMACIST_JOIN",
};

export const NOTIFICATION = {
  acceptCall: (language = "en") => ({
    body: "Practitioner has accepted your call. Please wait for the room to be ready.",
    title: "Alert",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
};
