import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => `height: ${props.height};`}
  ${(props) => `min-height: ${props.height ? 0 : "100"}vh`};
  width: 100%;
`;
