export const COVID_TITLE = [
  { title: "full_name", value: "firstName" },
  {
    title: "last_name",
    value: "lastName",
  },
  {
    title: "age",
    value: "age",
  },
  {
    title: "gender",
    value: "gender",
  },
  {
    title: "height",
    value: "height",
  },
  {
    title: "weight",
    value: "weight",
  },
  { title: "BMI", value: "bmi" },
  {
    title: "ems_name",
    value: "emsName",
  },
  {
    title: "ems_number",
    value: "emsNumber",
  },
  {
    title: "first_date_symtom",
    value: "firstDateSymtom",
  },
  { title: "latest_infected", value: "lastestInfected" },
];

export const COVID_FORM = [
  {
    title: "todySymtoms",
    label: "tody_symtoms",
    value: {
      coughALot: "cough_a_lot",
      tightChest: "tight_chest",
      shortnessOfBreath: "shortness_of_breath",
      cantEat: "cant_eat",
      veryTired: "very_tired",
      haveFever: "have_fever_all_day",
      other: "other",
    },
  },
  {
    title: "vaccines",
    label: "vaccines_history",
    value: {},
  },
  {
    title: "pregnantStatus",
    label: "pregnant_status",
    value: {
      isPregent: "is_pregent",
      isMenstruationPeriod: "is_menstruation_period",
      isBreastFeeding: "is_breast_feeding",
    },
  },
  {
    title: "congenitalDisease",
    label: "congenital_disease",
    value: {
      chronicRespiratory: "chronic_respiratory",
      cardiovascular: "cardiovascular",
      chronicRenal: "chronic_renal",
      cerebrovascular: "cerebrovascular",
      overweight: "overweight",
      cancer: "cancer",
      diabetes: "diabetes",
      cirrhosis: "cirrhosis",
      weakenedImmuneSystem: "weakened_immune_system",
    },
  },
  {
    title: "firstDateSymtoms",
    label: "first_date_symtoms",
    value: {
      haveFever: "have_fever",
      cough: "cough",
      soreThroat: "sore_throat",
      runny: "runny",
      havePhlegm: "have_phlegm",
      headache: "headache",
      liquidStool: "liquid_stool",
      tired: "tired",
      musclePain: "muscle_pain",
      rash: "rash",
      conjunctivitis: "conjunctivitis",
      difficultyBreathing: "difficulty_breathing",
      noseCantSmell: "nose_cant_smell",
      tastelessTongue: "tasteless_tongue",
      cantEat: "cant_eat",
    },
  },
];
