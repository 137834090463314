import React from "react";
import EverLogo from "../../../images/EverLogo.png";
import { isNumberValidate, checkDateInvalid } from "../utils";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  HeaderContainer,
  SpaceItemFlex,
  TextVetical,
  DropDownWidget,
} from "../styles";
import { Row, Col, Space } from "antd";
import { Y_OR_N, TABLE_SERIOUS_ADVERSE_CONTENT } from "../constant";

export default function SeriousAdverseEventForm(props) {
  const { t, form = {}, editable } = props;
  const onChangeValueHandler = (key, index, e) => {
    const dataArray = form.values.dataList;
    if (dataArray[index]) {
      dataArray[index][key] = e?.target?.value ?? e;
    }
    form.setFieldValue("dataList", dataArray);
  };

  const renderDropDownValue = (key, index, items) => {
    return (
      <Container
        style={{ backgroundColor: "white" }}
        className="shadow px-2 py-1"
      >
        {items.map((item) => {
          return (
            <a
              key={item.key}
              onClick={() => onChangeValueHandler(key, index, item.key)}
            >
              <Text className="py-1 my-1" key={item.key}>
                {item.label}
              </Text>
            </a>
          );
        })}
      </Container>
    );
  };

  return (
    <Container>
      <Container className="px-5 mt-3 rounded border">
        <Image className="mt-4" src={EverLogo} />
        <HeaderContainer className="my-3">
          <Text className="my-2 text-center" bold size="16px">
            {t(form.values?.type)}
          </Text>
        </HeaderContainer>
        <Container className="mb-4">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <SpaceItemFlex>
                <Text>Principal Investigator:</Text>
                <Input
                  disabled={!editable}
                  value={form.values?.piName}
                  padding="2px"
                  onChange={form.handleChange("piName")}
                />
              </SpaceItemFlex>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]} className="w-100">
                <Col span={12}>
                  <SpaceItemFlex>
                    <Text>IRB Number:</Text>
                    <Input
                      disabled={!editable}
                      value={form.values?.irbNumber}
                      padding="2px"
                      onChange={form.handleChange("irbNumber")}
                    />
                  </SpaceItemFlex>
                </Col>
                <Col span={12}>
                  <Space className="mr-2">
                    <Text>Page:</Text>
                    <Input
                      width="50px"
                      disabled={!editable}
                      value={form.values?.page}
                      padding="2px"
                      onChange={form.handleChange("page")}
                    />
                  </Space>
                  <Space>
                    <Text>of:</Text>
                    <Input
                      width="50px"
                      disabled={!editable}
                      value={form.values?.of}
                      padding="2px"
                      onChange={form.handleChange("of")}
                    />
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <SpaceItemFlex>
                <Text>Short Protocol Title:</Text>
                <Input
                  disabled={!editable}
                  value={form.values?.shortProtocolTitle}
                  padding="2px"
                  onChange={form.handleChange("shortProtocolTitle")}
                  maxLength={3}
                />
              </SpaceItemFlex>
            </Col>
          </Row>
        </Container>
        <Container style={{ overflowX: "scroll" }}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="align-middle text-center">
                  <Text>Subject ID No.</Text>
                </th>
                <th className="align-middle text-center">
                  <Text>SAE Description</Text>
                </th>
                <th className="align-middle text-center">
                  <Text
                    style={{ whiteSpace: "pre-line" }}
                  >{`Adverse Event* \n(Select from Safety Profiler)`}</Text>
                </th>
                <th className="align-middle text-center">
                  <TextVetical>SAE Classification</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical>Event Start Date</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical>Event End Date</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical>
                    {`Date Site Became \nAware of Event \n(Reported Date)`}
                  </TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical>Grade</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical>Unexpected (Y or N)</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical>Attribution</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical>Outcome</TextVetical>
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(form.values?.dataList) &&
                form.values?.dataList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle text-center">
                        <Input
                          disabled={!editable}
                          maxLength={10}
                          value={item.subjectId}
                          onChange={onChangeValueHandler.bind(
                            this,
                            "subjectId",
                            index
                          )}
                          padding="2px"
                          width="100px"
                        />
                      </td>
                      <td className="align-middle text-center">
                        <Input
                          disabled={!editable}
                          maxLength={200}
                          value={item.desp}
                          onChange={onChangeValueHandler.bind(
                            this,
                            "desp",
                            index
                          )}
                          padding="2px"
                          width="200px"
                        />
                      </td>
                      <td className="align-middle text-center">
                        <Input
                          disabled={!editable}
                          maxLength={100}
                          value={item.adverseEvent}
                          onChange={onChangeValueHandler.bind(
                            this,
                            "adverseEvent",
                            index
                          )}
                          padding="2px"
                          width="220px"
                        />
                      </td>
                      <td className="align-middle text-center">
                        <Input
                          disabled={!editable}
                          width="25px"
                          value={item.sae}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler(
                                "sae",
                                index,
                                e.target.value
                              );
                            }
                          }}
                          padding="2px"
                          maxLength={1}
                        />
                      </td>
                      <td className="align-middle text-center">
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.startDate)}
                          format="DD/MM/YY"
                          padding="2px"
                          width="100px"
                          onChange={onChangeValueHandler.bind(
                            this,
                            "startDate",
                            index
                          )}
                        />
                      </td>
                      <td className="align-middle text-center">
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.endDate)}
                          format="DD/MM/YY"
                          padding="2px"
                          width="100px"
                          onChange={onChangeValueHandler.bind(
                            this,
                            "endDate",
                            index
                          )}
                        />
                      </td>
                      <td className="align-middle text-center">
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.reportedDate)}
                          format="DD/MM/YY"
                          padding="2px"
                          width="100px"
                          onChange={onChangeValueHandler.bind(
                            this,
                            "reportedDate",
                            index
                          )}
                        />
                      </td>
                      <td className="align-middle text-center">
                        <Input
                          disabled={!editable}
                          width="25px"
                          value={item.grade}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler(
                                "grade",
                                index,
                                e.target.value
                              );
                            }
                          }}
                          padding="2px"
                          maxLength={1}
                        />
                      </td>
                      <td>
                        <DropDownWidget
                          disabled={!editable}
                          width="40px"
                          value={item.unexpected}
                          renderList={renderDropDownValue(
                            "unexpected",
                            index,
                            Y_OR_N
                          )}
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          width="25px"
                          value={item.attribution}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler(
                                "attribution",
                                index,
                                e.target.value
                              );
                            }
                          }}
                          padding="2px"
                          maxLength={1}
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          width="25px"
                          value={item.outcome}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler(
                                "outcome",
                                index,
                                e.target.value
                              );
                            }
                          }}
                          padding="2px"
                          maxLength={1}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Container>
        <Text>
          **look up corresponding AE at:
          https://safetyprofiler-ctep.nci.nih.gov/
        </Text>
        <table
          style={{ tableLayout: "auto" }}
          className="table table-bordered my-3"
        >
          <thead>
            <tr>
              <th>
                <Text>SAE Classification</Text>
              </th>
              <th>
                <Text>Grade</Text>
              </th>
              <th>
                <Text>Attribution</Text>
              </th>
              <th>
                <Text>Outcome</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {TABLE_SERIOUS_ADVERSE_CONTENT.map((item) => {
              return (
                <tr>
                  <td>
                    <Text>{item.sae}</Text>
                  </td>
                  <td>
                    <Text>{item.grade}</Text>
                  </td>
                  <td style={{ width: 120 }}>
                    <Text>{item.add}</Text>
                  </td>
                  <td>
                    <Text>{item.out}</Text>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Space className="my-3" size={25}>
          <Space>
            <Text bold color="grey">
              {t("crf_form_number")}
            </Text>
            <Input
              disabled={!editable}
              value={form.values?.formNumber}
              padding="2px"
              width="60px"
              maxLength={2}
              onChange={(e) => {
                if (isNumberValidate(e.target.value)) {
                  form.setFieldValue("formNumber", e.target.value);
                }
              }}
            />
          </Space>
          <Text>{t("crf_version_date")}: 01/31/2022</Text>
        </Space>
      </Container>
    </Container>
  );
}
