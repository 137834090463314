import { Card, Button as BtnAnt, Space } from "antd";
import IconAnt from "@ant-design/icons";
import styled from "styled-components";
import TextBase from "../Text";

const Container = styled.div`
  width: 100%;
`;

const CardContainer = styled(Card)`
  &&& {
    margin:10px
    overflow: hidden;
    min-height: ${(props) => props.minheight};
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  }
  .ant-card-body {
    height:100%;
    padding: 10px
  }
  .ant-card-extra{
    padding: 10px 0;
  }
  .ant-card-head {
    padding: 0 12px;
    color: #2BA796;
    font-weight: bold
  }
`;

const Button = styled(BtnAnt)`
  &&& {
    background-color: ${(props) => props.color};
  }
`;

const Text = styled(TextBase)`
  color: ${(props) => (props.active ? "#2BA796" : props.color)};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const Icon = styled(IconAnt)`
  &&&& {
    color: ${(props) => props.color} !important;
  }
  font-size: ${(props) => props.size};
`;

const HeaderPanel = ({ title, iconComponent }) => {
  return (
    <Space direction="horizontal">
      <Icon component={iconComponent} size="20px" color="#2BA796" />
      <Text size="16px" bold>
        {title}
      </Text>
    </Space>
  );
};

export { Container, CardContainer, Text, Button, HeaderPanel, Icon };
