import { Container, Text, DatePicker, TextArea } from "../styles";
import { Row, Col, Radio, Descriptions } from "antd";
import moment from "moment";

const disabledDate = (current) => {
  return current && current > moment().endOf("day");
};

const PreviousOrCurrentClinicalPraticipant = ({ t, form, disabled }) => {
  if (disabled) {
    const {
      clinicalTrialCheck,
      clinicalTrialEndDate,
      clinicalTrialDescription,
    } = form;
    return (
      <Container>
        <Descriptions
          bordered
          labelStyle={{ fontWeight: "bold" }}
          size="small"
          column={1}
        >
          <Descriptions.Item label={t("trial_participation")}>
            {clinicalTrialCheck ? t(clinicalTrialCheck) : t("nothing")}
          </Descriptions.Item>
          {clinicalTrialEndDate && (
            <Descriptions.Item label={t("end_of_participation")}>
              {moment(clinicalTrialEndDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
          )}
        </Descriptions>
        {clinicalTrialDescription && (
          <Descriptions
            bordered
            labelStyle={{ fontWeight: "bold" }}
            size="small"
            column={1}
            style={{ marginTop: 20 }}
            layout="vertical"
          >
            <Descriptions.Item label={t("comment")}>
              {clinicalTrialDescription}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <Row gutter={[16, 8]}>
        <Col>
          <Text size="16px">{t("trial_participation")}</Text>
        </Col>
        <Col>
          <Radio.Group
            onChange={form.handleChange("clinicalTrialCheck")}
            value={form.values.clinicalTrialCheck}
          >
            <Radio value="YES">{t("yes")}</Radio>
            <Radio value="NO">{t("no")}</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row className="mt-2" gutter={[16, 8]} align="middle">
        <Col>
          <Text size="16px">{t("end_of_participation")}</Text>
        </Col>
        <Col xs={24} lg={10} xl={8}>
          <DatePicker
            format="DD MMMM YYYY"
            width="100%"
            disabledDate={disabledDate}
            defaultValue={
              form.values.clinicalTrialEndDate
                ? moment(form.values.clinicalTrialEndDate)
                : null
            }
            onChange={(date) =>
              form.setFieldValue(
                "clinicalTrialEndDate",
                moment(date).toISOString()
              )
            }
          />
        </Col>
      </Row>
      <TextArea
        value={form.values.clinicalTrialDescription}
        onChange={form.handleChange("clinicalTrialDescription")}
        className="mt-3"
      />
    </Container>
  );
};

export default PreviousOrCurrentClinicalPraticipant;
