import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookingTable from "../../components/bookingTable";
import { Booking } from "../../redux/actions";
import Layout from "../Home";
import { useTranslation } from "react-i18next";

const BookingFromPatient = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userOma = useSelector((state) => state.appUserOma);
  const [filterData, setFilterData] = useState(false);
  const [appUserId, setAppUserId] = useState(false);
  const [tab, setTab] = useState("allBookings");
  const [searchBy, setSearchBy] = useState("name");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (userOma && userOma.information) {
      const role = userOma.information.roles[0].name;
      const id =
        role === "callCenter" || role === "deliveryTeam"
          ? 0
          : userOma.information.id;
      if (searchText) {
        onSearch();
      } else {
        dispatch(Booking.getBookings(id, 1, "descend", 10, tab));
      }
      setAppUserId(id);
    }
  }, [userOma, tab]);

  const onSearch = (page = 1, pageSize = 10) => {
    if (searchText && searchText.length) {
      let firstname = "";
      let lastname = "";
      let cId = "";
      let tabName = "";
      let status = "";
      if (searchBy === "name") {
        firstname = searchText.split(" ")[0];
        lastname = searchText.split(" ")[1];
      } else if (searchBy === "cid") {
        cId = searchText;
      }
      switch (tab) {
        case "allBookings":
          tabName = "";
          break;
        case "lessThan":
          tabName = "past";
          break;
        case "greaterThan":
          tabName = "future";
          break;
        case "today":
          tabName = "today";
          break;
        case "problemReport":
          tabName = "";
          status = "DOCTOR_DECLINED,PHARMACY_DECLINED";
          break;
        default:
          tabName = "";
          break;
      }
      dispatch(
        Booking.searchBookings(
          null,
          firstname,
          lastname,
          cId,
          tabName,
          status,
          page,
          pageSize
        )
      );
    } else {
      const role = userOma.information.roles[0].name;
      const id =
        role === "callCenter" || role === "deliveryTeam"
          ? 0
          : userOma.information.id;
      dispatch(Booking.getBookings(id, 1, "descend", 10, tab));
    }
  };

  const getBookings = (pagination, filters, sorter) => {
    if (searchText) {
      onSearch(pagination.current, pagination.pageSize);
    } else {
      dispatch(
        Booking.getBookings(
          appUserId,
          pagination ? pagination.current : 1,
          sorter ? sorter.order : "descend",
          pagination ? pagination.pageSize : 10,
          tab
        )
      );
    }
  };

  return (
    <Layout
      {...props}
      render={(props) => (
        <BookingTable
          t={t}
          filterData={filterData}
          onSearch={onSearch}
          setTab={(tab) => setTab(tab.key)}
          style={{ width: "100%" }}
          handleTableChange={getBookings}
          setSearchBy={(value) => setSearchBy(value)}
          setSearchText={(value) => setSearchText(value)}
          {...props}
        />
      )}
    />
  );
};
export default BookingFromPatient;
