export const ROLE_ACCESS_TO_REMOVE_DRUG = ["communityPharmacist", "doctor"];

export const UNITS = [
  {
    th: "เม็ด",
    en: "tablet",
  },
  {
    th: "แคปซูล",
    en: "capsule",
  },
  {
    th: "ซอง",
    en: "sachet",
  },
  {
    th: "กด",
    en: "Puff",
  },
  {
    th: "",
    en: "",
  },
];

export const DRUG_TIME_EVENTS = [
  {
    code: "AC",
    nameEn: "before meal (from lat. ante cibus)",
    nameTh: "ก่อนอาหาร",
    id: 1,
    createdAt: "2022-01-17 07:33:53.61+00",
    updatedAt: "2022-01-17 07:33:53.61+00",
  },
  {
    code: "ACD",
    nameEn: "before lunch (from lat. ante cibus diurnus)",
    nameTh: "ก่อนอาหารกลางวัน",
    id: 2,
    createdAt: "2022-01-17 07:33:53.614+00",
    updatedAt: "2022-01-17 07:33:53.614+00",
  },
  {
    code: "ACM",
    nameEn: "before breakfast (from lat. ante cibus matutinus)",
    nameTh: "ก่อนอาหารเช้า",
    id: 3,
    createdAt: "2022-01-17 07:33:53.618+00",
    updatedAt: "2022-01-17 07:33:53.618+00",
  },
  {
    code: "ACV",
    nameEn: "before dinner (from lat. ante cibus vespertinus)",
    nameTh: "ก่อนอาหารเย็น",
    id: 4,
    createdAt: "2022-01-17 07:33:53.621+00",
    updatedAt: "2022-01-17 07:33:53.621+00",
  },
  {
    code: "C",
    nameEn: "Description: meal (from lat. ante cibus)",
    nameTh: "พร้อมอาหาร",
    id: 5,
    createdAt: "2022-01-17 07:33:53.625+00",
    updatedAt: "2022-01-17 07:33:53.625+00",
  },
  {
    code: "CD",
    nameEn: "Description: lunch (from lat. cibus diurnus)",
    nameTh: "พร้อมอาหารกลางวัน",
    id: 6,
    createdAt: "2022-01-17 07:33:53.628+00",
    updatedAt: "2022-01-17 07:33:53.628+00",
  },
  {
    code: "CM",
    nameEn: "Description: breakfast (from lat. cibus matutinus)",
    nameTh: "พร้อมอาหารเช้า",
    id: 7,
    createdAt: "2022-01-17 07:33:53.631+00",
    updatedAt: "2022-01-17 07:33:53.631+00",
  },
  {
    code: "CV",
    nameEn: "Description: dinner (from lat. cibus vespertinus)",
    nameTh: "พร้อมอาหารเย็น",
    id: 8,
    createdAt: "2022-01-17 07:33:53.634+00",
    updatedAt: "2022-01-17 07:33:53.634+00",
  },
  {
    code: "HS",
    nameEn:
      "Description: Prior to beginning a regular period of extended sleep (this would exclude naps). Note that this might occur at different times of day depending on a person's regular sleep schedule.",
    nameTh: "ก่อนนอน",
    id: 9,
    createdAt: "2022-01-17 07:33:53.637+00",
    updatedAt: "2022-01-17 07:33:53.637+00",
  },
  {
    code: "IC",
    nameEn: "between meals (from lat. inter cibus)",
    nameTh: "ระหว่างมื้ออาหาร",
    id: 10,
    createdAt: "2022-01-17 07:33:53.64+00",
    updatedAt: "2022-01-17 07:33:53.64+00",
  },
  {
    code: "ICD",
    nameEn: "between lunch and dinner",
    nameTh: "ระหว่างมื้อกลางวันกับมื้อเย็น",
    id: 11,
    createdAt: "2022-01-17 07:33:53.643+00",
    updatedAt: "2022-01-17 07:33:53.644+00",
  },
  {
    code: "ICM",
    nameEn: "between breakfast and lunch",
    nameTh: "ระหว่างมื้อเช้ากับมื้อกลางวัน",
    id: 12,
    createdAt: "2022-01-17 07:33:53.647+00",
    updatedAt: "2022-01-17 07:33:53.647+00",
  },
  {
    code: "ICV",
    nameEn: "between dinner and the hour of sleep",
    nameTh: "ระหว่างมื้อเย็นกับก่อนนอน",
    id: 13,
    createdAt: "2022-01-17 07:33:53.65+00",
    updatedAt: "2022-01-17 07:33:53.65+00",
  },
  {
    code: "PC",
    nameEn: "after meal (from lat. post cibus)",
    nameTh: "หลังอาหาร",
    id: 14,
    createdAt: "2022-01-17 07:33:53.654+00",
    updatedAt: "2022-01-17 07:33:53.654+00",
  },
  {
    code: "PCD",
    nameEn: "after lunch (from lat. post cibus diurnus)",
    nameTh: "หลังอาหารกลางวัน",
    id: 15,
    createdAt: "2022-01-17 07:33:53.663+00",
    updatedAt: "2022-01-17 07:33:53.663+00",
  },
  {
    code: "PCM",
    nameEn: "after breakfast (from lat. post cibus matutinus)",
    nameTh: "หลังอาหารเช้า",
    id: 16,
    createdAt: "2022-01-17 07:33:53.667+00",
    updatedAt: "2022-01-17 07:33:53.667+00",
  },
  {
    code: "PCV",
    nameEn: "after dinner (from lat. post cibus vespertinus)",
    nameTh: "หลังอาหารเย็น",
    id: 17,
    createdAt: "2022-01-17 07:33:53.673+00",
    updatedAt: "2022-01-17 07:33:53.673+00",
  },
  {
    code: "WAKE",
    nameEn:
      "Description: Upon waking up from a regular period of sleep, in order to start regular activities (this would exclude waking up from a nap or temporarily waking up during a period of sleep)\nUsage Notes: e.g.\nTake pulse rate on waking in management of thyrotoxicosis.\nTake BP on waking in management of hypertension\nTake basal body temperature on waking in establishing date of ovulation",
    nameTh: "เมื่อตื่นนอน",
    id: 18,
    createdAt: "2022-01-17 07:33:53.677+00",
    updatedAt: "2022-01-17 07:33:53.677+00",
  },
];

export const DRUG_ROUTES = [
  {
    topicalRoute: "54471007",
    routeEn: "Buccal use",
    routeTh: "กระพุ้งแก้ม",
    id: 1,
    createdAt: "2022-01-17 07:26:01.02+00",
    updatedAt: "2022-01-17 07:26:01.02+00",
  },
  {
    topicalRoute: "78421000",
    routeEn: "Intramuscular use",
    routeTh: "เข้ากล้ามเนื้อ",
    id: 2,
    createdAt: "2022-01-17 07:26:01.03+00",
    updatedAt: "2022-01-17 07:26:01.03+00",
  },
  {
    topicalRoute: "372464004",
    routeEn: "Intradermal use",
    routeTh: "เข้าชั้นผิวหนัง",
    id: 3,
    createdAt: "2022-01-17 07:26:01.034+00",
    updatedAt: "2022-01-17 07:26:01.034+00",
  },
  {
    topicalRoute: "448077001",
    routeEn: "Intraepidermal route",
    routeTh: "เข้าชั้นหนังกำพร้า",
    id: 4,
    createdAt: "2022-01-17 07:26:01.038+00",
    updatedAt: "2022-01-17 07:26:01.038+00",
  },
  {
    topicalRoute: "46713006",
    routeEn: "Nasal use",
    routeTh: "จมูก",
    id: 5,
    createdAt: "2022-01-17 07:26:01.046+00",
    updatedAt: "2022-01-17 07:26:01.046+00",
  },
  {
    topicalRoute: "372473007",
    routeEn: "Oromucosal use",
    routeTh: "ในปาก",
    id: 6,
    createdAt: "2022-01-17 07:26:01.051+00",
    updatedAt: "2022-01-17 07:26:01.051+00",
  },
  {
    topicalRoute: "54485002",
    routeEn: "Ophthalmic use",
    routeTh: "ตา",
    id: 7,
    createdAt: "2022-01-17 07:26:01.054+00",
    updatedAt: "2022-01-17 07:26:01.055+00",
  },
  {
    topicalRoute: "34206005",
    routeEn: "SC use",
    routeTh: "ใต้ผิวหนัง",
    id: 8,
    createdAt: "2022-01-17 07:26:01.058+00",
    updatedAt: "2022-01-17 07:26:01.058+00",
  },
  {
    topicalRoute: "90028008",
    routeEn: "Urethral use",
    routeTh: "ท่อปัสสาวะ",
    id: 9,
    createdAt: "2022-01-17 07:26:01.062+00",
    updatedAt: "2022-01-17 07:26:01.062+00",
  },
  {
    topicalRoute: "16857009",
    routeEn: "Vaginal use",
    routeTh: "ทางช่องคลอด",
    id: 10,
    createdAt: "2022-01-17 07:26:01.066+00",
    updatedAt: "2022-01-17 07:26:01.066+00",
  },
  {
    topicalRoute: "447694001",
    routeEn: "Respiratory tract route",
    routeTh: "ทางเดินหายใจ",
    id: 11,
    createdAt: "2022-01-17 07:26:01.069+00",
    updatedAt: "2022-01-17 07:26:01.069+00",
  },
  {
    topicalRoute: "447081004",
    routeEn: "Lower respiratory tract route",
    routeTh: "ทางเดินหายใจส่วนล่าง",
    id: 12,
    createdAt: "2022-01-17 07:26:01.073+00",
    updatedAt: "2022-01-17 07:26:01.073+00",
  },
  {
    topicalRoute: "447964005",
    routeEn: "Digestive tract route",
    routeTh: "ทางเดินอาหาร",
    id: 13,
    createdAt: "2022-01-17 07:26:01.077+00",
    updatedAt: "2022-01-17 07:26:01.077+00",
  },
  {
    topicalRoute: "37161004",
    routeEn: "Rectal use",
    routeTh: "ทางทวารหนัก",
    id: 14,
    createdAt: "2022-01-17 07:26:01.081+00",
    updatedAt: "2022-01-17 07:26:01.081+00",
  },
  {
    topicalRoute: "372452007",
    routeEn: "Endotracheopulmonary use",
    routeTh: "ทางท่อช่วยหายใจ",
    id: 15,
    createdAt: "2022-01-17 07:26:01.086+00",
    updatedAt: "2022-01-17 07:26:01.086+00",
  },
  {
    topicalRoute: "448598008",
    routeEn: "Cutaneous route",
    routeTh: "ทางผิวหนัง",
    id: 16,
    createdAt: "2022-01-17 07:26:01.09+00",
    updatedAt: "2022-01-17 07:26:01.09+00",
  },
  {
    topicalRoute: "418813001",
    routeEn: "Surgical drain route",
    routeTh: "ทางสายระบาย",
    id: 17,
    createdAt: "2022-01-17 07:26:01.094+00",
    updatedAt: "2022-01-17 07:26:01.094+00",
  },
  {
    topicalRoute: "127492001",
    routeEn: "Nasogastric use",
    routeTh: "ทางสายอาหาร",
    id: 18,
    createdAt: "2022-01-17 07:26:01.1+00",
    updatedAt: "2022-01-17 07:26:01.1+00",
  },
  {
    topicalRoute: "47625008",
    routeEn: "Intravenous use",
    routeTh: "ทางหลอดเลือดดำ",
    id: 19,
    createdAt: "2022-01-17 07:26:01.104+00",
    updatedAt: "2022-01-17 07:26:01.105+00",
  },
  {
    topicalRoute: "419993007",
    routeEn: "Intravenous peripheral route",
    routeTh: "ทางหลอดเลือดดำส่วนปลาย",
    id: 20,
    createdAt: "2022-01-17 07:26:01.108+00",
    updatedAt: "2022-01-17 07:26:01.108+00",
  },
  {
    topicalRoute: "372466002",
    routeEn: "Intralesional use",
    routeTh: "บริเวณที่เป็น",
    id: 21,
    createdAt: "2022-01-17 07:26:01.113+00",
    updatedAt: "2022-01-17 07:26:01.113+00",
  },
  {
    topicalRoute: "45890007",
    routeEn: "Transdermal use",
    routeTh: "แปะผิวหนัง",
    id: 22,
    createdAt: "2022-01-17 07:26:01.119+00",
    updatedAt: "2022-01-17 07:26:01.119+00",
  },
  {
    topicalRoute: "428191002",
    routeEn: "Percutaneous route",
    routeTh: "ผ่านชั้นผิวหนัง",
    id: 23,
    createdAt: "2022-01-17 07:26:01.124+00",
    updatedAt: "2022-01-17 07:26:01.124+00",
  },
  {
    topicalRoute: "372449004",
    routeEn: "Dental use",
    routeTh: "ฟัน",
    id: 24,
    createdAt: "2022-01-17 07:26:01.129+00",
    updatedAt: "2022-01-17 07:26:01.129+00",
  },
  {
    topicalRoute: "26643006",
    routeEn: "Oral use",
    routeTh: "รับประทาน",
    id: 25,
    createdAt: "2022-01-17 07:26:01.133+00",
    updatedAt: "2022-01-17 07:26:01.133+00",
  },
  {
    topicalRoute: "10547007",
    routeEn: "Auricular use",
    routeTh: "หู",
    id: 26,
    createdAt: "2022-01-17 07:26:01.137+00",
    updatedAt: "2022-01-17 07:26:01.137+00",
  },
  {
    topicalRoute: "372457001",
    routeEn: "Gingival use",
    routeTh: "เหงือก",
    id: 27,
    createdAt: "2022-01-17 07:26:01.141+00",
    updatedAt: "2022-01-17 07:26:01.141+00",
  },
  {
    topicalRoute: "37839007",
    routeEn: "Sublingual use",
    routeTh: "อมใต้ลิ้น",
    id: 28,
    createdAt: "2022-01-17 07:26:01.147+00",
    updatedAt: "2022-01-17 07:26:01.147+00",
  },
];

export const DRUG_FREQUENCIES = [
  {
    code: "A",
    nameEn: "Ante (before)",
    nameTh: "ก่อน",
    id: 1,
    createdAt: "2022-01-17 07:33:53.446+00",
    updatedAt: "2022-01-17 07:33:53.446+00",
  },
  {
    code: "BID",
    nameEn: "twice a day at institution-specified times",
    nameTh: "2 เวลา",
    id: 2,
    createdAt: "2022-01-17 07:33:53.452+00",
    updatedAt: "2022-01-17 07:33:53.452+00",
  },
  {
    code: "C",
    nameEn: "service is provided continuously between start time and stop time",
    nameTh: "ต่อเนื่อง",
    id: 3,
    createdAt: "2022-01-17 07:33:53.456+00",
    updatedAt: "2022-01-17 07:33:53.456+00",
  },
  {
    code: "D",
    nameEn: "Cibus Diurnus (lunch)",
    nameTh: "มื้อเที่ยง",
    id: 4,
    createdAt: "2022-01-17 07:33:53.46+00",
    updatedAt: "2022-01-17 07:33:53.46+00",
  },
  {
    code: "I",
    nameEn: "Inter",
    nameTh: "ระหว่าง",
    id: 5,
    createdAt: "2022-01-17 07:33:53.463+00",
    updatedAt: "2022-01-17 07:33:53.463+00",
  },
  {
    code: "M",
    nameEn: "Cibus Matutinus (breakfast)",
    nameTh: "มื้อเช้า",
    id: 6,
    createdAt: "2022-01-17 07:33:53.466+00",
    updatedAt: "2022-01-17 07:33:53.466+00",
  },
  {
    code: "Once",
    nameEn: "one time only.",
    nameTh: "ครั้งเดียว",
    id: 7,
    createdAt: "2022-01-17 07:33:53.469+00",
    updatedAt: "2022-01-17 07:33:53.469+00",
  },
  {
    code: "P",
    nameEn: "Post (after)",
    nameTh: "หลัง",
    id: 8,
    createdAt: "2022-01-17 07:33:53.473+00",
    updatedAt: "2022-01-17 07:33:53.473+00",
  },
  {
    code: "PRN",
    nameEn: "given as needed",
    nameTh: "เวลามีอาการ",
    id: 9,
    createdAt: "2022-01-17 07:33:53.475+00",
    updatedAt: "2022-01-17 07:33:53.475+00",
  },
  {
    code: "PRNQ4D",
    nameEn: "every 4 days",
    nameTh: "เวลามีอาการทุก 4 วัน",
    id: 10,
    createdAt: "2022-01-17 07:33:53.478+00",
    updatedAt: "2022-01-17 07:33:53.478+00",
  },
  {
    code: "PRNQ6D",
    nameEn: "every 6 days",
    nameTh: "เวลามีอาการทุก 6 วัน",
    id: 11,
    createdAt: "2022-01-17 07:33:53.481+00",
    updatedAt: "2022-01-17 07:33:53.481+00",
  },
  {
    code: "PRNQ8D",
    nameEn: "every 8 days",
    nameTh: "เวลามีอาการทุก 8 วัน",
    id: 12,
    createdAt: "2022-01-17 07:33:53.485+00",
    updatedAt: "2022-01-17 07:33:53.485+00",
  },
  {
    code: "PRNQ12D",
    nameEn: "every 12 days",
    nameTh: "เวลามีอาการทุก 12 วัน",
    id: 13,
    createdAt: "2022-01-17 07:33:53.488+00",
    updatedAt: "2022-01-17 07:33:53.488+00",
  },
  {
    code: "PRNQ4H",
    nameEn: "every 4 hours",
    nameTh: "เวลามีอาการทุก 4 ชั่วโมง",
    id: 14,
    createdAt: "2022-01-17 07:33:53.491+00",
    updatedAt: "2022-01-17 07:33:53.492+00",
  },
  {
    code: "PRNQ6H",
    nameEn: "every 6 hours",
    nameTh: "เวลามีอาการทุก 6 ชั่วโมง",
    id: 15,
    createdAt: "2022-01-17 07:33:53.495+00",
    updatedAt: "2022-01-17 07:33:53.495+00",
  },
  {
    code: "PRNQ8H",
    nameEn: "every 8 hours",
    nameTh: "เวลามีอาการทุก 8 ชั่วโมง",
    id: 16,
    createdAt: "2022-01-17 07:33:53.498+00",
    updatedAt: "2022-01-17 07:33:53.498+00",
  },
  {
    code: "PRNQ12H",
    nameEn: "every 12 hours",
    nameTh: "เวลามีอาการทุก 12 ชั่วโมง",
    id: 17,
    createdAt: "2022-01-17 07:33:53.502+00",
    updatedAt: "2022-01-17 07:33:53.502+00",
  },
  {
    code: "PRNQ4L",
    nameEn: "every 4 months (Lunar cycle)",
    nameTh: "เวลามีอาการทุก 4 เดือน",
    id: 18,
    createdAt: "2022-01-17 07:33:53.505+00",
    updatedAt: "2022-01-17 07:33:53.505+00",
  },
  {
    code: "PRNQ6L",
    nameEn: "every 6 months (Lunar cycle)",
    nameTh: "เวลามีอาการทุก 6 เดือน",
    id: 19,
    createdAt: "2022-01-17 07:33:53.508+00",
    updatedAt: "2022-01-17 07:33:53.508+00",
  },
  {
    code: "PRNQ8L",
    nameEn: "every 8 months (Lunar cycle)",
    nameTh: "เวลามีอาการทุก 8 เดือน",
    id: 20,
    createdAt: "2022-01-17 07:33:53.511+00",
    updatedAt: "2022-01-17 07:33:53.511+00",
  },
  {
    code: "PRNQ12L",
    nameEn: "every 12 months (Lunar cycle)",
    nameTh: "เวลามีอาการทุก 12 เดือน",
    id: 21,
    createdAt: "2022-01-17 07:33:53.515+00",
    updatedAt: "2022-01-17 07:33:53.515+00",
  },
  {
    code: "PRNQ4M",
    nameEn: "every 4 minutes",
    nameTh: "เวลามีอาการทุก 4 นาที",
    id: 22,
    createdAt: "2022-01-17 07:33:53.519+00",
    updatedAt: "2022-01-17 07:33:53.519+00",
  },
  {
    code: "PRNQ6M",
    nameEn: "every 6 minutes",
    nameTh: "เวลามีอาการทุก 6 นาที",
    id: 23,
    createdAt: "2022-01-17 07:33:53.522+00",
    updatedAt: "2022-01-17 07:33:53.522+00",
  },
  {
    code: "PRNQ8M",
    nameEn: "every 8 minutes",
    nameTh: "เวลามีอาการทุก 8 นาที",
    id: 24,
    createdAt: "2022-01-17 07:33:53.525+00",
    updatedAt: "2022-01-17 07:33:53.525+00",
  },
  {
    code: "PRNQ12M",
    nameEn: "every 12 minutes",
    nameTh: "เวลามีอาการทุก 12 นาที",
    id: 25,
    createdAt: "2022-01-17 07:33:53.529+00",
    updatedAt: "2022-01-17 07:33:53.529+00",
  },
  {
    code: "PRNQ4S",
    nameEn: "every 4 seconds",
    nameTh: "เวลามีอาการทุก 4 วินาที",
    id: 26,
    createdAt: "2022-01-17 07:33:53.532+00",
    updatedAt: "2022-01-17 07:33:53.532+00",
  },
  {
    code: "PRNQ6S",
    nameEn: "every 6 seconds",
    nameTh: "เวลามีอาการทุก 6 วินาที",
    id: 27,
    createdAt: "2022-01-17 07:33:53.535+00",
    updatedAt: "2022-01-17 07:33:53.535+00",
  },
  {
    code: "PRNQ8S",
    nameEn: "every 8 seconds",
    nameTh: "เวลามีอาการทุก 8 วินาที",
    id: 28,
    createdAt: "2022-01-17 07:33:53.538+00",
    updatedAt: "2022-01-17 07:33:53.538+00",
  },
  {
    code: "PRNQ12S",
    nameEn: "every 12 seconds",
    nameTh: "เวลามีอาการทุก 12 วินาที",
    id: 29,
    createdAt: "2022-01-17 07:33:53.542+00",
    updatedAt: "2022-01-17 07:33:53.542+00",
  },
  {
    code: "PRNQ4W",
    nameEn: "every 4 weeks",
    nameTh: "เวลามีอาการทุก 4 สัปดาห์",
    id: 30,
    createdAt: "2022-01-17 07:33:53.545+00",
    updatedAt: "2022-01-17 07:33:53.545+00",
  },
  {
    code: "PRNQ6W",
    nameEn: "every 6 weeks",
    nameTh: "เวลามีอาการทุก 6 สัปดาห์",
    id: 31,
    createdAt: "2022-01-17 07:33:53.548+00",
    updatedAt: "2022-01-17 07:33:53.548+00",
  },
  {
    code: "PRNQ8W",
    nameEn: "every 8 weeks",
    nameTh: "เวลามีอาการทุก 8 สัปดาห์",
    id: 32,
    createdAt: "2022-01-17 07:33:53.552+00",
    updatedAt: "2022-01-17 07:33:53.552+00",
  },
  {
    code: "PRNQ12W",
    nameEn: "every 12 weeks",
    nameTh: "เวลามีอาการทุก 12 สัปดาห์",
    id: 33,
    createdAt: "2022-01-17 07:33:53.557+00",
    updatedAt: "2022-01-17 07:33:53.557+00",
  },
  {
    code: "QAM",
    nameEn: "in the morning at institution-specified time",
    nameTh: "ทุกเช้า",
    id: 34,
    createdAt: "2022-01-17 07:33:53.56+00",
    updatedAt: "2022-01-17 07:33:53.56+00",
  },
  {
    code: "QHS",
    nameEn: "every day before the hour of sleep",
    nameTh: "ทุกวันก่อนนอน",
    id: 35,
    createdAt: "2022-01-17 07:33:53.563+00",
    updatedAt: "2022-01-17 07:33:53.563+00",
  },
  {
    code: "QID",
    nameEn: "four times a day at institution-specified times",
    nameTh: "4 เวลา",
    id: 36,
    createdAt: "2022-01-17 07:33:53.567+00",
    updatedAt: "2022-01-17 07:33:53.567+00",
  },
  {
    code: "QOD",
    nameEn: "every other day",
    nameTh: "วันเว้นวัน",
    id: 37,
    createdAt: "2022-01-17 07:33:53.57+00",
    updatedAt: "2022-01-17 07:33:53.57+00",
  },
  {
    code: "QPM",
    nameEn: "in the evening at institution-specified time",
    nameTh: "ทุกเย็น",
    id: 38,
    createdAt: "2022-01-17 07:33:53.574+00",
    updatedAt: "2022-01-17 07:33:53.574+00",
  },
  {
    code: "TID",
    nameEn: "three times a day at institution-specified times",
    nameTh: "3 เวลา",
    id: 39,
    createdAt: "2022-01-17 07:33:53.577+00",
    updatedAt: "2022-01-17 07:33:53.577+00",
  },
  {
    code: "V",
    nameEn: "Cibus Vespertinus (dinner)",
    nameTh: "มื้อเย็น",
    id: 40,
    createdAt: "2022-01-17 07:33:53.58+00",
    updatedAt: "2022-01-17 07:33:53.58+00",
  },
  {
    code: "5ID",
    nameEn: "5 times per day",
    nameTh: "5 ครั้งต่อวัน",
    id: 41,
    createdAt: "2022-01-17 07:33:53.584+00",
    updatedAt: "2022-01-17 07:33:53.584+00",
  },
  {
    code: "6ID",
    nameEn: "6 times per day",
    nameTh: "6 ครั้งต่อวัน",
    id: 42,
    createdAt: "2022-01-17 07:33:53.588+00",
    updatedAt: "2022-01-17 07:33:53.588+00",
  },
  {
    code: "7ID",
    nameEn: "7 times per day",
    nameTh: "7 ครั้งต่อวัน",
    id: 43,
    createdAt: "2022-01-17 07:33:53.591+00",
    updatedAt: "2022-01-17 07:33:53.591+00",
  },
  {
    code: "8ID",
    nameEn: "8 times per day",
    nameTh: "8 ครั้งต่อวัน",
    id: 44,
    createdAt: "2022-01-17 07:33:53.595+00",
    updatedAt: "2022-01-17 07:33:53.595+00",
  },
  {
    code: "9ID",
    nameEn: "9 times per day",
    nameTh: "9 ครั้งต่อวัน",
    id: 45,
    createdAt: "2022-01-17 07:33:53.598+00",
    updatedAt: "2022-01-17 07:33:53.598+00",
  },
  {
    code: "10ID",
    nameEn: "10 times per day",
    nameTh: "10 ครั้งต่อวัน",
    id: 46,
    createdAt: "2022-01-17 07:33:53.602+00",
    updatedAt: "2022-01-17 07:33:53.602+00",
  },
];
