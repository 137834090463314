import React, { useEffect, useState } from "react";
import { Card, Button, Modal } from "antd";
import Table from "../../../Table";
import moment from "moment";
import { DiagnosesTable } from "./DiagnosesTable";

const BookingCard = ({ booking, diagnosis }) => {
  const diagnosisHistory =
    diagnosis && diagnosis.visits ? diagnosis.visits : [];
  const [visibleNote, setVisibleNote] = useState(false);
  const [visibleDiagnoses, setVisibleDiagnoses] = useState(false);
  const [diagnoses, setDiagnoses] = useState(false);
  const [hpi, setHpi] = useState(false);
  const COLUMNS = [
    {
      title: "วันที่ - เวลา",
      key: "dateTime",
      width: 150,
      align: "center",
      fixed: "left",
      render: (record) =>
        moment(record.visitDateTime).format("DD/MM/YYYY, HH:mm:ss"),
      sorter: (a, b) =>
        moment(a.visitDateTime).unix() - moment(b.visitDateTime).unix(),
      defaultSortOrder: "descend",
    },
    {
      title: "ผลการวินิจฉัย",
      key: "diagnoses",
      width: 100,
      render: (text) =>
        text.diagnoses && (
          <Button
            onClick={() => {
              setVisibleDiagnoses(true);
              setDiagnoses(text.diagnoses);
            }}
          >
            Diagnosis
          </Button>
        ),
    },
    {
      title: "ประวัติผู้ป่วย(HPI)",
      key: "hpi",
      width: 100,
      render: (text) => (
        <Button
          onClick={() => {
            setVisibleNote(true);
            setHpi(text.visitVital.hpi);
          }}
        >
          HPI
        </Button>
      ),
    },
    {
      title: "ดูแลโดย",
      key: "consultWith",
      width: 200,
      align: "center",
      render: (record) => record.doctorName,
    },
  ];

  return (
    <div>
      <Card className="mt-2" title="ประวัติการนัดหมาย">
        <Table dataSource={diagnosisHistory} columns={COLUMNS} />
      </Card>
      <Modal
        width="50vw"
        title="ประวัติผู้ป่วย"
        visible={visibleNote}
        footer={null}
        onOk={() => setVisibleNote(false)}
        onCancel={() => setVisibleNote(false)}
      >
        <pre>{hpi ? hpi : "ไม่พบข้อมูล"}</pre>
      </Modal>
      <Modal
        width="70vw"
        title="ผลการวินิจฉัย"
        visible={visibleDiagnoses}
        footer={null}
        onOk={() => setVisibleDiagnoses(false)}
        onCancel={() => setVisibleDiagnoses(false)}
      >
        <DiagnosesTable diagnoses={diagnoses} />
      </Modal>
    </div>
  );
};

export default BookingCard;
