import React from "react";
import EverLogo from "../../../images/EverLogo.png";
import { isNumberValidate, checkDateInvalid } from "../utils";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  HeaderContainer,
  TextArea,
  TextError,
} from "../styles";
import {
  Row,
  Col,
  Space,
  Checkbox,
  Radio,
  Divider,
  Upload,
  message,
  Button,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { HEADER_DEFAULT_FORMS } from "../constant";
import moment from "moment";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default function EndOfSutdyExamForm(props) {
  const { t, form = {}, editable } = props;

  return (
    <Container>
      <Text className="text-center" size="16px" color="#0000008C">
        {t(HEADER_DEFAULT_FORMS)}
      </Text>
      <Container className="px-5 mt-3 rounded border">
        <Image className="mt-4" src={EverLogo} />
        <Row gutter={[16, 16]} className="my-4" align="middle">
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("subjects_initials")}:
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.subjectsInitials}
                padding="2px"
                onChange={form.handleChange("subjectsInitials")}
                maxLength={3}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_subject_id")}:
              </Text>
              <Input
                disabled
                value={form.values?.subjectId}
                padding="2px"
                onChange={form.handleChange("subjectId")}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_exam_date")}:
              </Text>
              <DatePicker
                disabled={!editable}
                value={checkDateInvalid(form.values?.createdAt)}
                format="DD/MM/YYYY"
                onChange={(date) =>
                  form.setFieldValue("createdAt", moment(date).toISOString())
                }
                padding="2px"
                width="130px"
              />
            </Space>
          </Col>
        </Row>
        <HeaderContainer>
          <Text className="my-2 text-center" bold size="16px">
            {t(form.values?.type)}
          </Text>
        </HeaderContainer>
        <Container>
          <Space align="center" direction="horizontal" className="mt-3">
            <Text bold color="grey">
              {t("crf_time")} :
            </Text>
            <DatePicker
              picker="time"
              allowClear={false}
              value={checkDateInvalid(form.values?.timeCreated)}
              format="HH:mm"
              padding="2px"
              width="100px"
              onChange={(time) =>
                form.setFieldValue("timeCreated", moment(time).toISOString())
              }
              disabled={!editable}
            />
            <Text color="grey" className="font-italic">
              {t("crf_of_24_format")}
            </Text>
          </Space>
          <Text bold color="grey" className="my-3">
            {t("crf_visit_number")}:
          </Text>
          <Container>
            <Radio.Group
              disabled={!editable}
              onChange={form.handleChange("visit")}
              value={form.values?.visit}
            >
              <Row gutter={[8, 16]}>
                <Col sm={24} md={12}>
                  <Radio value={1}>Visit 1</Radio>
                  <Radio value={2}>Visit 2</Radio>
                  <Radio value={3}>Visit 3</Radio>
                </Col>
                <Col sm={24} md={12}>
                  <Radio value={4}>Visit 4</Radio>
                  <Radio value={5}>Visit 5</Radio>
                  <Radio value={6}>Visit 6</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Container>
          <Container className="mt-3">
            <Space>
              <Checkbox
                disabled={!editable}
                onChange={form.handleChange("notPerform")}
                checked={form.values?.notPerform}
              />
              <Text>{t("crf_vital_sign_not_performed")}</Text>
            </Space>
          </Container>
        </Container>
        <Divider />
        <Container className="p-3 mt-3 rounded border">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[8, 16]}>
                <Col sm={24} md={14}>
                  <Space align="center" direction="horizontal">
                    <Text bold color="grey">
                      {t("crf_temperature")}:
                    </Text>
                    <Input
                      disabled={!editable}
                      value={form.values?.temperature}
                      placeholder={t("input_value")}
                      padding="2px"
                      onChange={form.handleChange("temperature")}
                    />
                    <Text color="grey">{t("crf_celcius")}</Text>
                  </Space>
                  {form.errors.temperature && (
                    <TextError>{form.errors.temperature}</TextError>
                  )}
                </Col>
                <Col sm={24} md={10}>
                  <Space>
                    <Checkbox
                      disabled={!editable}
                      onChange={form.handleChange("tempNotMeasured")}
                      checked={form.values?.tempNotMeasured}
                    />
                    <Text>{t("crf_temp_not_measured")}</Text>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Space align="center" direction="horizontal">
                <Text bold color="grey">
                  {t("crf_method")}:{" "}
                </Text>
                <Text color="grey">{t("crf_check_one")}</Text>
                <Radio.Group
                  disabled={!editable}
                  onChange={form.handleChange("tempMethod")}
                  value={form.values?.tempMethod}
                >
                  <Radio value="oral">{t("crf_oral")}</Radio>
                  <Radio value="axillary">{t("crf_axillary")}</Radio>
                  <Radio value="tympanic">{t("crf_tympanic")}</Radio>
                </Radio.Group>
              </Space>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <Col sm={24} md={14}>
                  <Space>
                    <Text bold color="grey">
                      {t("crf_respiratory_rate")}:
                    </Text>
                    <Input disabled value={form.values?.rr} padding="2px" />
                    <Text color="grey">{t("crf_breaths_min")}</Text>
                  </Space>
                </Col>
                <Col sm={24} md={10}>
                  <Space>
                    <Checkbox
                      disabled={!editable}
                      onChange={form.handleChange("rrNotMeasured")}
                      checked={form.values?.rrNotMeasured}
                    />
                    <Text>{t("crf_rr_not_measured")}</Text>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <Col sm={24} md={14}>
                  <Space>
                    <Text bold color="grey">
                      {t("crf_heart_rate")}:
                    </Text>
                    <Input
                      disabled
                      value={form.values?.heartRate}
                      padding="2px"
                    />
                    <Text color="grey">{t("crf_beats_min")}</Text>
                  </Space>
                </Col>
                <Col sm={24} md={10}>
                  <Space>
                    <Checkbox
                      disabled={!editable}
                      onChange={form.handleChange("heartRateNotMeasured")}
                      checked={form.values?.heartRateNotMeasured}
                    />
                    <Text>{t("crf_hr_not_measured")}</Text>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <Col sm={24} md={14}>
                  <Space>
                    <Text bold color="grey">
                      {t("crf_systolic_blood_pressure")}:
                    </Text>
                    <Input
                      disabled
                      value={form.values?.systolicBP}
                      padding="2px"
                    />
                    <Text color="grey">{t("crf_mmhg")}</Text>
                  </Space>
                </Col>
                <Col sm={24} md={10}>
                  <Space>
                    <Checkbox
                      disabled={!editable}
                      onChange={form.handleChange("bpNotNotMeasured")}
                      checked={form.values?.bpNotNotMeasured}
                    />
                    <Text>{t("crf_bp_not_measured")}</Text>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Space>
                <Text bold color="grey">
                  {t("crf_diastolic_blood_pressure")}:
                </Text>
                <Input
                  disabled
                  value={form.values?.diastolicBP}
                  padding="2px"
                />
                <Text color="grey">{t("crf_mmhg")}</Text>
              </Space>
            </Col>
            <Col span={24}>
              <Space align="center" direction="horizontal">
                <Text bold color="grey">
                  {t("crf_method")}:{" "}
                </Text>
                <Text color="grey">{t("crf_check_one")}</Text>
                <Radio.Group
                  disabled={!editable}
                  onChange={form.handleChange("bpMethod")}
                  value={form.values?.bpMethod}
                >
                  <Radio value="manual">{t("crf_manual")}</Radio>
                  <Radio value="automated">{t("crf_automated")}</Radio>
                </Radio.Group>
              </Space>
            </Col>
            <Col span={24}>
              <Space align="center" direction="horizontal">
                <Text bold color="grey">
                  {t("crf_location")}:{" "}
                </Text>
                <Text color="grey">{t("crf_check_one")}</Text>
                <Radio.Group
                  disabled={!editable}
                  onChange={form.handleChange("bpLocation")}
                  value={form.values?.bpLocation}
                >
                  <Radio value="left_arm">{t("crf_left_arm")}</Radio>
                  <Radio value="right_arm">{t("crf_right_arm")}</Radio>
                </Radio.Group>
              </Space>
            </Col>
            <Col span={24}>
              <Space align="center" direction="horizontal">
                <Text bold color="grey">
                  {t("crf_position")}:{" "}
                </Text>
                <Text color="grey">{t("crf_check_one")}</Text>
                <Radio.Group
                  disabled={!editable}
                  onChange={form.handleChange("bpPosition")}
                  value={form.values?.bpPosition}
                >
                  <Radio value="sitting">{t("crf_sitting")}</Radio>
                  <Radio value="supine">{t("crf_supine")}</Radio>
                  <Radio value="standing">{t("crf_standing")}</Radio>
                </Radio.Group>
              </Space>
            </Col>
          </Row>
        </Container>
        <Container className="my-4">
          <Row className="mt-4" align="middle">
            <Col span={5}>
              <Text bold color="grey">
                {t("crf_vital_obtained_by")}:
              </Text>
            </Col>
            <Col span={19}>
              <Input
                disabled={!editable}
                value={form.values?.vitalSignObtainedBy}
                onChange={form.handleChange("vitalSignObtainedBy")}
                padding="2px"
              />
            </Col>
          </Row>
          <Row className="mt-4" align="middle">
            <Col span={5}>
              <Text bold color="grey">
                {t("crf_aditional_notes")}:
              </Text>
            </Col>
            <Col span={19}>
              <TextArea
                disabled={!editable}
                maxLength={300}
                value={form.values?.comment}
                className="h-auto"
                onChange={form.handleChange("comment")}
              />
            </Col>
          </Row>
          <Row className="mt-4" align="middle">
            <Col span={5}>
              <Text bold color="grey">
                {t("crf_nasal_performed_by")}:
              </Text>
            </Col>
            <Col span={19}>
              <Input
                disabled={!editable}
                value={form.values?.performedBy}
                onChange={form.handleChange("performedBy")}
                padding="2px"
              />
            </Col>
          </Row>
          <Row className="mt-4" align="middle">
            <Col span={5}>
              <Text bold color="grey">
                {t("crf_pi_print_name")}:
              </Text>
            </Col>
            <Col span={19}>
              <Input
                disabled={!editable}
                value={form.values?.piPrintName}
                onChange={form.handleChange("piPrintName")}
                padding="2px"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-4" align="middle">
            <Col span={12}>
              <Space>
                <Text bold color="grey">
                  {t("crf_pi_signature")}:
                </Text>
                {form.values?.piSignatureURL && (
                  <Image
                    style={{ height: "100%", maxHeight: 100, width: "auto" }}
                    src={form.values?.piSignatureURL}
                  />
                )}
                <Upload
                  showUploadList={false}
                  onChange={(info) => {
                    if (info.file.status === "done") {
                      getBase64(info.file.originFileObj, (url) =>
                        form.setFieldValue("piSignatureURL", url)
                      );
                    } else if (info.file.status === "error") {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}
                  customRequest={dummyRequest}
                >
                  <Button disabled={!editable} icon={<UploadOutlined />}>
                    {t("crf_upload")}
                  </Button>
                </Upload>
              </Space>
              {form.errors.piSignatureURL && (
                <TextError>{form.errors.piSignatureURL}</TextError>
              )}
            </Col>
            <Col span={12}>
              <Space>
                <Text bold color="grey">
                  {t("crf_date")}:
                </Text>
                <DatePicker
                  disabled={!editable}
                  value={checkDateInvalid(form.values?.piSignatureDate)}
                  format="DD/MM/YYYY"
                  onChange={(date) =>
                    form.setFieldValue(
                      "piSignatureDate",
                      moment(date).toISOString()
                    )
                  }
                  padding="2px"
                  width="130px"
                />
              </Space>
            </Col>
          </Row>
          <Space className="mt-4" size={25}>
            <Space>
              <Text bold color="grey">
                {t("crf_form_number")}
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.formNumber}
                padding="2px"
                width="60px"
                maxLength={2}
                onChange={(e) => {
                  if (isNumberValidate(e.target.value)) {
                    form.setFieldValue("formNumber", e.target.value);
                  }
                }}
              />
            </Space>
            <Text color="grey">{t("crf_version_date")}: 01/30/2022</Text>
          </Space>
        </Container>
      </Container>
    </Container>
  );
}
