import * as type from "../types";

export function createBookingByTreatment(treatmentId, detail) {
  return {
    type: type.CREATE_BOOKING_BY_TREATMENT_REQUESTED,
    treatmentId,
    detail,
  };
}

export function getBookingsByTreatmentId(treatmentId) {
  return {
    type: type.GET_BOOKINGS_BY_TREATMENT_REQUESTED,
    treatmentId,
  };
}

export function updateTreatment(treatmentId, detail) {
  return {
    type: type.UPDATE_TREATMENT_REQUESTED,
    treatmentId,
    detail,
  };
}
