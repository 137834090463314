import { call, put, takeEvery } from "redux-saga/effects";
import { AppUserOma } from "../../api";
import * as type from "../types";

function* getProfile({ id }) {
  try {
    const result = yield call(AppUserOma.getProfile, id);
    yield put({
      type: type.GET_PROFILE_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_PROFILE_FAILED,
      error: e.message,
    });
  }
}
function* getOrganizationsByUser({ id }) {
  try {
    const result = yield call(AppUserOma.getOrganizationsUser, id);
    yield put({ type: type.GET_ORGANIZATIONS_BY_USER_SUCCESS, data: result });
  } catch (e) {
    yield put({
      type: type.GET_ORGANIZATIONS_BY_USER_FAILED,
      error: e.message,
    });
  }
}
function* updateProfile({ id, profile }) {
  try {
    const result = yield call(AppUserOma.updateProfile, id, profile);
    yield put({
      type: type.UPDATE_PROFILE_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_PROFILE_FAILED,
      error: e.message,
    });
  }
}

function* addPhoto({ id, photo }) {
  try {
    const result = yield call(AppUserOma.addPhoto, id, photo);
    yield put({
      type: type.ADD_PHOTO_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.ADD_PHOTO_FAILED,
      error: e.message,
    });
  }
}

function* updatePhoto({ id, photo, fk }) {
  try {
    const result = yield call(AppUserOma.updatePhoto, id, photo, fk);
    yield put({
      type: type.UPDATE_PHOTO_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_PHOTO_FAILED,
      error: e.message,
    });
  }
}

function* loginAppUserOma({ credential }) {
  try {
    const result = yield call(AppUserOma.login, credential);
    yield put({
      type: type.LOGIN_APP_USER_OMA_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.LOGIN_APP_USER_OMA_FAILED,
      error: e.message,
    });
  }
}

function* logout() {
  try {
    yield call(AppUserOma.logout);
    yield put({
      type: type.LOGOUT_SUCCESS,
    });
  } catch (e) {
    yield put({
      type: type.LOGOUT_FAILED,
      error: e.message,
    });
  }
}

function* registerAppUserOma({ credential }) {
  try {
    const result = yield call(AppUserOma.register, credential);
    yield put({
      type: type.CREATE_APP_USER_OMA_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.CREATE_APP_USER_OMA_FAILED,
      error: e.message,
    });
  }
}

function* getUser({ id }) {
  try {
    const result = yield call(AppUserOma.getUser, id);
    yield put({
      type: type.GET_APP_USER_OMA_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_APP_USER_OMA_FAILED,
      error: e.message,
    });
  }
}

function* updateSpecialties({ id, specialties }) {
  try {
    const result = yield call(AppUserOma.updateSpecialties, id, specialties);
    yield put({
      type: type.UPDATE_SPECIALTIES_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_SPECIALTIES_FAILED,
      error: e.message,
    });
  }
}

function* deleteUser({ id }) {
  try {
    const result = yield call(AppUserOma.deleteUser, id);
    yield put({
      type: type.DELETE_APP_USER_OMA_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.DELETE_APP_USER_OMA_FAILED,
      error: e.message,
    });
  }
}

function* appUserOmaSaga() {
  yield takeEvery(type.LOGIN_APP_USER_OMA_REQUESTED, loginAppUserOma);
  yield takeEvery(type.LOGOUT_REQUESTED, logout);
  yield takeEvery(type.CREATE_APP_USER_OMA_REQUESTED, registerAppUserOma);
  yield takeEvery(type.GET_APP_USER_OMA_REQUESTED, getUser);
  yield takeEvery(type.GET_PROFILE_REQUESTED, getProfile);
  yield takeEvery(type.UPDATE_PROFILE_REQUESTED, updateProfile);
  yield takeEvery(type.ADD_PHOTO_REQUESTED, addPhoto);
  yield takeEvery(type.UPDATE_PHOTO_REQUESTED, updatePhoto);
  yield takeEvery(type.UPDATE_SPECIALTIES_REQUESTED, updateSpecialties);
  yield takeEvery(type.DELETE_APP_USER_OMA_REQUESTED, deleteUser);
  yield takeEvery(
    type.GET_ORGANIZATIONS_BY_USER_REQUESTED,
    getOrganizationsByUser
  );
}

export default appUserOmaSaga;
