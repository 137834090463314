import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { GlucoseText } from "./styles";
import { Card, Row, Col } from "antd";
const GlucoseTrend = (props) => {
  const { t } = props;
  const [values, setValues] = useState(props.trends);
  const isVideoCall = props.isVideoCall;
  useEffect(() => {
    setValues(props.trends);
  }, [props]);

  const glucoseData = [
    {
      text: t("list"),
      color: "black",
    },
    {
      text: t("count"),
      color: "black",
    },
    {
      text: t("normal"),
      color: "#3c3c3c",
    },
    {
      text: values ? values.mid : "-",
      color: "#32cd32",
    },
    {
      text: t("low"),
      color: "#3c3c3c",
    },
    {
      text: values ? values.low : "-",
      color: "#76c9d4",
    },
    {
      text: t("high"),
      color: "#3c3c3c",
    },
    {
      text: values ? values.high : "-",
      color: "red",
    },
    {
      text: t("all"),
      color: "black",
    },
    {
      text: values ? values.low + values.mid + values.high : "-",
      color: "black",
    },
  ];
  const glucoseDataRender = (data, key) => {
    return (
      <GlucoseText span={12} color={data.color} key={key}>
        {data.text}
      </GlucoseText>
    );
  };
  return (
    <Card className="mt-2" title={t("blood_glucose_trend")}>
      <Row>
        <Col span={isVideoCall ? 24 : 12}>
          <Row type="flex">
            {glucoseData.map((data, index) => glucoseDataRender(data, index))}
          </Row>
        </Col>
        {values && values.low + values.mid + values.high ? (
          <Col span={12}>
            {!isVideoCall && (
              <Pie
                data={values.pie}
                options={{
                  legend: {
                    display: true,
                    position: "right",
                  },
                }}
              />
            )}
          </Col>
        ) : null}
      </Row>
    </Card>
  );
};

export default GlucoseTrend;
