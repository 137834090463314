import React from "react";
import { HEADER_DEFAULT_FORMS } from "../constant";
import { isNumberValidate, checkDateInvalid } from "../utils";
import EverLogo from "../../../images/EverLogo.png";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  HeaderContainer,
  TextArea,
} from "../styles";
import { Row, Col, Space, Checkbox } from "antd";
import moment from "moment";

export default function DailyAssessmentForm(props) {
  const { t, form = {}, editable } = props;
  return (
    <Container>
      <Text className="text-center" size="16px" color="#0000008C">
        {t(HEADER_DEFAULT_FORMS)}
      </Text>
      <Container className="px-5 mt-3 rounded border">
        <Image className="mt-4" src={EverLogo} />
        <Row gutter={[16, 16]} className="my-4" align="middle">
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("subjects_initials")}:
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.subjectsInitials}
                padding="2px"
                onChange={form.handleChange("subjectsInitials")}
                maxLength={3}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_subject_id")}:
              </Text>
              <Input disabled value={form.values?.subjectId} padding="2px" />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_date")}:
              </Text>
              <DatePicker
                disabled
                value={checkDateInvalid(form.values?.createdAt)}
                format="DD/MM/YYYY"
                padding="2px"
                width="130px"
              />
            </Space>
          </Col>
        </Row>
        <HeaderContainer>
          <Text className="my-2 text-center" bold size="16px">
            {t("daily_assessment")}
          </Text>
        </HeaderContainer>
        <Space className="mt-4">
          <Checkbox
            disabled={!editable}
            checked={form.values?.notPerformed}
            onChange={form.handleChange("notPerformed")}
          >
            <Text bold color="grey">
              {t("crf_da_sub_title")}
            </Text>
          </Checkbox>
        </Space>
        <Container className="my-4">
          <Row align="middle">
            <Col span={2}>
              <Text bold color="grey">
                {t("crf_comment")}:
              </Text>
            </Col>
            <Col span={22}>
              <TextArea
                maxLength={300}
                disabled={!editable}
                value={form.values?.comment}
                className="h-auto"
                onChange={form.handleChange("comment")}
              />
            </Col>
          </Row>
          <Space className="mt-4" size={25}>
            <Space>
              <Text bold color="grey">
                {t("crf_form_number")}
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.formNumber}
                padding="2px"
                width="60px"
                maxLength={2}
                onChange={(e) => {
                  if (isNumberValidate(e.target.value)) {
                    form.setFieldValue("formNumber", e.target.value);
                  }
                }}
              />
            </Space>
            <Text color="grey">{t("crf_version_date")}: 01/30/2022</Text>
          </Space>
        </Container>
      </Container>
    </Container>
  );
}
