import { Svg, Path } from "@react-pdf/renderer";

export default function CheckboxOutline({ width, height }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M11.5 12.25H2.5C2.0875 12.25 1.75 11.9125 1.75 11.5V2.5C1.75 2.0875 2.0875 1.75 2.5 1.75H11.5C11.9125 1.75 12.25 2.0875 12.25 2.5V11.5C12.25 11.9125 11.9125 12.25 11.5 12.25ZM12.25 0.25H1.75C0.925 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V1.75C13.75 0.925 13.075 0.25 12.25 0.25Z"
        fill="black"
        fillOpacity="0.45"
      />
    </Svg>
  );
}
