import moment from "moment";
import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/Questionnaires";

async function updateQuestionnaireById(id, payload) {
  const { data } = await http.patch(`${apiEndpoint}/${id}`, payload);
  return data;
}

async function getAllQuestionnaire() {
  const { data } = await http.get(
    `${apiEndpoint}?filter[order]=createdAt DESC`
  );
  return data;
}

async function getRegisteredQuestionnaire() {
  const filterDate = moment().endOf("date").toISOString();
  const filterMaximumDate = moment()
    .subtract(9, "days")
    .startOf("date")
    .toISOString();
  const filter1 = "filter[where][questionnaireTypeId][gt]=1";
  const filter2 = `filter[where][and][0][createdAt][lt]=${filterDate}`;
  const filter3 = `filter[where][and][1][createdAt][gt]=${filterMaximumDate}`;
  const filter4 = "filter[order]=createdAt DESC";
  const { data } = await http.get(
    `${apiEndpoint}?${filter1}&${filter2}&${filter3}&${filter4}`
  );
  return data;
}

async function getNewCaseQuestionnaire() {
  const filterDate = moment().startOf("date").toISOString();
  const filter1 = `filter[where][updatedAt][gt]=${filterDate}`;
  const filter2 = "filter[order]=createdAt DESC";
  const { data } = await http.get(`${apiEndpoint}?${filter1}&${filter2}`);
  return data;
}

export {
  updateQuestionnaireById,
  getAllQuestionnaire,
  getNewCaseQuestionnaire,
  getRegisteredQuestionnaire,
};
