import * as type from "../types";

export function updateQuestionaireById(id, payload) {
  return {
    type: type.UPDATE_QUESTIONAIRE_BY_ID_REQUESTED,
    id,
    payload,
  };
}

export function getAllNewCasesQuestionnaire() {
  return {
    type: type.GET_ALL_NEW_CASE_QUESTIONAIRE_REQUESTED,
  };
}

export function getAllRegisteredQuestionnaire() {
  return {
    type: type.GET_ALL_REGISTERED_QUESTIONAIRE_REQUESTED,
  };
}
