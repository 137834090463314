import React, { useCallback, useState } from "react";
import { ModalCustom, Icon, Text, Container, Table, Button } from "./styles";
import {
  AiFillFile,
  AiOutlineFile,
  FaRegFolder,
  FaFolderPlus,
  FaFolderMinus,
  FaFolder,
} from "react-icons/all";
import FileSaver from "file-saver";

import PropTypes from "prop-types";
import { Space } from "antd";
import { filterDataSubTable, manageFormHandler, formConvert } from "./utils";
import { pdf } from "@react-pdf/renderer";

export default function CtmsDocumentListModal(props) {
  const {
    t,
    previewComponent,
    fileNameHandler,
    visible,
    setVisible,
    constSubId,
    currentData,
    crfFormList,
  } = props;

  const [loading, setLoading] = useState(false);

  const expandedRowRender = (selected) => {
    const data = filterDataSubTable(currentData, selected);
    return (
      <Table
        rowKey={(item) => item.fileName}
        showHeader={false}
        pagination={false}
        dataSource={data}
        columns={SUBCOLUMNS}
      />
    );
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const savePDFHandler = useCallback(async (formData) => {
    if (formData?.type) {
      const PreviewComponent = previewComponent[formData.type] ?? Container;
      const blob = await pdf(
        <PreviewComponent data={manageFormHandler(formData)} t={t} />
      ).toBlob();
      FileSaver.saveAs(blob, fileNameHandler(formData));
    }
  }, []);

  const openPDFHandler = useCallback(async (formData) => {
    if (formData?.type) {
      const PreviewComponent = previewComponent[formData.type] ?? Container;
      const blob = await pdf(
        <PreviewComponent data={manageFormHandler(formData)} t={t} />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      openInNewTab(url);
    }
  }, []);

  const SUBCOLUMNS = [
    {
      width: "100%",
      render: (record) => {
        const IconComp = record.submitted ? AiFillFile : AiOutlineFile;
        return (
          <Container
            onClick={openPDFHandler.bind(
              this,
              formConvert(currentData, record)
            )}
            style={{
              cursor: "pointer",
            }}
          >
            <Space direction="horizontal">
              <Icon component={IconComp} size="24px" color="#2BA796" />
              <Text className="ml-1" type="text">
                {t(record.fileName)}
              </Text>
            </Space>
          </Container>
        );
      },
    },
    {
      title: "",
      align: "right",
      render: (record) => (
        <Space>
          <Button
            disabled={loading}
            key="preview"
            onClick={savePDFHandler.bind(
              this,
              formConvert(currentData, record)
            )}
          >
            <Text bold size="11px" color="#2ba796">
              {t("export")}
            </Text>
          </Button>
        </Space>
      ),
    },
  ];

  const COLUMNS = [
    {
      title: "FILE NAME",
      className: "pl-0",
      render: (record) => (
        <Container>
          <Space direction="horizontal">
            <Text>{t(record.key)}</Text>
          </Space>
        </Container>
      ),
    },
  ];

  return (
    <ModalCustom
      width={800}
      title={
        <Space className="w-100" align="center">
          <Icon component={FaFolder} size="26px" color="#2BA796" />
          <Text className="ml-1" bold color="#2BA796">
            {currentData.filtering?.subjectId}
          </Text>
        </Space>
      }
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Container>
        <Table
          pagination={false}
          dataSource={crfFormList}
          columns={COLUMNS}
          showHeader={false}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => {
              const data = filterDataSubTable(currentData, record);
              if (data.length === 0) {
                return false;
              }
              return true;
            },
            expandIcon: ({ expanded, onExpand, record }) => {
              const data = filterDataSubTable(currentData, record);
              if (data.length === 0) {
                return (
                  <Icon component={FaRegFolder} size="24px" color="#2BA796" />
                );
              }
              return expanded ? (
                <Icon
                  component={FaFolderMinus}
                  size="24px"
                  color="#2BA796"
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <Icon
                  component={FaFolderPlus}
                  size="24px"
                  color="#2BA796"
                  onClick={(e) => onExpand(record, e)}
                />
              );
            },
          }}
        />
      </Container>
    </ModalCustom>
  );
}

CtmsDocumentListModal.propTypes = {
  t: PropTypes.any,
  currentData: PropTypes.object,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setSubVisible: PropTypes.func,
  crfFormList: PropTypes.array,
  previewComponent: PropTypes.object,
  fileNameHandler: PropTypes.func,
  constSubId: PropTypes.array,
};
