import React, { useEffect, useState } from "react";
import HeartRateValueCard from "./HeartRateValueCard";
import HeartRateTrendCard from "./HeartRateTrendCard";
import HeartRateGraphCard from "./HeartRateGraphCard";
import { Container } from "./styles";
import { HeartRateValue, HeartRateTrend, HeartRateGraph } from "../constant";
const HeartRate = ({
  heartRates,
  filterReport,
  defaultHeartRates,
  isVideoCall,
  t,
}) => {
  const [values, setValues] = useState(HeartRateValue);
  const [trends, setTrends] = useState(HeartRateTrend);
  const [graphs, setGraphs] = useState(HeartRateGraph);

  useEffect(() => {
    if (
      heartRates.data &&
      heartRates.data.arr.length &&
      heartRates.data.dates.length
    ) {
      checkHeartRateValue();
    } else {
      setValues(HeartRateValue);
      setTrends(HeartRateTrend);
      setGraphs(HeartRateGraph);
    }
  }, [heartRates]);

  const findMin = (arr) => Math.min(...arr);
  const findMax = (arr) => Math.max(...arr);
  const findAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

  const checkHeartRateValue = () => {
    const { arr } = heartRates.data;
    setValues({
      min: findMin(arr),
      max: findMax(arr),
      average: findAverage(arr),
    });
    checkHeartRateTrend();
  };

  const checkHeartRateTrend = () => {
    if (defaultHeartRates) {
      const { arr } = heartRates.data;
      let lowest = defaultHeartRates[0];
      let highest = defaultHeartRates[1];
      const trend = { ...HeartRateTrend };
      arr.map((value) => {
        if (Number(value) < Number(lowest)) {
          trend.low++;
        } else if (
          Number(value) >= Number(lowest) &&
          Number(value) <= Number(highest)
        ) {
          trend.mid++;
        } else if (Number(value) > Number(highest)) {
          trend.high++;
        }
      });
      setTrends((prev) => ({
        ...trend,
        pie: {
          ...prev.pie,
          datasets: [
            {
              ...prev.pie.datasets[0],
              data: [trend.mid, trend.low, trend.high],
            },
          ],
        },
      }));
      checkHeartRateGraph(lowest, highest);
    }
  };

  const checkHeartRateGraph = (lowest, highest) => {
    const { arr, dates } = heartRates.data;
    setGraphs((prev) => ({
      defaultLow: lowest,
      defaultHigh: highest,
      lines: {
        options: {
          ...prev.lines.options,
          xaxis: {
            categories: dates,
          },
          annotations: {
            yaxis: [
              { ...prev.lines.options.annotations.yaxis[0], y: highest },
              {
                ...prev.lines.options.annotations.yaxis[1],
                y: lowest,
                y2: highest,
              },
              {
                ...prev.lines.options.annotations.yaxis[2],
                y: lowest,
              },
            ],
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="arrow_box" style="padding: 16px">
                      <p>HeartRate Times: <b>${
                        series[seriesIndex][dataPointIndex]
                      }</b></p>
                      <p>Date: <b>${dates[dataPointIndex] || "-"}</b></p>
                      </div>`;
            },
          },
        },
        series: [
          {
            name: "HeartRate Times",
            data: arr,
          },
        ],
      },
    }));
  };

  return (
    <Container>
      <HeartRateValueCard
        t={t}
        values={values}
        filterReport={(start, end) => filterReport(start, end, 8)}
      />
      <HeartRateTrendCard t={t} trends={trends} isVideoCall={isVideoCall} />
      <HeartRateGraphCard
        t={t}
        graphs={graphs}
        defaultHeartRates={defaultHeartRates}
      />
    </Container>
  );
};

export default HeartRate;
