import { Container, Text, VitalContainer, InputBoderBottom } from "../styles";
import { Row, Col, Space, Descriptions } from "antd";

const VitalSignsSection = ({ t, form, disabled }) => {
  const { handleChange = () => {}, values } = form;

  return (
    <Container>
      <Row gutter={[8, 8]}>
        <Col>
          <VitalContainer>
            <Text size="12px" bold>
              {t("blood_pressure")}
            </Text>
            {disabled ? (
              <Text style={{ height: 35, minWidth: 150 }}>
                {values.bloodPressureRange
                  ? values.bloodPressureRange
                  : values.bloodPressure.toString().replace(".", "/")}
              </Text>
            ) : (
              <InputBoderBottom
                value={values.bloodPressureRange || ""}
                onChange={handleChange("bloodPressureRange")}
                placeHolder="Value"
              />
            )}
          </VitalContainer>
        </Col>
        <Col>
          <VitalContainer>
            <Text size="12px" bold>
              {t("heart_rate")}
            </Text>
            {disabled ? (
              <Text style={{ height: 35, minWidth: 150 }}>
                {values.heartRate || t("nothing")}
              </Text>
            ) : (
              <InputBoderBottom
                value={values.heartRate || ""}
                onChange={handleChange("heartRate")}
                placeHolder="Value"
              />
            )}
          </VitalContainer>
        </Col>
        <Col>
          <VitalContainer>
            <Text size="12px" bold>
              {t("respiration_rate")}
            </Text>
            {disabled ? (
              <Text style={{ height: 35, minWidth: 150 }}>
                {values.rr || t("nothing")}
              </Text>
            ) : (
              <InputBoderBottom
                value={values.rr || ""}
                onChange={handleChange("rr")}
                placeHolder="Value"
              />
            )}
          </VitalContainer>
        </Col>
        <Col>
          <VitalContainer>
            <Text size="12px" bold>
              {t("pulse_oximeter")}
            </Text>
            {disabled ? (
              <Text style={{ height: 35, minWidth: 150 }}>
                {values.pulseOx || t("nothing")}
              </Text>
            ) : (
              <InputBoderBottom
                value={values.pulseOx || ""}
                onChange={handleChange("pulseOx")}
                placeHolder="Value"
              />
            )}
          </VitalContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default VitalSignsSection;
