import {
  Upload,
  Input as InputAnt,
  Button as ButtonAnt,
  DatePicker as DatpickerAnt,
  Tabs as TabsAnt,
  Dropdown,
  Space,
  Row,
  InputNumber as InputNumberAnt,
  Collapse as CollapseAnt,
} from "antd";
import { IoMdArrowDropdown } from "react-icons/all";
import IconAnt from "@ant-design/icons";
import styled from "styled-components";
import TextBase from "../Text";

const { Dragger: DraggerAnt } = Upload;
const { TextArea: TextAreaAnt } = InputAnt;
const { TabPane: TabPaneAnt } = TabsAnt;

const Container = styled.div`
  width: 100%;
`;

const Text = styled(TextBase)`
  color: ${(props) => (props.primary ? "#2BA796" : props.color)};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const TextError = styled(TextBase)`
  color: red;
  font-size: 12px;
  margin: 0px;
  position: absolute;
`;

const Icon = styled(IconAnt)`
  &&&& {
    color: ${(props) => props.color} !important;
  }
  font-size: ${(props) => props.size};
`;

const Dragger = styled(DraggerAnt)`
  &&& {
    border-radius: 10px;
    background: #fff;
  }
`;
const TextArea = styled(TextAreaAnt)`
  && {
    min-height: 100px;
    border-radius: 10px;
  }
`;

const Button = styled(ButtonAnt)`
  && {
    height: auto;
    border-radius: 8px;
    padding: 5px 25px;
  }
`;

const InputNumber = styled(InputNumberAnt)`
  &&& {
    width: 100%;
  }
`;

const DropDownContainer = styled(ButtonAnt)`
  && {
    border-radius: 8px;
    padding: 10px;
    height: auto;
    min-width: ${(props) => props.minWidth};
    width: ${(props) => props.width};
    text-align: left;
  }
`;

const DatePicker = styled(DatpickerAnt)`
  && {
    border-radius: 8px;
    padding: 10px;
    height: auto;
    min-width: ${(props) => props.minWidth};
    width: ${(props) => props.width};
    text-align: left;
    color: lightgrey;
  }
`;

const Input = styled(InputAnt)`
  && {
    border-radius: 8px;
    padding: 10px;
    min-width: ${(props) => props.minWidth};
    width: ${(props) => props.width};
    height: auto;
  }
`;

const TabPane = styled(TabPaneAnt)`
  && {
    margin: 0px;
  }
`;

const Tabs = styled(TabsAnt)`
  && {
    margin: 0px;
  }
`;

const CollapseCustom = styled(CollapseAnt)`
  && {
    background-color: unset;
    border: none;
  }
`;

const DropDownWidget = ({
  title,
  placeholder,
  value,
  required,
  renderList,
  className,
  width,
  bold,
  minWidth,
  handleButtonClick,
}) => {
  return (
    <Container
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineBreak: "auto",
      }}
      className={className}
    >
      {!!title && (
        <Space direction="horizontal w-100">
          <Text size="12px" bold={bold ? true : false}>
            {title}
          </Text>
          {required && <Text color="red">*</Text>}
        </Space>
      )}
      <Dropdown overlay={renderList} trigger={["click"]}>
        <DropDownContainer
          onClick={handleButtonClick}
          width={width}
          minWidth={minWidth ? minWidth : "100px"}
        >
          <Row align="middle" justify="space-between">
            <Text color={value ? "#000" : "lightgrey"}>
              {value ? value : placeholder}
            </Text>
            <Icon component={IoMdArrowDropdown} size="18px" color="#2BA796" />
          </Row>
        </DropDownContainer>
      </Dropdown>
    </Container>
  );
};

const HeaderPanel = ({ title, iconComponent }) => {
  return (
    <Space direction="horizontal">
      <Icon component={iconComponent} size="20px" color="#2BA796" />
      <Text size="16px" bold>
        {title}
      </Text>
    </Space>
  );
};

export {
  Container,
  Text,
  Icon,
  Dragger,
  TextArea,
  Button,
  ButtonAnt,
  DropDownContainer,
  DropDownWidget,
  DatePicker,
  Input,
  TabPane,
  Tabs,
  InputNumber,
  CollapseCustom,
  HeaderPanel,
  TextError,
};
