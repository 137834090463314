import * as type from "../types";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_MEDICATION_REQUESTED:
      return {
        loading: true,
        error: null,
      };
    case type.GET_MEDICATION_SUCCESS:
      return {
        data: action.data,
        loading: false,
      };
    case type.GET_MEDICATION_FAILED:
      return {
        loading: false,
        error: action.error,
      };
    case type.GET_MEDICATION_KEYWORD_REQUESTED:
      return {
        loading: true,
        error: null,
      };
    case type.GET_MEDICATION_KEYWORD_SUCCESS:
      return {
        data: action.data,
        loading: false,
      };
    case type.GET_MEDICATION_KEYWORD_FAILED:
      return {
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
