export const PENDING_NOTE_STATUS = {
  doctor: "DOCTOR_PENDING_NOTE",
  pharmacy: "PHARMACY_PENDING_NOTE",
  communityPharmacist: "COMMUNITY_PHARMACIST_PENDING_NOTE",
};

export const COLORS = [
  { label: "เขียว", value: "green" },
  { label: "เหลือง", value: "yellow" },
  { label: "แดง", value: "red" },
];
