import { Card } from "antd";
import styled from "styled-components";

export const CardSummaryStyled = styled(Card)`
  border: none !important;
  border-radius: 20px !important;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  padding: 8px;
  height: 100%;
`;

export const NoteBox = styled("pre")`
  width: 100%;
  height: 100%;
  word-break: break-all;
  white-space: pre-wrap;
`;
