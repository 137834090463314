import React from "react";
import EverLogo from "../../../images/EverLogo.png";
import { checkDateInvalid, isNumberValidate } from "../utils";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  HeaderContainer,
  Icon,
  SpaceItemFlex,
} from "../styles";
import { Row, Col, Space, Upload, message, Button, Descriptions } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { HEADER_DEFAULT_FORMS } from "../constant";
import moment from "moment";

import {
  CHECK_BOX_LIST_EXCLUSION,
  CHECK_BOX_LIST_INCLUSION,
} from "../../ClinicTrialParticipantRegister/constant";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineCheck,
} from "react-icons/all";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default function EligibilityCriteriaForm(props) {
  const { t, form = {}, editable } = props;
  return (
    <Container>
      <Text className="text-center" size="16px" color="#0000008C">
        {t(HEADER_DEFAULT_FORMS)}
      </Text>
      <Container className="px-5 mt-3 rounded border">
        <Image className="mt-4" src={EverLogo} />
        <Row gutter={[16, 16]} className="my-4" align="middle">
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("subjects_initials")}:
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.subjectsInitials}
                padding="2px"
                onChange={form.handleChange("subjectsInitials")}
                maxLength={3}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_subject_id")}:
              </Text>
              <Input disabled value={form.values?.subjectId} padding="2px" />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_exam_date")}:
              </Text>
              <DatePicker
                disabled
                value={checkDateInvalid(form.values?.createdAt)}
                format="DD/MM/YYYY"
                padding="2px"
                width="130px"
              />
            </Space>
          </Col>
        </Row>
        <HeaderContainer>
          <Text className="my-2 text-center" bold size="16px">
            {t(form.values?.type)}
          </Text>
        </HeaderContainer>
        <Container>
          <Text size="18px" bold color="grey" className="mt-3">
            {t("crf_inclusion_criteria")}
          </Text>
          <Text bold color="grey">
            Patients who meet all of the following criteria are eligible for
            enrollment as study participants:
          </Text>
          <table className="table table-bordered">
            <thead>
              <tr>
                <td></td>
                <td>YES</td>
                <td>NO</td>
              </tr>
            </thead>
            <tbody>
              {CHECK_BOX_LIST_INCLUSION.map((item) => {
                return (
                  <tr key={item.key}>
                    <td
                      style={{
                        backgroundColor: "#fafafa",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {t(item.title)}
                    </td>
                    <td className="align-middle text-center">
                      {form.values?.[item.key] && (
                        <Icon
                          component={AiOutlineCheck}
                          size="20px"
                          color="#2BA796df"
                        />
                      )}
                    </td>
                    <td className="align-middle text-center">
                      {!form.values?.[item.key] && (
                        <Icon
                          component={AiOutlineCheck}
                          size="20px"
                          color="#2BA796df"
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Text size="18px" bold color="grey">
            {t("crf_exclusion_criteria")}
          </Text>
          <Text bold color="grey">
            Patients who meet any of these criteria are not eligible for
            enrollment as study participants:
          </Text>
          <table className="table table-bordered">
            <thead>
              <tr>
                <td></td>
                <td>YES</td>
                <td>NO</td>
              </tr>
            </thead>
            <tbody>
              {CHECK_BOX_LIST_EXCLUSION.map((item) => {
                return (
                  <tr key={item.key}>
                    <td
                      style={{
                        backgroundColor: "#fafafa",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {t(item.title)}
                    </td>
                    <td className="align-middle text-center">
                      {form.values?.[item.key] && (
                        <Icon
                          component={AiOutlineCheck}
                          size="20px"
                          color="#2BA796df"
                        />
                      )}
                    </td>
                    <td className="align-middle text-center">
                      {!form.values?.[item.key] && (
                        <Icon
                          component={AiOutlineCheck}
                          size="20px"
                          color="#2BA796df"
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Container>
        <Container>
          <Row gutter={[16, 16]} className="mb-4">
            <Col span={14}>
              <SpaceItemFlex>
                <Text>Form Completed by:</Text>
                <Input
                  disabled={!editable}
                  value={form.values?.completedBy}
                  padding="2px"
                  width="100%"
                  maxLength={100}
                  onChange={form.handleChange("completedBy")}
                />
              </SpaceItemFlex>
            </Col>
            <Col span={10}>
              <Space>
                <Text>Date:</Text>
                <DatePicker
                  disabled={!editable}
                  value={checkDateInvalid(form.values?.completedDate)}
                  format="DD/MM/YYYY"
                  padding="2px"
                  width="130px"
                  onChange={(date) => form.setFieldValue("completedDate", date)}
                />
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={14}>
              <Space>
                <Text>Site PI Signature:</Text>
                {form.values?.sitePISignatureURL && (
                  <Image
                    style={{ height: "100%", maxHeight: 100, width: "auto" }}
                    src={form.values?.sitePISignatureURL}
                  />
                )}
                <Upload
                  showUploadList={false}
                  onChange={(info) => {
                    if (info.file.status === "done") {
                      getBase64(info.file.originFileObj, (url) =>
                        form.setFieldValue("sitePISignatureURL", url)
                      );
                    } else if (info.file.status === "error") {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}
                  customRequest={dummyRequest}
                >
                  <Button disabled={!editable} icon={<UploadOutlined />}>
                    {t("crf_upload")}
                  </Button>
                </Upload>
              </Space>
            </Col>
            <Col span={10}>
              <Space>
                <Text>Date:</Text>
                <DatePicker
                  disabled={!editable}
                  value={checkDateInvalid(form.values?.sitePISignatureDate)}
                  format="DD/MM/YYYY"
                  padding="2px"
                  width="130px"
                  onChange={(date) =>
                    form.setFieldValue("sitePISignatureDate", date)
                  }
                />
              </Space>
            </Col>
          </Row>
        </Container>

        <Space className="my-4" size={25}>
          <Space>
            <Text bold color="grey">
              {t("crf_form_number")}
            </Text>
            <Input
              disabled={!editable}
              value={form.values?.formNumber}
              padding="2px"
              width="60px"
              maxLength={2}
              onChange={(e) => {
                if (isNumberValidate(e.target.value)) {
                  form.setFieldValue("formNumber", e.target.value);
                }
              }}
            />
          </Space>
          <Text color="grey">{t("crf_version_date")}: 01/28/2022</Text>
        </Space>
      </Container>
    </Container>
  );
}
