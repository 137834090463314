import * as type from "../types";

export function getBookings(id, page, order, pageSize, orderByTab, status) {
  return {
    type: type.GET_BOOKINGS_REQUESTED,
    id,
    page,
    order,
    pageSize,
    orderByTab,
    status,
  };
}
export function getBooking(id) {
  return {
    type: type.GET_BOOKING_REQUESTED,
    id,
  };
}
export function getHistoryBookingByDateTime(status, routineType, dateTime) {
  return {
    type: type.GET_HISTORY_BOOKING_BY_DATE_TIME_REQUESTED,
    status,
    routineType,
    dateTime,
  };
}

export function getBookingHistory(userId, patientId, status) {
  return {
    type: type.GET_BOOKING_HISTORY_REQUESTED,
    userId,
    patientId,
    status,
  };
}

export function getBookingHistoryWithEarning(userId, patientId, status) {
  return {
    type: type.GET_BOOKING_HISTORY_WITH_EARNING_REQUESTED,
    userId,
    patientId,
    status,
  };
}

export function searchBookings(
  organizationId,
  firstname,
  lastname,
  cId,
  admitTimeRangeType,
  status,
  page,
  pageSize
) {
  return {
    type: type.SEARCH_BOOKING_REQUESTED,
    organizationId,
    firstname,
    lastname,
    cId,
    admitTimeRangeType,
    status,
    page,
    pageSize,
  };
}

export function updateBooking(id, isUpdate, detail) {
  return {
    type: type.UPDATE_BOOKING_REQUESTED,
    id,
    isUpdate,
    detail,
  };
}

export function getBookingMedication(id) {
  return {
    type: type.GET_MEDICATION_BOOKING_REQUESTED,
    id,
  };
}

export function addBookingMedication(id, medicines) {
  return {
    type: type.ADD_MEDICATION_BOOKING_REQUESTED,
    id,
    medicines,
  };
}

export function deleteMedicationItem(id, medicines) {
  return {
    type: type.DELETE_MEDICATION_ITEM_REQUESTED,
    id,
    medicines,
  };
}

export function setCurrentBooking(data) {
  return {
    type: type.GET_CURRENT_BOOKING_REQUESTED,
    data,
  };
}
