import * as type from "../types";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_RECRUITMENT_OBJECT_BY_ID_REQUESTED:
      return {
        isLoading: true,
        error: null,
        isSuccess: false,
      };
    case type.GET_RECRUITMENT_OBJECT_BY_ID_SUCCESS:
      return {
        data: action.data,
        isLoading: false,
        isSuccess: true,
      };
    case type.GET_RECRUITMENT_OBJECT_BY_ID_FAILED:
      return {
        isLoading: false,
        error: action.error,
        isSuccess: false,
      };

    default:
      return state;
  }
};
