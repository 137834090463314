import { call, put, takeEvery } from "redux-saga/effects";
import { MedicationForOrganization } from "../../api";
import * as type from "../types";

function* fetchMedications(data) {
  try {
    const result = yield call(
      MedicationForOrganization.fetchMedications,
      data.genericName
    );
    yield put({
      type: type.GET_MEDICATION_FOR_ORGANIZATION_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_MEDICATION_FOR_ORGANIZATION_FAILED,
      error: e.message,
    });
  }
}

function* medicationForOrganizationSaga() {
  yield takeEvery(
    type.GET_MEDICATION_FOR_ORGANIZATION_REQUESTED,
    fetchMedications
  );
}

export default medicationForOrganizationSaga;
