import React from "react";
import { Card } from "antd";
import Chart from "react-apexcharts";

const BmiGraph = (props) => {
  const { t } = props;
  const { graphs, defaultBmi } = props;
  const { lines } = graphs;

  return (
    <Card className="mt-2" title={t("bmi_trend")}>
      <Chart
        options={lines.options}
        series={lines.series}
        type="line"
        width="550"
      />
    </Card>
  );
};

export default BmiGraph;
