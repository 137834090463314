import React from "react";
import {
  Row,
  Col,
  List,
  Button,
  Modal,
  Typography,
  Table,
  Dropdown,
  Menu,
  InputNumber,
  Input,
  Select,
  Divider,
  Space,
} from "antd";
import {
  PlusOutlined,
  MoreOutlined,
  DeleteFilled,
  StarOutlined,
} from "@ant-design/icons";
import { FaPrescriptionBottleAlt } from "react-icons/fa";
import { SummaryCard } from "./styles";
import {
  ROLE_ACCESS_TO_REMOVE_DRUG,
  UNITS,
  DRUG_TIME_EVENTS,
  DRUG_ROUTES,
  DRUG_FREQUENCIES,
} from "./constant";
import "./style.css";
import Loading from "../Loading";

const { Option } = Select;
const { Title } = Typography;

const PrescriptionSummary = ({
  t,
  role,
  booking,
  medicines,
  medication,
  setMedicines,
  handleCloseSymptomGroup,
  handleOkSymptomGroup,
  handleChangeSymptomGroup,
  onSearch,
  visibleSymptomGroup,
  setVisibleSymptomGroup,
  deleteDrugFromPrescription,
  getDrugTimeEventInfo,
  getDrugTimeFrequencyInfo,
  symptomGroups,
  handleSelectMedicine,
}) => {
  const getDrugRouteInfo = (id, drugRoutes) => {
    const drugTimeInfo = DRUG_ROUTES.find((route) => route.id === id);
    return drugTimeInfo;
  };

  const column_medication = [
    {
      render: (text, record, index) => (
        <DeleteFilled
          onClick={() =>
            setMedicines((prev) => prev.filter((medicine, i) => i !== index))
          }
        />
      ),
    },
    {
      title: t("generic_name"),
      dataIndex: "activeIngredient",
      width: "220px",
      align: "left",
    },
    {
      title: t("trade_name"),
      dataIndex: "tradeName",
      width: "220px",
      align: "left",
    },
    {
      title: t("dose"),
      dataIndex: ["dose"],
      width: "100px",
      align: "center",
      render: (text, record, index) => (
        <InputNumber
          min={1}
          defaultValue={text}
          onChange={(dose) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              newMedicines[index].dose = dose;
              return newMedicines;
            })
          }
          className="w-100"
        />
      ),
    },
    {
      title: t("unit"),
      dataIndex: ["unitType"],
      width: "100px",
      align: "center",
    },
    {
      title: t("drug_routes"),
      dataIndex: ["medication", "drugRoutes"],
      align: "center",
      width: "200px",
      render: (text, record, index) => (
        <Select
          showSearch
          clearIcon
          defaultValue={text[0].id}
          optionFilterProp="children"
          onChange={(drugRouteId) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              const drugRouteInfo = getDrugRouteInfo(
                drugRouteId,
                newMedicines[index].drugRoutes
              );
              newMedicines[index].drugRoute = drugRouteInfo;
              return newMedicines;
            })
          }
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          className="w-100"
        >
          {text.map((item) => (
            <Option value={item.id} key={item.id}>
              <div>
                <p className="mb-0 pb-0">{item.routeTh}</p>
                <small>{item.routeEn}</small>
              </div>
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: t("drug_time_events"),
      key: "drugTimeEvents",
      dataIndex: ["drugTimeEvent", "code"],
      align: "center",
      width: "250px",
      render: (text, record, index) => (
        <Select
          showSearch
          value={text ?? "AC"}
          clearIcon
          optionFilterProp="children"
          onChange={(drugTime) => {
            setMedicines((prev) => {
              const newMedicines = [...prev];
              const drugTimeInfo = getDrugTimeEventInfo(drugTime);
              newMedicines[index].drugTimeEvent = drugTimeInfo;
              return newMedicines;
            });
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          className="w-100"
        >
          {DRUG_TIME_EVENTS.map((item) => (
            <Option value={item.code} key={item.id}>
              <div>
                <p className="mb-0 pb-0">{item.nameTh}</p>
                <small>{item.code}</small>
              </div>
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: t("drug_time_frequency"),
      dataIndex: ["drugTimeFrequency", "code"],
      index: "drugTimeFrequency",
      align: "center",
      width: "250px",
      render: (text, record, index) => (
        <Select
          showSearch
          value={text ?? "A"}
          clearIcon
          optionFilterProp="children"
          onChange={(drugTime) => {
            setMedicines((prev) => {
              const newMedicines = [...prev];
              const drugTimeInfo = getDrugTimeFrequencyInfo(drugTime);
              newMedicines[index].drugTimeFrequency = drugTimeInfo;
              return newMedicines;
            });
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          className="w-100"
        >
          {DRUG_FREQUENCIES.map((item) => (
            <Option value={item.code} key={item.id}>
              <div>
                <p className="mb-0 pb-0">{item.nameTh}</p>
                <small>{item.code}</small>
              </div>
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: t("total_amount"),
      dataIndex: ["amount"],
      align: "center",
      width: "150px",
      render: (text, record, index) => (
        <InputNumber
          min={1}
          value={text}
          onChange={(amount) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              newMedicines[index].amount = amount;
              return newMedicines;
            })
          }
          className="w-100"
        />
      ),
    },
    {
      title: t("dispense_unit"),
      dataIndex: ["medication", "dispenseUnitEn"],
      align: "center",
      width: "150px",
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              newMedicines[index].medication.dispenseUnitEn = e.target.value;
              return newMedicines;
            })
          }
          className="w-100"
        />
      ),
    },
    {
      title: t("price"),
      align: "center",
      dataIndex: ["unitPriceCents"],
      width: "150px",
      render: (text, record, index) => (
        <InputNumber
          min={1}
          value={text ? text / 100 : 1}
          onChange={(price) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              newMedicines[index].unitPriceCents = price * 100;
              return newMedicines;
            })
          }
        />
      ),
    },
    {
      title: t("note"),
      dataIndex: ["note"],
      align: "center",
      width: "200px",
      render: (text, record, index) => (
        <Input
          placeholder="Note"
          value={text}
          onChange={(e) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              newMedicines[index].note = e.target.value;
              return newMedicines;
            })
          }
        />
      ),
    },
  ];

  return (
    <>
      <Modal
        visible={visibleSymptomGroup}
        title={t("prescription")}
        width={1200}
        onCancel={handleCloseSymptomGroup}
        onOk={handleOkSymptomGroup}
        okText={t("confirm")}
        cancelText={t("cancel")}
      >
        <Select
          showSearch
          style={{ width: 500 }}
          onChange={handleSelectMedicine}
          value=""
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownRender={(menu) =>
            medication.loading ? (
              <div style={{ padding: 5, textAlign: "center" }}>
                <Loading />
              </div>
            ) : (
              <>
                {menu}
                <Divider style={{ margin: "4px 0" }}>
                  {t("symptom_group")}
                </Divider>
                <Space
                  style={{
                    padding: "0 8px 4px",
                    color: "#000",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100px",
                    overflowY: "scroll",
                  }}
                  align="left"
                >
                  {symptomGroups.length > 0 &&
                    symptomGroups.map((item, index) => (
                      <Typography
                        onClick={() => {
                          handleChangeSymptomGroup(item.id);
                        }}
                        key={index}
                      >
                        {item.name}
                        <StarOutlined style={{ marginLeft: 10 }} />
                      </Typography>
                    ))}
                </Space>
              </>
            )
          }
        >
          {medication.data.map((item) => {
            const name = `${item.genericName} (${
              item.medication && item.medication.tradeName
                ? item.medication.tradeName
                : ""
            })`;
            return (
              <Option value={item.id} key={item.id}>
                {name}
              </Option>
            );
          })}
        </Select>
        <Table
          dataSource={medicines}
          columns={
            role === "doctor"
              ? column_medication.filter(
                  (item) => !["tradeName", "price"].includes(item.key)
                )
              : column_medication
          }
          className="my-3"
          style={{
            maxHeight: "400px",
            border: "1px solid #ccc",
          }}
          scroll={{ x: "max-content", y: "300px" }}
          pagination={false}
          locale={{
            emptyText: (
              <div className="d-flex align-items-center justify-content-center flex-column text-center h-100 mt-5">
                <FaPrescriptionBottleAlt
                  style={{ fontSize: "36px", color: "#757575" }}
                />
                <p className="mt-2 mb-5 text-dark">{t("no_prescription")}</p>
              </div>
            ),
          }}
        />
      </Modal>
    </>
  );
};

export default PrescriptionSummary;
