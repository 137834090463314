import { Layout } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import { Menu, AppUserOma } from "../../redux/actions";
import "./style.css";
import { Container } from "./styles";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;

const { Header } = Layout;

const Home = (props) => {
  const { t } = useTranslation();
  const { render, history } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.appUserOma);
  const menu = useSelector((state) => state.menu);

  const [isOpenSidebar, setIsOpenSidebar] = useState(true);
  const userInfo = user && user.information;
  const userOrg = user && user.organization?.data;
  const isLoading = !(menu && menu.data && menu.data.length && menu.current);
  const { id, roles } = jwtDecode(localStorage.getItem("token"));

  useEffect(() => {
    if (!user.information) {
      dispatch(AppUserOma.getUser(id));
      if (["doctor", "communityPharmacist"].includes(roles[0])) {
        dispatch(AppUserOma.getProfile(id));
      }
    }
    if (user.organization.isSuccess === false) {
      dispatch(AppUserOma.getOrganizationsUser(id));
    }
  }, []);

  useEffect(() => {
    if (userInfo && userOrg && menu.data.length === 0) {
      fetchUser();
    }
  }, [user, menu]);

  useEffect(() => {
    if (menu && menu.data && menu.data.length) {
      if (!localStorage.getItem("menu") && !menu.current) {
        checkCurrentMenu();
      } else if (!menu.current) {
        const data = JSON.parse(localStorage.getItem("menu"));
        dispatch(Menu.setMenu(data));
        history.push(`/${data.value}`);
      }
    }
  }, [menu]);

  const checkCurrentMenu = () => {
    const { data } = menu;
    localStorage.setItem("menu", JSON.stringify(data[0]));
    dispatch(Menu.setMenu(data[0]));
    data[0].onClick();
  };

  const fetchUser = async () => {
    const sidebarOptions = await getSidebarOptions();
    dispatch(Menu.getMenus(sidebarOptions));
  };

  const getSidebarOptions = () => {
    const optionFilter = [
      {
        label: t("homepage"),
        icon: "home",
        value: "homepage",
        onClick: () => history.push("/homepage"),
      },
      {
        label: t("support_dashboard"),
        icon: "home",
        value: "support-dashboard",
        onClick: () => history.push("/support-dashboard"),
      },
      {
        label: t("dashboard_map"),
        icon: "home",
        value: "dashboard-map",
        onClick: () => history.push("/dashboard-map"),
      },
      {
        label: t("clinic_trial"),
        icon: "home",
        value: "clinic-trial",
        onClick: () => history.push("/clinic-trial"),
      },
      {
        label: t("clinic_trial_document"),
        icon: "folder",
        value: "clinic-document",
        onClick: () => history.push("/clinic-document"),
      },
      {
        label: t("scheduling"),
        icon: "inbox",
        value: "booking",
        onClick: () => history.push("/booking"),
      },
      {
        label: t("history_booking"),
        icon: "inbox",
        value: "history-booking",
        onClick: () => history.push("/history-booking"),
      },
      {
        label: t("covid_dashboard"),
        icon: "inbox",
        value: "dashboard-covid",
        onClick: () => history.push("/dashboard-covid"),
      },
      {
        label: t("telemedicine_room"),
        icon: "inbox",
        value: "lobby/telemedicine",
        onClick: () => history.push("/lobby/telemedicine"),
      },
      {
        label: t("covid_room"),
        icon: "inbox",
        value: "round-robin/covid",
        onClick: () => history.push("/round-robin/covid"),
      },
      {
        label: t("round_robin"),
        icon: "inbox",
        value: "round-robin",
        onClick: () => history.push("/round-robin"),
      },
      {
        label: t("doctor_lobby"),
        icon: "inbox",
        value: "doctor-lobby",
        onClick: () => history.push("/doctor-lobby"),
      },
      {
        label: t("general_room"),
        icon: "inbox",
        value: "round-robin/general",
        onClick: () => history.push("/round-robin/general"),
      },
      {
        label: t("monitor_lobby"),
        icon: "inbox",
        value: "monitor-lobby",
        onClick: () => history.push("/monitor-lobby"),
      },
      {
        label: t("user_list"),
        icon: "inbox",
        value: "userlist",
        onClick: () => history.push("/userlist"),
      },
      {
        label: t("submission_list"),
        icon: "inbox",
        value: "submissionlist",
        onClick: () => history.push("/submissionlist"),
      },
      {
        label: t("register"),
        icon: "inbox",
        value: "register",
        onClick: () => history.push("/register"),
      },
      {
        label: t("partner_earning"),
        icon: "inbox",
        value: "partner-earnings",
        onClick: () => history.push("/partner-earnings"),
      },
      {
        label: t("earning"),
        icon: "inbox",
        value: "earning",
        onClick: () => history.push("/earning"),
      },
      {
        label: t("settings"),
        icon: "setting",
        value: "doctor-setting",
        onClick: () => history.push("/settings"),
      },
      {
        label: t("logout"),
        icon: "inbox",
        value: "logout",
        onClick: () => {
          history.push("/logout");
        },
      },
    ];
    if (
      get(user.organization, "data", [])
        .map((item) => item.name)
        .includes("National Cancer Institute (NCI)") &&
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("callCenter")
    ) {
      return optionFilter.filter((option) =>
        ["submissionlist", "logout"].includes(option.value)
      );
    }
    if (
      get(user.organization, "data", [])
        .map((item) => item.name)
        .includes("National Cancer Institute (NCI)")
    ) {
      return optionFilter.filter((option) =>
        ["clinic-trial", "clinic-document", "logout"].includes(option.value)
      );
    }
    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("doctor")
    ) {
      return optionFilter.filter((option) =>
        [
          "doctor-lobby",
          "homepage",
          "booking",
          // "history-booking",
          "lobby/telemedicine",
          // "round-robin",
          "partner-earnings",
          "doctor-setting",
          "logout",
        ].includes(option.value)
      );
    }
    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("nurse")
    ) {
      return optionFilter.filter((option) =>
        [
          "homepage",
          "booking",
          "lobby/telemedicine",
          "round-robin",
          "logout",
        ].includes(option.value)
      );
    }
    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("callCenter")
    ) {
      return optionFilter.filter((option) =>
        [
          "support-dashboard",
          "userlist",
          "booking",
          "register",
          "dashboard-covid",
          "submissionlist",
          "logout",
        ].includes(option.value)
      );
    }
    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("pharmacy")
    ) {
      return optionFilter.filter((option) =>
        [
          "homepage",
          "booking",
          "lobby/telemedicine",
          "round-robin/general",
          "logout",
        ].includes(option.value)
      );
    }
    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("communityPharmacist")
    ) {
      return optionFilter.filter((option) =>
        ["dashboard-map", "earning", "logout"].includes(option.value)
      );
    }
  };
  return isLoading ? (
    <Loading />
  ) : (
    <Layout style={{ minHeight: "100vh" }}>
      <div
        style={{
          height: 70,
          background: "white",
          flexDirection: "row",
          display: "flex",
          paddingLeft: 50,
          paddingRight: 50,
          alignItems: "center",
          paddingTop: 10,
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ever-oma-dev.appspot.com/o/ivory-coast%2Ffavicon.png?alt=media&token=fee58d00-aa48-4a8d-bda3-cd162e772d0b"
          style={{ height: 50, width: 50 }}
        />
        <div style={{ fontWeight: "bold", fontSize: 26, paddingLeft: 8 }}>
          Santé Pour Tous
        </div>
      </div>
      <Header>
        {isOpenSidebar && <Sidebar options={menu} history={history} />}
      </Header>
      <Container>
        {render({
          userInfo,
          history,
        })}
      </Container>
    </Layout>
  );
};

export default Home;
