import styled from "styled-components";
import { Modal, Card, Typography, Button } from "antd";

const { Paragraph } = Typography;

export const ModalTransparent = styled(Modal)`
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none;
  }
`;

export const CardMap = styled(Card)`
  border-radius: 25px !important;
  .ant-card-body {
    padding: 0px !important;
  }
`;

export const ParagraphStyle = styled(Paragraph)`
  color: ${(props) => props.color ?? "#000000"} !important;
  font-size: ${(props) => props.size ?? 14}px;
  margin: 0px !important;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  text-align: ${(props) => props.align ?? "left"};
`;

export const ButtonCustom = styled(Button)`
  display: flex !important;
  align-items: center;
  padding: 20px 30px !important;
  border-radius: 10px !important;
  background-color: #06bae5 !important;
`;
