import * as type from "../types";

export function getAvailableTimes(id) {
  return {
    type: type.GET_AVAILABLE_TIMES_REQUESTED,
    id,
  };
}

export function updateAvailableTime(id, detail) {
  return {
    type: type.UPDATE_AVAILABLE_TIME_REQUESTED,
    id,
    detail,
  };
}
