import React, { useEffect, useState } from "react";

import { Card, Row, Col, Modal, DatePicker, Button } from "antd";
import { TemperatureText } from "./styles";
const { RangePicker } = DatePicker;

const TemperatureValue = (props) => {
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const { min, max, average } = props.values;
  const { filterReport, t } = props;

  const onDateChange = (value, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const handleOk = () => {
    filterReport(startDate, endDate);
    setVisible(false);
  };

  return (
    <>
      <Card
        title={
          <Row type="flex" justify="space-between">
            <Col>{t("body_temperature_celsius")}</Col>
            <Col>
              <Button type="primary" onClick={() => setVisible(true)}>
                {t("select_date_range")}
              </Button>
            </Col>
          </Row>
        }
      >
        <Row>
          <TemperatureText color="black" span={8}>
            {t("lowest")}
          </TemperatureText>
          <TemperatureText color="black" span={8}>
            {t("highest")}
          </TemperatureText>
          <TemperatureText color="black" span={8}>
            {t("average")}
          </TemperatureText>
          <TemperatureText color="blue" span={8}>
            {min}
          </TemperatureText>
          <TemperatureText color="red" span={8}>
            {max}
          </TemperatureText>
          <TemperatureText color="black" span={8}>
            {average}
          </TemperatureText>
        </Row>
      </Card>
      <Modal
        title={t("select_date_range")}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        okText={t("confirm")}
        cancelText={t("close")}
      >
        <RangePicker
          format="DD MMM YYYY"
          onChange={onDateChange}
          className="w-100"
        />
      </Modal>
    </>
  );
};

export default TemperatureValue;
