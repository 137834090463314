import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ResearchObjects, RecruitmentObjects } from "../../redux/actions";
import Layout from "../Home";
import {
  CLINIC_TRIAL_FORMAT_KEY_INCLUDES,
  CLINIC_TRIAL_FORMAT_INDEX_INCLUDES,
  GENERAL_CONTACT_FORMAT_KEY_INCLUDES,
  GENERAL_CONTACT_FORMAT_INDEX_INCLUDES,
  IN_EX_CRITERIA_FORMAT_KEY_INCLUDES,
  IN_EX_CRITERIA_FORMAT_INDEX_INCLUDES,
  ELIGIBILITY_FORMAT_KEY_INCLUDES,
  ELIGIBILITY_FORMAT_INDEX_INCLUDES,
  CLINIC_TRAIL_ADD_KEY_INDEX,
  IS_DATE,
  EMPTY_FIELD,
  EMPTY_SNOT,
  EMPTY_TNSS,
  EMPTY_ADVERSE_EVENTS,
  EMPTY_PRODUCT_APPLICATION,
} from "./constant";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "../../components/ResearchObjectDetail";
import Loading from "../../components/Loading";
import moment from "moment";

const ResearchObjectDetail = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [tabIndex, setTabIndex] = useState(0);
  const [formatClinicTrial, setFormatClinicTrial] = useState(null);
  const [formatGeneralContact, setFormatGeneralContact] = useState(null);
  const [formatInExCriteria, setFormatInExCriteria] = useState(null);
  const [formatEligibility, setFormatEligibility] = useState(null);
  const [formatFollowUp, setFormatFollowUp] = useState(null);
  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);

  const researchObject = useSelector((state) => state.researchObject);
  const recruitmentObject = useSelector((state) => state.recruitmentObject);

  useEffect(() => {
    dispatch(ResearchObjects.getResearchObjFromRecruitmentObj(id));
  }, []);

  useEffect(() => {
    if (researchObject.isSuccess) {
      if (formatGeneralContact === null) {
        initialFormatGeneralContact();
      }

      if (formatInExCriteria === null) {
        initialFormatInExCriteria();
      }
      if (formatEligibility === null) {
        initialFormatEligibility();
      }
      if (formatFollowUp === null) {
        initialFormatFollowUp();
      }
      if (!recruitmentObject.isSuccess) {
        dispatch(
          RecruitmentObjects.getRecruitmentObjectById(
            researchObject.data.recruitmentObjectId
          )
        );
      }
    }
  }, [researchObject]);

  useEffect(() => {
    if (recruitmentObject.isSuccess && formatClinicTrial === null) {
      initialFormatClinicTrialResult();
    }
  }, [recruitmentObject]);

  useEffect(() => {
    if (
      formatClinicTrial !== null &&
      formatGeneralContact !== null &&
      formatInExCriteria !== null &&
      formatEligibility !== null &&
      researchObject.isSuccess &&
      recruitmentObject.isSuccess
    ) {
      setIsFinishInitialLoad(true);
    }
  }, [
    formatClinicTrial,
    formatGeneralContact,
    formatInExCriteria,
    formatEligibility,
    researchObject,
    recruitmentObject,
  ]);

  const initialFormatClinicTrialResult = () => {
    const data = [];
    const array = [];
    const formArray = Object.entries(recruitmentObject.data.formObject).map(
      (result) => ({
        key: result[0],
        value: result[1].value,
        sort: CLINIC_TRAIL_ADD_KEY_INDEX[result[0]] || 0,
      })
    );
    const filterFormArray = formArray.filter((form) =>
      CLINIC_TRIAL_FORMAT_KEY_INCLUDES.includes(form.key)
    );
    const sortFormArray = filterFormArray.sort((a, b) => a.sort - b.sort);
    for (let j = 0; j < sortFormArray.length; j++) {
      if (CLINIC_TRIAL_FORMAT_INDEX_INCLUDES.includes(j)) {
        array.push(EMPTY_FIELD);
      }
      array.push({
        value: sortFormArray[j].value === "" ? "-" : sortFormArray[j].value,
        key: sortFormArray[j].key,
      });
    }
    data.push(array);
    setFormatClinicTrial(data);
  };

  const initialFormatGeneralContact = () => {
    const data = [];
    const array = [];
    const { generalInformation, address, filtering } = researchObject.data;
    const {
      identity,
      gender,
      birthDate,
      status,
      nationality,
      religion,
      education,
      occupation,
    } = generalInformation;
    const formObject = {
      identity,
      gender,
      birthDate,
      status,
      nationality,
      religion,
      education,
      occupation,
      ...address,
      ...{
        createdAt: filtering.createdAt,
        subjectId: filtering.subjectId,
        eligibilityStatus: filtering.eligibilityStatus,
      },
      ...filtering,
    };
    const formArray = Object.entries(formObject).map((result) => ({
      key: result[0],
      value: result[1],
    }));
    const filterFormArray = formArray.filter((form) =>
      GENERAL_CONTACT_FORMAT_KEY_INCLUDES.includes(form.key)
    );
    for (let j = 0; j < filterFormArray.length; j++) {
      const { key, value } = filterFormArray[j];
      let formatValue = value ?? "-";
      if (GENERAL_CONTACT_FORMAT_INDEX_INCLUDES.includes(j)) {
        array.push(EMPTY_FIELD);
      }
      if (IS_DATE.includes(key)) {
        formatValue = moment(formatValue).format("DD/MM/YYYY");
      }
      array.push({
        value: formatValue,
        key,
      });
    }
    data.push(array);
    setFormatGeneralContact(data);
  };

  const initialFormatInExCriteria = () => {
    const data = [];
    const array = [];
    if (
      researchObject.data.inclusionExclusionCriteria &&
      researchObject.data.inclusionExclusionCriteria.length > 0
    ) {
      const { inExclusionCriteria } = researchObject.data;
      const formArray = Object.entries(inExclusionCriteria).map((result) => ({
        key: result[0],
        value: result[1],
      }));
      const filterFormArray = formArray.filter((form) =>
        IN_EX_CRITERIA_FORMAT_KEY_INCLUDES.includes(form.key)
      );
      for (let j = 0; j < filterFormArray.length; j++) {
        const { key, value } = filterFormArray[j];
        let formatValue = value ?? "-";
        if (IN_EX_CRITERIA_FORMAT_INDEX_INCLUDES.includes(j)) {
          array.push(EMPTY_FIELD);
        }
        array.push({
          value: formatValue,
          key,
        });
      }
    }

    data.push(array);
    setFormatInExCriteria(data);
  };

  const initialFormatEligibility = () => {
    const { eligibilityAssessment } = researchObject.data;
    const data = [];
    for (let i = 0; i < eligibilityAssessment.length; i++) {
      let images = [];
      const array = [];
      const formArray = Object.entries(eligibilityAssessment[i]).map(
        (result) => ({
          key: result[0],
          value: result[1],
        })
      );
      const filterFormArray = formArray.filter((form) =>
        ELIGIBILITY_FORMAT_KEY_INCLUDES.includes(form.key)
      );
      for (let j = 0; j < filterFormArray.length; j++) {
        const { key, value } = filterFormArray[j];
        let sum = 0;
        let formatValue = value === "" || value === null ? "-" : value;
        if (j === 0) {
          array.push({
            value:
              eligibilityAssessment[i].day === -1
                ? "Day 0"
                : `Day ${eligibilityAssessment[i].day}`,
            key: "",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "34a853" } },
              border: {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
                right: { style: "thin" },
              },
            },
          });
        }
        if (ELIGIBILITY_FORMAT_INDEX_INCLUDES.includes(j)) {
          array.push(EMPTY_FIELD);
        }
        if (key === "medicationHistories") {
          for (let k = 0; k < 3; k++) {
            array.push(
              {
                value:
                  formatValue[k] && formatValue[k].medicationName
                    ? formatValue[k].medicationName
                    : "-",
                key: `medicationName${k + 1}`,
              },
              {
                value:
                  formatValue[k] && formatValue[k].date
                    ? formatValue[k].date
                    : "-",
                key: `date${k + 1}`,
              }
            );
          }
          array.push(EMPTY_FIELD);
        } else if (key === "underlingDiseases") {
          for (let k = 0; k < 4; k++) {
            array.push({
              value: formatValue[k] ? formatValue[k] : "-",
              key: `underlingDisease${k + 1}`,
            });
          }
        } else if (key === "currentMedications") {
          for (let k = 0; k < 4; k++) {
            array.push({
              value: formatValue[k] ? formatValue[k] : "-",
              key: `currentMedication${k + 1}`,
            });
          }
          array.push(EMPTY_FIELD);
        } else if (key === "covidInfectionHistory") {
          for (let k = 0; k < 4; k++) {
            const vaccineName =
              formatValue &&
              formatValue.vaccinneHistory[k] &&
              formatValue.vaccinneHistory[k].vaccineName
                ? formatValue.vaccinneHistory[k].vaccineName
                : "-";
            const vaccineDate =
              formatValue &&
              formatValue.vaccinneHistory[k] &&
              formatValue.vaccinneHistory[k].date
                ? formatValue.vaccinneHistory[k].date
                : "-";
            const vaccineSite =
              formatValue &&
              formatValue.vaccinneHistory[k] &&
              formatValue.vaccinneHistory[k].site
                ? formatValue.vaccinneHistory[k].site
                : "-";
            array.push(
              {
                value: vaccineName,
                key: `vaccineName${k + 1}`,
              },
              {
                value: vaccineDate,
                key: `date${k + 1}`,
              },
              {
                value: vaccineSite,
                key: `site${k + 1}`,
              }
            );
          }
          array.push(
            {
              value:
                formatValue && formatValue.covidInfectionHistoryUrl
                  ? formatValue.covidInfectionHistoryUrl
                  : "-",
              key: "covidInfectionHistoryUrl",
            },
            {
              value:
                formatValue && formatValue.closeContact
                  ? formatValue.closeContact
                  : "-",
              key: "closeContact",
            },
            {
              value:
                formatValue && formatValue.closeContactComment
                  ? formatValue.closeContactComment
                  : "-",
              key: "closeContactComment",
            },
            {
              value:
                formatValue && formatValue.covidInfected
                  ? formatValue.covidInfected
                  : "-",
              key: "covidInfected",
            },
            {
              value:
                formatValue && formatValue.covidInfectedComment
                  ? formatValue.covidInfectedComment
                  : "-",
              key: "covidInfectedComment",
            }
          );
        } else if (key === "nasopharyngealStatus") {
          array.push(
            {
              value: formatValue,
              key,
            },
            { value: "-", key: "nasopharyngealComment" }
          );
        } else if (
          key === "sinuscopyPolypsLeft" ||
          key === "sinuscopyPolypsRight" ||
          key === "sinuscopyOedemaLeft" ||
          key === "sinuscopyOedemaRight" ||
          key === "sinuscopyDischargeRight" ||
          key === "sinuscopyDischargeLeft"
        ) {
          sum += formatValue;
          array.push({
            value: formatValue,
            key,
          });
        } else if (
          key === "sinuscopyDischargeImageUrlRight" ||
          key === "sinuscopyDischargeImageUrlLeft"
        ) {
          images.push({
            value: formatValue,
            key,
          });
          if (key === "sinuscopyDischargeImageUrlLeft") {
            array.push(
              {
                value: sum,
                key: "allSinus",
              },
              images[1],
              images[0]
            );
          }
        } else {
          array.push({
            value: formatValue,
            key,
          });
        }
      }
      data.push(array);
    }
    setFormatEligibility(data);
  };

  const initialFormatFollowUp = () => {
    const data = [];

    const { followUpForm } = researchObject.data;
    for (let i = 0; i < followUpForm.length; i++) {
      const { jsonObj, day, period, submitted } = followUpForm[i];
      const TIMES = {
        MORNING: "เช้า",
        AFTERNOON: "กลางวัน",
        EVENING: "เย็น",
      };
      const array = [
        {
          value: `D${day}: SNOT-22 ${TIMES[period]} (${period})`,
          key: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "34a853" } },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          },
        },
      ];
      const findSnot = jsonObj.form.stepForm.find(
        (step) => step.stepName === "SNOT-22"
      );

      if (findSnot !== undefined && submitted) {
        const findSchemaListValue = jsonObj.schemaList.find(
          (schema) => String(schema.stepFormId) === findSnot.id
        );
        const subFormKey = jsonObj.form.subForm.find(
          (subForm) => subForm.stepFormId === findSnot.id
        );
        const conmbineKeyAndValue = combinedKeyAndValueFollowUp(
          subFormKey.elements,
          findSchemaListValue.schema
        );
        array = [...array, ...conmbineKeyAndValue];
      } else {
        array = [...array, ...EMPTY_SNOT];
      }
      array.push({
        value: `D${day}: TNSS ${TIMES[period]} (${period})`,
        key: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "34a853" } },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        },
      });
      const findTNSS = jsonObj.form.stepForm.find(
        (step) => step.stepName === "TNSS"
      );
      if (findTNSS !== undefined && submitted) {
        const findSchemaListValue = jsonObj.schemaList.find(
          (schema) => String(schema.stepFormId) === findTNSS.id
        );
        const subFormKey = jsonObj.form.subForm.find(
          (subForm) => subForm.stepFormId === findTNSS.id
        );
        const conmbineKeyAndValue = combinedKeyAndValueFollowUp(
          subFormKey.elements,
          findSchemaListValue.schema
        );
        array = [...array, ...conmbineKeyAndValue];
      } else {
        array = [...array, ...EMPTY_TNSS];
      }
      array.push({
        value: `D${day}: Adverse ${TIMES[period]} (${period})`,
        key: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "34a853" } },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        },
      });
      const findAdverse = jsonObj.form.stepForm.find(
        (step) => step.stepName === "อื่นๆ"
      );
      if (findAdverse !== undefined && submitted) {
        const findSchemaListValue = jsonObj.schemaList.find(
          (schema) => String(schema.stepFormId) === findAdverse.id
        );
        const subFormKey = jsonObj.form.subForm.find(
          (subForm) => subForm.stepFormId === findAdverse.id
        );
        const conmbineKeyAndValue = combinedKeyAndValueFollowUpAdverse(
          subFormKey.elements,
          findSchemaListValue.schema
        );
        array = [...array, ...conmbineKeyAndValue];
      } else {
        array = [...array, ...EMPTY_ADVERSE_EVENTS];
      }
      array.push({
        value: `D${day}: การใช้ยา ${TIMES[period]} (${period})`,
        key: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "34a853" } },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        },
      });
      const findProduct = jsonObj.form.stepForm.find(
        (step) => step.stepName === "การใช้ยา"
      );
      if (findProduct !== undefined && submitted) {
        const findSchemaListValue = jsonObj.schemaList.find(
          (schema) => String(schema.stepFormId) === findProduct.id
        );
        const subFormKey = jsonObj.form.subForm.find(
          (subForm) => subForm.stepFormId === findProduct.id
        );
        const conmbineKeyAndValue = combinedKeyAndValueFollowUp(
          subFormKey.elements,
          findSchemaListValue.schema
        );
        array = [...array, ...conmbineKeyAndValue];
      } else {
        array = [...array, ...EMPTY_PRODUCT_APPLICATION];
      }
      data.push(array);
    }
    setFormatFollowUp(data);
  };

  const combinedKeyAndValueFollowUpAdverse = (elements, schema) => {
    const array = [];
    for (const element of elements) {
      if (element.type === "select") {
        const value =
          schema[element.field].value === ""
            ? "-"
            : element.items.find(
                (item) => item.key === schema[element.field].value
              ).value;
        array.push({
          key: element.field,
          value,
        });
      } else if (element.type === "radio") {
        const findValue =
          schema[element.field].value === ""
            ? "-"
            : element.items.find(
                (item) => item.key === schema[element.field].value
              );
        if (findValue.key === "yes") {
          array.push(
            {
              key: element.field,
              value: findValue.label,
            },
            {
              key: "adverse_events_additional",
              value: findValue.info ?? "-",
            }
          );
        } else {
          array.push(
            {
              key: element.field,
              value: findValue.label,
            },
            {
              key: "adverse_events_additional",
              value: "-",
            }
          );
        }
      } else if (element.type === "multiple-select") {
        let text = "";
        for (const item of element.items) {
          if (
            schema[element.field] &&
            schema[element.field].value &&
            schema[element.field].value.includes(item.key)
          ) {
            text += text.length === 0 ? item.label : `, ${item.label}`;
          }
        }
        array.push({
          key: element.field,
          value: text,
        });
      }
    }

    const checkProductComplicated = elements.find(
      (element) => element.field === "using_product_complicated"
    );
    if (checkProductComplicated === undefined) {
      array.push({
        key: "using_product_complicated",
        value: "-",
      });
    }
    return array;
  };
  const combinedKeyAndValueFollowUp = (elements, schema) => {
    const array = [];
    for (const element of elements) {
      if (element.type === "select") {
        const value =
          schema[element.field].value === ""
            ? "-"
            : element.items.find(
                (item) => item.key === schema[element.field].value
              ).value;
        array.push({
          key: element.field,
          value,
        });
      } else if (element.type === "radio") {
        const value =
          schema[element.field].value === ""
            ? "-"
            : element.items.find(
                (item) => item.key === schema[element.field].value
              ).label;
        array.push({
          key: element.field,
          value,
        });
      } else if (element.type === "checkbox") {
        let text = "";
        for (const item of element.items) {
          if (
            schema[element.field] &&
            schema[element.field].value.includes(item.key)
          ) {
            text += text.length === 0 ? item.label : `, ${item.label}`;
          }
        }
        array.push({
          key: element.field,
          value: text,
        });
      } else if (element.type === "timepicker") {
        const value =
          schema[element.field].value === ""
            ? "-"
            : moment(schema[element.field].value).format("DD/MM/YYYY HH:mm");
        array.push({
          key: element.field,
          value,
        });
      }
    }
    return array;
  };

  return (
    <Layout
      {...props}
      render={(props) =>
        isFinishInitialLoad ? (
          <MainComponent
            t={t}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            researchObjectData={researchObject.data}
            formatClinicTrial={formatClinicTrial}
            formatGeneralContact={formatGeneralContact}
            formatInExCriteria={formatInExCriteria}
            formatEligibility={formatEligibility}
            formatFollowUp={formatFollowUp}
          />
        ) : (
          <Loading />
        )
      }
    />
  );
};

export default ResearchObjectDetail;
