import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Firebase } from "../../redux/actions";
import { ROOMS } from "./constant";
import RoundRobin from "../../components/RoundRobin";
import Layout from "../Home";
import Loading from "../../components/Loading";

const RoundRobinContainer = (props) => {
  const { history } = props;
  const dispatch = useDispatch();

  const user = useSelector((state) => state.appUserOma);
  const roundRobin = useSelector((state) => state.firebase.room.roundRobin);

  const [isFinishInitial, setIsFinishInitial] = useState(false);
  const [role, setRole] = useState("");
  const [countRoomType, setCountRoomType] = useState({});

  useEffect(() => {
    if (user.isSuccess && user.information !== null) {
      const roleName = user.information.roles[0].name.toUpperCase();
      dispatch(Firebase.getRooms("GENERAL", 0, roleName));
      dispatch(Firebase.getRooms("COVID", 0, roleName));
      setRole(roleName);
    }
  }, [user]);

  useEffect(() => {
    if (
      roundRobin["general"].isSuccess &&
      roundRobin["covid"].isSuccess &&
      role !== ""
    ) {
      initialCountRoom();
    }
  }, [roundRobin, role]);

  const initialCountRoom = () => {
    for (let key in roundRobin) {
      if (roundRobin[key] && roundRobin[key].isSuccess) {
        const { rooms } = roundRobin[key];
        const filterPendingRooms = rooms
          ? Object.keys(rooms).filter(
              (roomId) => rooms[roomId].status === `${role}_PENDING`
            ).length
          : 0;
        setCountRoomType((prev) => ({ ...prev, [key]: filterPendingRooms }));
      }
    }
    setIsFinishInitial(true);
  };

  const navigate = (path) => {
    history.push(path);
  };

  return (
    <Layout
      {...props}
      render={(props) =>
        isFinishInitial ? (
          <RoundRobin
            navigate={navigate}
            countRoomType={countRoomType}
            rooms={ROOMS[role] || []}
          />
        ) : (
          <Loading />
        )
      }
    />
  );
};

export default RoundRobinContainer;
