import { call, put, takeEvery } from 'redux-saga/effects';
import { DrugTimeFrequency } from '../../api';
import * as type from '../types';

function* getDrugTimeFrequency() {
    try {
        const result = yield call(DrugTimeFrequency.getDrugTimeFrequency);
        yield put({ type: type.GET_DRUG_TIME_FREQUENCY_SUCCESS, data: result });
    } catch (e) {
        yield put({
            type: type.GET_DRUG_TIME_FREQUENCY_FAILED,
            error: e.message,
        });
    }
}

function* drugTimeFrequencySaga() {
    yield takeEvery(
        type.GET_DRUG_TIME_FREQUENCY_REQUESTED,
        getDrugTimeFrequency
    );
}

export default drugTimeFrequencySaga;
