import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import Video from "twilio-video";
import { decryptHelper } from "../../common/hash";
import {
  Firebase,
  Treatment,
  Booking,
  Communication,
  AppUserOma,
} from "../../redux/actions";

import Room from "../../components/Room";
import { PENDING_NOTE_STATUS, COLORS } from "./constant";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import useExitPrompt from "../../hooks/useExitPrompt/useExitPrompt";
import { message } from "antd";

const RoomContainer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { match, history } = props;

  const appUserOma = useSelector((state) => state.appUserOma);
  const communication = useSelector((state) => state.communication);
  const booking = useSelector((state) => state.booking);
  const treatment = useSelector((state) => state.treatment);

  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true);

  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);
  const [roomType, setRoomType] = useState(null);
  const [date, setDate] = useState("");
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [note, setNote] = useState("");
  const [color, setColor] = useState("");
  const [isChat, setIsChat] = useState(true);
  const [isMute, setIsMute] = useState(false);
  const [role, setRole] = useState(null);
  const [country, setCountry] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [isDisableVideo, setIsDisableVideo] = useState(false);
  const [collapsedNote, setCollapsedNote] = useState(true);
  const [collapsedChat, setCollapsedChat] = useState(false);
  const [collapsedTelemonitoring, setCollapsedTelemonitoring] = useState(true);
  const [collapsedInformation, setCollapsedInformation] = useState(false);
  const [visibleCovidModal, setVisibleCovidModal] = useState(false);
  const [visibleHistoryBookingModal, setVisibleHistoryBookingModal] =
    useState(false);
  const [visiblePrescriptionModal, setVisiblePrescriptionModal] =
    useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    if (appUserOma.isSuccess && appUserOma.information !== null) {
      setRole(appUserOma.information.roles[0].name);
    }
  }, [appUserOma]);

  useEffect(() => {
    if (
      communication.isSuccess &&
      booking.isSuccess &&
      room === null &&
      communication.token &&
      isFirstLoad
    ) {
      setIsFirstLoad(false);
      const participantConnected = (participant) => {
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ]);
      };
      const participantDisconnected = (participant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant)
        );
      };

      Video.connect(communication.token, {
        name: booking.current.id + date,
        audio: true,
        video: {
          height: 1080,
          frameRate: 45,
          width: 1920,
          contentPreferencesMode: "manual",
        },
        bandwidthProfile: {
          video: {
            mode: "collaboration",
            dominantSpeakerPriority: "standard",
          },
        },
        dominantSpeaker: true,
        preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
        networkQuality: { local: 1, remote: 1 },
      }).then((room) => {
        setRoom(room);
        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.participants.forEach(participantConnected);
        setParticipants((prev) => [...prev]);
      });

      return () => {
        setRoom((prevRoom) => {
          if (prevRoom && prevRoom.localParticipant.state === "connected") {
            prevRoom.localParticipant.tracks.forEach((trackPublication) => {
              trackPublication.track.stop();
            });
            prevRoom.disconnect();
            return null;
          }
          return prevRoom;
        });
      };
    }
  }, [communication, booking]);

  useEffect(() => {
    if (
      communication.isSuccess &&
      booking.isSuccess &&
      appUserOma.isSuccess &&
      room !== null &&
      !isFirstLoad
    ) {
      handleVideoButton(true);
      setIsFinishInitialLoad(true);
    }
  }, [communication, booking, appUserOma, room]);

  const initialData = () => {
    const userId = jwtDecode(localStorage.getItem("token")).id;
    const unHash = JSON.parse(decryptHelper(match.params.id));
    const { bookingId, date, roomType, country, specialty } = unHash;
    setRoomType(roomType);
    setDate(date);
    setCountry(country);
    setSpecialty(specialty);
    dispatch(Booking.getBooking(bookingId));
    dispatch(Communication.getToken());
    dispatch(AppUserOma.getUser(userId));
    dispatch(AppUserOma.getProfile(userId));
  };

  const handleCloseSider = () => {
    setCollapsedNote(false);
    setCollapsedChat(false);
    setCollapsedTelemonitoring(false);
  };

  const handleVisibleCovidModal = () => {
    setVisibleCovidModal(true);
  };

  const handleVisibleHistoryBookingModal = () => {
    dispatch(Treatment.getBookingsByTreatmentId(booking.current.treatmentId));
    setVisibleHistoryBookingModal(true);
  };

  const handleAudioButton = (mute) => {
    toggle(mute, "audioTracks");
    setIsMute(mute);
  };

  const handleVideoButton = (disabled) => {
    toggle(disabled, "videoTracks");
    setIsDisableVideo(disabled);
  };

  const handleVisibleNote = () => {
    setCollapsedChat(true);
    setCollapsedNote(!collapsedNote);
    setCollapsedTelemonitoring(true);
    setIsChat(false);
  };

  const handleVisibleChat = () => {
    setCollapsedChat(!collapsedChat);
    setCollapsedNote(true);
    setCollapsedTelemonitoring(true);
    if (booking.current.patient.userInformation) {
      setIsChat(true);
    }
  };

  const handleVisibleTelemonitoring = () => {
    setCollapsedChat(true);
    setCollapsedNote(true);
    setCollapsedTelemonitoring(!collapsedTelemonitoring);
    setIsChat(false);
  };

  const handleVisibleInformation = () => {
    setCollapsedInformation(!collapsedInformation);
  };

  const handleDisconnect = () => {
    setIsFinishInitialLoad(false);
    const firebaseData = { status: PENDING_NOTE_STATUS[role] };
    let bookingData = {
      notes: note,
      status: PENDING_NOTE_STATUS[role],
    };
    if (roomType === "COVID") {
      bookingData = {
        ...bookingData,
        covidForm: {
          ...booking.current.covidForm,
          color: color,
        },
      };
    }
    disconnect();
    if (booking.current.bookingCategory === "scheduling") {
      dispatch(
        Firebase.updateRoom(
          "SCHEDULING",
          appUserOma.information.id,
          booking.current.patient.id,
          {
            ...firebaseData,
          },
          role
        )
      );
    } else {
      if (role === "communityPharmacist") {
        dispatch(
          Firebase.updateRoom(
            "COMMUNITY_PHARMACIST",
            appUserOma.information.id,
            booking.current.patient.id,
            {
              status: "COMMUNITY_PHARMACIST_PENDING_NOTE",
            },
            "communityPharmacist"
          )
        );
      } else {
        dispatch(
          Firebase.updateRoomByCountryAndSpecialty(
            country,
            specialty,
            { ...firebaseData },
            booking.current.patientId
          )
        );
      }
    }

    dispatch(
      Booking.updateBooking(booking.current.id, true, {
        ...bookingData,
      })
    );
    dispatch(Communication.deleteToken());
    history.push(`/summary/${match.params.id}`);
  };

  const disconnect = () => {
    if (room && room.localParticipant.state === "connected") {
      room.localParticipant.tracks.forEach((trackPublication) => {
        trackPublication.track.stop();
      });
      room.disconnect();
      return null;
    }
  };

  const toggle = (mute, key) => {
    room.localParticipant[key].forEach((track) => {
      if (mute) {
        track.track.disable();
      } else {
        track.track.enable();
      }
    });
  };
  return isFinishInitialLoad ? (
    <Room
      t={t}
      room={room}
      participants={participants}
      note={note}
      setNote={setNote}
      color={color}
      setColor={setColor}
      isChat={isChat}
      isMute={isMute}
      roomType={roomType}
      booking={booking}
      treatment={treatment}
      practitioner={appUserOma.information}
      role={role}
      isDisableVideo={isDisableVideo}
      visibleCovidModal={visibleCovidModal}
      visibleHistoryBookingModal={visibleHistoryBookingModal}
      setVisibleCovidModal={setVisibleCovidModal}
      setVisibleHistoryBookingModal={setVisibleHistoryBookingModal}
      handleCloseSider={handleCloseSider}
      handleVisibleCovidModal={handleVisibleCovidModal}
      handleVisibleHistoryBookingModal={handleVisibleHistoryBookingModal}
      handleAudioButton={handleAudioButton}
      handleVideoButton={handleVideoButton}
      handleDisconnect={handleDisconnect}
      collapsedNote={collapsedNote}
      collapsedChat={collapsedChat}
      collapsedTelemonitoring={collapsedTelemonitoring}
      collapsedInformation={collapsedInformation}
      handleVisibleNote={handleVisibleNote}
      handleVisibleChat={handleVisibleChat}
      handleVisibleTelemonitoring={handleVisibleTelemonitoring}
      handleVisibleInformation={handleVisibleInformation}
    />
  ) : (
    <Loading />
  );
};
export default RoomContainer;
