import {
  Container,
  Text,
  Input,
  Icon,
  SpaceItemFlex,
  ButtonRounded,
} from "../styles";
import { Row, Col, Space, Divider, Descriptions } from "antd";
import { AiFillPlusCircle, FaTimes } from "react-icons/all";

const UnderlyingDiseases = ({ t, form, disabled }) => {
  // NOTE disabled
  if (disabled) {
    const { underlingDiseases = [], currentMedications = [] } = form;
    const finalUnderlying = underlingDiseases.filter((item) => item !== "");
    const finalMedication = currentMedications.filter((item) => item !== "");
    return (
      <Container>
        <Text size="18px" bold>
          {t("underlying_disease")}
        </Text>
        {
          <Descriptions
            bordered
            labelStyle={{ fontWeight: "bold" }}
            size="small"
            column={2}
          >
            {finalUnderlying.length !== 0 ? (
              finalUnderlying.map((item, index) => (
                <Descriptions.Item label={`${index + 1}`} key={index}>
                  {`${item}`}
                </Descriptions.Item>
              ))
            ) : (
              <Text>{t("nothing")}</Text>
            )}
          </Descriptions>
        }
        <Text size="16px" bold className="mt-3">
          {t("current_medications_taken")}
        </Text>
        {
          <Descriptions
            bordered
            labelStyle={{ fontWeight: "bold" }}
            size="small"
            column={2}
          >
            {finalMedication.length !== 0 ? (
              finalMedication.map((item, index) => (
                <Descriptions.Item label={`${index + 1}`} key={index}>
                  {`${item}`}
                </Descriptions.Item>
              ))
            ) : (
              <Text>{t("nothing")}</Text>
            )}
          </Descriptions>
        }
      </Container>
    );
  }

  // NOTE not disabled
  const onChangeValueDiseases = (index, text) => {
    const diseasesArray = Array.isArray(form.values.underlingDiseases)
      ? form.values.underlingDiseases
      : [];
    if (diseasesArray[index] != undefined) {
      diseasesArray[index] = text;
    }
    form.setFieldValue("underlingDiseases", diseasesArray);
  };

  const onAddmoreCurrentMedication = () => {
    form.setFieldValue("currentMedications", [
      ...form.values.currentMedications,
      "",
    ]);
  };

  const onAddmoreDiseases = () => {
    form.setFieldValue("underlingDiseases", [
      ...form.values.underlingDiseases,
      "",
    ]);
  };

  const onDeleteDiseases = (index) => {
    form.setFieldValue(
      "underlingDiseases",
      form.values.underlingDiseases.filter((item, i) => i !== index)
    );
  };

  const onChangeValueCurrentMedication = (index, text) => {
    const currentMedArray = Array.isArray(form.values.currentMedications)
      ? form.values.currentMedications
      : [];
    if (currentMedArray[index] !== undefined) {
      currentMedArray[index] = text;
    }
    form.setFieldValue("currentMedications", currentMedArray);
  };

  const onDeleteCurrentMedicaiton = (index) => {
    form.setFieldValue(
      "currentMedications",
      form.values.currentMedications.filter((item, i) => i !== index)
    );
  };

  return (
    <>
      <>
        <Container>
          <Text size="20px" bold>
            {t("underlying_disease")}
          </Text>
        </Container>
        <Container>
          <Row gutter={[16, 8]} className="mt-2">
            {form.values.underlingDiseases.map((item, index) => {
              return (
                <Col key={index} xs={24} sm={24} lg={12}>
                  <SpaceItemFlex className="w-100" align="center" id="space">
                    <Input
                      value={item}
                      onChange={(e) => {
                        onChangeValueDiseases(index, e.target.value);
                      }}
                      width="100%"
                      placeHolder={`Disease name ${index + 1}`}
                    />
                    <Icon
                      component={FaTimes}
                      size="20px"
                      color="#00000059"
                      onClick={() => onDeleteDiseases(index)}
                    />
                  </SpaceItemFlex>
                </Col>
              );
            })}
          </Row>
        </Container>
        <Container className="mt-3">
          <ButtonRounded onClick={onAddmoreDiseases}>
            <Space className="py-1" direction="horizontal" align="center">
              <Icon component={AiFillPlusCircle} size="24px" color="#2BA796" />
              <Text>{t("add_more")}</Text>
            </Space>
          </ButtonRounded>
        </Container>
      </>
      <Divider />
      <>
        <Container>
          <Text size="20px" bold>
            {t("current_medications_taken")}
          </Text>
        </Container>
        <Container>
          {form.values.currentMedications.map((item, index) => {
            return (
              <Row key={index} className="my-2">
                <SpaceItemFlex className="w-100" align="center" id="space">
                  <Input
                    onChange={(e) => {
                      onChangeValueCurrentMedication(index, e.target.value);
                    }}
                    value={item}
                    width="100%"
                    placeHolder={`Medication name ${index + 1}`}
                  />
                  <Icon
                    component={FaTimes}
                    size="20px"
                    color="#00000059"
                    onClick={() => onDeleteCurrentMedicaiton(index)}
                  />
                </SpaceItemFlex>
              </Row>
            );
          })}
        </Container>
        <Container className="mt-3">
          <ButtonRounded onClick={onAddmoreCurrentMedication}>
            <Space className="py-1" direction="horizontal" align="center">
              <Icon component={AiFillPlusCircle} size="24px" color="#2BA796" />
              <Text>{t("add_more")}</Text>
            </Space>
          </ButtonRounded>
        </Container>
      </>
    </>
  );
};

export default UnderlyingDiseases;
