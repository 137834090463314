import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import App from "./containers/App";
import "./index.less";
import "./common/i18n";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import Theme from "./theme";
import Loading from "./components/Loading";

const Root = (
  <Provider store={store}>
    <ThemeProvider theme={Theme}>
      <React.Fragment>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </React.Fragment>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(Root, document.getElementById("root"));

serviceWorker.unregister();
