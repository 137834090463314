import { Button as ButtonAnt } from "antd";
import styled from "styled-components";
import TextBase from "../Text";

const Container = styled.div`
  width: 100%;
`;

const Text = styled(TextBase)`
  color: ${(props) => (props.primary ? "#2BA796" : props.color)};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const Button = styled(ButtonAnt)`
  &&&& {
    height: auto;
    border-radius: 50px;
    padding: 5px 25px;
    background-color: #2ba796; 
    }
  }
`;

export { Button, Text, Container };
