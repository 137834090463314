import { CheckCircleOutlined } from "@ant-design/icons";

export const SUMMARY_STEPS = [
  { title: "ผู้ป่วยกำลังรอ", icon: <CheckCircleOutlined /> },
  { title: "วิดิโอคอล", icon: <CheckCircleOutlined /> },
  { title: "ยืนยันรายการ", icon: <CheckCircleOutlined /> },
  { title: "การจัดส่ง", icon: <CheckCircleOutlined /> },
];

export const SUMMARY_STEPS_DELIVER_DRUG = [
  { title: "Departed" },
  { title: "Reached" },
  { title: "Received" },
];

export const SUMMARY_STEPS_CURRENT = {
  COMMUNITY_PHARMACIST_DEPARTED: 0,
  COMMUNITY_PHARMACIST_REACHED: 1,
  COMMUNITY_PHARMACIST_COMPLETED: 2,
};

export const IS_STEPS = [0, 1, 2];

export const SUMMARY_STEPS_ACTION = {
  COMMUNITY_PHARMACIST_PENDING_NOTE: "DEPARTED",
  COMMUNITY_PHARMACIST_DEPARTED: "REACHED",
  COMMUNITY_PHARMACIST_REACHED: "COMPLETED",
  COMMUNITY_PHARMACIST_COMPLETED: "CLOSE",
};
