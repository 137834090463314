export const STUDY_CASE = [
  {
    label: "Treatment",
    key: "Treatment",
  },
  {
    label: "Placebo",
    key: "Placebo",
  },
];

export const NASAL_SWAB = [
  {
    label: "Immediately",
    key: "Immediately",
  },
  {
    label: "Wait for 6 hours",
    key: "Wait",
  },
];
