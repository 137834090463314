import React, { useEffect, useState } from "react";
import { Row, Typography, Badge, Space, Table, Dropdown, Card } from "antd";
import { Container, Icon, Text, Button } from "./styles";
import CtmsIconHeaderModal from "../Modal/CtmsIconHeaderModal";
import CtmsWithdrawnFormModal from "../Modal/CtmsWithdrawnFormModal";
import { MdArchive, FcMinus, FaCheck, FiMoreVertical } from "react-icons/all";
import { useDispatch } from "react-redux";
import { getOrganizations } from "../../api/organization";
import { ClinicTrial } from "../../redux/actions";
import moment from "moment";

const ClinicTrialParticipantList = (props) => {
  const { history, t, clinicTrial, userPerm, sortValue: SORT_VALUE } = props;
  const { Title } = Typography;
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [sortData, setSortData] = useState({
    order: "descend",
    field: "filtering",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [withdrawnModal, setWithdrawnModal] = useState(false);
  const [withdrawnFormModal, setWithdrawnFormModal] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (clinicTrial.deviationData?.submit) {
      setWithdrawnFormModal(false);
      init();
    }
  }, [clinicTrial.deviationData?.submit]);

  useEffect(() => {
    if (
      clinicTrial.particitpantList?.data &&
      Array.isArray(clinicTrial.particitpantList?.data)
    ) {
      setData(clinicTrial.particitpantList?.data);
    }
  }, [clinicTrial.particitpantList?.data]);

  const init = () => {
    const filterOrg = `?filter[where][name]=National Cancer Institute (NCI)`;
    getOrganizations(filterOrg).then((res) => {
      if (res.length) {
        dispatch(ClinicTrial.getResearchObjectsByOrganization(res[0]?.id));
      }
    });
  };

  const onFilterChangeHandler = (pagination, filters, sorter) => {
    setSortData(sorter);
    setPagination(pagination);
    if (data.length > 0 && filters.status) {
      const filterData = clinicTrial.particitpantList?.data.filter((item) =>
        filters.status.includes(item.status)
      );
      setFilterData(filters);
      setData(filterData);
      return;
    }
    setData(clinicTrial.particitpantList?.data);
    setFilterData({});
  };

  const onWitdrawnSubmitHandler = (payload) => {
    if (selectedItem.id) {
      dispatch(
        ClinicTrial.withdrawnResearchObjectById(selectedItem.id, payload)
      );
    }
  };

  const onSubmitSeperateSectionHandler = (id, payload) => {
    if (!id) {
      dispatch(ClinicTrial.createDeviationsById(selectedItem.id, payload));
    } else {
      dispatch(ClinicTrial.updateDeviationById(selectedItem.id, id, payload));
    }
  };

  const onDeleteDevationsHandler = (id) => {
    if (selectedItem.id) {
      dispatch(ClinicTrial.deleteDebviationById(selectedItem.id, id));
    }
  };

  const onOpenWithDrawnModal = () => {
    if (selectedItem.id) {
      dispatch(ClinicTrial.getDeviationsById(selectedItem.id));
      setWithdrawnFormModal(true);
    }
  };

  const checkFollowUpFormRender = (obj) => {
    let renderObject = {
      day: `${t("day")} 1`,
      morning: false,
      afternoon: false,
      evening: false,
    };
    const followUpForm = obj?.followUpForm ?? [];
    const currentDate =
      moment().diff(moment(obj.filtering?.createdAt).startOf("day"), "days") +
      1;

    const submittedItem = Array.isArray(followUpForm)
      ? followUpForm.filter((item) => +item.day === currentDate)
      : [];

    if (submittedItem.length > 0 && obj.status === "active") {
      renderObject = {
        day: `${t("day")} ${currentDate}`,
        morning:
          submittedItem.find(
            (item) => item.period === "MORNING" && item.submitted
          ) === undefined
            ? false
            : true,
        afternoon:
          submittedItem.find(
            (item) => item.period === "AFTERNOON" && item.submitted
          ) === undefined
            ? false
            : true,
        evening:
          submittedItem.find(
            (item) => item.period === "EVENING" && item.submitted
          ) === undefined
            ? false
            : true,
      };
    } else {
      renderObject = {
        day: "-",
        morning: false,
        afternoon: false,
        evening: false,
      };
    }
    return renderObject;
  };

  const columns = [
    {
      title: t("registered_date"),
      dataIndex: "filtering",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.filtering?.createdAt).unix() -
        moment(b.filtering?.createdAt).unix(),
      sortOrder: sortData.field === "filtering" ? sortData.order : null,
      render: (data) => (
        <Space direction="vertical" align="center">
          <Text>{moment(data?.createdAt).format("DD/MM/YYYY")}</Text>
          <Text type="secondary">
            {moment(data?.createdAt).format("hh:mm A")}
          </Text>
        </Space>
      ),
    },
    {
      title: t("subject_id"),
      dataIndex: "filtering",
      align: "center",
      render: (data) => data?.subjectId,
    },
    {
      title: t("eligibility"),
      align: "center",
      dataIndex: "status",
      filteredValue: filterData.status || null,
      filters: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
        { text: "Completed", value: "completed" },
        { text: "Withdrawn", value: "withdrawn" },
      ],
      render: (status) => (
        <Badge
          count={status}
          style={{
            borderColor:
              status === "active"
                ? "#52c41a"
                : status === "withdrawn"
                ? "#FF9800"
                : status === "completed"
                ? "#FFF"
                : "#000000",
            textTransform: "capitalize",
            backgroundColor:
              status === "active"
                ? "#52c41a"
                : status === "completed"
                ? "#FF9800"
                : status === "withdrawn"
                ? "#FFF5E5"
                : "#F9FAFA",
            color:
              status === "active" || status === "completed"
                ? "#ffffff"
                : status === "withdrawn"
                ? "#FF9800"
                : "#000000",
          }}
        />
      ),
    },
    {
      title: t("gender"),
      dataIndex: "filtering",
      align: "center",
      width: "100px",
      render: (data) => t(data?.gender),
    },
    {
      title: t("product_id"),
      dataIndex: "filtering",
      align: "center",
      render: (data) => data?.productId,
    },
    {
      title: t("nasal_swap"),
      dataIndex: "filtering",
      align: "center",
      render: (data) => data?.nasalSwab,
    },
    {
      title: t("follow_up_status"),
      children: [
        {
          title: t("morning"),
          key: "morning",
          width: 60,
          align: "center",
          render: (record) => {
            const data = checkFollowUpFormRender(record);
            return data.morning ? (
              <Icon component={FaCheck} color="#27A06D" />
            ) : (
              <Icon component={FcMinus} />
            );
          },
        },
        {
          title: t("afternoon"),
          key: "afternoon",
          width: 90,
          align: "center",
          render: (record) => {
            const data = checkFollowUpFormRender(record);
            return data.afternoon ? (
              <Icon component={FaCheck} color="#27A06D" />
            ) : (
              <Icon component={FcMinus} />
            );
          },
        },
        {
          title: t("evening"),
          key: "evening",
          width: 60,
          align: "center",
          render: (record) => {
            const data = checkFollowUpFormRender(record);
            return data.evening ? (
              <Icon component={FaCheck} color="#27A06D" />
            ) : (
              <Icon component={FcMinus} />
            );
          },
        },
      ],
    },
    {
      title: t("status"),
      align: "center",
      render: (record) => {
        const data = checkFollowUpFormRender(record);
        return data?.day;
      },
    },
    {
      title: "",
      width: 160,
      align: "center",
      render: (record) => {
        const [visible, setVisible] = useState(false);
        return (
          <Row gutter={[16, 16]} align="middle" className="mx-2">
            <Button
              shape="round"
              onClick={() =>
                history.push(`/clinic-trial/research-object/${record.id}`)
              }
            >
              View
            </Button>
            {userPerm.STUDY_AND_WITHDRAWN && record.status === "active" && (
              <Dropdown
                trigger={["click"]}
                visible={visible}
                onVisibleChange={setVisible}
                overlay={
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        type="text"
                        onClick={(e) => {
                          e.preventDefault();
                          setVisible(false);
                          setSelectedItem(record);
                          setWithdrawnModal(true);
                        }}
                      >
                        <Row align="middle" className="pr-5">
                          <Icon
                            onClick={(e) => {
                              e.preventDefault();
                              setVisible(true);
                            }}
                            component={MdArchive}
                            size="24px"
                            color="#ADADAD"
                          />
                          <Text className="ml-2">{t("withdrawn")}</Text>
                        </Row>
                      </Button>
                    </div>
                  </Card>
                }
                placement="bottomCenter"
                arrow
              >
                <Icon component={FiMoreVertical} className="ml-3" />
              </Dropdown>
            )}
          </Row>
        );
      },
    },
  ];

  const DataTable = () => {
    // * NOTE role PI can manage STUDY column
    if (userPerm.STUDY_AND_WITHDRAWN) {
      columns.splice(5, 0, {
        title: t("study"),
        dataIndex: "filtering",
        align: "center",
        render: (data) => {
          return (
            data?.study && (
              <Badge
                count={data?.study}
                style={{
                  backgroundColor:
                    data?.study === "Treatment" ? "#E9F4FE" : "#E9F5F0",
                  color: data?.study === "Treatment" ? "#64B5F7" : "#4CAF50",
                  borderColor:
                    data?.study === "Treatment" ? "#64B5F7" : "#4CAF50",
                }}
              />
            )
          );
        },
      });
    }

    return (
      <Table
        rowKey={(item) => item.id}
        columns={columns}
        pagination={pagination}
        onChange={onFilterChangeHandler}
        loading={clinicTrial.particitpantList.loading}
        dataSource={data}
        scroll={{ x: 1300 }}
      />
    );
  };

  return (
    <Container>
      <Container>
        <DataTable />
      </Container>
      <CtmsIconHeaderModal
        t={t}
        key="withdrawn"
        body={
          <>
            {t("withdrawn_confirmation_body")}{" "}
            <b>
              {t("subject_id")}: {selectedItem?.filtering?.subjectId}?
            </b>
          </>
        }
        title={t("withdrawn_confirmation")}
        visible={withdrawnModal}
        setVisible={setWithdrawnModal}
        onClick={onOpenWithDrawnModal}
      />
      <CtmsWithdrawnFormModal
        t={t}
        researchId={selectedItem?.id ?? null}
        subjectId={selectedItem.filtering?.subjectId ?? ""}
        clinicTrial={clinicTrial}
        visible={withdrawnFormModal}
        setVisible={setWithdrawnFormModal}
        onWitdrawnSubmitHandler={onWitdrawnSubmitHandler}
        onSubmitSeperateSectionHandler={onSubmitSeperateSectionHandler}
        onDeleteDevationsHandler={onDeleteDevationsHandler}
      />
    </Container>
  );
};

export default ClinicTrialParticipantList;
