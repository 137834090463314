import React from "react";
import { Avatar, Col, Row, Typography } from "antd";
const { Title } = Typography;
const ProfileSummary = ({
  firstname = "",
  lastname = "",
  profileImage = "",
}) => {
  return (
    <Row type="flex" gutter={12}>
      <Col>
        <Avatar src={profileImage} size={44} />
      </Col>
      <Col style={{ alignSelf: "center" }}>
        <Title level={5}>
          {firstname && lastname
            ? `${firstname} ${lastname}`
            : "ไม่พบข้อมูลเจ้าหน้าที่"}
        </Title>
      </Col>
    </Row>
  );
};

export default ProfileSummary;
