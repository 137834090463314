import { call, put, takeEvery } from "redux-saga/effects";
import { Organization, ResearchObjects, RecruitmentObjects } from "../../api";
import * as type from "../types";

function* getResearchProgramsByOrganization({ id }) {
  try {
    const result = yield call(
      Organization.getResearchProgramsByOrganization,
      id
    );
    yield put({
      type: type.GET_RESEARCH_PROGRAM_BY_ORG_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_RESEARCH_PROGRAM_BY_ORG_FAILED,
      error: e.message,
    });
  }
}

function* getRecruitmentObjectsByOrgAndProgram({ id, programId }) {
  try {
    const result = yield call(
      Organization.getRecruitmentObjectsByOrgAndProgram,
      id,
      programId
    );
    yield put({
      type: type.GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_FAILED,
      error: e.message,
    });
  }
}

function* getResearchObjectsByOrgAndProgram({ id, programId }) {
  try {
    const result = yield call(
      Organization.getReserchObjectsByOrgAndProgram,
      id,
      programId
    );
    yield put({
      type: type.GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_FAILED,
      error: e.message,
    });
  }
}

function* getResearchPrograms({ id }) {
  try {
    const result = yield call(
      Organization.getResearchObjectsByOrganization,
      id
    );
    yield put({ type: type.GET_RESEARCH_OBJECTS_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_RESEARCH_OBJECTS_FAILED, error: e.message });
  }
}

function* createConsentDataById({ id, payload }) {
  try {
    const result = yield call(ResearchObjects.createConsentData, id, payload);
    yield put({
      type: type.CREATE_CLINIC_TRIAL_CONSENT_FORM_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.CREATE_CLINIC_TRIAL_CONSENT_FORM_FAILED,
      error: e.message,
    });
  }
}
function* createGeneralInfoById({ id, payload }) {
  try {
    const result = yield call(
      ResearchObjects.createGeneralInfoData,
      id,
      payload
    );
    yield put({
      type: type.CREATE_CLINIC_TRIAL_GENERAL_FORM_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.CREATE_CLINIC_TRIAL_GENERAL_FORM_FAILED,
      error: e.message,
    });
  }
}

function* createContactInfoById({ id, payload }) {
  try {
    const result = yield call(
      ResearchObjects.createContactInfoData,
      id,
      payload
    );
    yield put({
      type: type.CREATE_CLINIC_TRIAL_CONTACT_FORM_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.CREATE_CLINIC_TRIAL_CONTACT_FORM_FAILED,
      error: e.message,
    });
  }
}

function* createEligibilityAssessmentFormById({ id, payload }) {
  try {
    const result = yield call(
      ResearchObjects.createEligibilityAssessmentData,
      id,
      payload
    );
    yield put({
      type: type.CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED,
      error: e.message,
    });
  }
}

function* createDeviationsById({ id, payload }) {
  try {
    const result = yield call(
      ResearchObjects.createDeviationsById,
      id,
      payload
    );
    yield put({
      type: type.CREATE_CLINIC_TRIAL_DEVIATIONS_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.CREATE_CLINIC_TRIAL_DEVIATIONS_FAILED,
      error: e.message,
    });
  }
}

function* updateDeviationsById({ id, fk, payload }) {
  try {
    const result = yield call(
      ResearchObjects.updateDeviationsById,
      id,
      fk,
      payload
    );
    yield put({
      type: type.UPDATE_CLINIC_TRIAL_DEVIATIONS_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_CLINIC_TRIAL_DEVIATIONS_FAILED,
      error: e.message,
    });
  }
}

function* getDeviationsById({ id }) {
  try {
    const result = yield call(ResearchObjects.getDeviationsById, id);
    yield put({
      type: type.GET_CLINIC_TRIAL_DEVIATIONS_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_CLINIC_TRIAL_DEVIATIONS_FAILED,
      error: e.message,
    });
  }
}

function* deleteDeviationsById({ id, fk }) {
  try {
    yield call(ResearchObjects.deleteDeviationsById, id, fk);
    yield put({
      type: type.DELETE_DEVIATIONS_BY_ID_SUCCESS,
      data: fk,
    });
  } catch (e) {
    yield put({
      type: type.DELETE_DEVIATIONS_BY_ID_FAILED,
      error: e.message,
    });
  }
}

function* updateEligibilityAssessmentFormByIdAndFk({ id, fk, payload }) {
  try {
    const result = yield call(
      ResearchObjects.updateEligibilityAssessmentData,
      id,
      fk,
      payload
    );
    yield put({
      type: type.UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED,
      error: e.message,
    });
  }
}

function* editEligibilityAssessmentFormByIdAndFk({ id, fk, payload }) {
  try {
    const result = yield call(
      ResearchObjects.updateEligibilityAssessmentData,
      id,
      fk,
      payload
    );
    yield put({
      type: type.EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED,
      error: e.message,
    });
  }
}

function* getClinicAssessmentObjectById({ id }) {
  try {
    const result = yield call(ResearchObjects.getAssessmentsObjectsById, id);
    yield put({
      type: type.GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_FAILED,
      error: e.message,
    });
  }
}

function* addNewClinicAssessmentbyId({ id, payload }) {
  try {
    const result = yield call(
      ResearchObjects.createEligibilityAssessmentData,
      id,
      payload
    );
    yield put({
      type: type.ADD_NEW_CLINIC_ASSESSMENT_BY_ID_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.ADD_NEW_CLINIC_ASSESSMENT_BY_ID_FAILED,
      error: e.message,
    });
  }
}

function* approveClinicTrialResearchObjectById({ id, payload }) {
  try {
    yield call(ResearchObjects.createCriteriaData, id, payload);
    const result = yield call(ResearchObjects.approveResearchObject, id);
    yield put({
      type: type.APPROVE_CLINIC_TRIAL_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.APPROVE_CLINIC_TRIAL_FAILED,
      error: e.message,
    });
  }
}

function* appproveManuallyResearchObjectById({ id, payload, criteriaPayload }) {
  try {
    yield call(ResearchObjects.createCriteriaData, id, criteriaPayload);
    const result = yield call(
      ResearchObjects.approveClinicTrailWithSubjectId,
      id,
      payload
    );
    yield put({
      type: type.APPROVE_CLINIC_TRIAL_MANUALLY_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.APPROVE_CLINIC_TRIAL_MANUALLY_FAILED,
      error: e.message,
    });
  }
}

function* declineClinicTrialResearchObjectById({ id, payload }) {
  try {
    yield call(ResearchObjects.createCriteriaData, id, payload);
    const result = yield call(ResearchObjects.rejectResearchObject, id);
    yield put({
      type: type.DECLINE_CLINIC_TRIAL_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.DECLINE_CLINIC_TRIAL_FAILED,
      error: e.message,
    });
  }
}

function* withdrawnClinicTrialResearchObjectById({ id, payload }) {
  try {
    yield call(ResearchObjects.updateResearchObjectById, id, payload);
    const result = yield call(ResearchObjects.withdrawnResearchObject, id);
    yield put({
      type: type.WITHDRAWN_CLINIC_TRIAL_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.WITHDRAWN_CLINIC_TRIAL_FAILED,
      error: e.message,
    });
  }
}

function* updateRecruitmentObjectById({ id, payload }) {
  try {
    const result = yield call(
      RecruitmentObjects.updateRecruitmentObject,
      id,
      payload
    );
    yield put({
      type: type.UPDATE_RECRUITMENT_OBJECT_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_RECRUITMENT_OBJECT_FAILED,
      error: e.message,
    });
  }
}

function* clinicTrialSaga() {
  yield takeEvery(
    type.GET_RESEARCH_PROGRAM_BY_ORG_REQUEST,
    getResearchProgramsByOrganization
  );
  yield takeEvery(
    type.GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST,
    getRecruitmentObjectsByOrgAndProgram
  );
  yield takeEvery(
    type.GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST,
    getResearchObjectsByOrgAndProgram
  );
  yield takeEvery(
    type.CREATE_CLINIC_TRIAL_CONSENT_FORM_REQUEST,
    createConsentDataById
  );
  yield takeEvery(
    type.CREATE_CLINIC_TRIAL_GENERAL_FORM_REQUEST,
    createGeneralInfoById
  );
  yield takeEvery(
    type.CREATE_CLINIC_TRIAL_CONTACT_FORM_REQUEST,
    createContactInfoById
  );
  yield takeEvery(
    type.CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST,
    createEligibilityAssessmentFormById
  );
  yield takeEvery(
    type.UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST,
    updateEligibilityAssessmentFormByIdAndFk
  );
  yield takeEvery(
    type.APPROVE_CLINIC_TRIAL_REQUEST,
    approveClinicTrialResearchObjectById
  );
  yield takeEvery(
    type.DECLINE_CLINIC_TRIAL_REQUEST,
    declineClinicTrialResearchObjectById
  );
  yield takeEvery(
    type.EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST,
    editEligibilityAssessmentFormByIdAndFk
  );
  yield takeEvery(
    type.GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_REQUEST,
    getClinicAssessmentObjectById
  );
  yield takeEvery(
    type.ADD_NEW_CLINIC_ASSESSMENT_BY_ID_REQUEST,
    addNewClinicAssessmentbyId
  );
  yield takeEvery(
    type.UPDATE_RECRUITMENT_OBJECT_REQUEST,
    updateRecruitmentObjectById
  );
  yield takeEvery(
    type.CREATE_CLINIC_TRIAL_DEVIATIONS_REQUEST,
    createDeviationsById
  );
  yield takeEvery(type.GET_CLINIC_TRIAL_DEVIATIONS_REQUEST, getDeviationsById);
  yield takeEvery(type.DELETE_DEVIATIONS_BY_ID_REQUEST, deleteDeviationsById);
  yield takeEvery(
    type.WITHDRAWN_CLINIC_TRIAL_REQUEST,
    withdrawnClinicTrialResearchObjectById
  );
  yield takeEvery(
    type.UPDATE_CLINIC_TRIAL_DEVIATIONS_REQUEST,
    updateDeviationsById
  );
  yield takeEvery(type.GET_RESEARCH_OBJECTS_REQUESTED, getResearchPrograms);
  yield takeEvery(
    type.APPROVE_CLINIC_TRIAL_MANUALLY_REQUEST,
    appproveManuallyResearchObjectById
  );
}

export default clinicTrialSaga;
