import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  AppUserOma,
  Booking,
  Firebase,
  Treatment,
  UserFcmToken,
} from "../../redux/actions";
import { ROLE_TO_STATUS, JOIN_STATUS, NOTIFICATION } from "./constant";
import DoctorLobby from "../../components/DoctorLobby";
import Layout from "../Home";
import Loading from "../../components/Loading";
import { hashHelper } from "../../common/hash";

const DoctorLobbyContainer = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const appUserOma = useSelector((state) => state.appUserOma);
  const roundRobin = useSelector((state) => state.firebase.room.roundRobin);
  const booking = useSelector((state) => state.booking);
  const treatment = useSelector((state) => state.treatment);

  const [isFinishInitial, setIsFinishInitial] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [role, setRole] = useState(null);
  const [visibleCallModal, setVisibleCallModal] = useState(false);
  const [visibleCovidModal, setVisibleCovidModal] = useState(false);
  const [visibleHistoryBookingModal, setVisibleHistoryBookingModal] =
    useState(false);
  const [title, setTitle] = useState("");
  const [room, setRoom] = useState(null);

  useEffect(() => {
    initialProfileAndBooking();
    initialRoom();
  }, []);

  useEffect(() => {
    if (booking.history.isSuccess) {
      const reduceTotalPrice = booking.data.reduce((acc, curr) => {
        return acc + curr.totalPrice;
      }, 0);
      setTotalPrice(reduceTotalPrice);
    }
  }, [booking]);

  useEffect(() => {
    if (
      appUserOma.isSuccess &&
      roundRobin.isSuccess &&
      appUserOma.practitioner &&
      booking.history.isSuccess
    ) {
      setIsFinishInitial(true);
    }
  }, [appUserOma, roundRobin, booking]);

  const initialProfileAndBooking = () => {
    const { id, roles } = jwtDecode(localStorage.getItem("token"));
    const status = ROLE_TO_STATUS[roles[0]];
    setRole(roles[0]);
    dispatch(AppUserOma.getUser(id));
    dispatch(AppUserOma.getProfile(id));
    dispatch(Booking.getHistoryBookingByDateTime(status, "day", moment()));
  };

  const initialRoom = () => {
    dispatch(Firebase.getRoomByCountryAndSpecialty());
  };

  const navigate = (path) => {
    history.push(path);
  };

  const handleOpenCallModal = (name, room) => {
    setTitle(name);
    setVisibleCallModal(true);
    setRoom(room);
    dispatch(Booking.getBooking(room.bookingId));
  };

  const fetchBookingByTreatmentId = () => {
    dispatch(Treatment.getBookingsByTreatmentId(booking.current.treatmentId));
    setVisibleHistoryBookingModal(true);
  };

  const handleAcceptCall = () => {
    setVisibleCallModal(false);
    setIsFinishInitial(false);
    dispatch(
      UserFcmToken.sendNotification(
        booking.current.patientId,
        NOTIFICATION["acceptCall"]
      )
    );
    dispatch(
      Booking.updateBooking(booking.current.id, false, {
        practitionerAppUserId: appUserOma.information.id,
        practitionerId: appUserOma.practitioner.id,
      })
    );
    dispatch(
      Firebase.updateRoomByCountryAndSpecialty(
        room.country,
        room.specialty.id,
        {
          status: JOIN_STATUS[role],
        },
        booking.current.patientId
      )
    );
    const hash = hashHelper(
      JSON.stringify({
        bookingId: booking.current.id,
        date: room.date,
        country: room.country,
        specialty: room.specialty.id,
      })
    );
    history.push(`/rooms/${hash}`);
  };

  return (
    <Layout
      {...props}
      render={(props) =>
        isFinishInitial ? (
          <DoctorLobby
            t={t}
            navigate={navigate}
            rooms={roundRobin.data}
            booking={booking}
            totalPrice={totalPrice}
            role={role}
            title={title}
            treatment={treatment}
            visibleCallModal={visibleCallModal}
            setVisibleCallModal={setVisibleCallModal}
            visibleCovidModal={visibleCovidModal}
            setVisibleCovidModal={setVisibleCovidModal}
            visibleHistoryBookingModal={visibleHistoryBookingModal}
            setVisibleHistoryBookingModal={setVisibleHistoryBookingModal}
            handleOpenCallModal={handleOpenCallModal}
            fetchBookingByTreatmentId={fetchBookingByTreatmentId}
            handleAcceptCall={handleAcceptCall}
          />
        ) : (
          <Loading />
        )
      }
    />
  );
};

export default DoctorLobbyContainer;
