import styled from "styled-components";

export const ContainerVideo = styled.div`
  display: flex;
  justify-content: flex-end;
  width: ${(prop) => (prop.width ? `${prop.width}px` : "100%")};
  height: ${(prop) => (prop.height ? `${prop.height}px` : "100%")};
`;

export const ContainerName = styled.div`
  align-items: center;
  bottom: -0.5px;
  display: flex;
  padding: ${(props) => (props.isLocal ? "8px 0" : "12px 0")};
  position: absolute;
  width: 100%;
`;

export const Name = styled.h2`
  font-size: 0.875rem;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  transform: scale(-1, 1);
  border-radius: 8px;
  ${(props) => props.isObjectFit && "object-fit: cover"}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const WrapperName = styled.div`
  margin: ${(props) => (props.isLocal ? "0 12px" : "0 16px")};
`;
