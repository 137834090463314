import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/MedicationForOrganizations";

async function fetchMedications(genericName) {
  const { data } = await http.get(
    `${apiEndpoint}?filter[where][or][0][activeIngredient][ilike]=%${genericName}%&filter[where][or][1][tradeName][ilike]=%${genericName}%&filter[where][organizationId]=4`
  );
  return data;
}

export { fetchMedications };
