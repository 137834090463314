import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Divider } from "antd";
import { COVID_TITLE, COVID_FORM } from "./constant";
import Loading from "../../Loading";
import moment from "moment";

const Covid = (props) => {
  const { visible, setVisible, booking, t } = props;

  const [isFinishInitial, setIsFinishInitial] = useState(false);

  useEffect(() => {
    if (booking.isSuccess || booking.error !== null) {
      setIsFinishInitial(true);
    }
  }, [booking]);

  const checkTypeValue = (covid) => {
    return covid.value ? covid.value : covid.isChecked ? t("yes") : t("no");
  };

  return (
    <Modal
      width={1000}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      {isFinishInitial ? (
        <>
          {booking.error === null && booking.current ? (
            <>
              {booking.current.covidForm ? (
                <>
                  <b>{t("history_patient")}</b>
                  {COVID_TITLE.map((item, index) =>
                    booking.current.covidForm[item.value] ? (
                      <Row key={index}>
                        <Col span={12}>{t(item.title)}</Col>
                        <Col span={12}>
                          {item.value === "firstDateSymtom" ||
                          item.value === "lastestInfected"
                            ? moment(
                                booking.current.covidForm[item.value]
                              ).isValid()
                              ? moment(
                                  booking.current.covidForm[item.value]
                                ).format("LL")
                              : booking.current.covidForm[item.value]
                            : booking.current.covidForm[item.value]}
                        </Col>
                      </Row>
                    ) : null
                  )}
                  <Divider />
                  {COVID_FORM.map((item, itemIndex) => {
                    const isNotEmpty =
                      !!booking.current.covidForm[item.title] &&
                      Array.isArray(booking.current.covidForm[item.title]) &&
                      booking.current.covidForm[item.title].length > 0;
                    if (item.title === "vaccines") {
                      const vaccinesList =
                        booking.current.covidForm[item.title];
                      return Array.isArray(vaccinesList) &&
                        vaccinesList.length > 0 &&
                        vaccinesList[0]?.vaccineName !== "" ? (
                        <>
                          <b>{t(item.label)}</b>
                          {vaccinesList.map((vac, vacIndex) => {
                            return (
                              <Row key={vac.id}>
                                <Col span={12}>
                                  {`${vacIndex + 1}. `}
                                  {vac.vaccineName}
                                </Col>
                                <Col span={12}>
                                  {moment(vac.time).format("DD/MM/YYYY")}
                                </Col>
                              </Row>
                            );
                          })}
                          <Divider />
                        </>
                      ) : null;
                    }
                    return (
                      <>
                        {isNotEmpty && <b>{t(item.label)}</b>}
                        {(isNotEmpty
                          ? booking.current.covidForm[item.title]
                          : []
                        ).map((covid, index) => (
                          <Row key={itemIndex + index}>
                            <Col span={12}>{t(item.value[covid.title])}</Col>
                            <Col span={12}>{checkTypeValue(covid)}</Col>
                          </Row>
                        ))}
                        {COVID_FORM.length - 1 !== itemIndex && isNotEmpty && (
                          <Divider />
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                `${t("not_found_covid19_form")}`
              )}
            </>
          ) : (
            <Error />
          )}
        </>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};

export default Covid;
