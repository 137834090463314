import React, { useEffect, useRef, useState } from "react";
import NetworkQualityLevel from "../../components/NetworkQualityLevel/NetworkQualityLevel";
import AudioLevelIndicator, {
  useVolume,
} from "../../components/Video/AudioLevelIndicator/AudioLevelIndicator";
import usePublications from "../../hooks/Video/usePublications/usePublications";
import useTrack from "../../hooks/Video/useTrack/useTrack";
import {
  ContainerName,
  ContainerVideo,
  Name,
  Video,
  Wrapper,
  WrapperName,
} from "./styles";

const Participant = ({
  participant,
  videoWidth,
  videoHeight,
  isObjectFit,
  name,
  isLocal,
}) => {
  const publications = usePublications(participant);
  const audioPublication = publications.find((p) => p.kind === "audio");
  const audioTrack = useTrack(audioPublication);
  const volume = useVolume(audioTrack);
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <Wrapper>
      <AudioLevelIndicator volume={volume} />
      <ContainerVideo width={videoWidth} height={videoHeight}>
        <Video ref={videoRef} isObjectFit={isObjectFit} />
        <audio ref={audioRef} />
        <NetworkQualityLevel participant={participant} />
        <ContainerName isLocal={isLocal}>
          <WrapperName isLocal={isLocal}>
            <Name style={{ color: "white" }}>{name}</Name>
          </WrapperName>
        </ContainerName>
      </ContainerVideo>
    </Wrapper>
  );
};

export default Participant;
