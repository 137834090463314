import React, { useEffect, useState } from "react";
import BmiValueCard from "./BmiValueCard";
import BmiTrendCard from "./BmiTrendCard";
import BmiGraphCard from "./BmiGraphCard";
import { Container } from "./styles";
import { BmiValue, BmiTrend, BmiGraph } from "../constant";
const BMI = ({ bmi, filterReport, defaultBmi, isVideoCall, t }) => {
  const [values, setValues] = useState(BmiValue);
  const [trends, setTrends] = useState(BmiTrend);
  const [graphs, setGraphs] = useState(BmiGraph);

  useEffect(() => {
    if (bmi.data && bmi.data.arr.length && bmi.data.dates.length) {
      checkBmiValue();
    } else {
      setValues(BmiValue);
      setTrends(BmiTrend);
      setGraphs(BmiGraph);
    }
  }, [bmi]);

  const findMin = (arr) => Math.min(...arr);
  const findMax = (arr) => Math.max(...arr);
  const findAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

  const checkBmiValue = () => {
    const { arr } = bmi.data;
    setValues({
      min: findMin(arr),
      max: findMax(arr),
      average: findAverage(arr),
    });
    checkBmiTrend();
  };

  const checkBmiTrend = (defaultParams) => {
    if (bmi.data && defaultBmi) {
      const { arr } = bmi.data;
      let lowest = defaultBmi[0];
      let highest = defaultBmi[1];
      if (defaultParams && defaultParams.length) {
        lowest = defaultParams[0];
        highest = defaultParams[1];
      }
      const trend = { ...BmiTrend };
      arr.map((value) => {
        if (Number(value) < Number(lowest)) {
          trend.low++;
        } else if (
          Number(value) >= Number(lowest) &&
          Number(value) <= Number(highest)
        ) {
          trend.mid++;
        } else if (Number(value) > Number(highest)) {
          trend.high++;
        }
      });
      setTrends((prev) => ({
        ...trend,
        pie: {
          ...prev.pie,
          datasets: [
            {
              ...prev.pie.datasets[0],
              data: [trend.mid, trend.low, trend.high],
            },
          ],
        },
      }));
      checkBmiGraph(lowest, highest);
    }
  };

  const checkBmiGraph = (lowest, highest) => {
    const { arr, periods, reasons, dates } = bmi.data;
    setGraphs((prev) => ({
      defaultLow: lowest,
      defaultHigh: highest,
      lines: {
        options: {
          ...prev.lines.options,
          xaxis: {
            categories: dates,
          },
          annotations: {
            yaxis: [
              { ...prev.lines.options.annotations.yaxis[0], y: highest },
              {
                ...prev.lines.options.annotations.yaxis[1],
                y: lowest,
                y2: highest,
              },
              {
                ...prev.lines.options.annotations.yaxis[2],
                y: lowest,
              },
            ],
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="arrow_box" style="padding: 16px">
                      <p>Bmi Value: <b>${
                        series[seriesIndex][dataPointIndex]
                      }</b></p>
                      <p>Date: <b>${dates[dataPointIndex] || "-"}</b></p>
                      </div>`;
            },
          },
        },
        series: [
          {
            name: "Bmi Value",
            data: arr,
          },
        ],
      },
    }));
  };

  return (
    <Container>
      <BmiValueCard
        t={t}
        values={values}
        filterReport={(start, end) => filterReport(start, end, 5)}
      />
      <BmiTrendCard t={t} trends={trends} isVideoCall={isVideoCall} />
      <BmiGraphCard t={t} graphs={graphs} defaultBmi={defaultBmi} />
    </Container>
  );
};

export default BMI;
