import React from "react";
import { Row } from "antd";
import { ModalCustom, Text, Icon, Button } from "./styles";
import { RiErrorWarningFill } from "react-icons/all";
import PropTypes from "prop-types";

export default function CtmsIconHeaderModal(props) {
  const { title, visible, setVisible, onClick = () => {}, body, t } = props;
  return (
    <ModalCustom
      title={
        <Row align="middle">
          <div
            style={{
              borderRadius: 999,
              backgroundColor: "#3F98D255",
              alignItems: "center",
              marginRight: 15,
            }}
          >
            <Icon
              component={RiErrorWarningFill}
              color="#3F98D2"
              className="p-2"
            />
          </div>
          <Text bold size="20px">
            {title}
          </Text>
        </Row>
      }
      visible={visible}
      width={400}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          key="cancel"
          onClick={(e) => {
            e.preventDefault();
            setVisible(false);
          }}
        >
          <Text color="#2F729D">{t("cancel")}</Text>
        </Button>,
        <Button
          key="confirm"
          onClick={(e) => {
            e.preventDefault();
            setVisible(false);
            onClick();
          }}
          type="primary"
        >
          <Text color="white">{t("confirm")}</Text>
        </Button>,
      ]}
    >
      <Text>{body}</Text>
    </ModalCustom>
  );
}

CtmsIconHeaderModal.propTypes = {
  t: PropTypes.any,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.node,
  onClick: PropTypes.func,
};
