import TextBase from "../../Text";
import styled from "styled-components";
import IconAnt from "@ant-design/icons";
import { Modal, Button as ButtonAnt } from "antd";

const ModalCustom = styled(Modal)`
  .ant-modal-header {
    border-radius: 10px 10px 0px 0px !important;
    border-bottom: unset;
  }
  .ant-modal-content {
    border-radius: 10px !important;
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 24px 20px;
  }
  .ant-modal-footer {
    border-top: unset;
  }
`;

const Icon = styled(IconAnt)`
  &&&& {
    color: ${(props) => props.color} !important;
  }
  font-size: ${(props) => props.size};
`;

const Button = styled(ButtonAnt)`
  && {
    border-radius: 8px;
  }
`;

const Text = styled(TextBase)`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const Container = styled.div`
  width: 100%;
`;

export { ModalCustom, Text, Container, Icon, Button };
