import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Card, Row, Col, Form, Input, Button } from "antd";
import Chart from "react-apexcharts";
import { TemperatureText } from "./styles";

const TemperatureGraph = (props) => {
  const { graphs, defaultTemperature, t } = props;
  const { lines } = graphs;

  return (
    <Card className="mt-2" title={t("body_temperature_trend")}>
      <Row>
        <Col span={24} className="mb-2">
          <div>{t("body_temperature_threshold")}</div>
        </Col>

        <Col
          span={10}
        >{`${defaultTemperature[0]} - ${defaultTemperature[1]}`}</Col>
      </Row>
      <Chart
        options={lines.options}
        series={lines.series}
        type="line"
        width="550"
      />
    </Card>
  );
};

export default TemperatureGraph;
