import * as type from "../types";

export function getToken() {
  return {
    type: type.GET_TOKEN_REQUESTED,
  };
}

export function deleteToken() {
  return {
    type: type.DELETE_TOKEN_REQUESTED,
  };
}
