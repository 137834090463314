import { combineReducers } from "redux";
import appUserOma from "./appUserOma";
import availableTime from "./availableTimes";
import booking from "./bookings";
import communication from "./communications";
import firebase from "./firebase";
import kycImage from "./kycImages";
import menu from "./menus";
import organization from "./organizations";
import userFcmToken from "./userFcmTokens";
import userOma from "./userOma";
import Treatment from "./treatments";
import monitoringReport from "./monitoringReport";
import medication from "./medication";
import practitionerSpecialties from "./practitionerSpecialties";
import role from "./role";
import drugTimeEvent from "./drugTimeEvent";
import drugTimeFrequency from "./drugTimeFrequency";
import symptomGroup from "./symptomGroups";
import questionaires from "./questionaires";
import drugIngredientGroup from "./drugIngredientGroups";
import medicationForOrganization from "./medicationForOrganizations";
import clinicTrial from "./clinicTrial";
import researchObject from "./researchObject";
import recruitmentObject from "./recruitmentObject";

const appReducer = combineReducers({
  treatment: Treatment,
  userFcmToken: userFcmToken,
  appUserOma: appUserOma,
  organization: organization,
  kycImage: kycImage,
  booking: booking,
  userOma: userOma,
  menu: menu,
  firebase: firebase,
  communication: communication,
  availableTime: availableTime,
  monitoringReport: monitoringReport,
  medication: medication,
  practitionerSpecialties: practitionerSpecialties,
  role: role,
  drugTimeEvent: drugTimeEvent,
  drugTimeFrequency: drugTimeFrequency,
  symptomGroup: symptomGroup,
  questionaires: questionaires,
  drugIngredientGroup: drugIngredientGroup,
  medicationForOrganization: medicationForOrganization,
  clinicTrial: clinicTrial,
  researchObject: researchObject,
  recruitmentObject: recruitmentObject,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_REQUESTED") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
