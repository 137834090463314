import React from "react";
import {
  Container,
  Content,
  ContentWrapper,
  Description,
  Title,
} from "./styles";

const NotFound = ({ title, description }) => {
  return (
    <ContentWrapper>
      <Container>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
      </Container>
    </ContentWrapper>
  );
};

export default NotFound;
