import * as type from "../types";

const initialState = {
  symptom: {
    loading: false,
    error: null,
    data: [],
    isSuccess: false,
  },
  medication: {
    loading: false,
    error: null,
    data: [],
    isSuccess: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_SYMPTOM_GROUP_REQUESTED:
      return {
        ...state,
        symptom: {
          loading: true,
          error: null,
          data: null,
          isSuccess: false,
        },
      };
    case type.GET_SYMPTOM_GROUP_SUCCESS:
      return {
        ...state,
        symptom: {
          loading: false,
          data: action.data,
          isSuccess: true,
        },
      };
    case type.GET_SYMPTOM_GROUP_FAILED:
      return {
        ...state,
        symptom: {
          loading: false,
          error: action.error,
          data: [],
          isSuccess: false,
        },
      };
    case type.GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_REQUESTED:
      return {
        ...state,
        medication: {
          loading: true,
          error: null,
          data: [],
          isSuccess: false,
        },
      };
    case type.GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_SUCCESS:
      return {
        ...state,
        medication: {
          loading: false,
          error: null,
          data: action.data,
          isSuccess: true,
        },
      };
    case type.GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_FAILED:
      return {
        ...state,
        medication: {
          loading: false,
          error: action.error,
          data: [],
          isSuccess: false,
        },
      };
    case type.REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_REQUESTED:
      return {
        ...state,
        medication: {
          loading: true,
          error: null,
          data: null,
          isSuccess: false,
        },
      };
    case type.REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_SUCCESS:
      return {
        ...state,
        medication: {
          loading: false,
          data: [],
          isSuccess: true,
        },
      };
    case type.REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_FAILED:
      return {
        ...state,
        medication: {
          loading: false,
          error: action.error,
          data: [],
          isSuccess: false,
        },
      };
    default:
      return state;
  }
};
