import { call, put, takeEvery } from "redux-saga/effects";
import { DrugTimeEvent } from "../../api";
import * as type from "../types";

function* getDrugTimeEvent() {
  try {
    const result = yield call(DrugTimeEvent.getDrugTimeEvent);
    yield put({ type: type.GET_DRUG_TIME_EVENT_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_DRUG_TIME_EVENT_FAILED, error: e.message });
  }
}

function* drugTimeEventSaga() {
  yield takeEvery(type.GET_DRUG_TIME_EVENT_REQUESTED, getDrugTimeEvent);
}

export default drugTimeEventSaga;
