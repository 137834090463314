import { call, put, takeEvery } from "redux-saga/effects";
import { AvailableTime } from "../../api";
import * as type from "../types";

function* fetchAvailableTimes(data) {
  try {
    const availableTimes = yield call(AvailableTime.getAvailableTimes, data.id);
    yield put({
      type: type.GET_AVAILABLE_TIMES_SUCCESS,
      data: availableTimes,
    });
  } catch (e) {
    yield put({
      type: type.GET_AVAILABLE_TIMES_FAILED,
      message: e.message,
    });
  }
}

function* updateAvailableTime(data) {
  const { id, detail } = data;
  try {
    const availableTime = yield call(AvailableTime.updateAvailableTime, {
      id,
      detail,
    });
    yield put({
      type: type.UPDATE_AVAILABLE_TIME_SUCCESS,
      data: availableTime,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_AVAILABLE_TIME_FAILED,
      message: e.message,
    });
  }
}

function* availableTimeSaga() {
  yield takeEvery(type.GET_AVAILABLE_TIMES_REQUESTED, fetchAvailableTimes);
  yield takeEvery(type.UPDATE_AVAILABLE_TIME_REQUESTED, updateAvailableTime);
}

export default availableTimeSaga;
