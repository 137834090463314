import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { HEADER_DEFAULT_FORMS } from "../constant";
import styles, { TableContainer, TitlePDFFile } from "../styles";
import moment from "moment";
import Checkbox from "../assets/Checkbox";
import CheckboxOutline from "../assets/CheckboxOutline";
import { isNumber, range } from "lodash";

export default function ProductfeasibilityPreview(props) {
  const { t, data } = props;
  return (
    <Document>
      <Page size="A4">
        <Text style={styles.header}>{t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <TitlePDFFile t={t} />
          <View style={styles.titleHeader}>
            <Text style={styles.dateSignedText}>
              {t("subjects_initials")}: {data.subjectsInitials || "-"}
            </Text>
            <Text style={styles.dateSignedText}>
              {t("crf_subject_id")}: {data.subjectId}
            </Text>
            <Text style={styles.timeText}>
              {t("crf_exam_date")}:{" "}
              {moment(data.createdAt).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={[styles.titleContainer, { marginBottom: 10 }]}>
            <Text style={styles.titleText}>{t("product_feasibility")}</Text>
          </View>
          <Text style={[styles.textBold, { marginBottom: 10 }]}>
            {t("crf_date_of_assessment")}:{" "}
            {moment(data.createdAt).format("DD/MM/YYYY")}
          </Text>
          <TableContainer>
            <View style={[styles.row]}>
              <View
                style={
                  (styles.colLastItem,
                  { alignItems: "flex-start", padding: 15 })
                }
              >
                <Text style={[styles.textDescription]}>
                  {t("crf_prod_fes_sub_title")}
                </Text>
              </View>
            </View>
            <View style={[styles.row]}>
              {range(10).map((item, index) => {
                return (
                  <View
                    key={index}
                    style={[
                      styles.colItemProdFes,
                      index === 9 && { borderRightWidth: 0 },
                    ]}
                  >
                    <Text style={styles.textDescription}>{item}</Text>
                  </View>
                );
              })}
            </View>
            <View style={[styles.row]}>
              {range(10).map((_, index) => {
                return (
                  <View
                    key={index}
                    style={[
                      styles.colItemProdFes,
                      { borderBottomWidth: 0 },
                      index === 9 && { borderRightWidth: 0 },
                    ]}
                  >
                    {isNumber(+data.productComplicated) &&
                    +data.productComplicated === index ? (
                      <Checkbox width={10} height={10} />
                    ) : (
                      <CheckboxOutline width={10} height={10} />
                    )}
                  </View>
                );
              })}
            </View>
          </TableContainer>
          <View style={[styles.row, { marginTop: 20 }]}>
            <Text style={styles.textBold}>{t("crf_aditional_notes")}:</Text>
            <Text style={[styles.textDescription, { marginLeft: 3, flex: 1 }]}>
              {data.comment || "-"}
            </Text>
          </View>
        </View>
        <View style={[styles.paddingBody, styles.row, { marginBottom: 20 }]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/07/2022
          </Text>
        </View>
      </Page>
    </Document>
  );
}
