import { Col, Divider, Row, Typography } from "antd";
import { CardContainer } from "../styles";
import moment from "moment";

const InventionTimelineComponent = ({
  t,
  setFollowUpForms,
  setIsSchemaModalVisible,
  researchObjectData,
}) => {
  const { Link, Text } = Typography;
  let rows = [];

  const onClickDetail = (forms) => {
    setFollowUpForms(forms);
    setIsSchemaModalVisible(true);
  };

  for (let i = 14 - 1; i >= 0; i--) {
    const data = researchObjectData?.followUpForm?.filter(
      (item) => item.day == i + 1
    );
    const startDate = moment(researchObjectData?.filtering?.createdAt).startOf(
      "day"
    );
    const isSubmitted = data?.some((item) => item.submitted);
    const dateShowing = startDate.add(i, "day").format("DD MMM");

    const timeShowing = data?.reduce(
      (prev, curr) =>
        curr.submittedDate !== null
          ? moment(curr.submittedDate).format("LT")
          : prev,
      ""
    );

    rows.push(
      <Row type="flex" key={i}>
        <Col span={5} align="right" style={{ paddingRight: 5 }}>
          <Text style={{ color: "#2BA796" }}>{dateShowing}</Text>
        </Col>
        <Col span={2} align="center" style={{ padding: 5 }}>
          <Row align="middle" justify="center">
            <Row
              type="flex"
              style={{
                justifyContent: "center",
                backgroundColor: isSubmitted ? "#2BA796" : "#BDBDBD",
                width: "10px",
                height: "10px",
                borderRadius: 100,
                marginBottom: 5,
              }}
            />
            <Divider type="vertical" style={{ height: "80px" }} />
          </Row>
        </Col>
        <Col span={17}>
          <CardContainer minheight="80px">
            <Row type="flex">
              <Col span={12} align="left" style={{ padding: 5 }}>
                <Col>
                  <Text style={{ fontSize: 10 }}>{timeShowing}</Text>
                </Col>
              </Col>
              <Col span={12} align="right" style={{ padding: 5 }}>
                <Row
                  type="flex"
                  style={{
                    justifyContent: "center",
                    backgroundColor: "#F4FAFE",
                    borderRadius: 10,
                    margin: 5,
                    padding: 5,
                  }}
                >
                  <Text
                    style={{
                      color: "#2BA796",
                    }}
                  >{`${t("day")} ${i + 1}`}</Text>
                </Row>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={24} align="right" style={{ padding: 5 }}>
                {isSubmitted ? (
                  <Link onClick={() => onClickDetail(data)}>
                    {t("safety_assessment")}
                  </Link>
                ) : (
                  <Text>{t("safety_assessment")}</Text>
                )}
              </Col>
            </Row>
          </CardContainer>
        </Col>
      </Row>
    );
  }
  return rows;
};
export default InventionTimelineComponent;
