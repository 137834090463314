import http from "../services/httpServiceHealthCare";
const apiEndpoint = "/researchTemplates";

async function getResearchTemplateId() {
  const { data } = await http.get(
    `${apiEndpoint}?filter[where][name]=14-days-follow-up`
  );
  return data;
}

export { getResearchTemplateId };
