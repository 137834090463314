import * as type from "../types";

const initialState = {
  drug: {
    loading: false,
    error: null,
    data: [],
    isSuccess: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_DRUG_INGREDIENT_REQUESTED:
      return {
        ...state,
        drug: {
          loading: true,
          error: null,
          data: [],
          isSuccess: false,
        },
      };
    case type.GET_DRUG_INGREDIENT_SUCCESS:
      return {
        ...state,
        drug: {
          loading: false,
          data: action.data,
          isSuccess: true,
        },
      };
    case type.GET_DRUG_INGREDIENT_FAILED:
      return {
        ...state,
        drug: {
          loading: false,
          error: null,
          data: [],
          isSuccess: false,
        },
      };
    default:
      return state;
  }
};
