import country from "./assets/country.json";
import nationality from "./assets/nationality.json";
export const CHECK_BOX_LIST_INCLUSION = [
  {
    title: "inclusion_1",
    key: "qualifyAge",
  },
  {
    title: "inclusion_2",
    key: "beingHealthy",
  },
  {
    title: "inclusion_3",
    key: "getVaccinated",
  },
  {
    title: "inclusion_4",
    key: "noCloseContactCovid",
  },
  {
    title: "inclusion_5",
    key: "negativeCovid19Test",
  },
  {
    title: "inclusion_6",
    key: "signedWrittenConsent",
  },
  {
    title: "inclusion_7",
    key: "complyWithRequirements",
  },
];

export const CHECK_BOX_LIST_EXCLUSION = [
  {
    title: "exclusion_1",
    key: "abnormalitiesAtPhysicalExam",
  },
  {
    title: "exclusion_2",
    key: "vitalSignAbnormalities",
  },
  {
    title: "exclusion_3",
    key: "positiveUrinePregnancyTest",
  },
  {
    title: "exclusion_4",
    key: "covid19Infection",
  },
  {
    title: "exclusion_5",
    key: "allergicReaction",
  },
  {
    title: "exclusion_6",
    key: "partipationInClinicalStudy",
  },
  {
    title: "exclusion_7",
    key: "nasalProductUsage",
  },
  {
    title: "exclusion_8",
    key: "pulmonaryInfiltrate",
  },
  {
    title: "exclusion_9",
    key: "respiratotyTractAbnormalities",
  },
  {
    title: "exclusion_10",
    key: "chronicAllergicRhinitis",
  },
  {
    title: "exclusion_11",
    key: "anyReason",
  },
];

export const RELIGION = [
  {
    label: "buddhsim",
    key: "1",
  },
  {
    label: "islam",
    key: "2",
  },
  {
    label: "christianity",
    key: "3",
  },
  {
    label: "hindu",
    key: "4",
  },
  {
    label: "sikhism",
    key: "5",
  },
  {
    label: "confucius",
    key: "6",
  },
  {
    label: "other",
    key: "7",
  },
  {
    label: "no_religion",
    key: "8",
  },
];

export const GENDER = [
  {
    label: "MALE",
    key: "1",
  },
  {
    label: "FEMALE",
    key: "2",
  },
];

export const EDUCATION = [
  {
    label: "uneducated",
    key: "1",
  },
  {
    label: "primary_school",
    key: "2",
  },
  {
    label: "high_school",
    key: "3",
  },
  {
    label: "secondary_school",
    key: "4",
  },
  {
    label: "diploma",
    key: "5",
  },
  {
    label: "bachelor_degree",
    key: "6",
  },
  {
    label: "master_degree",
    key: "7",
  },
  {
    label: "other",
    key: "8",
  },
];

export const MARITAL_STATUS = [
  {
    label: "single",
    key: "1",
  },
  {
    label: "married",
    key: "2",
  },
  {
    label: "divorced",
    key: "3",
  },
  {
    label: "separated",
    key: "4",
  },
  {
    label: "widowed",
    key: "5",
  },
  {
    label: "engaged",
    key: "6",
  },
  {
    label: "civil_union",
    key: "7",
  },
];

export const NATIONALITY = (locale) => {
  return nationality.map((item, index) => ({
    label: locale === "th" ? item.th : item.en,
    key: index.toString(),
  }));
};
export const COUNTRY_LIST = (locale) =>
  country.map((item, index) => ({
    label: locale === "th" ? item.name : item.enName,
    key: index.toString(),
  }));
