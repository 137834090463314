import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
