import React from "react";
import PropTypes from "prop-types";
import { Spin, Row, Col, Space } from "antd";
import {
  ModalCustom,
  Container,
  Input,
  Text,
  DropDownWidget,
  Button,
  TextError,
} from "./styles";
import { NASAL_SWAB, STUDY_CASE } from "./constant";
export default function CtmsAddNewParticipant(props) {
  const { t, visible, setVisible, form, selectStudyHandler, loading } = props;

  const renderNormalValue = (key, items) => {
    return (
      <Container
        style={{ backgroundColor: "white" }}
        className="shadow px-2 py-1"
      >
        {items.map((item) => {
          return (
            <a
              key={item.key}
              onClick={(e) => {
                e.preventDefault();
                selectStudyHandler(key, item.label);
              }}
            >
              <Text className="py-1 my-1" key={item.key}>
                {item.label}
              </Text>
            </a>
          );
        })}
      </Container>
    );
  };
  return (
    <ModalCustom
      title="Edit"
      visible={visible}
      width={800}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={[
        <Button onClick={() => setVisible(false)} type="default">
          <Text bold color="#2ba796">
            {t("cancel")}
          </Text>
        </Button>,
        <Button onClick={form.handleSubmit} secondary="true">
          <Text bold color="white">
            {t("save")}
          </Text>
        </Button>,
      ]}
    >
      <Container>
        <Spin spinning={loading}>
          <Row gutter={[16, 16]} className="my-4">
            <Col span={12}>
              <Space direction="vertical" className="w-100">
                <Text size="12px" bold>
                  {t("subject_id")}
                </Text>
                <Input
                  value={form.values.subjectId}
                  disabled
                  placeholer=""
                  width="100%"
                />
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" className="w-100">
                <Text size="12px" bold>
                  {t("product_id")}
                </Text>
                <Input
                  value={form.values.productId}
                  disabled
                  placeholer=""
                  width="100%"
                />
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="my-4">
            <Col span={12}>
              <Space direction="vertical" className="w-100">
                <Text size="12px" bold>
                  {t("study")}
                </Text>
                <DropDownWidget
                  width="100%"
                  value={form.values.study}
                  placeholder="Select Value"
                  renderList={renderNormalValue("study", STUDY_CASE)}
                />
                {form.errors.study && (
                  <TextError>{form.errors.study}</TextError>
                )}
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" className="w-100">
                <Text size="12px" bold>
                  {t("nasal_swap")}
                </Text>
                <DropDownWidget
                  width="100%"
                  value={form.values.nasalSwab}
                  placeholder="Select Value"
                  renderList={renderNormalValue("nasalSwab", NASAL_SWAB)}
                />
                {form.errors.nasalSwab && (
                  <TextError>{form.errors.nasalSwab}</TextError>
                )}
              </Space>
            </Col>
          </Row>
        </Spin>
      </Container>
    </ModalCustom>
  );
}

CtmsAddNewParticipant.propTypes = {
  t: PropTypes.any,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  form: PropTypes.object,
  selectStudyHandler: PropTypes.func,
  loading: PropTypes.bool,
};
