import { View, Document, Page, Text, Image } from "@react-pdf/renderer";
import React from "react";
import { HEADER_DEFAULT_FORMS } from "../constant";
import EverLogo from "../../../images/EverLogo.png";
import CheckboxOutline from "../assets/CheckboxOutline";
import Checkbox from "../assets/Checkbox";
import styles, { TableContainer } from "../styles";
import moment from "moment";
import { DISPLAY_CATEGORY } from "../../Modal/CtmsWithdrawnFormModal/constant";
export default function SubjectDeviationPreview(props) {
  const { t, data } = props;

  return (
    <Document>
      <Page size="A4">
        <Text style={styles.header}>{t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <View style={styles.subHeader}>
            <Image source={EverLogo} style={styles.logoEver} />
          </View>
          <View style={styles.titleHeader}>
            <Text style={styles.dateSignedText}>
              {t("subjects_initials")}: {data.subjectsInitials || "-"}
            </Text>
            <Text style={styles.dateSignedText}>
              {t("crf_subject_id")}: {data.subjectId}
            </Text>
            <Text style={styles.timeText}>
              {t("crf_page")} {data.page || "-"} {t("crf_of")} {data.of || "-"}
            </Text>
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}> {t("withdrawn_title")}</Text>
          </View>
          <TableContainer>
            <View style={styles.row}>
              <View
                style={[styles.colItemAdv, { width: 20, alignItems: "center" }]}
              >
                <Text style={styles.textDescription}>#</Text>
              </View>
              <View
                style={[styles.colItemAdv, { width: 40, alignItems: "center" }]}
              >
                <Text style={styles.textDescription}>{`Start \nDate`}</Text>
              </View>
              <View
                style={[styles.colItemAdv, { width: 40, alignItems: "center" }]}
              >
                <Text style={styles.textDescription}>{`End \nDate`}</Text>
              </View>
              <View style={[styles.colItemTNSS, { justifyContent: "center" }]}>
                <Text style={styles.textDescription}>Description</Text>
              </View>
              <View
                style={[
                  styles.colItemAdv,
                  { width: 60, justifyContent: "center" },
                ]}
              >
                <Text style={styles.textDescription}>
                  {`Category \n(see below)`}
                </Text>
              </View>
              <View
                style={[{ width: 180, borderRight: 1, borderColor: "#E0E0E0" }]}
              >
                <View
                  style={{
                    padding: 6,
                    alignItems: "center",
                    borderBottom: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <Text style={styles.textDescription}>
                    {`Does the deviation \nUP have the potential to:`}
                  </Text>
                </View>
                <View style={[styles.row, { width: "100%" }]}>
                  <View
                    style={{
                      width: 60,
                      padding: 6,
                      alignItems: "center",
                      borderRightWidth: 1,
                      borderBottomWidth: 1,
                      borderColor: "#E0E0E0",
                    }}
                  >
                    <Text style={[styles.textDescription, { fontSize: 6 }]}>
                      {`Impact Subject \nSafety*`}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 60,
                      padding: 6,
                      alignItems: "center",
                      borderRightWidth: 1,
                      borderBottomWidth: 1,
                      borderColor: "#E0E0E0",
                    }}
                  >
                    <Text style={[styles.textDescription, { fontSize: 6 }]}>
                      {`Affect Data \nIntegrity*`}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 60,
                      padding: 6,
                      alignItems: "center",
                      borderBottomWidth: 1,
                      borderColor: "#E0E0E0",
                    }}
                  >
                    <Text style={[styles.textDescription, { fontSize: 6 }]}>
                      {`Affect Subject's\n Willingness to \nParticipate?*`}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={[
                  styles.colItemAdv,
                  { width: 60, justifyContent: "center" },
                ]}
              >
                <Text style={styles.textDescription}>Reported to IRB</Text>
              </View>
              <View
                style={[
                  styles.colHeaderAdv,
                  {
                    width: 35,
                    paddingVertical: 5,
                    paddingHorizontal: 5,
                    borderRightWidth: 1,
                    borderBottomWidth: 1,
                    borderColor: "#E0E0E0",
                  },
                ]}
              >
                <Text
                  style={{
                    width: 45,
                    bottom: "50%",
                    paddingLeft: 10,
                    transform: "rotate(-90deg)",
                    alignSelf: "center",
                    position: "absolute",
                    fontSize: 8,
                    color: "#0000008C",
                  }}
                >
                  {`PI Initials`}
                </Text>
              </View>
              <View style={[styles.colHeaderAdv, { width: 30 }]}>
                <Text
                  style={{
                    width: 55,
                    bottom: "50%",
                    paddingLeft: 10,
                    transform: "rotate(-90deg)",
                    alignSelf: "center",
                    position: "absolute",
                    fontSize: 8,
                    color: "#0000008C",
                  }}
                >
                  {`Date of \nPI Initials`}
                </Text>
              </View>
            </View>

            {Array.isArray(data.subjectDeviation) &&
              data.subjectDeviation.map((item, index) => {
                return (
                  <View style={[styles.row, { height: 40 }]}>
                    <View
                      style={[
                        styles.colItemAdv,
                        { width: 20, alignItems: "center" },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.sequence}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colItemAdv,
                        { width: 40, alignItems: "center" },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.startDate
                          ? moment(item.endDstartDateate).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colItemAdv,
                        { width: 40, alignItems: "center" },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.endDate
                          ? moment(item.endDate).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                    <View
                      style={[styles.colItemTNSS, { justifyContent: "center" }]}
                    >
                      <Text style={styles.textDescription}>
                        {item.description}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colItemAdv,
                        { width: 60, justifyContent: "center" },
                      ]}
                    >
                      <Text style={styles.textDescriptionCenter}>
                        {DISPLAY_CATEGORY[item.category] ?? ""}
                      </Text>
                    </View>
                    <View
                      style={[
                        {
                          width: 180,
                          borderRight: 1,
                          borderColor: "#E0E0E0",
                        },
                      ]}
                    >
                      <View style={[styles.row, { width: "100%", flex: 1 }]}>
                        <View style={styles.colWidthdrawn}>
                          <CheckboxGroupComponent
                            checked={item.impactSubjectSafety}
                          />
                        </View>
                        <View style={styles.colWidthdrawn}>
                          <CheckboxGroupComponent
                            checked={item.affectDataIntegrity}
                          />
                        </View>
                        <View style={styles.colLastWithDrawn}>
                          <CheckboxGroupComponent
                            checked={item.affectSubjectWillingness}
                          />
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.colItemAdv,
                        { width: 60, justifyContent: "center" },
                      ]}
                    >
                      <View style={[styles.row, { alignItems: "center" }]}>
                        {item.reported ? (
                          <Checkbox width={8} height={8} />
                        ) : (
                          <CheckboxOutline width={8} height={8} />
                        )}
                        <Text
                          style={[
                            styles.textDescription,
                            { fontSize: 6, marginLeft: 2 },
                          ]}
                        >
                          Not applicable
                        </Text>
                      </View>
                      <Text style={styles.textDescription}>
                        {item.reportedDate
                          ? moment(item.reportedDate).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colHeaderAdv,
                        {
                          width: 35,
                          paddingVertical: 5,
                          paddingHorizontal: 5,
                          borderRightWidth: 1,
                          borderBottomWidth: 1,
                          borderColor: "#E0E0E0",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: 45,
                          ...styles.textVerticalWidthdrawn,
                        }}
                      >
                        {item.piInitials}
                      </Text>
                    </View>
                    <View style={[styles.colHeaderAdv, { width: 30 }]}>
                      <Text
                        style={{
                          width: 55,
                          ...styles.textVerticalWidthdrawn,
                        }}
                      >
                        {item.piInitialsDate
                          ? moment(item.piInitialsDate).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </TableContainer>
          <Text style={[styles.textDescription, { fontSize: 7 }]}>
            *If one or more is answered yes for any event, it must be reported
            to the IRB promptly (14 business days from notification of or
            becoming aware of the event).
          </Text>
          <View style={{ marginVertical: 25 }}>
            <Text style={styles.textBold}>{t("category")}</Text>
            <View>
              <Text style={styles.textDescription}>
                {t("cateogory_clinic_1")}
              </Text>
              <Text style={styles.textDescription}>
                {t("cateogory_clinic_2")}
              </Text>
              <Text style={styles.textDescription}>
                {t("cateogory_clinic_3")}
              </Text>
              <Text style={styles.textDescription}>
                {t("cateogory_clinic_4")}
              </Text>
              <Text style={styles.textDescription}>
                {t("cateogory_clinic_5")}
              </Text>
              <Text style={styles.textDescription}>
                {t("cateogory_clinic_6")}
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.paddingBody, styles.row, { marginBottom: 20 }]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/29/2022
          </Text>
        </View>
      </Page>
    </Document>
  );
}

const CheckboxGroupComponent = ({ checked }) => {
  return (
    <View>
      <View style={[styles.row, { alignItems: "center", marginBottom: 5 }]}>
        {checked === "yes" ? (
          <Checkbox width={8} height={8} />
        ) : (
          <CheckboxOutline width={8} height={8} />
        )}
        <Text style={[styles.textDescription, { fontSize: 6, marginLeft: 2 }]}>
          Yes
        </Text>
      </View>
      <View style={[styles.row, { alignItems: "center", marginBottom: 5 }]}>
        {checked === "no" ? (
          <Checkbox width={8} height={8} />
        ) : (
          <CheckboxOutline width={8} height={8} />
        )}
        <Text style={[styles.textDescription, { fontSize: 6, marginLeft: 2 }]}>
          No
        </Text>
      </View>
      <View style={[styles.row, { alignItems: "center" }]}>
        {checked === "not_applicable" ? (
          <Checkbox width={8} height={8} />
        ) : (
          <CheckboxOutline width={8} height={8} />
        )}
        <Text style={[styles.textDescription, { fontSize: 6, marginLeft: 2 }]}>
          Not applicable
        </Text>
      </View>
    </View>
  );
};
