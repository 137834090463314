import http from "../services/httpServiceHealthCare";
import { getUserSetting } from "./appUserOma";
import axios from "axios";
const apiEndpoint = "/userFcmTokens";

async function sendNotification({ id, notification, detail }) {
  let language = "th";
  /** NOTE to prevent error when call GET user/{id}/userSetting API */
  try {
    language = await getUserSetting(id);
  } catch (e) {}
  const filter = `filter[where][userId]=${id}`;
  const { data } = await http.get(`${apiEndpoint}?${filter}`, {
    params: {
      userId: id,
    },
  });
  data.forEach(async (res) => {
    const notificationData = {
      to: res.fcmToken,
      notification: notification(language),
      data: detail,
    };
    await sendFcmMessages(notificationData);
  });
}

async function sendFcmMessages(detail) {
  const authorizationKey = process.env.REACT_APP_NOTIFICATION_KEY;
  try {
    await axios.post(
      "https://fcm.googleapis.com/fcm/send",
      {
        to: detail.to,
        notification: detail.notification,
        data: detail.data,
      },
      {
        headers: {
          Authorization: "key=" + authorizationKey,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export { sendNotification };
