import { Card, Button as BtnAnt } from "antd";
import IconAnt from "@ant-design/icons";
import styled from "styled-components";
import TextBase from "../Text";

const Container = styled.div`
  width: 100%;
`;

const CardContainer = styled(Card)`
  &&& {
    margin:0px
    overflow: hidden;
    height: 100%;
    min-width:400px;
    min-height: ${(props) => props.minheight};
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  }
  .ant-card-body {
    height:100%;
    padding: 0
  }
  .ant-card-extra{
    padding: 10px 0;
  }
`;

const Icon = styled(IconAnt)`
  &&&& {
    color: ${(props) => props.color} !important;
  }
  font-size: ${(props) => props.size};
`;

const Button = styled(BtnAnt)`
  &&& {
    border-color: ${(props) => props.color};
  }
`;

const Text = styled(TextBase)`
  color: ${(props) => (props.active ? "#2BA796" : props.color)};
  font-size: ${(props) => props.size};
  font-weight: "bold";
  margin: 0px;
`;

export { Container, CardContainer, Text, Button, Icon };
