import { Modal, Row, Col } from "antd";
import moment from "moment";
import { Container, Text } from "./styles";

const ParticipantInfomationModal = ({
  t,
  visible,
  setVisible,
  data,
  extraData,
  subjectId,
}) => {
  return (
    <Modal
      title={t("participant_profile")}
      width={800}
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <Container>
        <Row>
          <Col>
            <Text bold>{t("subject_id")}</Text>
            <Text>{subjectId}</Text>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Text bold>{t("gender")}</Text>
            <Text>{t(extraData?.gender)}</Text>
          </Col>
          <Col offset={2}>
            <Text bold>{t("date_of_birth_ctms")}</Text>
            <Text>
              {extraData?.birthDate
                ? moment(extraData?.birthDate).isValid() &&
                  moment(extraData?.birthDate).format("DD MMMM YYYY")
                : ""}
            </Text>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Text bold>{t("mobile_number")}</Text>
            <Text>{data?.phoneNumber ?? ""}</Text>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Text bold>{t("identity")}</Text>
            <Text>{extraData?.identity ?? ""}</Text>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ParticipantInfomationModal;
