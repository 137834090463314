import { message } from "antd";
import { isNumber, is } from "lodash";
import moment from "moment";

export const isNumberValidate = (val) => {
  if (isNumber(+val) && !isNaN(+val)) {
    return true;
  } else {
    message.warning("Value should be a number");
    return false;
  }
};

export const checkDateInvalid = (date) => {
  return moment(date).isValid() ? moment(date) : null;
};
