import React from "react";
import { Container, Content, Cover, ProfileImage } from "./styles";

const Profile = ({ name, email, profileImage }) => {
  return (
    <Container>
      <Cover />
      <Content>
        <ProfileImage src={profileImage} />
        <h2>{name}</h2>
        <p>{email}</p>
        {/*<ManageProfile>Manage Profile</ManageProfile>*/}
      </Content>
    </Container>
  );
};

export default Profile;
