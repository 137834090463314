import React from "react";
import {
  GoogleMap as GMap,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";

const containerStyle = "100vh";

const GoogleMap = (props) => {
  const {
    bookings,
    height,
    zoom,
    radius,
    isShowUI,
    communityPharmacy,
    location,
    isLocation,
    setBookingId,
    bookingId,
  } = props;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBDLYwoxv5GtDACl-UE5M4aaIN77Q1MYno",
  });

  let center;
  if (isShowUI) {
    const { latitude, longitude } = bookings[0];
    center = { lat: latitude, lng: longitude };
  } else {
    const { lat, lng } = communityPharmacy.address.location;
    center = { lat, lng };
  }

  return isLoaded ? (
    <GMap
      options={{ disableDefaultUI: isShowUI ?? false }}
      mapContainerStyle={{
        minHeight: height ?? containerStyle,
        borderBottomLeftRadius: radius ?? 0,
        borderBottomRightRadius: radius ?? 0,
      }}
      center={isLocation ? location : center}
      zoom={zoom ?? 15}
    >
      {bookings.map((booking, index) => {
        const position = { lat: booking.latitude, lng: booking.longitude };
        const icon =
          bookingId === booking.id
            ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
            : "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
        return (
          <Marker
            key={index}
            position={position}
            icon={{
              url: icon,
            }}
            onClick={() => setBookingId(booking.id)}
          />
        );
      })}
      {!isShowUI && (
        <Marker
          position={center}
          icon={{
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          }}
        />
      )}
    </GMap>
  ) : (
    <></>
  );
};

export default GoogleMap;
