import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { HEADER_DEFAULT_FORMS } from "../constant";
import styles, { TableContainer, TitlePDFFile } from "../styles";
import Checkbox from "../assets/Checkbox";
import CheckboxOutline from "../assets/CheckboxOutline";
import moment from "moment";

import { isNaN, isNumber, range } from "lodash";

export default function NasalAndSinusExamPreview(props) {
  const checkIsNumber = React.useCallback((value) => {
    return isNumber(+value) && !isNaN(+value) ? +value : 0;
  }, []);
  const { t, data } = props;
  return (
    <Document>
      <Page size="A4">
        <Text style={styles.header}>{t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <TitlePDFFile t={t} />
          <View style={styles.titleHeader}>
            <Text style={styles.dateSignedText}>
              {t("subjects_initials")}: {data.subjectsInitials || "-"}
            </Text>
            <Text style={styles.dateSignedText}>
              {t("crf_subject_id")}: {data.subjectId}
            </Text>
            <Text style={styles.timeText}>
              {t("crf_exam_date")}:{" "}
              {moment(data.createdAt).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={[styles.titleContainer, { marginBottom: 10 }]}>
            <Text style={styles.titleText}>{t(data?.type)}</Text>
          </View>
          <Text style={[styles.textDescription, { marginBottom: 10 }]}>
            <Text style={styles.fontBold}>{t("crf_time")}: </Text>
            {moment(data.timeCreated).format("HH:mm")} {t("crf_use_24_format")}
            (e.g. hh:mm)
          </Text>
          <Text style={styles.textBold}>{t("crf_visit_number")}:</Text>
          <View style={[styles.row, { marginBottom: 5, marginTop: 4 }]}>
            <View style={styles.col}>
              {range(3).map((item) => (
                <View
                  key={item}
                  style={[
                    styles.row,
                    { alignItems: "center", marginBottom: 6 },
                  ]}
                >
                  {item + 1 === data.visit ? (
                    <Checkbox width={10} height={10} />
                  ) : (
                    <CheckboxOutline width={10} height={10} />
                  )}
                  <Text style={[styles.textDescription, { marginLeft: 8 }]}>
                    Visit {item + 1}
                  </Text>
                </View>
              ))}
            </View>
            <View style={styles.col}>
              {range(3).map((item) => (
                <View
                  key={item}
                  style={[
                    styles.row,
                    { alignItems: "center", marginBottom: 6 },
                  ]}
                >
                  {item + 4 === data.visit ? (
                    <Checkbox width={10} height={10} />
                  ) : (
                    <CheckboxOutline width={10} height={10} />
                  )}
                  <Text style={[styles.textDescription, { marginLeft: 8 }]}>
                    Visit {item + 4}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={[styles.row, { marginBottom: 5 }]}>
            {data.notPerform ? (
              <Checkbox width={10} height={10} />
            ) : (
              <CheckboxOutline width={10} height={10} />
            )}
            <Text style={[styles.textDescription, { marginLeft: 8 }]}>
              {t("crf_nasal_not_performed")}
            </Text>
          </View>
          <TableContainer>
            <View style={styles.row}>
              <View style={[styles.colLastItem, { alignItems: "flex-start" }]}>
                <Text style={[styles.textDescription, { marginLeft: 8 }]}>
                  <Text style={styles.fontBold}>{t("crf_rt_pcr")} </Text>
                  {t("crf_rt_pcr_hours")}
                </Text>
              </View>
            </View>

            <View style={[styles.row, { padding: 6 }]}>
              <View style={styles.col}>
                <View style={[styles.row, { marginBottom: 10 }]}>
                  <Text style={styles.textDescription}>
                    <Text style={styles.fontBold}>
                      {t("crf_ date_of_sample_collec")}:
                    </Text>{" "}
                    {moment(data.sampleDateAt).format("DD/MM/YYYY")}{" "}
                    <Text style={styles.fontBold}>(day/month/year)</Text>
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.textBold, { marginRight: 5 }]}>
                    {t("crf_rt_pcr_test")}:
                  </Text>
                  {data.nasopharyngealStatus === "not_detected" ? (
                    <Checkbox width={10} height={10} />
                  ) : (
                    <CheckboxOutline width={10} height={10} />
                  )}
                  <Text
                    style={[styles.textDescription, { marginHorizontal: 8 }]}
                  >
                    {t("crf_negative")}
                  </Text>
                  {data.nasopharyngealStatus === "detected" ? (
                    <Checkbox width={10} height={10} />
                  ) : (
                    <CheckboxOutline width={10} height={10} />
                  )}
                  <Text
                    style={[styles.textDescription, { marginHorizontal: 8 }]}
                  >
                    {t("crf_positive")}
                  </Text>
                  <CheckboxOutline width={10} height={10} />
                  <Text
                    style={[styles.textDescription, { marginHorizontal: 8 }]}
                  >
                    {t("crf_rt_pcr_not_measure")}
                  </Text>
                </View>
              </View>
            </View>
          </TableContainer>
          <View style={{ height: 10 }} />
          <TableContainer>
            <View style={styles.row}>
              <View style={[styles.colLastItem, { alignItems: "flex-start" }]}>
                <Text style={[styles.textBold, { marginLeft: 8 }]}>
                  {t("crf_sinuscopy")}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[
                  styles.colItemTNSS,
                  { flex: 3, alignItems: "flex-start" },
                ]}
              >
                <Text style={[styles.textDescription, { marginLeft: 8 }]}>
                  {t("endoscopy_score")}
                </Text>
              </View>
              <View style={styles.colItemTNSS}>
                <Text style={styles.textDescription}>{t("left")}</Text>
              </View>
              <View style={styles.colLastItem}>
                <Text style={styles.textDescription}>{t("right")}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[
                  styles.colItemTNSS,
                  { flex: 3, alignItems: "flex-start" },
                ]}
              >
                <Text style={[styles.textDescription, { marginLeft: 8 }]}>
                  {t("middle_meatus")}
                </Text>
              </View>
              <View style={styles.colItemTNSS}>
                <Text style={styles.textDescription}>
                  {data.sinuscopyPolypsLeft}
                </Text>
              </View>
              <View style={styles.colLastItem}>
                <Text style={styles.textDescription}>
                  {data.sinuscopyPolypsRight}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[
                  styles.colItemTNSS,
                  { flex: 3, alignItems: "flex-start" },
                ]}
              >
                <Text style={[styles.textDescription, { marginLeft: 8 }]}>
                  {t("oedema")}
                </Text>
              </View>
              <View style={styles.colItemTNSS}>
                <Text style={styles.textDescription}>
                  {data.sinuscopyOedemaLeft}
                </Text>
              </View>
              <View style={styles.colLastItem}>
                <Text style={styles.textDescription}>
                  {data.sinuscopyOedemaRight}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[
                  styles.colItemTNSS,
                  { flex: 3, alignItems: "flex-start" },
                ]}
              >
                <Text style={[styles.textDescription, { marginLeft: 8 }]}>
                  {t("discharge")}
                </Text>
              </View>
              <View style={styles.colItemTNSS}>
                <Text style={styles.textDescription}>
                  {data.sinuscopyDischargeLeft}
                </Text>
              </View>
              <View style={styles.colLastItem}>
                <Text style={styles.textDescription}>
                  {data.sinuscopyDischargeRight}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[
                  styles.colItemTNSS,
                  { flex: 3, alignItems: "flex-end", borderBottomWidth: 2 },
                ]}
              >
                <Text style={styles.textBold}>{t("total_left_right")}</Text>
              </View>
              <View
                style={[
                  styles.colLastItem,
                  { paddingHorizontal: 12.5, flex: 2, borderBottomWidth: 2 },
                ]}
              >
                <Text style={styles.textDescription}>
                  {checkIsNumber(data.sinuscopyPolypsRight) +
                    checkIsNumber(data.sinuscopyOedemaRight) +
                    checkIsNumber(data.sinuscopyDischargeRight) +
                    checkIsNumber(data.sinuscopyPolypsLeft) +
                    checkIsNumber(data.sinuscopyOedemaLeft) +
                    checkIsNumber(data.sinuscopyDischargeLeft)}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[
                  styles.colItemTNSS,
                  { flex: 4, alignItems: "flex-start", borderRightWidth: 0 },
                ]}
              >
                <Text
                  style={[
                    styles.textDescription,
                    { marginLeft: 8, lineHeight: 1.4 },
                  ]}
                >{`${t("polyps")}\n${t("oedema_normal")}\n${t(
                  "discharge_normal"
                )}`}</Text>
              </View>
              <View
                style={[
                  styles.colItemTNSS,
                  { flex: 2, alignItems: "flex-start", borderRightWidth: 0 },
                ]}
              >
                <Text
                  style={[styles.textDescription, { lineHeight: 1.4 }]}
                >{`${t("0_absent")}\n${t("0_absent")}\n${t("0_absent")}`}</Text>
              </View>
              <View
                style={[
                  styles.colItemTNSS,
                  { flex: 3, alignItems: "flex-start", borderRightWidth: 0 },
                ]}
              >
                <Text
                  style={[styles.textDescription, { lineHeight: 1.4 }]}
                >{`${t("1_middle_meatus")}\n${t("1_mild")}\n${t(
                  "1_clear_thin"
                )}`}</Text>
              </View>
              <View
                style={[
                  styles.colLastItem,
                  { flex: 4, alignItems: "flex-start" },
                ]}
              >
                <Text
                  style={[styles.textDescription, { lineHeight: 1.4 }]}
                >{`${t("2_extending_meatus")}\n${t("2_severse")}\n${t(
                  "2_thick_purulent"
                )}`}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.colLastItem, { alignItems: "flex-start" }]}>
                <Text style={[styles.textBold, { marginLeft: 8 }]}>
                  {t("crf_sinusscope")}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[
                  styles.colItemTNSS,
                  { borderBottom: 0, alignItems: "flex-start" },
                ]}
              >
                <Text style={[styles.textBold, { marginLeft: 8 }]}>Left</Text>
              </View>
              <View
                style={[
                  styles.colLastItem,
                  { borderBottom: 0, alignItems: "flex-start" },
                ]}
              >
                <Text style={[styles.textBold, { marginLeft: 8 }]}>Right</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.colItemLastRow}>
                {data.sinuscopyDischargeImageUrlLeft ? (
                  <Image
                    src={{ uri: data.sinuscopyDischargeImageUrlLeft }}
                    style={{ height: 90, width: 100 }}
                  />
                ) : (
                  <View style={{ height: 90 }} />
                )}
              </View>
              <View style={styles.colLastItemLastRow}>
                {data.sinuscopyDischargeImageUrlRight ? (
                  <Image
                    src={{ uri: data.sinuscopyDischargeImageUrlRight }}
                    style={{ height: 90, width: 100 }}
                  />
                ) : (
                  <View style={{ height: 90 }} />
                )}
              </View>
            </View>
          </TableContainer>
          <View style={[styles.row, { marginTop: 15 }]}>
            <Text style={styles.textBold}>{t("crf_aditional_notes")}:</Text>
            <Text style={[styles.textDescription, { marginLeft: 3, flex: 1 }]}>
              {data.comment}
            </Text>
          </View>
          <View style={[styles.row, { marginTop: 10 }]}>
            <Text style={styles.textBold}>{t("crf_nasal_performed_by")}:</Text>
            <Text style={[styles.textDescription, { marginLeft: 3 }]}>
              {t("crf_pi_name_fixed")}
            </Text>
          </View>
        </View>
        <View style={[styles.paddingBody, styles.row]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/28/2022
          </Text>
        </View>
        <View style={styles.paddingFooter}>
          <Text style={styles.textDescriptionCenter}>{t("crf_page")} 1/1</Text>
        </View>
      </Page>
    </Document>
  );
}
