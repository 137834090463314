import { Col, Row, Space, Descriptions } from "antd";
import { Container, Text, Input } from "../styles";

const DemographicDataSection = ({ t, form, disabled = false }) => {
  if (disabled) {
    const { height = "-", weight = "-", bmi = "-" } = form;

    return (
      <Container>
        <Row className="mt-2" align="middle">
          <Col>
            <Space direction="horizontal">
              <Descriptions
                labelStyle={{ fontWeight: "bold" }}
                size="small"
                column={1}
              >
                {height && (
                  <Descriptions.Item label={t("height")}>
                    {height}
                  </Descriptions.Item>
                )}
                {weight && (
                  <Descriptions.Item label={t("weight")}>
                    {weight}
                  </Descriptions.Item>
                )}
                {bmi && (
                  <Descriptions.Item label={t("BMI")}>{bmi}</Descriptions.Item>
                )}
              </Descriptions>
            </Space>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="mt-2" align="middle">
        <Col xs={6} sm={6} md={3} xxl={1}>
          <Text size="16px">{t("height")}</Text>
        </Col>
        <Col>
          <Space direction="horizontal">
            <Input
              value={form.values.height}
              onChange={(e) => {
                form.setFieldValue("height", e.target.value);
                if (
                  !isNaN(+form.values.weight) &&
                  !isNaN(+e.target.value) &&
                  typeof +form.values.weight === "number" &&
                  typeof +e.target.value === "number" &&
                  +e.target.value !== 0
                ) {
                  const heightInMeter = +e.target.value / 100;
                  form.setFieldValue(
                    "bmi",
                    (+form.values.weight / Math.pow(heightInMeter, 2)).toFixed(
                      2
                    )
                  );
                } else {
                  form.setFieldValue("bmi", 0);
                }
              }}
              maxLength={3}
            />
            <Text size="16px">{t("cm")}</Text>
          </Space>
        </Col>
      </Row>
      <Row className="mt-2" align="middle">
        <Col xs={6} sm={6} md={3} xxl={1}>
          <Text size="16px">{t("weight")}</Text>
        </Col>
        <Col>
          <Space direction="horizontal">
            <Input
              value={form.values.weight}
              onChange={(e) => {
                form.setFieldValue("weight", e.target.value);
                if (
                  !isNaN(+form.values.height) &&
                  !isNaN(+e.target.value) &&
                  typeof +form.values.height === "number" &&
                  typeof +e.target.value === "number"
                ) {
                  const heightInMeter = +form.values.height / 100;
                  form.setFieldValue(
                    "bmi",
                    (+e.target.value / Math.pow(heightInMeter, 2)).toFixed(2)
                  );
                }
              }}
              maxLength={3}
            />
            <Text size="16px">{t("kg")}</Text>
          </Space>
        </Col>
      </Row>
      <Row className="mt-2" align="middle">
        <Col xs={6} sm={6} md={3} xxl={1}>
          <Text size="16px">{t("bmi")}</Text>
        </Col>
        <Col>
          <Space direction="horizontal">
            <Input
              value={form.values.bmi}
              onChange={form.handleChange("bmi")}
              maxLength={3}
              disabled
            />
          </Space>
        </Col>
      </Row>
    </Container>
  );
};

export default DemographicDataSection;
