import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Organization, UserFcmToken, KycImage } from "../../redux/actions";
import { NOTIFICATION, NOTIFICATION_DATA, STATUS } from "./constant";

import Layout from "../Home";
import SubmissionListTable from "../../components/SubmissionListTable";
import { useTranslation } from "react-i18next";

const SubmissionList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organization);
  const appUserOma = useSelector((state) => state.appUserOma);
  const kycImage = useSelector((state) => state.kycImage);
  const [filterList, setFilterList] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentRecord, setCerrentRecrod] = useState({});

  useEffect(() => {
    if (appUserOma.information?.id) {
      dispatch(Organization.getSubmissionList(appUserOma.information?.id));
    }
  }, [appUserOma.information?.id]);

  useEffect(() => {
    if (!organization.loading && organization.organizations?.isSuccess) {
      setFilterList(organization.organizations.submissionList);
    }
  }, [organization]);

  const getKycPatientImage = (userId) => {
    dispatch(KycImage.getKycImages(userId));
  };

  const updateSubmissionList = (reqId, tag) => {
    dispatch(Organization.updateSubmissionList(reqId, tag));
  };

  const sendUserNotification = (userId, option) => {
    const currentReqIndex = filterList.findIndex(
      (item) => item.id === currentRecord.id
    );
    const listLabelIndex = organization.organizations?.userData.findIndex(
      (item) => item.id === filterList[currentReqIndex]?.organizationId
    );
    const notificationKey = option ? "approve" : "reject";
    dispatch(
      UserFcmToken.sendNotification(
        userId,
        NOTIFICATION(
          organization.organizations.userData[listLabelIndex]?.nameTh
            ? organization.organizations.userData[listLabelIndex]?.nameTh
            : organization.organizations.userData[listLabelIndex]?.name
        )[notificationKey],
        NOTIFICATION_DATA[notificationKey]
      )
    );
  };

  const onSearch = (query) => {
    if (query) {
      const queryText = `${query}`.toLowerCase();
      const searchList = organization.organizations.submissionList.filter(
        (ele) => {
          return (
            `${ele.id}`.toString().includes(queryText) ||
            `${ele.userId}`.toString().includes(queryText) ||
            `${ele.cId}`.includes(queryText) ||
            `${ele.firstName}`.toLowerCase().includes(queryText) ||
            `${ele.lastName}`.toLowerCase().includes(queryText)
          );
        }
      );
      setFilterList(searchList);
    } else {
      setFilterList(organization.organizations.submissionList);
    }
  };

  return (
    <Layout
      {...props}
      render={(props) => (
        <SubmissionListTable
          loading={organization.loading}
          filterList={filterList}
          onSearch={onSearch}
          sendUserNotification={sendUserNotification}
          updateSubmissionList={updateSubmissionList}
          kycImage={kycImage}
          getKycPatientImage={getKycPatientImage}
          statusConstatnt={STATUS}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          currentRecord={currentRecord}
          setCerrentRecrod={setCerrentRecrod}
          t={t}
          style={{ width: "100%" }}
        />
      )}
    />
  );
};

export default SubmissionList;
