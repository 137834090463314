import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { TABLE_SERIOUS_ADVERSE_CONTENT } from "../constant";
import EverLogo from "../../../images/EverLogo.png";
import styles, { TableContainer } from "../styles";

import moment from "moment";

export default function SeriousAdverseEventPreview(props) {
  const { t, data } = props;

  return (
    <Document>
      <Page size="A4">
        <View style={[styles.body, styles.paddingBody, { marginTop: 20 }]}>
          <View style={styles.subHeader}>
            <Image source={EverLogo} style={styles.logoEver} />
          </View>
          <View style={[styles.titleContainer, { marginVertical: 15 }]}>
            <Text style={styles.titleText}>
              {t("crf_serious_adverse_event")}
            </Text>
          </View>
          <View style={{ marginBottom: 10 }}>
            <View
              style={[styles.row, { alignItems: "center", marginBottom: 5 }]}
            >
              <Text style={styles.textDescription}>
                Principal Investigator:
              </Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {data.piName}
                </Text>
              </View>
            </View>
            <View style={[styles.row, { marginBottom: 5 }]}>
              <View style={[styles.row, { alignItems: "center", flex: 1 }]}>
                <Text style={styles.textDescription}>IRB Number:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.irbNumber}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.row,
                  {
                    alignItems: "center",
                    marginLeft: 5,
                    width: 100,
                  },
                ]}
              >
                <Text style={styles.textDescription}>Page:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.page}
                  </Text>
                </View>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  Of:
                </Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.of}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={[styles.row, { alignItems: "center", marginBottom: 5 }]}
            >
              <Text style={styles.textDescription}>Short Protocol Title:</Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {data.shortProtocolTitle}
                </Text>
              </View>
            </View>
          </View>
          <TableContainer>
            <View style={[styles.row, { height: 80 }]}>
              <View style={[styles.colItemTNSS, { justifyContent: "center" }]}>
                <Text style={styles.textDescription}>Subject ID No.</Text>
              </View>
              <View style={[styles.colItemTNSS, { justifyContent: "center" }]}>
                <Text style={styles.textDescription}>SAE Description</Text>
              </View>
              <View style={[styles.colItemTNSS, { justifyContent: "center" }]}>
                <Text style={styles.textDescription}>
                  {`Adverse Event* \n(Select from Safety Profiler)`}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>SAE Classification</Text>
              </View>
              <View style={[styles.colHeaderAdv, { width: 40 }]}>
                <Text
                  style={[styles.textVerticalAdv, { width: 25, bottom: 5 }]}
                >
                  Event Start Date
                </Text>
              </View>
              <View style={[styles.colHeaderAdv, { width: 40 }]}>
                <Text
                  style={[styles.textVerticalAdv, { width: 25, bottom: 5 }]}
                >
                  Event End Date
                </Text>
              </View>
              <View
                style={[
                  styles.colHeaderAdv,
                  {
                    width: 40,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textVerticalAdv,
                    { bottom: "50%", width: 100 },
                  ]}
                >{`Date Site Became \nAware of Event \n(Reported Date)`}</Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>Grade</Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>
                  {`Unexpected \n(Y or N)`}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>Attribution</Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>Outcome</Text>
              </View>
            </View>
            {Array.isArray(data.dataList) &&
              data.dataList.map((item, index) => {
                const isLast = data.dataList.length - 1 === index;
                return (
                  <View key={index} style={styles.row}>
                    <View
                      style={[
                        styles.colItemTNSS,
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.subjectId}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colItemTNSS,
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>{item.desp}</Text>
                    </View>
                    <View
                      style={[
                        styles.colItemTNSS,
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.adverseEvent}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colHeaderAdv,
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>{item.sae}</Text>
                    </View>
                    <View
                      style={[
                        styles.colHeaderAdv,
                        { width: 40 },
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.startDate
                          ? moment(item.startDate).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colHeaderAdv,
                        { width: 40 },
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.endDate
                          ? moment(item.endDate).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colHeaderAdv,
                        {
                          width: 40,
                        },
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.reportedDate
                          ? moment(item.reportedDate).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colHeaderAdv,
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>{item.grade}</Text>
                    </View>
                    <View
                      style={[
                        styles.colHeaderAdv,
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.unexpected}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colHeaderAdv,
                        isLast && { borderBottom: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {item.attribution}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colHeaderAdv,
                        isLast && { borderBottom: 0, borderRight: 0 },
                      ]}
                    >
                      <Text style={styles.textDescription}>{item.outcome}</Text>
                    </View>
                  </View>
                );
              })}
          </TableContainer>
          <Text style={[styles.textDescription, { marginBottom: 10 }]}>
            **look up corresponding AE at:
            https://safetyprofiler-ctep.nci.nih.gov/{" "}
          </Text>
          <TableContainer>
            <View style={styles.row}>
              <View
                style={[
                  styles.colItemTNSS,
                  {
                    flex: 4,
                    alignItems: "flex-start",
                  },
                ]}
              >
                <Text style={[styles.textBold, { left: 15 }]}>
                  SAE Classification
                </Text>
              </View>
              <View style={[styles.colItemTNSS, { alignItems: "flex-start" }]}>
                <Text style={styles.textBold}>Grade</Text>
              </View>
              <View style={[styles.colItemTNSS, { alignItems: "flex-start" }]}>
                <Text style={styles.textBold}>Attribution</Text>
              </View>
              <View style={[styles.colLastItem, { alignItems: "flex-start" }]}>
                <Text style={styles.textBold}>Outcome</Text>
              </View>
            </View>
            {TABLE_SERIOUS_ADVERSE_CONTENT.map((item, index) => {
              const isLastItem =
                index === TABLE_SERIOUS_ADVERSE_CONTENT.length - 1;
              return (
                <View key={index} style={styles.row}>
                  <View
                    style={[
                      styles.colItemTNSS,
                      {
                        flex: 4,
                      },
                      styles.viewCenter,
                      isLastItem && {
                        borderBottom: 0,
                      },
                    ]}
                  >
                    <Text style={styles.textDescription}>{item.sae}</Text>
                  </View>
                  <View
                    style={[
                      styles.colItemTNSS,
                      styles.viewCenter,
                      isLastItem && {
                        borderBottom: 0,
                      },
                    ]}
                  >
                    <Text style={styles.textDescription}>{item.grade}</Text>
                  </View>
                  <View
                    style={[
                      styles.colItemTNSS,
                      styles.viewCenter,
                      isLastItem && {
                        borderBottom: 0,
                      },
                    ]}
                  >
                    <Text style={styles.textDescription}>{item.add}</Text>
                  </View>
                  <View
                    style={[
                      styles.colLastItem,
                      styles.viewCenter,
                      isLastItem && {
                        borderBottom: 0,
                      },
                    ]}
                  >
                    <Text style={styles.textDescription}>{item.out}</Text>
                  </View>
                </View>
              );
            })}
          </TableContainer>
        </View>
        <View style={[styles.paddingBody, styles.row]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/31/2022
          </Text>
        </View>
        <View style={styles.paddingFooter}>
          <Text style={styles.textDescriptionCenter}>{t("crf_page")} 1/1</Text>
        </View>
      </Page>
    </Document>
  );
}
