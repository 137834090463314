import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/Treatments";

export async function createBookingByTreatment(treatmentDetail) {
  const { treatmentId, detail } = treatmentDetail;
  const { data } = await http.post(
    `${apiEndpoint}/${treatmentId}/bookings`,
    detail
  );
  return data;
}

export async function getBookingsByTreatmentId(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/bookings`);
  return data;
}

export async function updateTreatment({ treatmentId, detail }) {
  const { data } = await http.patch(`${apiEndpoint}/${treatmentId}`, {
    ...detail,
  });
  return data;
}
