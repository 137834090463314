import { call, put, takeEvery } from "redux-saga/effects";
import { SymptomGroup } from "../../api";
import * as type from "../types";

function* getSymptom() {
  try {
    const result = yield call(SymptomGroup.getSymptom);
    yield put({ type: type.GET_SYMPTOM_GROUP_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_SYMPTOM_GROUP_FAILED, error: e.message });
  }
}

function* getMedicationForOrgs(data) {
  try {
    const result = yield call(
      SymptomGroup.getMedicationForOrgs,
      data.symptomGroupId
    );
    yield put({
      type: type.GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_FAILED,
      error: e.message,
    });
  }
}

function* removeMedicationBySymptomGroupId() {
  yield put({
    type: type.REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_SUCCESS,
  });
}

function* symptomSaga() {
  yield takeEvery(type.GET_SYMPTOM_GROUP_REQUESTED, getSymptom);
  yield takeEvery(
    type.GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_REQUESTED,
    getMedicationForOrgs
  );
  yield takeEvery(
    type.REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_REQUESTED,
    removeMedicationBySymptomGroupId
  );
}

export default symptomSaga;
