import {
  Container,
  Text,
  Input,
  DatePicker,
  SpaceItemFlex,
  ButtonRounded,
  Icon,
} from "../styles";
import { Row, Col, Space, Descriptions } from "antd";
import { AiFillPlusCircle, FaTimes } from "react-icons/all";
import moment from "moment";

const disabledDate = (current) => {
  return current && current > moment().endOf("day");
};

const MedicalAndMedication = ({ t, form, disabled }) => {
  if (disabled) {
    const { medicationHistories = [] } = form;
    const finalData = medicationHistories.filter(
      (item) => item.medicationName !== ""
    );
    return (
      <Container>
        {finalData.length !== 0 ? (
          finalData.map((item, index) => {
            return (
              <Descriptions
                key={index}
                bordered
                labelStyle={{ fontWeight: "bold" }}
                size="small"
                column={1}
              >
                <Descriptions.Item label={`${index + 1}`}>
                  <Row gutter={[8, 8]}>
                    <Text bold>{t("name")} : </Text>
                    <Text className="ml-1" style={{ fontWeight: 300 }}>
                      {item.medicationName}
                    </Text>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Text bold>{t("date")} : </Text>
                    <Text className="ml-1" style={{ fontWeight: 300 }}>
                      {moment(item.date).format("DD/MM/YYYY")}
                    </Text>
                  </Row>
                </Descriptions.Item>
              </Descriptions>
            );
          })
        ) : (
          <Text>{t("nothing")}</Text>
        )}
      </Container>
    );
  }

  const onMedicationChange = (index, type, value) => {
    const medicationArray = Array.isArray(form.values.medicationHistories)
      ? form.values.medicationHistories
      : [];

    if (medicationArray[index]) {
      if (type === "date") {
        medicationArray[index].date = moment(value).toISOString();
      } else {
        medicationArray[index].medicationName = value;
      }
    }
    form.setFieldValue("medicationHistories", medicationArray);
  };

  const onAddmoreMedication = () => {
    form.setFieldValue("medicationHistories", [
      ...form.values.medicationHistories,
      {
        medicationName: "",
        date: "",
      },
    ]);
  };

  const onDeleteMedication = (index) => {
    form.setFieldValue(
      "medicationHistories",
      form.values.medicationHistories.filter((item, i) => i !== index)
    );
  };

  return (
    <>
      <Container>
        {form.values.medicationHistories.map((item, index) => {
          return (
            <Row key={index} className="my-2" gutter={[16, 8]}>
              <Col xs={12} sm={14} lg={16}>
                <>
                  <Space direction="horizontal">
                    <Text size="12px">HISTORY #{index + 1}</Text>
                    <Text color="red">*</Text>
                  </Space>
                  <Input
                    value={item.medicationName}
                    onChange={(e) => {
                      onMedicationChange(index, "text", e.target.value);
                    }}
                    width="100%"
                    placeHolder="Medications name"
                  />
                </>
              </Col>
              <Col xs={12} sm={10} lg={8}>
                <>
                  <Space direction="horizontal">
                    <Text size="12px">ESTIMATED ONSET</Text>
                    <Text color="red">*</Text>
                  </Space>
                  <SpaceItemFlex direction="horizontal w-100" align="center">
                    <DatePicker
                      format="DD MMMM YYYY"
                      disabledDate={disabledDate}
                      defaultValue={item.date ? moment(item.date) : null}
                      width="100%"
                      onChange={(date) => {
                        onMedicationChange(index, "date", date);
                      }}
                    />
                    <Icon
                      component={FaTimes}
                      size="20px"
                      color="#00000059"
                      onClick={() => onDeleteMedication(index)}
                    />
                  </SpaceItemFlex>
                </>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Container className="pt-2 ">
        <ButtonRounded onClick={onAddmoreMedication}>
          <Space className="py-1" direction="horizontal" align="center">
            <Icon component={AiFillPlusCircle} size="24px" color="#2BA796" />
            <Text>{t("add_more")}</Text>
          </Space>
        </ButtonRounded>
      </Container>
    </>
  );
};

export default MedicalAndMedication;
