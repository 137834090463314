import { call, put, takeEvery } from "redux-saga/effects";
import { RecruitmentObjects } from "../../api";
import * as type from "../types";

function* getRecruitmentObjectsById({ id }) {
  try {
    const result = yield call(
      RecruitmentObjects.getResearchObjFromRecruitmentObj,
      id
    );
    yield put({
      type: type.GET_RECRUITMENT_OBJECT_BY_ID_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_RECRUITMENT_OBJECT_BY_ID_FAILED,
      error: e.message,
    });
  }
}

function* RecruitmentObjectSaga() {
  yield takeEvery(
    type.GET_RECRUITMENT_OBJECT_BY_ID_REQUESTED,
    getRecruitmentObjectsById
  );
}

export default RecruitmentObjectSaga;
