import React from "react";
import EverLogo from "../../../images/EverLogo.png";
import { isNumberValidate, checkDateInvalid } from "../utils";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  HeaderContainer,
  TextArea,
} from "../styles";
import { Row, Col, Space, Checkbox, Radio, Divider } from "antd";
import { HEADER_DEFAULT_FORMS } from "../constant";
import moment from "moment";

export default function NasalAndSinusExamForm(props) {
  const { t, form = {}, editable } = props;

  return (
    <Container>
      <Text className="text-center" size="16px" color="#0000008C">
        {t(HEADER_DEFAULT_FORMS)}
      </Text>
      <Container className="px-5 mt-3 rounded border">
        <Image className="mt-4" src={EverLogo} />
        <Row gutter={[16, 16]} className="my-4" align="middle">
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("subjects_initials")}:
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.subjectsInitials}
                padding="2px"
                onChange={form.handleChange("subjectsInitials")}
                maxLength={3}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_subject_id")}:
              </Text>
              <Input
                disabled
                value={form.values?.subjectId}
                padding="2px"
                onChange={form.handleChange("subjectId")}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_exam_date")}:
              </Text>
              <DatePicker
                disabled
                value={checkDateInvalid(form.values?.createdAt)}
                format="DD/MM/YYYY"
                onChange={(date) =>
                  form.setFieldValue("createdAt", moment(date).toISOString())
                }
                padding="2px"
                width="130px"
              />
            </Space>
          </Col>
        </Row>
        <HeaderContainer>
          <Text className="my-2 text-center" bold size="16px">
            {t(form.values?.type)}
          </Text>
        </HeaderContainer>
        <Container>
          <Space align="center" direction="horizontal" className="mt-3">
            <Text bold color="grey">
              {t("crf_time")}:
            </Text>
            <DatePicker
              picker="time"
              allowClear={false}
              value={checkDateInvalid(form.values?.timeCreated)}
              format="HH:mm"
              padding="2px"
              width="100px"
              onChange={(time) =>
                form.setFieldValue("timeCreated", moment(time).toISOString())
              }
              disabled={!editable}
            />
            <Text color="grey" className="font-italic">
              {t("crf_of_24_format")}
            </Text>
          </Space>
          <Text bold color="grey" className="my-3">
            {t("crf_visit_number")}:
          </Text>
          <Container>
            <Radio.Group
              disabled={!editable}
              onChange={form.handleChange("visit")}
              value={form.values?.visit}
            >
              <Row gutter={[8, 16]}>
                <Col sm={24} md={12}>
                  <Radio value={1}>Visit 1</Radio>
                  <Radio value={2}>Visit 2</Radio>
                  <Radio value={3}>Visit 3</Radio>
                </Col>
                <Col sm={24} md={12}>
                  <Radio value={4}>Visit 4</Radio>
                  <Radio value={5}>Visit 5</Radio>
                  <Radio value={6}>Visit 6</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Container>
          <Container className="mt-3">
            <Space>
              <Checkbox
                disabled={!editable}
                onChange={form.handleChange("notPerform")}
                checked={form.values?.notPerform}
              />
              <Text>{t("crf_nasal_not_performed")}</Text>
            </Space>
          </Container>
        </Container>
        <Divider />
        <Container className="my-4">
          <Row align="middle">
            <Col span={4}>
              <Text bold color="grey">
                {t("crf_aditional_notes")}:
              </Text>
            </Col>
            <Col span={20}>
              <TextArea
                disabled={!editable}
                maxLength={300}
                value={form.values?.comment}
                className="h-auto"
                onChange={form.handleChange("comment")}
              />
            </Col>
          </Row>
          <Row className="mt-4" align="middle">
            <Col span={4}>
              <Text bold color="grey">
                {t("crf_nasal_performed_by")}:
              </Text>
            </Col>
            <Col span={20}>
              <Input
                disabled={!editable}
                value={form.values?.performedBy}
                padding="2px"
                maxLength={100}
                onChange={form.handleChange("performedBy")}
              />
            </Col>
          </Row>
          <Space className="mt-4" size={25}>
            <Space>
              <Text bold color="grey">
                {t("crf_form_number")}
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.formNumber}
                padding="2px"
                width="60px"
                maxLength={2}
                onChange={(e) => {
                  if (isNumberValidate(e.target.value)) {
                    form.setFieldValue("formNumber", e.target.value);
                  }
                }}
              />
            </Space>
            <Text color="grey">{t("crf_version_date")}: 01/28/2022</Text>
          </Space>
        </Container>
      </Container>
    </Container>
  );
}
