import React from "react";
import { Row, Col, Image, Tag } from "antd";
import EverLogo from "../../../images/logo-evermed.png";
import { Container, ModalCustom, Text } from "./styles";
import moment from "moment";

const colorText = (value) => {
  return value ? "#1B4B63" : "#00000040";
};

const CovidPatientHistoryModal = (props) => {
  const { visible, setVisible, data, t, colorCode: COLOR_CODE } = props;
  const sequence = moment(data?.createTime).isValid()
    ? moment()
        .startOf("date")
        .diff(moment(data?.createTime).startOf("date"), "days") || 1
    : "";
  return (
    <ModalCustom
      title={t("history_patient")}
      width={900}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Row>
        <Col xs={24} sm={24} md={12} lg={8} className="border-right">
          <Row justify="center">
            <Container className="mt-5 mb-3">
              <Image
                style={{ objectFit: "contain" }}
                src={data.img ? data.img : EverLogo}
                width={200}
              />
            </Container>
          </Row>
          <Container className="p-4">
            <Text size={16} bold>
              {data.firstName && data.lastName
                ? `${data.firstName} ${data.lastName}`
                : ""}
            </Text>
            <Text className="mt-2" size={16} bold>
              {t("covid_label")}
            </Text>
            <Container>
              {data.typeId === 2 && (
                <Col className="mt-3" span={24}>
                  <Tag color="default">{t("waiting_medicine")}</Tag>
                </Col>
              )}
              <Col className="mt-2" span={24}>
                <Tag color={COLOR_CODE[data.result] ?? "default"}>
                  {data.result ? t(`covid_${data.result}`) : t("nothing")}
                </Tag>
              </Col>
            </Container>
            <Text className="mt-3" bold>
              {t("gender") + `: ${data.gender ? t(data.gender) : t("nothing")}`}
            </Text>
            <Row gutter={5}>
              <Col className="gutter-row">
                <Row className="mt-3">
                  <Text bold>{t("date_of_birth") + " : "}</Text>
                  <Text className="ml-1" color={colorText(data.birthDate)} bold>
                    {data.birthDate
                      ? moment(data.birthDate).format("LL")
                      : t("nothing")}
                  </Text>
                </Row>
              </Col>
              <Col className="gutter-row">
                <Text color={colorText(data.age)} className="mt-3" bold>
                  {t("age")} : {data.age ?? 0}{" "}
                  {(data.age ?? 0) > 1 ? t("years") : t("year")}
                </Text>
              </Col>
            </Row>
            <Text className="mt-3" bold>
              {t("c_id")} : {data.cId ?? t("nothing")}
            </Text>
            <Text className="mt-3" bold>
              {data.address ?? ""}
            </Text>
          </Container>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16}>
          <Container className="p-4">
            <Text size={20} bold>
              {t("vital")}
            </Text>
            <Container className="pt-2 pb-2 rounded bg-light">
              <Row className="pb-2 border-bottom">
                <ListVitalTile
                  className="pl-2 pr-2 pb-2 border-right"
                  span={6}
                  title={t("height")}
                  desp={data.height ? `${data.height} CM` : "-"}
                />
                <ListVitalTile
                  className="pl-2 pr-2 pb-2 border-right"
                  span={6}
                  title={t("weight")}
                  desp={data.weight ? `${data.weight} KG` : "-"}
                />
                <ListVitalTile
                  className="pl-2 pr-2 pb-2 border-right"
                  span={6}
                  title={t("bmi")}
                  desp={data.bmi ?? "-"}
                />
                <ListVitalTile
                  className="pl-2 pr-2 pb-2"
                  span={6}
                  title={t("covid")}
                  desp={data?.typeId === 2 ? `DETECTABLE` : "-"}
                />
              </Row>
              <Row className="pt-2">
                <ListVitalTile
                  className="pl-2 pr-2 pb-2 border-right"
                  span={6}
                  title={t("body_temp")}
                  desp={
                    data?.dailyReport?.temperature
                      ? `${data?.dailyReport?.temperature} °C`
                      : "-"
                  }
                />
                <ListVitalTile
                  className="pl-2 pr-2 pb-2 border-right"
                  span={6}
                  title={t("O2_sat")}
                  desp={
                    data?.dailyReport?.oxygen
                      ? `${data?.dailyReport?.oxygen} %`
                      : "-"
                  }
                />
                <ListVitalTile
                  className="pl-2 pr-2 pb-2 border-right"
                  span={6}
                  title={t("pulse_rate")}
                  desp={t("nothing")}
                />
                <ListVitalTile
                  className="pl-2 pr-2 pb-2"
                  span={6}
                  title={t("respiratory_rate")}
                  desp={t("nothing")}
                />
              </Row>
            </Container>
            <Container className="border mt-5 p-4 rounded">
              <Row align="middle">
                <Col xs={12} sm={12} md={10}>
                  <Text bold>{t("date_of_infection")}</Text>
                </Col>
                <Col xs={12} sm={12} md={14}>
                  <Text color={colorText(data?.testDate)}>
                    {data?.testDate
                      ? moment(data?.testDate).format("LL")
                      : t("nothing")}
                  </Text>
                </Col>
              </Row>
              <Row align="middle mt-2">
                <Col xs={12} sm={12} md={10}>
                  <Text bold>{t("first_date_symtoms")}</Text>
                </Col>
                <Col sm={12} md={14}>
                  <Text color={colorText(data?.firstDateSymtom)}>
                    {data?.firstDateSymtom
                      ? moment(data?.firstDateSymtom).isValid()
                        ? moment(data?.firstDateSymtom).format("LL")
                        : data?.firstDateSymtom
                      : t("nothing")}
                  </Text>
                </Col>
              </Row>
              <Row align="middle mt-2">
                <Col xs={12} sm={12} md={10}>
                  <Text bold>{t("symptom_color")}</Text>
                </Col>
                <Col xs={12} sm={12} md={14}>
                  <Text color={colorText(data?.result)}>
                    {data.result ? t(`covid_${data.result}`) : t("nothing")}
                  </Text>
                </Col>
              </Row>
            </Container>
            <Container className="border mt-5 p-4 rounded">
              <ListTile
                disabled
                align="top"
                title={t("household_infected")}
                desp={t("nothing")}
              />
              <ListTile
                disabled={!data.dailyReport}
                align="top mt-2"
                title={t("daily_report")}
                desp={
                  data.dailyReport
                    ? sequence + ` ${t("covid_tracing_date")}`
                    : t("nothing")
                }
              />
              <ListTile
                disabled={
                  !(
                    Array.isArray(data.congenitalDisease) &&
                    data.congenitalDisease.length > 0
                  )
                }
                align="top mt-2"
                title={t("congenital_disease")}
                desp={
                  Array.isArray(data.congenitalDisease) &&
                  data.congenitalDisease.length > 0
                    ? data.congenitalDisease.map((item) => {
                        return t(item.title) + ", ";
                      })
                    : t("nothing")
                }
              />
              <ListTile
                disabled
                align="top mt-2"
                title={t("conciousness")}
                desp={t("nothing")}
              />
              <ListTile
                disabled
                align="top mt-2"
                title={t("onset_start_infected")}
                desp={t("nothing")}
              />
              <ListTile
                disabled
                align="top mt-2"
                title={t("regular_medication")}
                desp={t("nothing")}
              />
              <ListTile
                disabled
                align="top mt-2"
                title={t("drug_allergy_food_allergy")}
                desp={t("nothing")}
              />
              <ListTile
                disabled
                align="top mt-2"
                title={t("other_medicine")}
                desp={t("nothing")}
              />
              <ListTile
                disabled
                align="top mt-2"
                title={t("side_effect_normal_medication")}
                desp={t("nothing")}
              />
              <ListTile
                disabled
                align="top mt-2"
                title={t("side_effect_medication")}
                desp={t("nothing")}
              />
            </Container>
            {data.typeId !== 1 && (
              <Container className="border mt-5 p-4 rounded">
                <ListTile
                  disabled
                  align="top"
                  title={t("note")}
                  desp={data?.note ?? t("nothing")}
                />
                <Row align="middle">
                  <Col xs={12} sm={12} md={10}>
                    <Text bold>{t("more_detail")}</Text>
                  </Col>
                  <Col className="mt-2" xs={12} sm={12} md={14}>
                    <Image
                      className="rounded"
                      width={200}
                      src={data.imagePCRUrl}
                    />
                  </Col>
                </Row>
              </Container>
            )}
          </Container>
        </Col>
      </Row>
    </ModalCustom>
  );
};

export default CovidPatientHistoryModal;

const ListTile = (props) => {
  return (
    <Row {...props}>
      <Col xs={12} sm={12} md={10}>
        <Text bold>{props.title}</Text>
      </Col>
      <Col xs={12} sm={12} md={14}>
        <Text color={props.disabled ? "#00000040" : "black"}>{props.desp}</Text>
      </Col>
    </Row>
  );
};

const ListVitalTile = (props) => {
  return (
    <Col {...props}>
      <Text size={10}>{props.title}</Text>
      <Text className="text-center" size={14} bold>
        {props.desp}
      </Text>
    </Col>
  );
};
