import { Modal, Table as TableAnt, Button as ButtonAnt } from "antd";
import styled from "styled-components";
import IconAnt from "@ant-design/icons";
import TextBase from "../../Text";

const ModalCustom = styled(Modal)`
  .ant-modal-header {
    width: 100% !important;
    border-radius: 10px 10px 0px 0px !important;
    border-bottom: unset;
  }
  .ant-modal-content {
    border-radius: 10px !important;
    min-width: 380px;
  }
  .ant-modal-body {
    padding: 24px 20px;
    overflow: scroll;
  }
`;

const Button = styled(ButtonAnt)`
  && {
    border-radius: 8px;
    padding: 5px 10px;
    height: auto;
    border-color: ${(props) => (props.secondary ? "#2ba796" : "#d3d3d3")};
    background: ${(props) => (props.secondary ? "#2ba796" : "white")};
  }
  &&:hover {
    background: ${(props) => (props.secondary ? "#2ba796d4" : "white")};
    border-color: #2ba796;
  }
  &&:focus {
    background: ${(props) => (props.secondary ? "#2ba796d4" : "white")};
    border-color: #2ba796;
  }
`;

const Table = styled(TableAnt)`
  .ant-table-thead > tr > th {
    background: white;
    border-bottom: unset;
  }
`;

const Icon = styled(IconAnt)`
  &&&& {
    color: ${(props) => props.color} !important;
  }
  font-size: ${(props) => props.size};
`;

const Text = styled(TextBase)`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const Container = styled.div`
  width: 100%;
`;

export { ModalCustom, Icon, Text, Container, Table, Button, ButtonAnt };
