import { Container, Text, DropDownWidget, Dragger, Icon } from "../styles";
import { Row, Col, Image } from "antd";
import { IoMdCloudUpload } from "react-icons/all";
import { PICK_UP_VALUE } from "../constant";

const SinuscopyAndImages = ({
  t,
  form = { values: {} },
  customRequest = () => {},
  getNameFromUrl = () => {},
  currentRecord = {},
  disabled,
}) => {
  const renderNormalValue = (key, items) => {
    return (
      <Container
        style={{ minWidth: 250, backgroundColor: "white" }}
        className="shadow px-2 py-1"
      >
        {items.map((item) => {
          return (
            <a
              key={item.key}
              onClick={(e) => {
                e.preventDefault();
                form.setFieldValue(key, +item.label);
              }}
            >
              <Text className="py-1 my-1" key={item.key}>
                {item.label}
              </Text>
            </a>
          );
        })}
      </Container>
    );
  };
  return (
    <>
      <Container className="overflow-hidden">
        <Row>
          <Col className="border p-2" xs={8} sm={12}>
            <Text bold className="text-ellipsis">
              {t("endoscopy_score")}
            </Text>
          </Col>
          <Col className="border p-2" xs={8} sm={6}>
            <Text bold className="text-center">
              {t("right")}
            </Text>
          </Col>
          <Col className="border p-2" xs={8} sm={6}>
            <Text bold className="text-center">
              {t("left")}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col className="border p-2 text-ellipsis" xs={8} sm={12}>
            {t("middle_meatus")}
          </Col>
          <Col className="border p-2" xs={8} sm={6}>
            {disabled ? (
              <Text className="ml-2">{form.values.sinuscopyPolypsRight}</Text>
            ) : (
              <DropDownWidget
                bold
                width="100%"
                value={form.values.sinuscopyPolypsRight}
                placeholder="0"
                renderList={renderNormalValue(
                  "sinuscopyPolypsRight",
                  PICK_UP_VALUE
                )}
              />
            )}
          </Col>
          <Col className="border p-2" xs={8} sm={6}>
            {disabled ? (
              <Text className="ml-2">{form.values.sinuscopyPolypsLeft}</Text>
            ) : (
              <DropDownWidget
                bold
                width="100%"
                value={form.values.sinuscopyPolypsLeft}
                placeholder="0"
                renderList={renderNormalValue(
                  "sinuscopyPolypsLeft",
                  PICK_UP_VALUE
                )}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="border p-2 text-ellipsis" xs={8} sm={12}>
            {t("oedema")}
          </Col>
          <Col className="border p-2" xs={8} sm={6}>
            {disabled ? (
              <Text className="ml-2">{form.values.sinuscopyOedemaRight}</Text>
            ) : (
              <DropDownWidget
                bold
                width="100%"
                value={form.values.sinuscopyOedemaRight}
                placeholder="0"
                renderList={renderNormalValue(
                  "sinuscopyOedemaRight",
                  PICK_UP_VALUE
                )}
              />
            )}
          </Col>
          <Col className="border p-2" xs={8} sm={6}>
            {disabled ? (
              <Text className="ml-2">{form.values.sinuscopyOedemaLeft}</Text>
            ) : (
              <DropDownWidget
                bold
                width="100%"
                value={form.values.sinuscopyOedemaLeft}
                placeholder="0"
                renderList={renderNormalValue(
                  "sinuscopyOedemaLeft",
                  PICK_UP_VALUE
                )}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="border p-2 text-ellipsis" xs={8} sm={12}>
            {t("discharge")}
          </Col>
          <Col className="border p-2" xs={8} sm={6}>
            {disabled ? (
              <Text className="ml-2">
                {form.values.sinuscopyDischargeRight}
              </Text>
            ) : (
              <DropDownWidget
                bold
                width="100%"
                value={form.values.sinuscopyDischargeRight}
                placeholder="0"
                renderList={renderNormalValue(
                  "sinuscopyDischargeRight",
                  PICK_UP_VALUE
                )}
              />
            )}
          </Col>
          <Col className="border p-2" xs={8} sm={6}>
            {disabled ? (
              <Text className="ml-2">{form.values.sinuscopyDischargeLeft}</Text>
            ) : (
              <DropDownWidget
                bold
                width="100%"
                value={form.values.sinuscopyDischargeLeft}
                placeholder="0"
                renderList={renderNormalValue(
                  "sinuscopyDischargeLeft",
                  PICK_UP_VALUE
                )}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="border p-2 text-ellipsis" xs={8} sm={12}>
            {t("total_left_right")}
          </Col>
          <Col
            className="border-bottom border-left border-top p-2"
            xs={8}
            sm={6}
          >
            <Text bold className="ml-3">
              {form.values.sinuscopyPolypsRight +
                form.values.sinuscopyOedemaRight +
                form.values.sinuscopyDischargeRight +
                form.values.sinuscopyPolypsLeft +
                form.values.sinuscopyOedemaLeft +
                form.values.sinuscopyDischargeLeft}
            </Text>
          </Col>
          <Col
            className="border-bottom border-right border-top"
            xs={8}
            sm={6}
          ></Col>
        </Row>
      </Container>
      <Container className="overflow-hidden border mt-2 p-2">
        <Row gutter={4} align="middle">
          <Col xs={6} sm={4}>
            {t("polyps")}
          </Col>
          <Col xs={6} sm={4} className="text-center">
            {t("0_absent")}
          </Col>
          <Col xs={6} sm={8} className="text-ellipsis pl-2">
            {t("1_middle_meatus")}
          </Col>
          <Col xs={6} sm={8} className="text-ellipsis pl-2">
            {t("2_extending_meatus")}
          </Col>
        </Row>
        <Row gutter={4} align="middle">
          <Col xs={6} sm={4}>
            {t("oedema_normal")}
          </Col>
          <Col xs={6} sm={4} className="text-center">
            {t("0_absent")}
          </Col>
          <Col xs={6} sm={8} className="text-ellipsis pl-2">
            {t("1_mild")}
          </Col>
          <Col xs={6} sm={8} className="text-ellipsis pl-2">
            {t("2_severse")}
          </Col>
        </Row>
        <Row gutter={4} align="middle">
          <Col xs={6} sm={4}>
            {t("discharge_normal")}
          </Col>
          <Col xs={6} sm={4} className="text-center">
            {t("0_absent")}
          </Col>
          <Col xs={6} sm={8} className="text-ellipsis pl-2">
            {t("1_clear_thin")}
          </Col>
          <Col xs={6} sm={8} className="text-ellipsis pl-2">
            {t("2_thick_purulent")}
          </Col>
        </Row>
      </Container>
      <Container className="mt-3 border">
        <Row className="border-bottom">
          <Text className="ml-3" size="12px">
            {t("sinusscope_images")}
          </Text>
        </Row>
        <Row align="middle">
          <Col span={12} className="text-center">
            <Text size="16px">{t("right")}</Text>
          </Col>
          <Col span={12} className="text-center">
            <Text size="16px">{t("left")}</Text>
          </Col>
        </Row>
        <Row gutter={16} className="p-2" align="middle">
          <Col className="text-center" span={12}>
            {form.values.sinuscopyDischargeImageUrlRight ? (
              <Image
                height={200}
                src={form.values.sinuscopyDischargeImageUrlRight}
              />
            ) : (
              <div style={{ height: 200 }}></div>
            )}
          </Col>
          <Col className="text-center" span={12}>
            {form.values.sinuscopyDischargeImageUrlLeft ? (
              <Image
                height={200}
                src={form.values.sinuscopyDischargeImageUrlLeft}
              />
            ) : (
              <div style={{ height: 200 }}></div>
            )}
          </Col>
        </Row>
      </Container>
      {!disabled && (
        <Container className="mb-5 pb-1">
          <Row gutter={16}>
            <Col span={12}>
              <Dragger
                defaultFileList={
                  form.values.sinuscopyDischargeImageUrlRight
                    ? [
                        {
                          uid: "1",
                          name: getNameFromUrl(
                            form.values.sinuscopyDischargeImageUrlRight
                          ),
                          status: "done",
                          response: {
                            url: form.values.sinuscopyDischargeImageUrlRight,
                          },
                          url: form.values.sinuscopyDischargeImageUrlRight,
                        },
                      ]
                    : []
                }
                onChange={async (info) => {
                  const { status } = info.file;
                  if (status === "done") {
                    form.setFieldValue(
                      "sinuscopyDischargeImageUrlRight",
                      info.file.response?.url
                    );
                  }
                }}
                onRemove={(_) =>
                  form.setFieldValue("sinuscopyDischargeImageUrlRight", "")
                }
                customRequest={(option) =>
                  customRequest({
                    ...option,
                    key: currentRecord.researchId,
                    pathFb: "sinuscopyDischargeRight",
                  })
                }
                maxCount={1}
                className="mt-3 py-4"
              >
                <Row justify="center" align="middle">
                  <Icon
                    component={IoMdCloudUpload}
                    size="24px"
                    color="#2BA796"
                  />
                  <Text className="mx-2" primary>
                    {t("drop_file_to_upload_or_click")}
                  </Text>
                </Row>
              </Dragger>
            </Col>
            <Col span={12}>
              <Dragger
                defaultFileList={
                  form.values.sinuscopyDischargeImageUrlLeft
                    ? [
                        {
                          uid: "1",
                          name: getNameFromUrl(
                            form.values.sinuscopyDischargeImageUrlLeft
                          ),
                          status: "done",
                          response: {
                            url: form.values.sinuscopyDischargeImageUrlLeft,
                          },
                          url: form.values.sinuscopyDischargeImageUrlLeft,
                        },
                      ]
                    : []
                }
                onChange={async (info) => {
                  const { status } = info.file;
                  if (status === "done") {
                    form.setFieldValue(
                      "sinuscopyDischargeImageUrlLeft",
                      info.file.response?.url
                    );
                  }
                }}
                onRemove={(_) =>
                  form.setFieldValue("sinuscopyDischargeImageUrlLeft", "")
                }
                customRequest={(option) =>
                  customRequest({
                    ...option,
                    key: currentRecord.researchId,
                    pathFb: "sinuscopyDischargeLeft",
                  })
                }
                maxCount={1}
                className="mt-3 py-4"
              >
                <Row justify="center" align="middle">
                  <Icon
                    component={IoMdCloudUpload}
                    size="24px"
                    color="#2BA796"
                  />
                  <Text className="mx-2" primary>
                    {t("drop_file_to_upload_or_click")}
                  </Text>
                </Row>
              </Dragger>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default SinuscopyAndImages;
