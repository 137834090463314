import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

import DashboardMap from "../../components/DashboardMap";
import { Firebase, AppUserOma, Organization } from "../../redux/actions";
import Loading from "../../components/Loading";
import { getBooking } from "../../api/booking";

const DashboardMapContainer = (props) => {
  const { history } = props;
  const dispatch = useDispatch();

  const firebase = useSelector((state) => state.firebase);
  const appUserOma = useSelector((state) => state.appUserOma);
  const communityPharmacy = useSelector(
    (state) => state.organization.communityPharmacy
  );

  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);
  const [isFinishInitialBooking, setIsFinishInitialBooking] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [location, setLocation] = useState({});
  const [isLocation, setIsLocation] = useState(false);
  const [bookingId, setBookingId] = useState(false);

  useEffect(() => {
    setInitialUser();
    dispatch(Firebase.getRooms("COMMUNITY_PHARMACIST"));
  }, []);

  useEffect(() => {
    if (
      appUserOma.organization.isSuccess &&
      !communityPharmacy.isSuccess &&
      communityPharmacy.data === null
    ) {
      getCommunityPharmacistByOrgId(4);
    }
  }, [appUserOma]);

  useEffect(() => {
    if (firebase.isSuccess && communityPharmacy.isSuccess) {
      setInitialBooking();
    }
  }, [firebase, communityPharmacy]);

  useEffect(() => {
    if (
      firebase.isSuccess &&
      communityPharmacy.isSuccess &&
      isFinishInitialBooking &&
      !communityPharmacy.loading &&
      communityPharmacy.data.length > 0
    ) {
      setIsFinishInitialLoad(true);
    }
  }, [firebase, communityPharmacy, isFinishInitialBooking]);

  const getCommunityPharmacistByOrgId = (orgId) => {
    dispatch(Organization.getCommunityPharmaciesByOrganization(orgId));
  };

  const setInitialBooking = async () => {
    let array = [];
    const rooms = firebase.room.roundRobin.communityPharmacist.rooms;
    if (rooms) {
      array = await fetchBookingWithRooms(rooms);
    }
    setBookings([...array]);
    setIsFinishInitialBooking(true);
  };

  const setInitialUser = () => {
    const userId = jwtDecode(localStorage.getItem("token")).id;
    dispatch(AppUserOma.getUser(userId));
    dispatch(AppUserOma.getOrganizationsUser(userId));
    dispatch(AppUserOma.getProfile(userId));
  };

  const fetchBookingWithRooms = async (rooms) => {
    let array = [];
    await Promise.all(
      Object.keys(rooms).map(async (key) => {
        if (rooms[key].status === "COMMUNITY_PHARMACIST_PENDING") {
          const data = await getBooking(rooms[key].bookingId);
          array.push({
            current: rooms[key],
            date: rooms[key].date,
            firebaseStatus: rooms[key].status,
            latitude: rooms[key].latitude ?? 0,
            longitude: rooms[key].longitude ?? 0,
            ...data,
          });
        }
      })
    );
    return array;
  };

  const handleClickLocation = (locationProps) => {
    setIsLocation(true);
    setLocation(locationProps);
  };

  return isFinishInitialLoad ? (
    <DashboardMap
      bookings={bookings}
      history={history}
      communityPharmacy={communityPharmacy.data[0]}
      handleClickLocation={handleClickLocation}
      handleBackLocation={() => setIsLocation(false)}
      location={location}
      isLocation={isLocation}
      setBookingId={setBookingId}
      bookingId={bookingId}
    />
  ) : (
    <Loading />
  );
};

export default DashboardMapContainer;
