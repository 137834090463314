import styled from "styled-components";
import { Col } from "antd";
export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const HeartRateText = styled(Col)`
  text-align: center;
  border: 1px solid #eee;
  padding: 10px;
  backgroundcolor: #fffff;
  color: ${(props) => props.color};
`;
