import React from "react";
import { Row, Col } from "antd";
import ModalNote from "../Modal/NoteModal/Note";
import {
  Container,
  ParagraphStyle,
  ButtonPrescription,
  CustomIcon,
  ImageStyle,
  CompassIcon,
} from "./styles";
import moment from "moment";
import { UserAddOutlined } from "@ant-design/icons";
const PrescriptionOrderCard = (props) => {
  const {
    booking,
    handleModal,
    visibleModalNote,
    setVisibleModalNote,
    handleClickLocation,
    t,
  } = props;
  const { locationAddress, id, patient, date, notes } = booking;

  const { firstname, lastname, img } = patient.userInformation;
  const fullName = `${firstname} ${lastname}`;
  return (
    <Container>
      <Row justify="space-between">
        <Col>
          <ParagraphStyle>{t("prescription")}</ParagraphStyle>
        </Col>
        <Col>
          <Row gutter={12}>
            {handleClickLocation && (
              <Col>
                <CompassIcon
                  onClick={() =>
                    handleClickLocation({
                      lat: booking.latitude,
                      lng: booking.longitude,
                    })
                  }
                />
              </Col>
            )}
            <Col>
              <ParagraphStyle color="#e0e0e0" size="12">
                {moment(date).format("HH:mm")}
              </ParagraphStyle>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" style={{ marginTop: 10 }}>
        <Col className="ml-2">
          <UserAddOutlined />
        </Col>
        <Col>
          <ButtonPrescription onClick={() => handleModal(id, booking)}>
            <ParagraphStyle size="12" color="#61D0F5" bold="true">
              {t("see_prescription")}
            </ParagraphStyle>
            <CustomIcon color="#61D0F5" />
          </ButtonPrescription>
        </Col>
      </Row>
      <Row justify="space-between" style={{ marginTop: 10 }}>
        <Col>
          <Row gutter={12}>
            <Col>
              <ParagraphStyle size="12">{fullName}</ParagraphStyle>
            </Col>
          </Row>
        </Col>
        <Col>
          <ParagraphStyle size="12" color="#e0e0e0">
            {t("waiting_for")}
          </ParagraphStyle>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col style={{ marginTop: 10 }}>
          <ParagraphStyle size="10">
            {locationAddress && locationAddress.address}
          </ParagraphStyle>
        </Col>
        <Col>
          <ParagraphStyle size="24" bold="true">
            {moment(date).format("HH:mm")}
          </ParagraphStyle>
        </Col>
      </Row>

      <ModalNote
        visible={visibleModalNote}
        setVisible={setVisibleModalNote}
        note={notes}
      />
    </Container>
  );
};

export default PrescriptionOrderCard;
