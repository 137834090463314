import { Spin } from "antd";
import React from "react";
import { Container } from "./styles";

const Loading = ({ height }) => (
  <Container height={height}>
    <Spin size="large" />
  </Container>
);

export default Loading;
