import React, { useCallback } from "react";
import { HEADER_DEFAULT_FORMS, Y_OR_N } from "../constant";
import EverLogo from "../../../images/EverLogo.png";
import { isNumberValidate, checkDateInvalid } from "../utils";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  HeaderContainer,
  DropDownWidget,
  TextVetical,
} from "../styles";
import { Row, Col, Space, Checkbox } from "antd";
import moment from "moment";

export default function AdverseLogForm(props) {
  const { t, form = {}, editable } = props;

  const onChangeValueHandler = (key, index, e) => {
    const dataArray = form.values.dataList;
    if (dataArray[index]) {
      dataArray[index][key] = e?.target?.value ?? e;
    }
    form.setFieldValue("dataList", dataArray);
  };

  const renderDropDownValue = (key, index, items) => {
    return (
      <Container
        style={{ backgroundColor: "white" }}
        className="shadow px-2 py-1"
      >
        {items.map((item) => {
          return (
            <a
              key={item.key}
              onClick={() => onChangeValueHandler(key, index, item.key)}
            >
              <Text className="py-1 my-1" key={item.key}>
                {item.label}
              </Text>
            </a>
          );
        })}
      </Container>
    );
  };

  return (
    <Container>
      <Text className="text-center" size="16px" color="#0000008C">
        {t(HEADER_DEFAULT_FORMS)}
      </Text>
      <Container
        className="px-5 mt-3 pb-3 rounded border"
        style={{ overflowX: "scroll" }}
      >
        <Image className="mt-4" src={EverLogo} />
        <Row gutter={[16, 16]} className="my-4" align="middle">
          <Col xs={12} md={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("subjects_initials")}:
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.subjectsInitials}
                padding="2px"
                onChange={form.handleChange("subjectsInitials")}
                maxLength={3}
              />
            </Space>
          </Col>
          <Col xs={12} md={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_subject_id")}:
              </Text>
              <Input
                disabled
                value={form.values?.subjectId}
                padding="2px"
                onChange={form.handleChange("subjectId")}
              />
            </Space>
          </Col>
          <Col xs={12} md={8}>
            <Row align="middle">
              <Text bold color="grey">
                {t("crf_page")}
              </Text>
              <Input
                disabled={!editable}
                className="ml-2"
                value={form.values?.page}
                padding="2px"
                width="60px"
                onChange={(e) => {
                  if (isNumberValidate(e.target.value)) {
                    form.setFieldValue("page", e.target.value);
                  }
                }}
                maxLength={1}
              />
              <Text bold className="ml-2" color="grey">
                {t("crf_of")}
              </Text>
              <Input
                disabled={!editable}
                className="ml-2"
                value={form.values?.of}
                padding="2px"
                width="60px"
                onChange={(e) => {
                  if (isNumberValidate(e.target.value)) {
                    form.setFieldValue("of", e.target.value);
                  }
                }}
                maxLength={1}
              />
            </Row>
          </Col>
          <Col span={8}></Col>
        </Row>
        <HeaderContainer className="px-4">
          <Row className="my-2" align="middle" justify="space-between">
            <Col>
              <Text bold size="16px">
                {t("adverse_log")}
              </Text>
            </Col>
            <Col>
              <Row>
                <Text>{t("crf_adverse_check_box")}</Text>
                <Checkbox
                  disabled={!editable}
                  checked={form.values?.allAdverseEvent}
                  className="ml-2"
                  onChange={form.handleChange("allAdverseEvent")}
                />
              </Row>
            </Col>
          </Row>
        </HeaderContainer>
        <Text className="my-2" color="grey">
          {t("crf_adverse_check_sub_title")}
        </Text>
        <Container style={{ overflowX: "scroll" }}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: 40 }} className="text-center align-middle">
                  #
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">
                    {t("crf_date_reported")}
                  </TextVetical>
                </th>
                <th className="align-middle text-center">
                  <Text size="12px">{t("crf_adverse_desp")}</Text>
                </th>
                <th className="align-middle text-center">
                  <Text size="12px">{t("crf_adverse_event")}</Text>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">{t("crf_start_date")}</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">{t("crf_end_date")}</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">
                    {t("crf_adverse_ongoing")}
                  </TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">
                    {t("crf_adverse_outcome")} <sup>1</sup>
                  </TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">
                    {t("crf_severity_grade")} <sup>2</sup>
                  </TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">{t("crf_serious")}</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">
                    {t("crf_ae_treatment")} <sup>3</sup>
                  </TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">{t("crf_expected")}</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">
                    {t("crf_study_attribution")} <sup>5</sup>
                  </TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">
                    {t("crf_action_taken")} <sup>4</sup>
                  </TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">
                    {t("crf_device_attribution")} <sup>5</sup>
                  </TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">{t("pi_initials")}</TextVetical>
                </th>
                <th className="align-middle text-center">
                  <TextVetical size="12px">{t("date_pi_initials")}</TextVetical>
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(form?.values?.dataList) &&
                form.values.dataList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="35px"
                          value={item.aeNumner}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler("aeNumner", index, e);
                            }
                          }}
                          padding="2px"
                          maxLength={2}
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.dateReported)}
                          format="DD/MM/YY"
                          defaultValue={checkDateInvalid(item.dateReported)}
                          padding="2px"
                          width="100px"
                          onChange={onChangeValueHandler.bind(
                            this,
                            "dateReported",
                            index
                          )}
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="50px"
                          value={item.description}
                          onChange={onChangeValueHandler.bind(
                            this,
                            "description",
                            index
                          )}
                          padding="2px"
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="50px"
                          value={item.adverseEvent}
                          onChange={onChangeValueHandler.bind(
                            this,
                            "adverseEvent",
                            index
                          )}
                          padding="2px"
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.startDate)}
                          format="DD/MM/YY"
                          defaultValue={checkDateInvalid(item.startDate)}
                          padding="2px"
                          width="100px"
                          onChange={onChangeValueHandler.bind(
                            this,
                            "startDate",
                            index
                          )}
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.endDate)}
                          format="DD/MM/YY"
                          defaultValue={checkDateInvalid(item.endDate)}
                          padding="2px"
                          width="100px"
                          onChange={onChangeValueHandler.bind(
                            this,
                            "endDate",
                            index
                          )}
                        />
                      </td>
                      <td>
                        <DropDownWidget
                          disabled={!editable}
                          width="40px"
                          value={item.ongoing}
                          renderList={renderDropDownValue(
                            "ongoing",
                            index,
                            Y_OR_N
                          )}
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="25px"
                          value={item.outcome}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler("outcome", index, e);
                            }
                          }}
                          maxLength={1}
                          padding="2px"
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="25px"
                          value={item.severityGrade}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler("severityGrade", index, e);
                            }
                          }}
                          maxLength={1}
                          padding="2px"
                        />
                      </td>
                      <td>
                        <DropDownWidget
                          disabled={!editable}
                          width="40px"
                          value={item.serious}
                          renderList={renderDropDownValue(
                            "serious",
                            index,
                            Y_OR_N
                          )}
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="25px"
                          value={item.aeTreatment}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler("aeTreatment", index, e);
                            }
                          }}
                          maxLength={1}
                          padding="2px"
                        />
                      </td>
                      <td>
                        <DropDownWidget
                          disabled={!editable}
                          width="40px"
                          value={item.expected}
                          renderList={renderDropDownValue(
                            "expected",
                            index,
                            Y_OR_N
                          )}
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="25px"
                          value={item.studyAttribution}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler(
                                "studyAttribution",
                                index,
                                e
                              );
                            }
                          }}
                          maxLength={1}
                          padding="2px"
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="25px"
                          value={item.actionTaken}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler("actionTaken", index, e);
                            }
                          }}
                          maxLength={1}
                          padding="2px"
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="25px"
                          value={item.deviceAttribution}
                          onChange={(e) => {
                            if (isNumberValidate(e.target.value)) {
                              onChangeValueHandler(
                                "deviceAttribution",
                                index,
                                e
                              );
                            }
                          }}
                          maxLength={1}
                          padding="2px"
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          minwidth="25px"
                          value={item.piInitials}
                          onChange={onChangeValueHandler.bind(
                            this,
                            "piInitials",
                            index
                          )}
                          padding="2px"
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.dateofInitials)}
                          format="DD/MM/YY"
                          defaultValue={checkDateInvalid(item.dateofInitials)}
                          padding="2px"
                          width="100px"
                          onChange={onChangeValueHandler.bind(
                            this,
                            "dateofInitials",
                            index
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Text color="grey">{t("crf_adverse_footer")}</Text>
          <Container style={{ overflowX: "scroll" }}>
            <table
              className="table table-bordered mt-4"
              style={{ tableLayout: "fixed" }}
            >
              <thead>
                <tr>
                  <th className="align-middle text-center">
                    {t("crf_adverse_outcome")} <sup>1</sup>
                  </th>
                  <th className="align-middle text-center">
                    {t("crf_severity_grade")} <sup>2</sup>
                  </th>
                  <th className="align-middle text-center">
                    {t("crf_ae_treatment")} <sup>3</sup>
                  </th>
                  <th className="align-middle text-center">
                    {t("crf_action_taken_study_intervention")} <sup>4</sup>
                  </th>
                  <th className="align-middle text-center">
                    {t("crf_attribution_relatedness")} <sup>5</sup>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Text>{t("crf_adverse_fatal")}</Text>
                    <Text>{t("crf_adverse_not_recovered")}</Text>
                    <Text>{t("crf_adverse_recovered_sequelae")}</Text>
                    <Text>{t("crf_adverse_recovered_o_sequelae")}</Text>
                    <Text>{t("crf_adverse_recovering_resolving")}</Text>
                  </td>
                  <td>
                    <Text>{t("crf_adverse_mild")}</Text>
                    <Text>{t("crf_adverse_moderate")}</Text>
                    <Text>{t("crf_adverse_severe")}</Text>
                    <Text>{t("crf_adverse_threatening")}</Text>
                    <Text>{t("crf_adverse_death")}</Text>
                  </td>
                  <td>
                    <Text>{t("crf_adverse_none")}</Text>
                    <Text>{t("crf_adverse_medications")}</Text>
                    <Text>{t("crf_adverse_non_medications")}</Text>
                  </td>
                  <td>
                    <Text>{t("crf_adverse_none")}</Text>
                    <Text>{t("crf_adverse_interrupted")}</Text>
                    <Text>{t("crf_adverse_discontinued")}</Text>
                    <Text>{t("crf_dose_reduced")}</Text>
                    <Text>{t("crf_dose_increased")}</Text>
                    <Text>{t("crf_not_applicable")}</Text>
                  </td>
                  <td>
                    <Text>{t("crf_definite")}</Text>
                    <Text>{t("crf_probable")}</Text>
                    <Text>{t("crf_possible")}</Text>
                    <Text>{t("crf_unlikely")}</Text>
                    <Text>{t("crf_unrelated")}</Text>
                    <Text>{t("crf_adverse_not_applicable")}</Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </Container>
          <Text color="grey">
            {t("crf_look_up_corresponding_ae")}:
            <a className="ml-1" href={t("crf_look_up_corresponding_ae_url")}>
              {t("crf_look_up_corresponding_ae_url")}
            </a>
          </Text>
        </Container>
        <Space className="mt-4" size={25}>
          <Space>
            <Text bold color="grey">
              {t("crf_form_number")}
            </Text>
            <Input
              disabled={!editable}
              value={form.values?.formNumber}
              padding="2px"
              width="60px"
              maxLength={2}
              onChange={(e) => {
                if (isNumberValidate(e.target.value)) {
                  form.setFieldValue("formNumber", e.target.value);
                }
              }}
            />
          </Space>
          <Text>{t("crf_version_date")}: 01/31/2022</Text>
        </Space>
      </Container>
    </Container>
  );
}
