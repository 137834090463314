import { call, put, takeEvery } from "redux-saga/effects";
import { DrugIngredientGroup } from "../../api";
import * as type from "../types";

function* getDrugIngredient({ id }) {
  try {
    const result = yield call(DrugIngredientGroup.getDrugIngredient, id);
    yield put({ type: type.GET_DRUG_INGREDIENT_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_DRUG_INGREDIENT_FAILED, error: e.message });
  }
}

function* drugIngredientSaga() {
  yield takeEvery(type.GET_DRUG_INGREDIENT_REQUESTED, getDrugIngredient);
}

export default drugIngredientSaga;
