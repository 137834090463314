import React, { Component } from "react";
import { Button as ButtonAnt } from "antd";
import styled, { css } from "styled-components";

const StyledButton = styled(ButtonAnt)`
  ${(props) => {
    if (props.primary) {
      if (props.transparent) {
        return css`
          border-color: ${props.theme.primaryButton};
          color: ${props.theme.primaryButton};
          background-color: transparent;
        `;
      }
      return css`
        background-color: ${props.theme.primaryButton};
        color: ${props.color};
      `;
    }
    return css`
      color: ${props.color};
    `;
  }}
`;
export class MyButton extends Component {
  render() {
    return <StyledButton {...this.props} />;
  }
}

export default MyButton;
