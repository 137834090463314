import * as type from "../types";

export function getMenus(menus) {
  return {
    type: type.GET_MENUS_REQUESTED,
    data: menus,
  };
}

export function setMenu(menu) {
  return {
    type: type.SET_CURRENT_MENU_REQUESTED,
    data: menu,
  };
}
