import { Button, Input, Select } from "antd";
import styled from "styled-components";

export const SelectStyled = styled(Select)`
  z-index: 1;
`;

export const Item = styled(Input)`
  width: 340px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 24px;
`;

export const ItemPassword = styled(Input.Password)`
  width: 340px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 16px 0px;
`;
export const Container = styled.div`
  width: 920px;
  height: 592px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 16px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  h5 {
    color: ${(props) => props.theme.primaryText};
    font-weight: bold;
  }
  p {
    color: ${(props) => props.theme.primaryText};
  }
  .forgot-password {
    font-size: 0.8125rem;
    color: ${(props) => props.theme.primaryButton};
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const WrapperLanguage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
`;

export const Form = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderContent = styled.div`
  width: 100%;
  text-align: left;
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;

    letter-spacing: 0.15px;

    color: #3f98d2;
  }
`;

export const ContainerImage = styled.div`
  height: 100%;
  width: 50%;
  height: 100%;
  padding: 0px;
  position: static;
  background: linear-gradient(
    153.84deg,
    #a5d0ff -8.46%,
    #c9e3ff 6.91%,
    #f7fbff 109.58%
  );
  border-radius: 16px 0px 0px 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;

  position: absolute;
  width: 100%;
  height: 64.05px;
`;

export const WrapLogo = styled.div`
  position: static;
  height: 29px;
  top: 35.05px;
`;

export const Logo = styled.img`
  position: static;
`;

export const ContentText = styled.div`
  position: static;
  margin: 2px 0px;
  margin-top: 10px;
  margin-left: 8px;
`;
export const Text = styled.h2`
  font-size: 24px;
  line-height: 120%;
  color: #fff;
`;
export const Bold = styled.span`
  font-size: 24px;
  line-height: 120%;
  color: #000;
  font-weight: bold;
`;

export const WrapBackground = styled.div`
  height: 100%;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BackgroundImage = styled.img`
  width: 100%;
`;

export const Error = styled.div`
  width: 100%;
`;

export const WrapText = styled.div`
  width: 100%;
  margin-top: 40px;
  text-align: left;
  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.15px;
    color: #3f98d2;
  }
`;

export const WrapButton = styled.div`
  margin: 16px 0px;
`;

export const LoginButton = styled(Button)`
  padding: 8px 22px;
  border-radius: 8px;
  height: 42px;
  width: 340px;
  background: #9fcce8;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
`;

export const Footer = styled.div``;

export const MarginForm = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position:relative
  margin: 0px 60px;
`;

export const WrapContact = styled.div`
  width: 100%;
  position: absolute;
  text-align: center;
  margin: 25px 0px;
  p {
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.004em;
    text-transform: uppercase;
  }
  h2 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    letter-spacing: 0.15 px;
    text-transform: uppercase;
    color: #3f98d2;
  }
`;
