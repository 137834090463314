export const COLOR_CODE = ["red", "green", "yellow"];

export const COLOR_CODE_NEWCASE = {
  high: "red",
  medium: "orange",
  low: "default",
  red: "red",
  yellow: "yellow",
  green: "green",
};

export const COLOR_INDEX = {
  red: 3,
  yellow: 2,
  green: 1,
};

export const dataGraphCovid = (t, totalNewCases, totalRegsitered) => ({
  labels: [
    t("new_case"),
    t("waiting_for_test_again"),
    t("confirmed_infection"),
  ],
  datasets: [
    {
      label: "",
      data: [totalNewCases, 0, totalRegsitered],
      backgroundColor: ["#80DDF2", "#00BAE5", "#008BAC"],
      borderColor: ["#80DDF2", "#00BAE5", "#008BAC"],
      borderWidth: 1,
    },
  ],
});
