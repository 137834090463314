import * as type from "../types";

const initialState = {
  loading: false,
  error: null,
  data: null,
  history: {
    isSuccess: false,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_BOOKING_BY_TREATMENT_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.CREATE_BOOKING_BY_TREATMENT_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case type.CREATE_BOOKING_BY_TREATMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case type.UPDATE_TREATMENT_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.UPDATE_TREATMENT_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case type.UPDATE_TREATMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case type.GET_BOOKINGS_BY_TREATMENT_REQUESTED:
      return {
        ...state,
        loading: true,
        history: {
          isSuccess: false,
        },
        error: null,
      };
    case type.GET_BOOKINGS_BY_TREATMENT_SUCCESS:
      return {
        ...state,
        history: {
          isSuccess: true,
          data: action.data,
        },
        loading: false,
      };
    case type.GET_BOOKINGS_BY_TREATMENT_FAILED:
      return {
        ...state,
        history: {
          isSuccess: false,
        },
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
