import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ClinicTrial as ClinicTrialAction } from "../../redux/actions";
import { createResearchObjFromRecruitmentObj } from "../../api/recruitmentObjects";
import { getResearchTemplateId } from "../../api/researchTemplates";
import { getFilterResearchObjectsWithSomeConditions } from "../../api/researchSubjects";

import Layout from "../Home";
import ClinicTrialMainComponent from "../../components/ClinicTrialMainComponent";
import { message } from "antd";
import { get, map, intersection } from "lodash";
import {
  REGISTER_NEW_PARTICIPANTS,
  STUDY_AND_WITHDRAWN,
  EDIT_RECRUIT_OBJECTS,
  CAN_SUBMIT_PP,
  CAN_MANUAL_SUBMIT_PP,
  SORT_VALUE,
} from "./constant";

const ClinicTrial = (props) => {
  const { history } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const appUserOma = useSelector((state) => state.appUserOma);
  const clinicTrial = useSelector((state) => state.clinicTrial);
  const organizationId = get(appUserOma.organization, "data[0].id", null);
  const researchProgramId = get(
    clinicTrial.researchPrograms,
    "data[0].id",
    null
  );
  const [tabIndex, setTabIndex] = useState(0);
  const [currentPageRegister, setCurrentPageRegister] = useState(0);
  const [userPerm, setUserPerm] = useState({
    REGISTER_NEW_PARTICIPANTS: false,
    STUDY_AND_WITHDRAWN: false,
    EDIT_RECRUIT_OBJECTS: false,
    CAN_SUBMIT_PP: false,
    CAN_MANUAL_SUBMIT_PP: false,
  });

  // * NOTE check roles and permissions
  useEffect(() => {
    const userRoles = map(get(appUserOma.information, "roles", []), "name");
    setUserPerm({
      EDIT_RECRUIT_OBJECTS:
        intersection(EDIT_RECRUIT_OBJECTS, userRoles).length > 0,
      REGISTER_NEW_PARTICIPANTS:
        intersection(REGISTER_NEW_PARTICIPANTS, userRoles).length > 0,
      STUDY_AND_WITHDRAWN:
        intersection(STUDY_AND_WITHDRAWN, userRoles).length > 0,
      CAN_SUBMIT_PP: intersection(CAN_SUBMIT_PP, userRoles).length > 0,
      CAN_MANUAL_SUBMIT_PP:
        intersection(CAN_MANUAL_SUBMIT_PP, userRoles).length > 0,
    });
  }, [appUserOma.information]);

  useEffect(() => {
    if (organizationId) {
      if (clinicTrial.researchPrograms.data.length === 0) {
        dispatch(
          ClinicTrialAction.getResearchProgramsByOrganization(organizationId)
        );
      }
    }
  }, [organizationId]);

  const onSelectStudyCaseHandler = async (type) => {
    const listSubjectId = await getFilterResearchObjectsWithSomeConditions(
      type
    );
    if (Array.isArray(listSubjectId)) {
      const finalList = listSubjectId.filter((item) => !item.activated);
      return finalList.length > 0 ? finalList[0] : null;
    } else {
      return null;
    }
  };

  const getResearchObjectsByorgAndProgram = () => {
    if (researchProgramId && organizationId) {
      dispatch(
        ClinicTrialAction.getResearchObjectsByOrgAndProgram(
          organizationId,
          researchProgramId
        )
      );
    }
  };

  const getRecruitmentObjectsByOrgAndProgram = () => {
    if (researchProgramId && organizationId) {
      dispatch(
        ClinicTrialAction.getRecruitmentObjectsByOrgAndProgram(
          organizationId,
          researchProgramId
        )
      );
    }
  };

  const onRegisterClinicTrialHandler = async (record) => {
    try {
      const researchRes = await getResearchTemplateId();
      if (researchRes) {
        const response = await createResearchObjFromRecruitmentObj(record.id, {
          researchTemplateId: researchRes[0].id,
        });
        return response;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  const onUpdateRecuitmentObjectHandler = (id, payload) => {
    dispatch(ClinicTrialAction.updateRecruitmentObject(id, payload));
  };

  const onConsentFormSubmit = (id, payload) => {
    dispatch(ClinicTrialAction.createConsentData(id, payload));
  };

  const onGeneralFormSubmit = (id, payload) => {
    dispatch(ClinicTrialAction.createGeneralInfoForm(id, payload));
  };

  const onContactFormSubmit = (id, payload) => {
    dispatch(ClinicTrialAction.createContactInfoForm(id, payload));
  };

  const onEigibilityAssessmentSubmit = (id, fk, payload) => {
    if (fk) {
      dispatch(ClinicTrialAction.updateEligibilityForm(id, fk, payload));
    } else {
      dispatch(ClinicTrialAction.createEligibilityForm(id, payload));
    }
  };

  const onApproveResearchObjectById = (id, payload) => {
    dispatch(ClinicTrialAction.approveResearchObjectById(id, payload));
  };

  const onManuallyApproveResearchObjectById = (
    id,
    payload,
    criteriaPayload
  ) => {
    dispatch(
      ClinicTrialAction.approveResearchObjectManually(
        id,
        payload,
        criteriaPayload
      )
    );
  };

  const onDeclineResearchObjectById = (id, payload) => {
    dispatch(ClinicTrialAction.declineResearchObjectById(id, payload));
  };

  const getNameFromUrl = (url) => {
    try {
      return firebase.storage().refFromURL(url).name;
    } catch (e) {
      return url;
    }
  };

  const customRequest = async ({ file, onSuccess, onError, key, pathFb }) => {
    try {
      const fileUpload = file;
      const fileName = fileUpload.name;
      const storageRef = firebase
        .storage()
        .ref(`clinicTrial/${key}/${pathFb}/${Date.now()}/${fileName}`);
      await storageRef.put(fileUpload);
      const imageUrl = await storageRef.getDownloadURL();
      onSuccess({ url: imageUrl });
    } catch (e) {
      onError({ e });
      message.error(e.message);
    }
  };

  return (
    <Layout
      {...props}
      render={(props) => (
        <ClinicTrialMainComponent
          t={t}
          i18n={i18n}
          clinicTrial={clinicTrial}
          appUserOma={appUserOma}
          sortValue={SORT_VALUE}
          userPerm={userPerm}
          onConsentFormSubmit={onConsentFormSubmit}
          onGeneralFormSubmit={onGeneralFormSubmit}
          onContactFormSubmit={onContactFormSubmit}
          onEigibilityAssessmentSubmit={onEigibilityAssessmentSubmit}
          onRegisterClinicTrialHandler={onRegisterClinicTrialHandler}
          onApproveResearchObjectById={onApproveResearchObjectById}
          onDeclineResearchObjectById={onDeclineResearchObjectById}
          onUpdateRecuitmentObjectHandler={onUpdateRecuitmentObjectHandler}
          getRecruitmentObjectsByOrgAndProgram={
            getRecruitmentObjectsByOrgAndProgram
          }
          onSelectStudyCaseHandler={onSelectStudyCaseHandler}
          onManuallyApproveResearchObjectById={
            onManuallyApproveResearchObjectById
          }
          getResearchObjectsByorgAndProgram={getResearchObjectsByorgAndProgram}
          organizationId={organizationId}
          customRequest={customRequest}
          getNameFromUrl={getNameFromUrl}
          currentPageRegister={currentPageRegister}
          setCurrentPageRegister={setCurrentPageRegister}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          history={history}
        />
      )}
    />
  );
};

export default ClinicTrial;
