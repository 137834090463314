import TextBase from "../../Text";
import styled from "styled-components";
import IconAnt from "@ant-design/icons";
import {
  Modal,
  Button as ButtonAnt,
  Input as InputAnt,
  DatePicker as DatpickerAnt,
  Radio as RadioAnt,
  Row,
  Dropdown,
} from "antd";
import { IoMdArrowDropdown } from "react-icons/all";

const ModalCustom = styled(Modal)`
  .ant-modal-header {
    width: 100% !important;
    background: #f3f4f6;
    border-radius: 10px 10px 0px 0px !important;
    border-bottom: unset;
  }
  .ant-modal-content {
    width: 1300px;
    border-radius: 10px !important;
  }
  .ant-modal-body {
    width: 1300px;
    padding: 24px 20px;
    overflow: scroll;
  }
`;

const ButtonRounded = styled(ButtonAnt)`
  && {
    height: auto;
    border-radius: 40px;
    padding: 5px 20px;
  }
`;

const Icon = styled(IconAnt)`
  &&&& {
    color: ${(props) => props.color} !important;
  }
  font-size: ${(props) => props.size};
`;

const Button = styled(ButtonAnt)`
  && {
    border-radius: 8px;
    padding: 8px 20px;
    height: auto;
    background: ${(props) => (props.secondary ? "#2ba796" : "white")};
  }
  &&:hover {
    background: ${(props) => (props.secondary ? "#2ba796d4" : "white")};
  }
  &&:focus {
    background: ${(props) => (props.secondary ? "#2ba796d4" : "white")};
  }
`;

const Input = styled(InputAnt)`
  && {
    border-radius: 8px;
    padding: ${(props) => (props.padding ? props.padding : "10px")};
    padding-left: 8px;
    min-width: ${(props) => props.minWidth};
    width: ${(props) => props.width};
    height: auto;
  }
`;

const DropDownContainer = styled(ButtonAnt)`
  && {
    padding: 4px;
    padding-left: 8px;
    border-radius: 8px;
    height: auto;
    width: ${(props) => props.width};
    text-align: left;
  }
`;

const TextError = styled(TextBase)`
  color: red;
  font-size: 12px;
  margin: 0px;
  position: absolute;
`;

const Image = styled.img`
  width: 150px;
  object-fit: cover;
`;

const Text = styled(TextBase)`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const TextVetical = styled.span`
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const Container = styled.div`
  width: 100%;
`;

const DatePicker = styled(DatpickerAnt)`
  && {
    border-radius: 8px;
    padding: ${(props) => (props.padding ? props.padding : "10px")};
    padding-left: 8px;
    padding-right: 8px;
    height: auto;
    min-width: ${(props) => props.minWidth};
    width: ${(props) => props.width};
    text-align: left;
    color: lightgrey;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: #e4edee;
  border: 1px solid #4f4f4f !important;
`;

const Radio = styled(RadioAnt)`
  &&.ant-radio-wrapper {
    font-size: 12px;
    .ant-radio-disabled + span {
      color: black;
    }
  }
`;

const DropDownWidget = ({
  placeholder,
  value,
  disabled,
  renderList,
  className,
  width,
  minWidth,
  handleButtonClick,
}) => {
  return (
    <Container className={className}>
      <Dropdown disabled={disabled} overlay={renderList} trigger={["click"]}>
        <DropDownContainer onClick={handleButtonClick} width={width}>
          <Row align="middle" justify="space-between">
            <Text
              color={!disabled ? "#000" : "lightgrey"}
              style={{ height: 18 }}
              size="12px"
            >
              {value ? value : placeholder}
            </Text>
            <Icon component={IoMdArrowDropdown} size="14px" color="#2BA796" />
          </Row>
        </DropDownContainer>
      </Dropdown>
    </Container>
  );
};
export {
  ModalCustom,
  Text,
  Container,
  Icon,
  Button,
  Image,
  Input,
  HeaderContainer,
  DatePicker,
  TextVetical,
  Radio,
  DropDownWidget,
  ButtonRounded,
  TextError,
};
