import { Input, Card, Button as BtnAnt } from "antd";
import styled from "styled-components";
import TextBase from "../Text";

const { Search: SearchAnt } = Input;

const Container = styled.div`
  width: 100%;
`;

const Text = styled(TextBase)`
  color: ${(props) => (props.active ? "#00BAE5" : props.color)};
  font-size: ${(props) => props.size + "px"};
  margin: 0px;
`;

const CardStat = styled(Card)`
  &&& {
    margin:0px
    min-width:400px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  }
  .ant-card-body {
    padding: 15px 20px;
  }
  .ant-card-head {
    border-bottom: 0px;
    padding: 0 20px;
    color: #00000080;
  }
  .ant-card-head-title{
    padding: 5px 0;
  }
  .ant-card-extra{
    padding: 10px 0;
  }
`;

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`;

const Button = styled(BtnAnt)`
  &&& {
    background-color: ${(props) => props.color};
  }
`;

const CardTable = styled(Card)`
  &&& {
    margin:0px
    overflow: hidden;
    height:100%;
    min-width:400px;
    min-height: ${(props) => `${props.minheight}px`};
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  }
  .ant-card-body {
    padding: 0
  }
  .ant-card-extra{
    padding: 10px 0;
  }
`;

const Bullet = styled.div`
  background-color: ${(props) => props.color};
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: 0px 5px 0px 0px;
`;

const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`;

export {
  Container,
  CardTable,
  CardStat,
  Search,
  Text,
  Bullet,
  HeaderWrapper,
  Button,
};
