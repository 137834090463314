import { Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 304px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #f0f0f1;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1),
    0px 4px 5px rgba(0, 0, 0, 0.05), 0px 1px 10px rgba(0, 0, 0, 0.05);
`;

export const Cover = styled.div`
  height: 104px;
  width: 100%;
  background: linear-gradient(180deg, #6fb2dd 0%, #d1e6f4 100%);
  position: absolute;
  top: 0;
  border-radius: 16px 16px 0px 0px;
`;

export const Content = styled.div`
  position: absolute;
  top: 13%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: rgb(47, 114, 157);
    text-align: center;
    opacity: 0.9;
    margin-top: 5px;
  }
  p {
    margin-top: 5px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    opacity: 0.9;
    color: rgba(0, 0, 0, 0.38);
  }
`;

export const ProfileImage = styled.img`
  top: 13%;
  border-radius: 50%;
  border: 8px solid #f9f9f9;
  width: 100%;
  height: 130px;
  object-fit: overflow;
`;

export const ManageProfile = styled(Button)`
  margin-top: 15px;
  background: #ffffff;
  border: 1px solid rgba(63, 152, 210, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
  height: 40px;
  color: #3f98d2;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;
