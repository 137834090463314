import React from "react";
import { HEADER_DEFAULT_FORMS } from "../constant";
import { isNumberValidate, checkDateInvalid } from "../utils";
import EverLogo from "../../../images/EverLogo.png";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  HeaderContainer,
  SpaceItemFlex,
  TextError,
} from "../styles";
import { Row, Col, Space, Checkbox, Divider, Radio } from "antd";
import moment from "moment";

export default function DemographicsForm(props) {
  const { t, form = {}, editable } = props;
  return (
    <Container>
      <Text className="text-center" size="16px" color="#0000008C">
        {t(HEADER_DEFAULT_FORMS)}
      </Text>
      <Container className="px-5 mt-3 rounded border">
        <Image className="mt-4" src={EverLogo} />
        <Row gutter={[16, 16]} className="my-4" align="middle">
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("subjects_initials")}:
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.subjectsInitials}
                padding="2px"
                onChange={form.handleChange("subjectsInitials")}
                maxLength={3}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_subject_id")}:
              </Text>
              <Input disabled value={form.values?.subjectId} padding="2px" />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_date")}:
              </Text>
              <DatePicker
                disabled
                value={checkDateInvalid(form.values?.createdAt)}
                format="DD/MM/YYYY"
                padding="2px"
                width="130px"
              />
            </Space>
          </Col>
        </Row>
        <HeaderContainer>
          <Text className="my-2 text-center" bold size="16px">
            {t("crf_demographics")}
          </Text>
        </HeaderContainer>
        <Container className="my-4">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Space>
                <Text color="grey">Date of Consent:</Text>
                <DatePicker
                  disabled
                  format="DD/MM/YYYY"
                  value={checkDateInvalid(form.values?.createdAt)}
                  padding="2px"
                  width="200px"
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <Text color="grey">Birthdate:</Text>
                <DatePicker
                  disabled
                  format="DD/MM/YYYY"
                  value={checkDateInvalid(form.values?.birthDate)}
                  padding="2px"
                  width="200px"
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space direction="vertical">
                <Space>
                  <Text color="grey" bold>
                    Gender:{" "}
                  </Text>
                  <Text color="grey">(check one)</Text>
                </Space>
                <Space>
                  <Checkbox
                    checked={form.values.gender === "MALE"}
                    value="MALE"
                  />
                  <Text className="mx-2">Male</Text>
                </Space>
                <Space>
                  <Checkbox
                    checked={form.values.gender === "FEMALE"}
                    value="FEMALE"
                  />
                  <Text className="mx-2">Female</Text>
                </Space>
              </Space>
            </Col>
          </Row>
          <Divider />
          <Text color="grey" bold>
            Contact Information:
          </Text>
          <Row className="mt-3" gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col span={18}>
                  <SpaceItemFlex className="w-100">
                    <Text color="grey">Address :</Text>
                    <Input
                      disabled
                      value={form.values?.address1 + form.values?.address2}
                      padding="2px"
                      width="100%"
                    />
                  </SpaceItemFlex>
                </Col>
                <Col span={6}>
                  <SpaceItemFlex>
                    <Text color="grey">Unit #:</Text>
                    <Input
                      disabled
                      value={form.values?.unit}
                      padding="2px"
                      width="100%"
                    />
                  </SpaceItemFlex>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col span={20}>
                  <SpaceItemFlex>
                    <Text color="grey">
                      Sub-district/sub-area, Area/District, Province:
                    </Text>
                    <Input
                      disabled
                      value={form.values?.addressDetail}
                      padding="2px"
                      width="100%"
                    />
                  </SpaceItemFlex>
                </Col>

                <Col span={4}>
                  <SpaceItemFlex>
                    <Text color="grey">Zip:</Text>
                    <Input
                      disabled
                      value={form.values?.postalCode}
                      padding="2px"
                      width="100%"
                    />
                  </SpaceItemFlex>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col md={24} lg={14}>
                  <SpaceItemFlex>
                    <Text color="grey">Phone Number :</Text>
                    <Input
                      disabled
                      value={form.values?.phoneNumber}
                      padding="2px"
                      width="100%"
                      maxLength={10}
                    />
                  </SpaceItemFlex>
                </Col>
                <Col md={24} lg={6}>
                  <Radio.Group
                    onChange={form.handleChange("phoneNumberMethod")}
                    value={form.values?.phoneNumberMethod}
                  >
                    <Space>
                      <Radio value={"home"}>Home</Radio>
                      <Radio value={"work"}>Work</Radio>
                      <Radio value={"mobile"}>Mobile</Radio>
                      <Radio value={"other"}>Other</Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col md={24} lg={14}>
                  <SpaceItemFlex>
                    <Text color="grey">Alternate Phone Number :</Text>
                    <Input
                      disabled={!editable}
                      value={form.values?.alterNatePhoneNumber}
                      padding="2px"
                      onChange={(e) => {
                        if (isNumberValidate(e.target.value)) {
                          form.setFieldValue(
                            "alterNatePhoneNumber",
                            e.target.value
                          );
                        }
                      }}
                      width="100%"
                      maxLength={10}
                    />
                  </SpaceItemFlex>
                </Col>
                <Col md={24} lg={6}>
                  <Radio.Group
                    onChange={form.handleChange("alterNatePhoneNumberMethod")}
                    value={form.values?.alterNatePhoneNumberMethod}
                  >
                    <Space>
                      <Radio value={"home"}>Home</Radio>
                      <Radio value={"work"}>Work</Radio>
                      <Radio value={"mobile"}>Mobile</Radio>
                      <Radio value={"other"}>Other</Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <SpaceItemFlex>
                <Text color="grey">Email :</Text>
                <Input
                  disabled
                  value={form.values?.email}
                  padding="2px"
                  width="100%"
                  maxLength={10}
                />
              </SpaceItemFlex>
            </Col>
            <Col span={24}>
              <SpaceItemFlex>
                <Text color="grey">Preferred method of contact :</Text>
                <Input
                  disabled={!editable}
                  value={form.values?.pfMethodContact}
                  padding="2px"
                  onChange={form.handleChange("pfMethodContact")}
                  width="100%"
                  maxLength={100}
                />
              </SpaceItemFlex>
            </Col>
          </Row>
          <Text className="mt-4" color="grey" bold>
            Emergency Contact:
          </Text>
          <Row className="mt-3" gutter={[16, 16]}>
            <Col span={24}>
              <SpaceItemFlex className="w-100">
                <Text color="grey">Contact Name :</Text>
                <Input
                  disabled={!editable}
                  maxLength={100}
                  value={form.values?.emsContactName}
                  onChange={form.handleChange("emsContactName")}
                  padding="2px"
                  width="100%"
                />
              </SpaceItemFlex>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col span={18}>
                  <SpaceItemFlex className="w-100">
                    <Text color="grey">Address :</Text>
                    <Input
                      disabled={!editable}
                      value={form.values?.emsAddress}
                      onChange={form.handleChange("emsAddress")}
                      padding="2px"
                      width="100%"
                    />
                  </SpaceItemFlex>
                </Col>
                <Col span={6}>
                  <SpaceItemFlex>
                    <Text color="grey">Unit #:</Text>
                    <Input
                      disabled={!editable}
                      value={form.values?.emsUnit}
                      onChange={form.handleChange("emsUnit")}
                      padding="2px"
                      width="100%"
                    />
                  </SpaceItemFlex>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col span={20}>
                  <SpaceItemFlex>
                    <Text color="grey">
                      Sub-district/sub-area, Area/District, Province:
                    </Text>
                    <Input
                      disabled={!editable}
                      value={form.values?.emsAddressDetail}
                      onChange={form.handleChange("emsAddressDetail")}
                      padding="2px"
                      width="100%"
                    />
                  </SpaceItemFlex>
                </Col>

                <Col span={4}>
                  <SpaceItemFlex>
                    <Text color="grey">Zip:</Text>
                    <Input
                      disabled={!editable}
                      value={form.values?.emsPostalCode}
                      onChange={(e) => {
                        if (isNumberValidate(e.target.value)) {
                          form.setFieldValue("emsPostalCode", e.target.value);
                        }
                      }}
                      padding="2px"
                      width="100%"
                      maxLength={5}
                    />
                  </SpaceItemFlex>
                  {form.errors?.emsPostalCode && (
                    <TextError>{form.errors?.emsPostalCode}</TextError>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col md={24} lg={14}>
                  <SpaceItemFlex>
                    <Text color="grey">Phone Number :</Text>
                    <Input
                      disabled={!editable}
                      value={form.values?.emsPhoneNumber}
                      onChange={(e) => {
                        if (isNumberValidate(e.target.value)) {
                          form.setFieldValue("emsPhoneNumber", e.target.value);
                        }
                      }}
                      padding="2px"
                      width="100%"
                      maxLength={10}
                    />
                  </SpaceItemFlex>
                </Col>
                <Col md={24} lg={6}>
                  <Radio.Group
                    onChange={form.handleChange("emsPhoneNumberMethod")}
                    value={form.values?.emsPhoneNumberMethod}
                  >
                    <Space>
                      <Radio value={"home"}>Home</Radio>
                      <Radio value={"work"}>Work</Radio>
                      <Radio value={"mobile"}>Mobile</Radio>
                      <Radio value={"other"}>Other</Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col md={24} lg={14}>
                  <SpaceItemFlex>
                    <Text color="grey">Alternate Phone Number :</Text>
                    <Input
                      disabled={!editable}
                      value={form.values?.emsAlterPhoneNumber}
                      onChange={(e) => {
                        if (isNumberValidate(e.target.value)) {
                          form.setFieldValue(
                            "emsAlterPhoneNumber",
                            e.target.value
                          );
                        }
                      }}
                      padding="2px"
                      width="100%"
                      maxLength={10}
                    />
                  </SpaceItemFlex>
                </Col>
                <Col md={24} lg={6}>
                  <Radio.Group
                    onChange={form.handleChange("emsAlterPhoneNumberMethod")}
                    value={form.values?.emsAlterPhoneNumberMethod}
                  >
                    <Space>
                      <Radio value={"home"}>Home</Radio>
                      <Radio value={"work"}>Work</Radio>
                      <Radio value={"mobile"}>Mobile</Radio>
                      <Radio value={"other"}>Other</Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <SpaceItemFlex>
                <Text color="grey">Email :</Text>
                <Input
                  disabled={!editable}
                  value={form.values?.emsEmail}
                  onChange={form.handleChange("emsEmail")}
                  padding="2px"
                  width="100%"
                  maxLength={10}
                />
              </SpaceItemFlex>
              {form.errors?.emsEmail && (
                <TextError>{form.errors?.emsEmail}</TextError>
              )}
            </Col>
            <Col span={24}>
              <SpaceItemFlex>
                <Text color="grey">Preferred method of contact :</Text>
                <Input
                  disabled={!editable}
                  value={form.values?.emsPfMethodContact}
                  onChange={form.handleChange("emsPfMethodContact")}
                  padding="2px"
                  width="100%"
                  maxLength={10}
                />
              </SpaceItemFlex>
            </Col>
          </Row>
        </Container>
        <Container className="my-3">
          <Row gutter={[16, 16]}>
            <Col span={16}>
              <SpaceItemFlex>
                <Text color="grey">Form Completed By:</Text>
                <Input
                  disabled={!editable}
                  value={form.values?.formCompletedBy}
                  onChange={form.handleChange("formCompletedBy")}
                  padding="2px"
                  width="100%"
                  maxLength={100}
                />
              </SpaceItemFlex>
            </Col>
            <Col span={8}>
              <Space align="center" direction="horizontal">
                <Text bold color="grey">
                  {t("crf_date")}:
                </Text>
                <DatePicker
                  disabled
                  value={checkDateInvalid(form.values?.formCompletedDate)}
                  format="DD/MM/YYYY"
                  padding="2px"
                  width="130px"
                />
              </Space>
            </Col>
          </Row>
        </Container>
        <Space className="my-4" size={25}>
          <Space>
            <Text bold color="grey">
              {t("crf_form_number")}
            </Text>
            <Input
              disabled={!editable}
              value={form.values?.formNumber}
              padding="2px"
              width="60px"
              maxLength={2}
              onChange={(e) => {
                if (isNumberValidate(e.target.value)) {
                  form.setFieldValue("formNumber", e.target.value);
                }
              }}
            />
          </Space>
          <Text color="grey">{t("crf_version_date")}: 01/28/2022</Text>
        </Space>
      </Container>
    </Container>
  );
}
