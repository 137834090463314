import { View, Document, Page, Text, Image } from "@react-pdf/renderer";
import React from "react";
import { HEADER_DEFAULT_FORMS } from "../constant";
import CheckboxOutline from "../assets/CheckboxOutline";
import Checkbox from "../assets/Checkbox";
import styles, { TitlePDFFile } from "../styles";
import moment from "moment";
import RadioboxOutline from "../assets/RadioOutline";
import RadioChecked from "../assets/RadioChecked";

export default function DemographicsPreview(props) {
  const { t, data = {} } = props;
  const ContentContainer = View;
  return (
    <Document>
      <Page size="A4">
        <Text style={styles.header}> {t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <TitlePDFFile t={t} />
          <View style={styles.titleHeader}>
            <Text style={styles.dateSignedText}>
              {t("subjects_initials")}: {data.subjectsInitials || "-"}
            </Text>
            <Text style={styles.dateSignedText}>
              {t("crf_subject_id")}: {data.subjectId}
            </Text>
            <Text style={styles.timeText}>
              {t("crf_date")}: {moment(data.createdAt).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}> {t("crf_demographics")}</Text>
          </View>
          <Text style={[styles.textDescription, { marginVertical: 5 }]}>
            Date of Consent*: {moment(data.consentDate).format("DD/MM/YYYY")}
          </Text>
          <Text style={[styles.textDescription, { marginVertical: 5 }]}>
            Birthdate*: {moment(data.birthDate).format("DD/MM/YYYY")}
          </Text>
          <View style={{ marginVertical: 5 }}>
            <Text style={styles.textDescription}>
              <Text style={styles.fontBold}>Gender* : </Text>
              {t("crf_check_one")}
            </Text>
            <View
              style={[styles.row, { alignItems: "center", marginVertical: 5 }]}
            >
              {data.gender === "MALE" ? (
                <Checkbox width={10} height={10} />
              ) : (
                <CheckboxOutline width={10} height={10} />
              )}
              <Text style={[styles.textDescription, { marginLeft: 5 }]}>
                Male
              </Text>
            </View>
            <View style={[styles.row, { alignItems: "center" }]}>
              {data.gender === "FEMALE" ? (
                <Checkbox width={10} height={10} />
              ) : (
                <CheckboxOutline width={10} height={10} />
              )}
              <Text style={[styles.textDescription, { marginLeft: 5 }]}>
                Female
              </Text>
            </View>
          </View>
          <View style={styles.divider} />
          <ContentContainer style={{ marginBottom: 15 }}>
            <Text style={[styles.textBold, { marginBottom: 5 }]}>
              Contact Information:
            </Text>
            <View style={[styles.row, { marginVertical: 3 }]}>
              <View style={[styles.row, { flex: 5, alignItems: "center" }]}>
                <Text style={styles.textDescription}>Address:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.address1 + (data.address2 ? "," + data.address2 : "")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <Text style={styles.textDescription}>Unit#:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.unit || "-"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.row, { marginVertical: 3 }]}>
              <View style={[styles.row, { flex: 5, alignItems: "center" }]}>
                <Text style={styles.textDescription}>
                  Sub-district/sub-area, Area/District, Province:
                </Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.addressDetail}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 0.6,
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <Text style={styles.textDescription}>Zip:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.postalCode || "-"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.rowInputBox}>
              <View
                style={{
                  flex: 5,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={styles.textDescription}>Phone Number:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.phoneNumber || "-"}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 3 }}>
                <RadioGroup value={data.phoneNumberMethod} />
              </View>
            </View>
            <View style={styles.rowInputBox}>
              <View
                style={{
                  flex: 5,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={styles.textDescription}>
                  Alternate Phone Number :
                </Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.alterNatePhoneNumber || "-"}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 3 }}>
                <RadioGroup value={data.alterNatePhoneNumberMethod} />
              </View>
            </View>
            <View style={styles.rowInputBox}>
              <Text style={styles.textDescription}>Email Address:</Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {data.email || "-"}
                </Text>
              </View>
            </View>
            <View style={styles.rowInputBox}>
              <Text style={styles.textDescription}>
                Preferred method of contact:
              </Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {data.pfMethodContact || "-"}
                </Text>
              </View>
            </View>
          </ContentContainer>
          <ContentContainer>
            <Text style={[styles.textBold, { marginBottom: 5 }]}>
              Emergency Contact:
            </Text>
            <View style={styles.rowInputBox}>
              <Text style={styles.textDescription}>Contact Name:</Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {data.emsContactName || "-"}
                </Text>
              </View>
            </View>
            <View style={[styles.row, { marginVertical: 3 }]}>
              <View style={[styles.row, { flex: 5, alignItems: "center" }]}>
                <Text style={styles.textDescription}>Address:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.emsAddress || "-"}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <Text style={styles.textDescription}>Unit#:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.emsUnit || "-"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.row, { marginVertical: 3 }]}>
              <View style={[styles.row, { flex: 5, alignItems: "center" }]}>
                <Text style={styles.textDescription}>
                  Sub-district/sub-area, Area/District, Province:
                </Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.emsAddressDetail || "-"}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 0.6,
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <Text style={styles.textDescription}>Zip:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.emsPostalCode || "-"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.rowInputBox}>
              <View
                style={{
                  flex: 5,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={styles.textDescription}>Phone Number:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.emsPhoneNumber || "-"}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 3 }}>
                <RadioGroup value={data.emsPhoneNumberMethod} />
              </View>
            </View>
            <View style={styles.rowInputBox}>
              <View
                style={{
                  flex: 5,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={styles.textDescription}>
                  Alternate Phone Number :
                </Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.emsAlterPhoneNumber || "-"}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 3 }}>
                <RadioGroup value={data.emsAlterPhoneNumberMethod} />
              </View>
            </View>
            <View style={styles.rowInputBox}>
              <Text style={styles.textDescription}>Email Address:</Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {data.emsEmail || "-"}
                </Text>
              </View>
            </View>
            <View style={styles.rowInputBox}>
              <Text style={styles.textDescription}>
                Preferred method of contact:
              </Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {data.emsPfMethodContact || "-"}
                </Text>
              </View>
            </View>
          </ContentContainer>
          <View>
            <Text style={[styles.textDescription, { fontStyle: "italic" }]}>
              *Indicates required field
            </Text>
          </View>
          <View style={[styles.row, { marginTop: 40 }]}>
            <View style={[styles.row, { flex: 5, alignItems: "center" }]}>
              <Text style={styles.textDescription}>Form Completed By:</Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {t("crf_pi_name_fixed")}
                </Text>
              </View>
            </View>
            <View
              style={{
                flex: 2,
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 5,
              }}
            >
              <Text style={styles.textDescription}>Date:</Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {moment("6/14/2022").format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.paddingBody, styles.row, { marginBottom: 15 }]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/28/2022
          </Text>
        </View>
      </Page>
    </Document>
  );
}

const RadioComponent = ({ checked, title }) => {
  return (
    <View style={[styles.row, { alignItems: "center", marginHorizontal: 5 }]}>
      {checked ? (
        <RadioChecked width={12} height={12} />
      ) : (
        <RadioboxOutline width={12} height={12} />
      )}
      <Text style={[{ marginLeft: 5 }, styles.textDescription]}>{title}</Text>
    </View>
  );
};

const RadioGroup = ({ value }) => {
  return (
    <View style={[styles.row, { flex: 3 }]}>
      <RadioComponent title="Home" checked={value === "home"} />
      <RadioComponent title="Work" checked={value === "work"} />
      <RadioComponent title="Mobile" checked={value === "mobile"} />
      <RadioComponent title="Others" checked={value === "others"} />
    </View>
  );
};
