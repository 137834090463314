import * as type from "../types";

const initialState = {
  medication: {
    loading: false,
    error: null,
    data: [],
    isSuccess: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_MEDICATION_FOR_ORGANIZATION_REQUESTED:
      return {
        ...state,
        medication: {
          data: [],
          loading: true,
          error: null,
        },
      };
    case type.GET_MEDICATION_FOR_ORGANIZATION_SUCCESS:
      return {
        ...state,
        medication: {
          data: action.data,
          loading: false,
        },
      };
    case type.GET_MEDICATION_FOR_ORGANIZATION_FAILED:
      return {
        ...state,
        medication: {
          data: [],
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
