import TextBase from "../../Text";
import styled from "styled-components";
import IconAnt from "@ant-design/icons";
import {
  Modal,
  Button as ButtonAnt,
  Input as InputAnt,
  Dropdown,
  Row,
} from "antd";
import { IoMdArrowDropdown } from "react-icons/all";

const ModalCustom = styled(Modal)`
  .ant-modal-header {
    width: 100% !important;
    background: #f3f4f6;
    border-radius: 10px 10px 0px 0px !important;
    border-bottom: unset;
  }
  .ant-modal-content {
    border-radius: 10px !important;
  }
  .ant-modal-body {
    padding: 24px 20px;
    overflow: scroll;
  }
  .ant-modal-footer {
    border-top: none;
  }
`;

const Icon = styled(IconAnt)`
  &&&& {
    color: ${(props) => props.color} !important;
  }
  font-size: ${(props) => props.size};
`;

const Button = styled(ButtonAnt)`
  && {
    border-radius: 8px;
    border-radius: 8px;
    padding: 8px 20px;
    height: auto;
    background: ${(props) => (props.secondary ? "#2ba796" : "white")};
  }
  &&:hover {
    background: ${(props) => (props.secondary ? "#2ba796" : "white")};
  }
  &&:focus {
    background: ${(props) => (props.secondary ? "#2ba796" : "white")};
  }
`;

const Input = styled(InputAnt)`
  && {
    border-radius: 8px;
    padding: ${(props) => (props.padding ? props.padding : "10px")};
    padding-left: 8px;
    min-width: ${(props) => props.minWidth};
    width: ${(props) => props.width};
    height: auto;
  }
`;

const TextError = styled(TextBase)`
  color: red;
  font-size: 12px;
  margin: 0px;
  position: absolute;
`;

const Text = styled(TextBase)`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const Container = styled.div`
  width: 100%;
`;

const DropDownContainer = styled(ButtonAnt)`
  && {
    padding: 8px;
    padding-left: 8px;
    border-radius: 8px;
    height: auto;
    width: ${(props) => props.width};
    text-align: left;
  }
`;

const DropDownWidget = ({
  placeholder,
  value,
  disabled,
  renderList,
  className,
  width,
  handleButtonClick,
}) => {
  return (
    <Container className={className}>
      <Dropdown disabled={disabled} overlay={renderList} trigger={["click"]}>
        <DropDownContainer onClick={handleButtonClick} width={width}>
          <Row align="middle" justify="space-between">
            <Text color={value ? "#000" : "lightgrey"} size="14px">
              {value ? value : placeholder}
            </Text>
            <Icon component={IoMdArrowDropdown} size="16px" color="#2BA796" />
          </Row>
        </DropDownContainer>
      </Dropdown>
    </Container>
  );
};

export {
  ModalCustom,
  Text,
  TextError,
  Container,
  Icon,
  Button,
  Input,
  DropDownWidget,
};
