import { Layout, Row } from "antd";
import styled from "styled-components";
const { Content, Sider } = Layout;

export const LayoutContainer = styled(Layout)`
  min-height: 100vh;
  background-color: #263240 !important;
`;

export const LayoutContent = styled(Layout)`
  background-color: #202124 !important;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const ContentRelative = styled(Content)`
  position: relative;
`;

export const ConversationContainer = styled.div`
  position: absolute;
  inset: 16px 16px 80px;
  margin: 0;
  display: flex;
  justify-content: center;
`;

export const ConversationContent = styled.div`
  position: relative;
  background: #3c4043;
  border-radius: 8;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OwnParticipant = styled.div`
  inset: auto 16px 16px auto;
  position: absolute;
`;

export const SiderLayoutLeft = styled(Sider)`
  flex: ${(prop) => (prop.collapsed ? "0" : "0 0 360")}px !important;
  max-width: ${(prop) => (prop.collapsed ? "0" : "360")}px !important;
  min-width: ${(prop) => (prop.collapsed ? "0" : "360")}px !important;
  width: ${(prop) => (prop.collapsed ? "0" : "360")}px !important;
  border-radius: 8px;
  background-color: #ffffff !important;
  margin: ${(prop) =>
    prop.collapsed ? "16px 0px 80px 0px" : "16px 0px 80px 16px"};
  .ant-layout-sider-trigger {
    display: none;
  }
  .ant-layout-sider-children {
    padding: 16px;
  }
`;
export const SiderLayoutRight = styled(Sider)`
  flex: ${(prop) => (prop.collapsed ? "0" : "0 0 360")}px !important;
  max-width: ${(prop) => (prop.collapsed ? "0" : "360")}px !important;
  min-width: ${(prop) => (prop.collapsed ? "0" : "360")}px !important;
  width: ${(prop) => (prop.collapsed ? "0" : "360")}px !important;
  border-radius: 8px;
  background-color: #ffffff !important;
  margin: ${(prop) =>
    prop.collapsed ? "16px 0px 80px 0px" : "16px 16px 80px 0px"};
  .ant-layout-sider-trigger {
    display: none;
  }
  .ant-layout-sider-children {
    padding: 16px;
  }
`;

export const SiderTelemonitoringLayout = styled(Sider)`
  flex: ${(prop) => (prop.collapsed ? "0" : "0 0 720")}px !important;
  max-width: ${(prop) => (prop.collapsed ? "0" : "720")}px !important;
  min-width: ${(prop) => (prop.collapsed ? "0" : "720")}px !important;
  width: ${(prop) => (prop.collapsed ? "0" : "720")}px !important;
  border-radius: 8px;
  background-color: #ffffff !important;
  margin: ${(prop) =>
    prop.collapsed ? "16px 0px 80px 0px" : "16px 16px 80px 0px"};
  .ant-layout-sider-trigger {
    display: none;
  }
  .ant-layout-sider-children {
    padding: 16px;
  }
  overflow: scroll;
`;

export const FooterContent = styled(Row)`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 80px;
  justify-content: space-between;
  align-items: center;
`;

export const CircleButtonNormal = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #000000;
  cursor: pointer;
`;

export const CircleButtonAlert = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #2ba275;
  color: #ffffff;
  cursor: pointer;
`;

export const CircleIcon = styled.div`
  cursor: pointer;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
