import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/Users";

async function getMonitoringReportByUserId(appUserId) {
  let page = 1;
  const filter = `filter[limit]=100`;
  let { data } = await http.get(
    `${apiEndpoint}/${appUserId}/monitoringReports?page=${page}&${filter}`
  );
  let reports = data.data;
  if (data.meta.totalPageCount > 1) {
    let meta = data.meta;
    for (let i = 2; i <= meta.totalPageCount; i++) {
      const { data } = await http.get(
        `${apiEndpoint}/${appUserId}/monitoringReports?page=${meta.nextPage}&${filter}`
      );
      meta = data.meta;
      reports = [...reports, ...data.data];
    }
  }
  return reports;
}

export { getMonitoringReportByUserId };
