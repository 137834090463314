import http from "../services/httpServiceHealthCare";
const apiEndpoint = "/RecruitmentObjects";

async function createResearchObjFromRecruitmentObj(id, payload) {
  const { data } = await http.post(
    `${apiEndpoint}/${id}/researchObject`,
    payload
  );
  return data;
}

async function getResearchObjFromRecruitmentObj(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}`);
  return data;
}

async function updateRecruitmentObject(id, payload) {
  const { data } = await http.patch(`${apiEndpoint}/${id}`, payload);
  return data;
}
export {
  createResearchObjFromRecruitmentObj,
  getResearchObjFromRecruitmentObj,
  updateRecruitmentObject,
};
