import React from "react";
import { Modal } from "antd";

const Note = (props) => {
  const { visible, setVisible, note } = props;
  return (
    <Modal
      title="Note"
      closable={false}
      visible={visible}
      footer={null}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
    >
      <div>{note}</div>
    </Modal>
  );
};

export default Note;
