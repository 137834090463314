import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_HEALTH_CARE,
});

instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = localStorage.getItem("token");
    if (token) {
      config.params = {};
      config.params["access_token"] = token;
    }
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

function setJwt(jwt) {
  if (jwt) {
    // axios.defaults.headers.common['x-auth-token'] = jwt
    axios.defaults.params = {};
    axios.defaults.params["access_token"] = jwt; //eslint-disable-line
  }
}

function clearJwt() {
  if (axios.defaults.params) {
    delete axios.defaults.params;
  }
}

export default {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  patch: instance.patch,
  setJwt,
  clearJwt,
};
