import * as type from "../types";

const initialState = {
  isAuthenticated: null,
  loading: false,
  error: null,
  data: null,
  information: null,
  practitioner: null,
  register: false,
  isSuccess: false,
  organization: {
    isSuccess: false,
    data: null,
    error: null,
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.LOGIN_APP_USER_OMA_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        register: false,
        isSuccess: false,
      };
    case type.LOGIN_APP_USER_OMA_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        data: action.data,
        loading: false,
        register: false,
        isSuccess: true,
      };
    case type.LOGIN_APP_USER_OMA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        register: false,
        isSuccess: false,
      };
    case type.LOGOUT_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        register: false,
      };
    case type.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        data: false,
        information: false,
        loading: false,
        register: false,
      };
    case type.LOGOUT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        register: false,
      };
    case type.CREATE_APP_USER_OMA_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        register: false,
      };
    case type.CREATE_APP_USER_OMA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        register: true,
      };
    case type.CREATE_APP_USER_OMA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        register: true,
      };
    case type.GET_APP_USER_OMA_REQUESTED:
      return {
        ...state,
        data: state.data,
        loading: true,
        error: null,
        register: false,
        isSuccess: false,
      };
    case type.GET_APP_USER_OMA_SUCCESS:
      return {
        ...state,
        data: state.data,
        information: action.data,
        loading: false,
        register: false,
        isSuccess: true,
      };
    case type.GET_APP_USER_OMA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        register: false,
        isSuccess: false,
      };
    case type.GET_PROFILE_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        register: false,
        isSuccess: false,
      };
    case type.GET_PROFILE_SUCCESS:
      return {
        ...state,
        practitioner: action.data,
        loading: false,
        error: null,
        register: false,
        isSuccess: true,
      };
    case type.GET_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: null,
        register: false,
        isSuccess: false,
      };
    case type.UPDATE_PROFILE_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        register: false,
      };
    case type.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        practitioner: action.data,
        loading: false,
        error: null,
        register: false,
      };
    case type.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: null,
        register: false,
      };
    case type.ADD_PHOTO_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        register: false,
      };
    case type.ADD_PHOTO_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null,
        register: false,
      };
    case type.ADD_PHOTO_FAILED:
      return {
        ...state,
        loading: false,
        error: null,
        register: false,
      };
    case type.UPDATE_PHOTO_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        register: false,
      };
    case type.UPDATE_PHOTO_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null,
        register: false,
      };
    case type.UPDATE_PHOTO_FAILED:
      return {
        ...state,
        loading: false,
        error: null,
        register: false,
      };
    case type.UPDATE_SPECIALTIES_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        register: false,
      };
    case type.UPDATE_SPECIALTIES_SUCCESS:
      return {
        ...state,
        practitioner: action.data,
        loading: false,
        error: null,
        register: false,
      };
    case type.UPDATE_SPECIALTIES_FAILED:
      return {
        ...state,
        loading: false,
        error: null,
        register: false,
      };
    case type.DELETE_APP_USER_OMA_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.DELETE_APP_USER_OMA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        isSuccess: true,
      };
    case type.DELETE_APP_USER_OMA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    case type.GET_ORGANIZATIONS_BY_USER_REQUESTED:
      return {
        ...state,
        organization: {
          loading: true,
          error: null,
          isSuccess: false,
        },
      };
    case type.GET_ORGANIZATIONS_BY_USER_SUCCESS:
      return {
        ...state,
        organization: {
          loading: false,
          error: null,
          isSuccess: true,
          data: action.data,
        },
      };
    case type.GET_ORGANIZATIONS_BY_USER_FAILED:
      return {
        ...state,
        organization: {
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };
    default:
      return state;
  }
};
