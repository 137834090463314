import * as type from "../types";

export function getSymptomGroup() {
  return {
    type: type.GET_SYMPTOM_GROUP_REQUESTED,
  };
}

export function getMedicationForOrgs(symptomGroupId) {
  return {
    type: type.GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_REQUESTED,
    symptomGroupId,
  };
}

export function removeMedicationBySymptomGroupId() {
  return {
    type: type.REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_REQUESTED,
  };
}
