import React, { useEffect, useState } from "react";
import { Button, Text } from "./styles";
import { Space, message, Spin, Table } from "antd";
import ClinicTrialParticipantRegister from "../ClinicTrialParticipantRegister";
import CtmsPatientDetailModal from "../Modal/CtmsPatientDetailModal";
import { useFormik } from "formik";
import moment from "moment";

const ClinicTrialResults = (props) => {
  const {
    t,
    clinicTrial,
    setTabIndex,
    userPerm,
    onRegisterClinicTrialHandler,
    onUpdateRecuitmentObjectHandler,
    currentPageRegister,
    setCurrentPageRegister,
    getRecruitmentObjectsByOrgAndProgram,
    getResearchObjectsByorgAndProgram,
  } = props;
  const [visible, setVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalManuallyVisible, setModalManuallyVisible] = useState(false);
  const [onRegister, setOnRegister] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const transFormDate = (value) => {
    return value && moment(value).isValid() ? moment(value) : null;
  };

  const detailForm = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: (values) => {
      const formObject = {
        age: { value: values?.age },
        allergy: { value: values.allergy, info: values.allergyInfo },
        bmi: { value: values.bmi },
        smoke: { value: values.smoke },
        diagnosedHistory: { value: values.diagnosedHistory },
        checkDate: { value: values.checkDate },
        clinicalTrial: {
          value: values.clinicalTrial,
          info: values.clinicalTrialInfo,
          date: moment(values.clinicalTrialDate).toISOString(),
        },
        closeToPatient: {
          value: values.closeToPatient,
        },
        covidInfected: {
          value: values.covidInfected,
        },
        examinationHistory: {
          value: values.examinationHistory,
          info: values.examinationHistoryInfo,
        },
        gender: {
          value: values.gender,
        },
        height: {
          value: values.height,
        },
        lungAbnormal: {
          value: values.lungAbnormal,
          info: values.lungAbnormalInfo,
          date: moment(values.lungAbnormalDate).toISOString(),
        },
        nasalSpray: {
          value: values.nasalSpray,
          info: values.nasalSprayInfo,
          date: moment(values.nasalSprayDate).toISOString(),
        },
        phoneno: {
          value: values.phoneno,
        },
        pregnancy: {
          value: values.pregnancy,
        },
        rhinits: {
          value: values.rhinits,
          info: values.rhinitsInfo,
          date: moment(values.rhinitsDate).toISOString(),
        },
        sinus: {
          value: values.sinus,
          info: values.sinusInfo,
          date: moment(values.sinusDate).toISOString(),
        },
        vacinated2dose: {
          value: values.vacinated2dose,
        },
        weight: {
          value: values.weight,
        },
      };
      onUpdateRecuitmentObjectHandler(currentRecord.id, { formObject });
      setVisible(false);
    },
  });

  useEffect(() => {
    getResearchObjectsByorgAndProgram();
    getRecruitmentObjectsByOrgAndProgram();
  }, []);

  useEffect(() => {
    init();
  }, [clinicTrial.clinicTrialResults, clinicTrial.researchObjects]);

  useEffect(() => {
    if (clinicTrial.registerForm.isSuccess) {
      if (currentPageRegister === 4 && clinicTrial.registerForm.submit) {
        setCurrentPageRegister(0);
        setOnRegister(false);
        setTabIndex(0);
        return;
      }
      setCurrentPageRegister((prev) => Math.min(4, prev + 1));
    } else if (clinicTrial.registerForm.error) {
      message.error(t("error_occurred"));
    }
  }, [clinicTrial.registerForm]);

  useEffect(() => {
    if (clinicTrial.clinicTrialResults.error) {
      message.error(t("error_occurred"));
    }
  }, [clinicTrial.clinicTrialResults.error]);

  const init = async () => {
    try {
      if (
        clinicTrial.clinicTrialResults.isSuccess &&
        clinicTrial.researchObjects.isSuccess &&
        Array.isArray(clinicTrial.clinicTrialResults.data) &&
        Array.isArray(clinicTrial.researchObjects.data)
      ) {
        const newData = clinicTrial.clinicTrialResults.data.flatMap((item) => {
          const activeStatus = clinicTrial.researchObjects.data.find(
            (ele) => ele.recruitmentObjectId === item.id
          );
          if (activeStatus?.status && activeStatus?.status !== "in-progress") {
            return [];
          }
          return {
            ...item,
            researchId: activeStatus?.id,
            consentForm: activeStatus?.consentForm ?? {},
            generalInformation: activeStatus?.generalInformation ?? {},
            address: activeStatus?.address ?? {},
            eligibilityAssessment: activeStatus?.eligibilityAssessment[0] ?? {},
            eligibilityAssessmentId: activeStatus?.eligibilityAssessment[0]?.id,
          };
        });
        setData(newData);
      }
    } catch (e) {
      message.error(t("error_try_again"));
    }
  };

  const COLUMNS = [
    {
      title: "No",
      key: "id",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return id;
      },
    },
    {
      title: "Date Of Pre-screening",
      key: "createdAt",
      align: "center",
      render: (record) => {
        return moment(record.createdAt).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      title: "Phone No.",
      key: "createdAt",
      align: "center",
      render: (record) => {
        return record.formObject.phoneno?.value || t("nothing");
      },
    },
    {
      key: "action",
      align: "right",
      render: (record) => {
        return (
          <Space>
            {userPerm.REGISTER_NEW_PARTICIPANTS && (
              <Button
                onClick={async () => {
                  if (!record.researchId) {
                    const result = await onRegisterClinicTrialHandler(record);
                    if (result) {
                      setCurrentRecord({ ...record, researchId: result.id });
                      setCurrentPageRegister(0);
                      setOnRegister(true);
                    } else {
                      setCurrentRecord(record);
                      setCurrentPageRegister(0);
                    }
                  } else {
                    setCurrentRecord(record);
                    setCurrentPageRegister(0);
                    setOnRegister(true);
                  }
                }}
              >
                <Text color="white">{t("register_new_participant")}</Text>
              </Button>
            )}
            <Button
              onClick={() => {
                setInitialValues({
                  phoneno: record.formObject?.phoneno?.value ?? "",
                  age: record.formObject?.age?.value ?? "",
                  gender: record.formObject?.gender?.value ?? "",
                  height: record.formObject?.height?.value ?? "",
                  weight: record.formObject?.weight?.value ?? "",
                  bmi: record.formObject?.bmi?.value ?? "",
                  smoke: record.formObject?.smoke.value ?? "",
                  diagnosedHistory:
                    record.formObject?.diagnosedHistory.value ?? [],
                  pregnancy: record.formObject?.pregnancy?.value ?? "",
                  lungAbnormal: record.formObject?.lungAbnormal?.value ?? "",
                  lungAbnormalInfo: record.formObject?.lungAbnormal?.info ?? "",
                  lungAbnormalDate: transFormDate(
                    record.formObject?.lungAbnormal?.date
                  ),
                  examinationHistory:
                    record.formObject?.examinationHistory?.value ?? "",
                  examinationHistoryInfo:
                    record.formObject?.examinationHistory?.info ?? "",
                  rhinits: record.formObject?.rhinits?.value ?? "",
                  rhinitsInfo: record.formObject?.rhinits?.info ?? "",
                  rhinitsDate: transFormDate(record.formObject?.rhinits?.date),
                  sinus: record.formObject?.sinus?.value ?? "",
                  sinusInfo: record.formObject?.sinus?.info ?? "",
                  sinusDate: transFormDate(record.formObject?.sinus?.date),
                  vacinated2dose:
                    record.formObject?.vacinated2dose?.value ?? "",
                  covidInfected: record.formObject?.covidInfected?.value ?? "",
                  closeToPatient:
                    record.formObject?.closeToPatient?.value ?? "",
                  allergy: record.formObject?.allergy?.value ?? "",
                  allergyInfo: record.formObject?.allergy?.info ?? "",
                  clinicalTrial: record.formObject?.clinicalTrial?.value ?? "",
                  clinicalTrialInfo:
                    record.formObject?.clinicalTrial?.info ?? "",
                  clinicalTrialDate: transFormDate(
                    record.formObject?.clinicalTrial?.date
                  ),
                  nasalSpray: record.formObject?.nasalSpray?.value ?? "",
                  nasalSprayInfo: record.formObject?.nasalSpray?.info ?? "",
                  nasalSprayDate: transFormDate(
                    record.formObject?.nasalSpray?.date
                  ),
                  checkDate: record.formObject?.checkDate?.value ?? "",
                });
                setIsEditMode(false);
                setCurrentRecord(record);
                setVisible(true);
              }}
            >
              <Text color="white">{t("more_detail")}</Text>
            </Button>
          </Space>
        );
      },
    },
  ];

  if (onRegister)
    return (
      <Spin spinning={clinicTrial?.registerForm?.loading}>
        <ClinicTrialParticipantRegister
          setOnRegister={setOnRegister}
          currentRecord={currentRecord}
          setModalManuallyVisible={setModalManuallyVisible}
          modalManuallyVisible={modalManuallyVisible}
          {...props}
        />
      </Spin>
    );

  return (
    <>
      <Table
        columns={COLUMNS}
        loading={
          clinicTrial.clinicTrialResults.loading ||
          clinicTrial.researchObjects.loading
        }
        dataSource={data}
        rowKey={(item) => item.id}
      />
      <CtmsPatientDetailModal
        t={t}
        detailForm={detailForm}
        visible={visible}
        canEdit={userPerm.EDIT_RECRUIT_OBJECTS}
        setVisible={setVisible}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    </>
  );
};

export default ClinicTrialResults;
