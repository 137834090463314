export const VACCINES_NAME = [
  {
    label: "Pfizer",
    key: "1",
  },
  {
    label: "Moderna",
    key: "2",
  },
  {
    label: "AstraZeneca",
    key: "3",
  },
  {
    label: "Sinovac",
    key: "4",
  },
  {
    label: "Sinopharm",
    key: "5",
  },
  {
    label: "other",
    key: "6",
  },
];

export const PICK_UP_VALUE = [
  {
    label: "0",
    key: "0",
  },
  {
    label: "1",
    key: "1",
  },
  {
    label: "2",
    key: "2",
  },
];
