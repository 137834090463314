import { Svg, Path } from "@react-pdf/renderer";

export default function CheckboxOutline({ width, height }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M7.83372 13.7175H6.16706V3.71746L1.58372 8.30079L0.400391 7.11746L7.00039 0.517456L13.6004 7.11746L12.4171 8.30079L7.83372 3.71746V13.7175Z"
        fill="black"
        fillOpacity="0.45"
      />
    </Svg>
  );
}
