import React from "react";
import { FileOutlined } from "@ant-design/icons";

import { Container, HeaderWrapper, Search, Text } from "./styles";
import Table from "../Table";

function UserListTable(props) {
  const { users, user, onSearch, t } = props;

  const columns = [
    {
      title: t("user_id"),
      key: "customerID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: t("full_name"),
      key: "name",
      dataIndex: "userInformation",
      render: (text) => {
        if (text) {
          const { firstname, lastname } = text;
          const fullName = `${firstname} ${lastname}`;
          return <Text>{fullName}</Text>;
        }
      },
    },
    {
      title: t("c_id"),
      key: "cId",
      dataIndex: "cId",
    },
    {
      title: t("role"),
      key: "role",
      dataIndex: "roles",
      render: (text) => text[0].name,
    },
    {
      title: t("mobile_number"),
      key: "mobileNumber",
      dataIndex: "mobileNumber",
    },
    {
      title: t("action"),
      key: "action",
    },
  ];

  return (
    <Container>
      <HeaderWrapper>
        <FileOutlined />
        <Text bold style={{ paddingTop: 10 }}>
          {t("user_list")}
        </Text>
        <Search
          onSearch={onSearch}
          style={{ width: 200, marginLeft: "auto" }}
        />
      </HeaderWrapper>

      <Table
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={users}
        loading={user.loading}
      />
    </Container>
  );
}
export default UserListTable;
