import React from "react";
import { Row, Col, Typography, Steps, Input, Button } from "antd";
import {
  DeliverDrugContainer,
  DeliverDrugContent,
  StepsContainer,
  SummarySteps,
  StepsCard,
  DeliverWrapper,
  DeliverCardDetail,
  CardStyled,
  ChatContent,
} from "./styles";
import {
  SUMMARY_STEPS,
  SUMMARY_STEPS_DELIVER_DRUG,
  SUMMARY_STEPS_CURRENT,
  IS_STEPS,
  SUMMARY_STEPS_ACTION,
} from "./constant";
import GoogleMapDirection from "../GoogleMapDirection";
import PrescriptionOrderCard from "../PrescriptionOrderCard";
import SummaryPatientDetailCard from "../Card/SummaryPatientDetail";

const { Title } = Typography;
const { Step } = Steps;
const { TextArea } = Input;

const DeliverDrug = (props) => {
  const {
    origin,
    t,
    destination,
    booking,
    visibleModalNote,
    setVisibleModalNote,
    setVisibleModalPrescription,
    handleUpdateStatus,
  } = props;
  return (
    <DeliverDrugContainer>
      <DeliverDrugContent>
        <Row
          gutter={[24, 12]}
          justify="center"
          align="middle"
          className="p-3 pb-0"
          style={{ height: "15vh" }}
        >
          <Col span={12} className="d-flex align-items-center" align="middle">
            <Title className="m-0" level={3}>
              Deliver Drug
            </Title>
          </Col>
          <Col span={12}>
            <StepsCard>
              <StepsContainer>
                <SummarySteps
                  size="small"
                  current={3}
                  className="d-flex align-items-center mr-4"
                >
                  {SUMMARY_STEPS.map((step, index) => (
                    <Step key={index} icon={step.icon} title={step.title} />
                  ))}
                </SummarySteps>
              </StepsContainer>
            </StepsCard>
          </Col>
        </Row>
        <DeliverWrapper>
          <GoogleMapDirection origin={origin} destination={destination} />
          <DeliverCardDetail>
            <PrescriptionOrderCard
              t={t}
              booking={booking}
              handleModal={() => setVisibleModalPrescription(true)}
              visibleModalNote={visibleModalNote}
              setVisibleModalNote={setVisibleModalNote}
            />
            <CardStyled title="Address">
              <div>
                <h4>Address Detail</h4>
                <TextArea
                  style={{ borderRadius: 10 }}
                  value={booking.locationAddress.address}
                  disabled
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <h4>Note</h4>
                <TextArea
                  style={{ borderRadius: 10 }}
                  value={booking.locationAddress.note}
                  disabled
                />
              </div>
            </CardStyled>
            {IS_STEPS.includes(SUMMARY_STEPS_CURRENT[booking.status]) && (
              <CardStyled>
                <Steps
                  current={SUMMARY_STEPS_CURRENT[booking.status]}
                  labelPlacement="vertical"
                >
                  {SUMMARY_STEPS_DELIVER_DRUG.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </CardStyled>
            )}
            <CardStyled>
              <div style={{ marginTop: 10 }}>
                <h4>
                  Please make sure your deliverer has reached the destination
                </h4>
                <div style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    onClick={() =>
                      handleUpdateStatus(SUMMARY_STEPS_ACTION[booking.status])
                    }
                  >
                    {SUMMARY_STEPS_ACTION[booking.status]}
                  </Button>
                </div>
              </div>
            </CardStyled>
          </DeliverCardDetail>
          <ChatContent>
            <SummaryPatientDetailCard
              patient={booking.patient}
              userId={booking.practitionerAppUserId}
            />
          </ChatContent>
        </DeliverWrapper>
      </DeliverDrugContent>
    </DeliverDrugContainer>
  );
};

export default DeliverDrug;
