import { Col, Row } from "antd";
import React from "react";
import Calendar from "../../components/Calendar";
import PractitionerInformation from "../../components/Card/Practitioner";
import Tabs from "../../components/Tabs/Profile";
import { useSelector } from "react-redux";
const { TabPane } = Tabs;

const ProfileMenu = ({ t }) => {
  const user = useSelector((state) => state.appUserOma);
  return (
    <Tabs>
      <TabPane tab={t("timeline")} key="1">
        <Calendar t={t} />
      </TabPane>
      <TabPane tab={t("profile")} key="2">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <PractitionerInformation practitioner={user.practitioner} t={t} />
          </Col>
        </Row>
      </TabPane>
      {/* <TabPane tab="Personal Health" key="3">
        Content of Tab Pane 3
      </TabPane>
      <TabPane tab="Smart Health" key="4">
        Content of Tab Pane 3
      </TabPane> */}
    </Tabs>
  );
};

export default ProfileMenu;
