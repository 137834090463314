import React from "react";
import { HEADER_DEFAULT_FORMS } from "../constant";
import { isNumberValidate, checkDateInvalid } from "../utils";
import EverLogo from "../../../images/EverLogo.png";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  DropDownWidget,
  TextVetical,
  HeaderContainer,
} from "../styles";
import { Row, Col, Space, Radio } from "antd";
import moment from "moment";
import {
  DISPLAY_CATEGORY,
  CATEGORY,
} from "../../Modal/CtmsWithdrawnFormModal/constant";

export default function SubjectDeviationForm(props) {
  const { t, form = {}, editable } = props;

  const renderNormalValue = (key, index, items) => {
    return (
      <Container
        style={{ backgroundColor: "white" }}
        className="shadow px-2 py-1"
      >
        {items.map((item) => {
          return (
            <a
              key={item.key}
              onClick={(e) => {
                handleChangeValue(key, index, item.key);
                e.preventDefault();
              }}
            >
              <Text className="py-1 my-1" key={item.key}>
                {t(item.label)}
              </Text>
            </a>
          );
        })}
      </Container>
    );
  };

  const handleChangeValue = (key, index, e) => {
    const dataArray = form.values.subjectDeviation;
    if (dataArray[index]) {
      dataArray[index][key] = e?.target?.value ?? e;
    }
    form.setFieldValue("subjectDeviation", dataArray);
  };

  return (
    <Container>
      <Text className="text-center" size="16px" color="#0000008C">
        {t(HEADER_DEFAULT_FORMS)}
      </Text>
      <Container className="px-5 mt-3 rounded border">
        <Image className="mt-4" src={EverLogo} />
        <Row gutter={[16, 16]} className="my-4" align="middle">
          <Col xs={12} md={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("subjects_initials")}:
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.subjectsInitials}
                padding="2px"
                onChange={form.handleChange("subjectsInitials")}
                maxLength={3}
              />
            </Space>
          </Col>
          <Col xs={12} md={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_subject_id")}:
              </Text>
              <Input
                disabled
                value={form.values?.subjectId}
                padding="2px"
                onChange={form.handleChange("subjectId")}
              />
            </Space>
          </Col>
          <Col xs={12} md={8}>
            <Row align="middle">
              <Text bold color="grey">
                {t("crf_page")}
              </Text>
              <Input
                disabled={!editable}
                className="ml-2"
                value={form.values?.page}
                padding="2px"
                width="60px"
                onChange={(e) => {
                  if (isNumberValidate(e.target.value)) {
                    form.setFieldValue("page", e.target.value);
                  }
                }}
                maxLength={1}
              />
              <Text bold className="ml-2" color="grey">
                {t("crf_of")}
              </Text>
              <Input
                disabled={!editable}
                className="ml-2"
                value={form.values?.of}
                padding="2px"
                width="60px"
                onChange={(e) => {
                  if (isNumberValidate(e.target.value)) {
                    form.setFieldValue("of", e.target.value);
                  }
                }}
                maxLength={1}
              />
            </Row>
          </Col>
        </Row>
        <HeaderContainer>
          <Text className="my-2 text-center" bold size="16px">
            {t(form.values?.type)}
          </Text>
        </HeaderContainer>
        <Container className="my-3" style={{ overflowX: "scroll" }}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  rowSpan={2}
                  style={{ width: 40 }}
                  className="text-center align-middle"
                >
                  #
                </th>
                <th rowSpan={2} className="align-middle text-center">
                  <Text size="12px">{t("crf_start_date")}</Text>
                </th>
                <th rowSpan={2} className="align-middle text-center">
                  <Text size="12px">{t("crf_end_date")}</Text>
                </th>
                <th rowSpan={2} className="align-middle text-center">
                  <Text size="12px">{t("description")}</Text>
                </th>
                <th rowSpan={2} className="align-middle text-center">
                  <Text size="12px">{t("category")}</Text>
                  <Text size="10px">({t("see_below")})</Text>
                </th>
                <th colSpan={3} className="align-middle text-center">
                  <Text size="12px" bold>
                    {t("does_the_deviation")}
                  </Text>
                </th>
                <th rowSpan={2} className="align-middle text-center">
                  <Text size="12px">{t("report_to_irb")}</Text>
                </th>
                <th rowSpan={2} className="align-middle text-center">
                  <TextVetical size="12px">{t("pi_initials")}</TextVetical>
                </th>
                <th rowSpan={2} className="align-middle text-center">
                  <TextVetical size="12px">{t("date_pi_initials")}</TextVetical>
                </th>
              </tr>
              <tr>
                <th
                  style={{ whiteSpace: "pre-line" }}
                  className="align-middle text-center"
                >
                  <Text size="10px">{t("impact_subject")}</Text>
                </th>
                <th
                  style={{ whiteSpace: "pre-line" }}
                  className="align-middle text-center"
                >
                  <Text size="10px">{t("affect_data")}</Text>
                </th>
                <th
                  style={{ whiteSpace: "pre-line" }}
                  className="align-middle text-center"
                >
                  <Text size="10px">{t("affect_subject")}</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(form.values?.subjectDeviation) &&
                form.values?.subjectDeviation.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th className="text-center">{index + 1}</th>
                      <td>
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.startDate)}
                          format="DD/MM/YY"
                          defaultValue={
                            item.startDate ? moment(item.startDate) : null
                          }
                          padding="2px"
                          width="100px"
                          onChange={(date) =>
                            handleChangeValue(
                              "startDate",
                              index,
                              moment(date).toISOString()
                            )
                          }
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.endDate)}
                          format="DD/MM/YY"
                          defaultValue={
                            item.endDate ? moment(item.endDate) : null
                          }
                          padding="2px"
                          width="100px"
                          onChange={(date) =>
                            handleChangeValue(
                              "endDate",
                              index,
                              moment(date).toISOString()
                            )
                          }
                        />
                      </td>
                      <td>
                        <Input
                          disabled={!editable}
                          value={item.description}
                          padding="2px"
                          onChange={(e) =>
                            handleChangeValue(
                              "description",
                              index,
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <Space direction="vertical">
                          <DropDownWidget
                            disabled={!editable}
                            width="50px"
                            value={DISPLAY_CATEGORY[item.category] ?? ""}
                            renderList={renderNormalValue(
                              "category",
                              index,
                              CATEGORY
                            )}
                          />
                          {item.category === "Other" && (
                            <Input
                              disabled={!editable}
                              value={item.otherCategoryReason}
                              padding="2px"
                              onChange={(e) =>
                                handleChangeValue(
                                  "otherCategoryReason",
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        </Space>
                      </td>
                      <td>
                        <Radio.Group
                          disabled={!editable}
                          onChange={(e) =>
                            handleChangeValue(
                              "impactSubjectSafety",
                              index,
                              e.target.value
                            )
                          }
                          value={item.impactSubjectSafety}
                        >
                          <Space className="mt-1" direction="vertical" size={0}>
                            <Radio value="yes">{t("radio_yes")}</Radio>
                            <Radio className="mt-1" value="no">
                              {t("radio_no")}
                            </Radio>
                            <Radio className="mt-1" value="not_applicable">
                              {t("not_applicable")}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </td>
                      <td>
                        <Radio.Group
                          disabled={!editable}
                          onChange={(e) =>
                            handleChangeValue(
                              "affectDataIntegrity",
                              index,
                              e.target.value
                            )
                          }
                          value={item.affectDataIntegrity}
                        >
                          <Space className="mt-1" direction="vertical" size={0}>
                            <Radio value="yes">{t("radio_yes")}</Radio>
                            <Radio className="mt-1" value="no">
                              {t("radio_no")}
                            </Radio>
                            <Radio className="mt-1" value="not_applicable">
                              {t("not_applicable")}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </td>
                      <td>
                        <Radio.Group
                          disabled={!editable}
                          onChange={(e) =>
                            handleChangeValue(
                              "affectSubjectWillingness",
                              index,
                              e.target.value
                            )
                          }
                          value={item.affectSubjectWillingness}
                        >
                          <Space className="mt-1" direction="vertical" size={0}>
                            <Radio value="yes">{t("radio_yes")}</Radio>
                            <Radio className="mt-1" value="no">
                              {t("radio_no")}
                            </Radio>
                            <Radio className="mt-1" value="not_applicable">
                              {t("not_applicable")}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </td>
                      <td>
                        <Radio.Group disabled={!editable} value={item.reported}>
                          <Radio
                            className="mb-2"
                            onClick={(e) => {
                              handleChangeValue(
                                "reported",
                                index,
                                item.reported !== "not_applicable"
                                  ? "not_applicable"
                                  : ""
                              );
                            }}
                            value="not_applicable"
                          >
                            {t("not_applicable")}
                          </Radio>
                        </Radio.Group>
                        <DatePicker
                          disabled={!editable || item.reported}
                          value={checkDateInvalid(item.reportedDate)}
                          format="DD/MM/YY"
                          defaultValue={checkDateInvalid(item.reportedDate)}
                          padding="2px"
                          width="100px"
                          onChange={(date) => {
                            handleChangeValue(
                              "reportedDate",
                              index,
                              moment(date).toISOString()
                            );
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          minwidth="50px"
                          disabled={!editable}
                          value={item.piInitials}
                          onChange={(e) =>
                            handleChangeValue(
                              "piInitials",
                              index,
                              e.target.value
                            )
                          }
                          padding="2px"
                        />
                      </td>
                      <td>
                        <DatePicker
                          disabled={!editable}
                          value={checkDateInvalid(item.piInitialsDate)}
                          format="DD/MM/YY"
                          defaultValue={checkDateInvalid(item.piInitialsDate)}
                          padding="2px"
                          width="100px"
                          onChange={(date) =>
                            handleChangeValue(
                              "piInitialsDate",
                              index,
                              moment(date).toISOString()
                            )
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Container>
        <Container className="mb-3">
          <Text className="mb-5">{t("withdrawn_footter")}</Text>
          <Space direction="vertical" size={4}>
            <Text bold>{t("category")}:</Text>
            <Text>{t("cateogory_clinic_1")}</Text>
            <Text>{t("cateogory_clinic_2")}</Text>
            <Text>{t("cateogory_clinic_3")}</Text>
            <Text>{t("cateogory_clinic_4")}</Text>
            <Text>{t("cateogory_clinic_5")}</Text>
            <Text>{t("cateogory_clinic_6")}</Text>
          </Space>
        </Container>
        <Space className="mb-4" size={25}>
          <Space>
            <Text bold color="grey">
              {t("crf_form_number")}
            </Text>
            <Input
              disabled={!editable}
              value={form.values?.formNumber}
              padding="2px"
              width="60px"
              maxLength={2}
              onChange={(e) => {
                if (isNumberValidate(e.target.value)) {
                  form.setFieldValue("formNumber", e.target.value);
                }
              }}
            />
          </Space>
          <Text>{t("crf_version_date")}: 01/29/2022</Text>
        </Space>
      </Container>
    </Container>
  );
}
