import React, { Component } from "react";
import { Table as TableAnt } from "antd";
import styled from "styled-components";

const Table = styled(TableAnt)`
  &&&& {
    thead.ant-table-thead > tr > th {
      background-color: ${(props) => props.theme.primaryText};
      border-radius: 0;
      color: white;
    }
    tr.ant-table-row {
      &:nth-child(odd) {
        td {
          background-color: #f8f8f8;
        }
      }
    }
    .ant-table-tbody > tr {
      &:hover {
        td {
          background-color: lightblue;
        }
      }
    }
    .ant-table-tbody > tr > td {
      padding: 10px;
      background-color: white;
      border: none;
      i {
        color: ${(props) => props.theme.primaryButton};
      }
    }
    .ant-table-placeholder {
      z-index: 0;
    }
  }
`;
export default class index extends Component {
  render() {
    return <Table pagination={{ pageSize: 7 }} {...this.props} />;
  }
}
