import React from "react";
import { Row } from "antd";
import { BookOutlined } from "@ant-design/icons";

import Table from "./table";
import { Container, HeaderWrapper, Text } from "./styles";
import SearchBox from "../SearchBox";

const SupportDashboard = (props) => {
  const {
    handleToSummary,
    setSearchBy,
    setSearchText,
    onSearch,
    t,
    handleTableChange,
  } = props;
  return (
    <Container>
      <HeaderWrapper>
        <Row align="middle">
          <BookOutlined />
          <Text bold style={{ paddingTop: 10 }}>
            {t("support_dashboard")}
          </Text>
        </Row>
        <SearchBox
          setSearchBy={setSearchBy}
          setSearchText={setSearchText}
          onSearch={onSearch}
          t={t}
        />
      </HeaderWrapper>
      <Table
        t={t}
        handleToSummary={handleToSummary}
        handleTableChange={handleTableChange}
        isActive={true}
        isHistoryBooking={false}
        title="นัดหมายทั้งหมด"
      />
    </Container>
  );
};

export default SupportDashboard;
("");
