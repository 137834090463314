import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/DrugIngredientGroups";

async function getDrugIngredient(id) {
  let array = [];
  const { data } = await http.get(
    `${apiEndpoint}?filter[where][symptomGroupId]=${id}&filter[include]=drugIngredient`
  );
  for (const item of data) {
    array.push({
      ...item,
      medications: await findMedicationByOrg(item.id),
    });
  }
  return array;
}

async function findMedicationByOrg(id) {
  const { data } = await http.get(
    `${apiEndpoint}/${id}/findMedicationByOrg?filter[where][orgId]=4`
  );
  return data;
}

export { getDrugIngredient };
