export const NOTIFICATION = {
  success: (language = "en") => ({
    body: "Congratulations! Your account has been approved.",
    title: "Alert",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
  failed: (language = "en") => ({
    body: "Sorry, your account has been rejected. Please check your information before re-applying again.",
    title: "Alert",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
};

export const NOTIFICATION_DATA = {
  success: {
    auth: true,
    page: "Home",
  },
  failed: {
    auth: false,
    page: "Profile",
  },
};
