import { call, put, takeEvery } from "redux-saga/effects";
import { UserFcmToken } from "../../api";
import * as type from "../types";

function* sendNotification({ id, notification, detail }) {
  try {
    yield call(UserFcmToken.sendNotification, {
      id,
      notification,
      detail,
    });
    yield put({ type: type.SEND_NOTIFICATION_SUCCESS });
  } catch (e) {
    yield put({ type: type.SEND_NOTIFICATION_FAILED, error: e.message });
  }
}

function* userFcmTokenSaga() {
  yield takeEvery(type.SEND_NOTIFICATION_REQUESTED, sendNotification);
}

export default userFcmTokenSaga;
