import { Button, Popconfirm, Row } from "antd";
import React from "react";

const action = ({ t, status, id, handleToSummary }) => {
  return (
    <Row type="flex" gutter={[16, 8]}>
      {status &&
        ["DOCTOR_PENDING_NOTE", "COMMUNITY_PHARMACIST_PENDING_NOTE"].includes(
          status
        ) && (
          <Popconfirm
            title={t("are_you_sure_to_redirect_to_a_summary_page")}
            onConfirm={() => handleToSummary(id)}
          >
            <Button type="primary">{t("summary")}</Button>
          </Popconfirm>
        )}
    </Row>
  );
};

export default action;
