import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/DrugTimeEvents";

async function getDrugTimeEvent() {
  const { data } = await http.get(`${apiEndpoint}`);
  return data;
}

export { getDrugTimeEvent };
