import React from "react";

export const SMBGPie = {
  labels: ["น้ำตาลปกติ", "น้ำตาลต่ำ", "น้ำตาลสูง"],
  datasets: [
    {
      label: "ค่าน้ำตาล",
      backgroundColor: ["green", "blue", "red"],
      hoverBackgroundColor: ["green", "blue", "red"],
      data: [],
    },
  ],
};

export const HeartRatePie = {
  labels: [
    "อัตราการเต้นของหัวใจปกติ",
    "อัตราการเต้นของหัวใจต่ำ",
    "อัตราการเต้นของหัวใจสูง",
  ],
  datasets: [
    {
      label: "อัตราการเต้นของหัวใจ",
      backgroundColor: ["green", "blue", "red"],
      hoverBackgroundColor: ["green", "blue", "red"],
      data: [],
    },
  ],
};
export const TemperaturePie = {
  labels: [
    "อุณหภูมิของร่างกายปกติ",
    "อุณหภูมิของร่างกายต่ำ",
    "อุณหภูมิของร่างกายสูง",
  ],
  datasets: [
    {
      label: "อุณหภูมิของร่างกาย",
      backgroundColor: ["green", "blue", "red"],
      hoverBackgroundColor: ["green", "blue", "red"],
      data: [],
    },
  ],
};

export const OxygenPie = {
  labels: [
    "ความเข้มข้นของออกซิเจนปกติ",
    "ความเข้มข้นของออกซิเจนต่ำ",
    "ความเข้มข้นของออกซิเจนสูง",
  ],
  datasets: [
    {
      label: "ความเข้มข้นของออกซิเจน",
      backgroundColor: ["green", "blue", "red"],
      hoverBackgroundColor: ["green", "blue", "red"],
      data: [],
    },
  ],
};

export const HBPMPie = {
  labels: ["ความดันปกติ", "ความดันต่ำ", "ความดันสูง"],
  datasets: [
    {
      label: "ความดัน",
      backgroundColor: ["green", "blue", "red"],
      hoverBackgroundColor: ["green", "blue", "red"],
      data: [0],
    },
  ],
};

export const BMIPie = {
  labels: ["เกณฑ์ดี", "ต่ำกว่าเกณฑ์", "สูงกว่าเกณฑ์"],
  datasets: [
    {
      label: "ค่าดัชนีมวลกาย",
      backgroundColor: ["green", "blue", "red"],
      hoverBackgroundColor: ["green", "blue", "red"],
      data: [],
    },
  ],
};

export const SMBGLine = {
  options: {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [],
    },
    annotations: {
      yaxis: [
        {
          y: 139,
          y2: 600,
          borderColor: "#000",
          fillColor: "red",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 139,
          borderColor: "#000",
          fillColor: "#94e194",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 0,
          borderColor: "#000",
          fillColor: "blue",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
      ],
    },
  },
  series: [
    {
      name: "Glucose Value",
      data: [],
    },
  ],
};

export const HeartRateLine = {
  options: {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [],
    },
    annotations: {
      yaxis: [
        {
          y: 139,
          y2: 600,
          borderColor: "#000",
          fillColor: "red",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 139,
          borderColor: "#000",
          fillColor: "#94e194",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 0,
          borderColor: "#000",
          fillColor: "blue",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
      ],
    },
  },
  series: [
    {
      name: "HeartRate Times",
      data: [],
    },
  ],
};

export const OxygenLine = {
  options: {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [],
    },
    annotations: {
      yaxis: [
        {
          y: 139,
          y2: 600,
          borderColor: "#000",
          fillColor: "red",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 139,
          borderColor: "#000",
          fillColor: "#94e194",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 0,
          borderColor: "#000",
          fillColor: "blue",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
      ],
    },
  },
  series: [
    {
      name: "Oxygen Concentration",
      data: [],
    },
  ],
};

export const HBPMLine = {
  options: {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [],
    },
    annotations: {
      yaxis: [
        {
          y: 139,
          y2: 600,
          borderColor: "#000",
          fillColor: "red",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 139,
          borderColor: "#000",
          fillColor: "#94e194",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 0,
          y2: 0,
          borderColor: "#000",
          fillColor: "blue",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
      ],
    },
  },
  series: [
    {
      name: "Pressure Value",
      data: [],
    },
  ],
};
export const TemperatureLine = {
  options: {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [],
    },
    annotations: {
      yaxis: [
        {
          y: 139,
          y2: 600,
          borderColor: "#000",
          fillColor: "red",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 139,
          borderColor: "#000",
          fillColor: "#94e194",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 0,
          y2: 0,
          borderColor: "#000",
          fillColor: "blue",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
      ],
    },
  },
  series: [
    {
      name: "Temperature Value",
      data: [],
    },
  ],
};

export const BMILine = {
  options: {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [],
    },
    annotations: {
      yaxis: [
        {
          y: 139,
          y2: 600,
          borderColor: "#000",
          fillColor: "red",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 139,
          borderColor: "#000",
          fillColor: "#94e194",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
        {
          y: 60,
          y2: 0,
          borderColor: "#000",
          fillColor: "blue",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
            },
          },
        },
      ],
    },
  },
  series: [
    {
      name: "BMI Value",
      data: [],
    },
  ],
};

export const LabHeaders = [
  {
    title: "Lab/Date",
    key: "date",
  },
  { title: "FBS", key: "1905" },
  { title: "HbA1c", key: "1007" },
  { title: "LDL", key: "1014" },
  { title: "Cr", key: "1010" },
  { title: "eGFR", key: "1987" },
  { title: "Microalbuminuria", key: "0001" },
  { title: "AST", key: "1026" },
  { title: "ALT", key: "1027" },
  { title: "ALP", key: "1028" },
  { title: "FT4", key: "1957" },
  { title: "TSH", key: "1960" },
  { title: "Ca", key: "0002" },
  { title: "PO4", key: "0003" },
  { title: "Alb", key: "1030" },
];

export const GlucoseValue = {
  min: 0,
  max: 0,
  average: 0,
};

export const GlucoseTrend = {
  low: 0,
  high: 0,
  mid: 0,
  pie: SMBGPie,
};

export const GlucoseGraph = {
  defaultLow: 0,
  defaultHigh: 0,
  lines: SMBGLine,
};

export const HeartRateValue = {
  min: 0,
  max: 0,
  average: 0,
};

export const HeartRateTrend = {
  low: 0,
  high: 0,
  mid: 0,
  pie: HeartRatePie,
};

export const HeartRateGraph = {
  defaultLow: 60,
  defaultHigh: 100,
  lines: HeartRateLine,
};

export const TemperatureValue = {
  min: 0,
  max: 0,
  average: 0,
};

export const TemperatureTrend = {
  low: 0,
  high: 0,
  mid: 0,
  pie: TemperaturePie,
};

export const TemperatureGraph = {
  defaultLow: 35.4,
  defaultHigh: 40,
  lines: TemperatureLine,
};

export const OxygenValue = {
  min: 0,
  max: 0,
  average: 0,
};

export const OxygenTrend = {
  low: 0,
  high: 0,
  mid: 0,
  pie: OxygenPie,
};

export const OxygenGraph = {
  defaultLow: 60,
  defaultHigh: 100,
  lines: OxygenLine,
};

export const PressureValue = {
  systolic: {
    min: 0,
    max: 0,
    average: 0,
  },
  diastolic: {
    min: 0,
    max: 0,
    average: 0,
  },
};

export const PressureTrend = {
  low: 0,
  high: 0,
  mid: 0,
  pie: HBPMPie,
};

export const PressureGraph = {
  defaultLowAbove: 0,
  defaultHighAbove: 0,
  defaultLowBelow: 0,
  defaultHighBelow: 0,
  lines: HBPMLine,
};

export const BmiValue = {
  min: 0,
  max: 0,
  average: 0,
};

export const BmiGraph = {
  defaultLow: 0,
  defaultHigh: 0,
  lines: BMILine,
};

export const BmiTrend = {
  low: 0,
  high: 0,
  mid: 0,
  pie: BMIPie,
};
