import React from "react";
import { Col, Row } from "antd";
import Profile from "../../components/Card/Profile";
import ProfileList from "../ProfileMenu";
import { Container } from "./styles";
const Dashboard = ({ practitionerProfile, profileImage, t }) => {
  return (
    <Container>
      <Row gutter={24}>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 0 }}
          lg={{ span: 0 }}
          xl={{ span: 6 }}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              {practitionerProfile && (
                <Profile
                  name={`${practitionerProfile.firstName} ${practitionerProfile.lastName}`}
                  email={""}
                  profileImage={profileImage}
                />
              )}
            </Col>
            {/* <Col span={24}>
                  <Menu />
                </Col> */}
          </Row>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 16 }}
          xl={{ span: 12 }}
        >
          <ProfileList t={t} />
        </Col>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 0 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
          style={{ marginTop: "60px" }}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              {/* <Widget />
                </Col>
                <Col span={24}>
                  <Friend />
                </Col>
                <Col span={24}>
                  <Group /> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default Dashboard;
