import React from "react";
import { Card, Row, Col } from "antd";
import Chart from "react-apexcharts";

const GlucoseGraph = (props) => {
  const { graphs, defaultAbove, defaultBelow, t } = props;
  const { lines } = graphs;

  return (
    <Card className="mt-2" title={t("blood_pressure_trend")}>
      <Row type="flex" gutter={[8, 8]}>
        <Col>
          <Row>
            <Col span={24} className="mb-2">
              <div>{t("high_pressure_threshold")}</div>
            </Col>
            <Col span={10}>{`${defaultAbove[0]} - ${defaultAbove[1]}`}</Col>
          </Row>
          <Row>
            <Col span={24} className="mb-2">
              <div>{t("low_pressure_threshold")}</div>
            </Col>
            <Col span={10}>{`${defaultBelow[0]} - ${defaultBelow[1]}`}</Col>
          </Row>
        </Col>
        <Col>
          <Chart
            options={lines.options}
            series={lines.series}
            type="line"
            width="550"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default GlucoseGraph;
