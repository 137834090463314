import React, { useEffect, useState } from "react";
import GlucoseValueCard from "./GlucoseValueCard";
import GlucoseTrendCard from "./GlucoseTrendCard";
import GlucoseGraphCard from "./GlucoseGraphCard";
import { Container } from "./styles";
import { GlucoseValue, GlucoseTrend, GlucoseGraph } from "../constant";
const SMBG = ({ glucoses, filterReport, defaultGlucoses, isVideoCall, t }) => {
  const [values, setValues] = useState(GlucoseValue);
  const [trends, setTrends] = useState(GlucoseTrend);
  const [graphs, setGraphs] = useState(GlucoseGraph);

  useEffect(() => {
    if (
      glucoses.data &&
      glucoses.data.arr.length &&
      glucoses.data.dates.length
    ) {
      checkGlucoseValue();
    } else {
      setValues(GlucoseValue);
      setTrends(GlucoseTrend);
      setGraphs(GlucoseGraph);
    }
  }, [glucoses]);

  const findMin = (arr) => Math.min(...arr);
  const findMax = (arr) => Math.max(...arr);
  const findAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

  const checkGlucoseValue = () => {
    const { arr } = glucoses.data;
    setValues({
      min: findMin(arr),
      max: findMax(arr),
      average: findAverage(arr),
    });
    checkGlucoseTrend();
  };

  const checkGlucoseTrend = (defaultParams) => {
    if (glucoses.data && defaultGlucoses) {
      const { arr } = glucoses.data;
      let lowest = defaultGlucoses[0];
      let highest = defaultGlucoses[1];
      if (defaultParams && defaultParams.length) {
        lowest = defaultParams[0];
        highest = defaultParams[1];
      }
      const trend = { ...GlucoseTrend };
      arr.map((value) => {
        if (Number(value) < Number(lowest)) {
          trend.low++;
        } else if (
          Number(value) >= Number(lowest) &&
          Number(value) <= Number(highest)
        ) {
          trend.mid++;
        } else if (Number(value) > Number(highest)) {
          trend.high++;
        }
      });
      setTrends((prev) => ({
        ...trend,
        pie: {
          ...prev.pie,
          datasets: [
            {
              ...prev.pie.datasets[0],
              data: [trend.mid, trend.low, trend.high],
            },
          ],
        },
      }));
      checkGlucoseGraph(lowest, highest);
    }
  };

  const checkGlucoseGraph = (lowest, highest) => {
    const { arr, periods, reasons, dates } = glucoses.data;
    setGraphs((prev) => ({
      defaultLow: lowest,
      defaultHigh: highest,
      lines: {
        options: {
          ...prev.lines.options,
          xaxis: {
            categories: dates,
          },
          annotations: {
            yaxis: [
              { ...prev.lines.options.annotations.yaxis[0], y: highest },
              {
                ...prev.lines.options.annotations.yaxis[1],
                y: lowest,
                y2: highest,
              },
              {
                ...prev.lines.options.annotations.yaxis[2],
                y: lowest,
              },
            ],
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="arrow_box" style="padding: 16px">
                      <p>Glucose Value: <b>${
                        series[seriesIndex][dataPointIndex]
                      }</b></p>
                      <p>Reason: <b>${reasons[dataPointIndex] || "-"}</b></p>
                      <p>Period: <b>${periods[dataPointIndex] || "-"}</b></p>
                      <p>Date: <b>${dates[dataPointIndex] || "-"}</b></p>
                      </div>`;
            },
          },
        },
        series: [
          {
            name: "Glucose Value",
            data: arr,
          },
        ],
      },
    }));
  };

  return (
    <Container>
      <GlucoseValueCard
        t={t}
        values={values}
        filterReport={(start, end) => filterReport(start, end, 3)}
      />
      <GlucoseTrendCard t={t} trends={trends} isVideoCall={isVideoCall} />
      <GlucoseGraphCard
        t={t}
        graphs={graphs}
        defaultGlucoses={defaultGlucoses}
      />
    </Container>
  );
};

export default SMBG;
