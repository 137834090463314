import { call, put, takeEvery } from "redux-saga/effects";
import { Questionaires } from "../../api";
import * as type from "../types";

function* updateQuestionairesById({ id, payload }) {
  try {
    const result = yield call(
      Questionaires.updateQuestionnaireById,
      id,
      payload
    );
    yield put({ type: type.UPDATE_QUESTIONAIRE_BY_ID_SUCCESS, data: result });
  } catch (e) {
    yield put({
      type: type.UPDATE_QUESTIONAIRE_BY_ID_FAILED,
      error: e.message,
    });
  }
}

function* getNewCasesQuestionaires() {
  try {
    const result = yield call(Questionaires.getNewCaseQuestionnaire);
    yield put({
      type: type.GET_ALL_NEW_CASE_QUESTIONAIRE_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_ALL_NEW_CASE_QUESTIONAIRE_FAILED,
      error: e.message,
    });
  }
}

function* getRegisteredQuestionaires() {
  try {
    const result = yield call(Questionaires.getRegisteredQuestionnaire);
    yield put({
      type: type.GET_ALL_REGISTERED_QUESTIONAIRE_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_ALL_REGISTERED_QUESTIONAIRE_FAILED,
      error: e.message,
    });
  }
}

function* questionairesSaga() {
  yield takeEvery(
    type.UPDATE_QUESTIONAIRE_BY_ID_REQUESTED,
    updateQuestionairesById
  );
  yield takeEvery(
    type.GET_ALL_NEW_CASE_QUESTIONAIRE_REQUESTED,
    getNewCasesQuestionaires
  );
  yield takeEvery(
    type.GET_ALL_REGISTERED_QUESTIONAIRE_REQUESTED,
    getRegisteredQuestionaires
  );
}

export default questionairesSaga;
