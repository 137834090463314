import { call, put, takeEvery } from "redux-saga/effects";
import { Medication } from "../../api";
import * as type from "../types";

function* getMedication() {
  try {
    const result = yield call(Medication.getMedication);
    yield put({ type: type.GET_MEDICATION_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_MEDICATION_FAILED, error: e.message });
  }
}

function* getMedicationWithKeyword(keyword, page) {
  try {
    const result = yield call(
      Medication.getMedicationWithKeyword,
      keyword,
      page
    );
    yield put({ type: type.GET_MEDICATION_KEYWORD_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_MEDICATION_KEYWORD_FAILED, error: e.message });
  }
}

function* getDrugTimeEvent() {
  try {
    const result = yield call(DrugTimeEvent.getDrugTimeEvent);
    yield put({ type: type.GET_DRUG_TIME_EVENT_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_DRUG_TIME_EVENT_FAILED, error: e.message });
  }
}

function* medicationSaga() {
  yield takeEvery(type.GET_MEDICATION_REQUESTED, getMedication);
  yield takeEvery(
    type.GET_MEDICATION_KEYWORD_REQUESTED,
    getMedicationWithKeyword
  );
  yield takeEvery(type.GET_DRUG_TIME_EVENT_REQUESTED, getDrugTimeEvent);
}

export default medicationSaga;
