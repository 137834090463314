import React from "react";
import {
  CardStat,
  Container,
  Text,
  Bullet,
  CardTable,
  HeaderWrapper,
  Search,
  Button,
} from "./styles";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { FaChevronDown, FaEllipsisV } from "react-icons/fa";
import { Pie } from "react-chartjs-2";
import { Col, Row, Tag } from "antd";
import Table from "../Table";
import moment from "moment";
import CovidPatientHistoryModal from "../Modal/CovidPatientHistoryModal";

const DashboardCovid = (props) => {
  const {
    t,
    i18n,
    colorCode: COLOR_CODE,
    questionaires,
    HIFormList,
    newCases,
    totalMapData,
    totalRegsitered,
    totalNewCases,
    setCurrentData,
    currentData,
    setModalVisible,
    modalVisible,
    setTableIndex,
    tableIndex,
    dataGraphCovid,
  } = props;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBDLYwoxv5GtDACl-UE5M4aaIN77Q1MYno",
  });

  const COLUMNS = [
    {
      title: t("covid_case"),
      key: "id",
      dataIndex: "id",
      width: 150,
      align: "center",
      render: (_) => {
        return t("home_isolation");
      },
    },
    {
      title: t("name_lastName"),
      key: "firstName",
      width: 150,
      align: "center",
      render: (record) => {
        return `${record?.firstName} ${record?.lastName}`;
      },
    },
    {
      title: t("address"),
      key: "address",
      width: 300,
      align: "center",
      render: (record) => {
        return record.address ?? "-";
      },
    },
    {
      title: t("symptom_tracing_date"),
      key: "dailyReport",
      width: 150,
      align: "center",
      render: ({ createTime }) => {
        const sequence = moment(createTime).isValid()
          ? moment()
              .startOf("date")
              .diff(moment(createTime).startOf("date"), "days")
          : "";
        return sequence
          ? `${sequence} ` + t("covid_tracing_date")
          : `1 ${t("covid_tracing_date")}`;
      },
    },
    {
      title: t("medication_status"),
      width: 150,
      align: "center",
      render: (_) => {
        return <Tag color="default">{t("waiting_medicine")}</Tag>;
      },
    },
    {
      title: t("symptom"),
      key: "resultIndex",
      width: 120,
      align: "center",
      sorter: {
        compare: (a, b) => a.resultIndex - b.resultIndex,
      },
      render: ({ result }) => {
        return <Tag color={result}>{t(`covid_${result}`)}</Tag>;
      },
    },
    {
      title: t("history"),
      width: 130,
      key: "typeId",
      align: "center",
      render: (record) => (
        <Button
          block
          shape="round"
          onClick={() => {
            setCurrentData(record);
            setModalVisible(true);
          }}
        >
          {t("history")}
        </Button>
      ),
    },
  ];

  const COLUMNS_NEWCASE = [
    {
      title: t("covid_case"),
      width: 150,
      key: "id",
      align: "center",
      render: (record) => {
        return record.typeId === 2 ? t("home_isolation") : t("risk_assessment");
      },
    },
    {
      title: t("name_lastName"),
      key: "firstName",
      width: 150,
      align: "center",
      render: (record) => {
        return record?.firstName && record?.lastName
          ? `${record?.firstName} ${record?.lastName}`
          : "-";
      },
    },
    {
      title: t("address"),
      key: "address",
      width: 300,
      align: "center",
      render: (record) => {
        return record.address ?? "-";
      },
    },
    {
      title: t("status"),
      key: "resultIndex",
      width: 120,
      align: "center",
      sorter: {
        compare: (a, b) => a.resultIndex - b.resultIndex,
      },
      render: ({ result }) => {
        return (
          <Tag color={COLOR_CODE[result] ?? "default"}>
            {result ? t(`covid_${result}`) : t("nothing")}
          </Tag>
        );
      },
    },
    {
      title: t("history"),
      width: 130,
      key: "typeId",
      align: "center",
      render: (record) => (
        <Button
          block
          shape="round"
          onClick={() => {
            setCurrentData(record);
            setModalVisible(true);
          }}
        >
          {t("history")}
        </Button>
      ),
    },
  ];

  return (
    <>
      <CovidPatientHistoryModal
        data={currentData}
        setVisible={setModalVisible}
        visible={modalVisible}
        colorCode={COLOR_CODE}
        i18n={i18n}
        t={t}
      />
      <Container className="pr-4 pl-4 pt-5 pb-5">
        <Row className="gutter-row" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={8}>
            <CardStat
              title={t("covid_title")}
              extra={<FaEllipsisV color="#C2C9D1" />}
            >
              <Row align="middle">
                <Col offset={2} span={10}>
                  <Text color="#00000080">
                    {`${t("total_case")} (${moment().format("DD/MM/YYYY")})`}
                  </Text>
                  <Text size={40} bold>
                    {totalNewCases + totalRegsitered}
                  </Text>
                </Col>
                <Col span={12}>
                  <Pie
                    data={dataGraphCovid(t, totalNewCases, totalRegsitered)}
                    options={{
                      legend: {
                        display: false,
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row justify="space-between" className="mt-4">
                <Col span={8}>
                  <Row justify="center" align="middle">
                    <Bullet color="#80DDF2" />{" "}
                    <Text color="#00000080">{t("new_case")}</Text>
                  </Row>
                  <Text className="text-center" size={20} bold>
                    {totalNewCases}
                  </Text>
                </Col>
                <Col span={8}>
                  <Row justify="center" align="middle">
                    <Bullet color="#00BAE5" />{" "}
                    <Text color="#00000080">{t("waiting_for_test_again")}</Text>
                  </Row>
                  <Text className="text-center" size={20} bold>
                    0
                  </Text>
                </Col>
                <Col span={8}>
                  <Row justify="center" align="middle">
                    <Bullet color="#008BAC" />{" "}
                    <Text color="#00000080">{t("confirmed_infection")}</Text>
                  </Row>
                  <Text className="text-center" size={20} bold>
                    {totalRegsitered}
                  </Text>
                </Col>
              </Row>
            </CardStat>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={16}>
            <CardTable minheight={250}>
              {isLoaded ? (
                <GoogleMap
                  options={{ disableDefaultUI: true }}
                  center={{ lat: 13.7244416, lng: 100.3529173 }}
                  zoom={6}
                  mapContainerStyle={{
                    display: "contents",
                  }}
                >
                  {totalMapData.map((item, index) => {
                    if (item.marker) {
                      const position = {
                        lat: item.marker?.latitude,
                        lng: item.marker?.longitude,
                      };
                      return (
                        <Marker
                          key={index}
                          position={position}
                          icon={{
                            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                          }}
                        />
                      );
                    }
                  })}
                </GoogleMap>
              ) : null}
            </CardTable>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-5" span={24}>
            <CardTable>
              <Row>
                <Col
                  sm={24}
                  xs={24}
                  md={10}
                  lg={6}
                  xl={4}
                  className="border-right"
                >
                  <Container className="p-3">
                    <Row justify="space-between" align="middle">
                      <Text bold size={16} color="#00000073">
                        {t("covid_case")}
                      </Text>
                      <FaChevronDown size={18} color="#C2C9D1" />
                    </Row>
                  </Container>
                  <Row>
                    <Button
                      color={tableIndex === 0 ? "#F2FCFE" : "#FFF"}
                      className="col-12"
                      type="text"
                      key={0}
                      onClick={() => setTableIndex(0)}
                    >
                      <Text
                        active={tableIndex === 0}
                        bold={tableIndex === 0}
                        className="text-left ml-3"
                      >
                        {t("new_case")}
                      </Text>
                    </Button>
                    <Button
                      color={tableIndex === 1 ? "#F2FCFE" : "#FFF"}
                      className="col-12 active"
                      type="text"
                      key={1}
                      onClick={() => setTableIndex(1)}
                    >
                      <Text
                        active={tableIndex === 1}
                        bold={tableIndex === 1}
                        className="text-left ml-3"
                      >
                        {t("registered_cases")}
                      </Text>
                    </Button>
                  </Row>
                </Col>
                <Col sm={24} xs={24} md={14} lg={18} xl={20}>
                  <HeaderWrapper>
                    <Text size={24} bold>
                      {tableIndex === 0 ? t("new_case") : t("registered_cases")}
                    </Text>
                    <Search style={{ width: 200, marginLeft: "auto" }} />
                  </HeaderWrapper>
                  {tableIndex === 0 ? (
                    <Table
                      columns={COLUMNS_NEWCASE}
                      loading={questionaires.todayCases?.loading}
                      dataSource={newCases}
                    />
                  ) : (
                    <Table
                      columns={COLUMNS}
                      loading={questionaires.registeredCases?.loading}
                      dataSource={HIFormList}
                    />
                  )}
                </Col>
              </Row>
            </CardTable>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardCovid;
