import { Tabs as TabsBase } from "antd";
import styled from "styled-components";

const Tabs = styled(TabsBase)`
  color: #3f98d2;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  .ant-tabs-tab {
    padding: 10px !important;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 16px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    background: linear-gradient(
        0deg,
        rgba(63, 152, 210, 0.08),
        rgba(63, 152, 210, 0.08)
      ),
      #ffffff;
    border: 1px solid rgba(63, 152, 210, 0.5);
    border-radius: 10px;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
`;

export default Tabs;
