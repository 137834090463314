import React from "react";
import { Space, Checkbox } from "antd";
import { Container, Text, Icon, CollapseCustom } from "../styles";
import { GiHealthNormal } from "react-icons/all";
import "../styles.css";

const { Panel } = CollapseCustom;

const ExclusionInclusionCriteria = (props) => {
  const { t, inclusion, exclusion, inAndExCriteriaForm } = props;

  return (
    <>
      <Container>
        <Space align="center">
          <Icon component={GiHealthNormal} size="30px" color="#2BA796" />
          <Text primary className="ml-2" bold size="24px">
            {t("inclusion_exclusion_criteria")}
          </Text>
        </Space>
      </Container>
      <Container className="container-inclusion-exclusion mt-4">
        <CollapseCustom defaultActiveKey={["1"]} onChange={() => {}}>
          <Panel
            key="1"
            header={
              <Text primary bold size="16px">
                Inclusion criteria (7)
              </Text>
            }
          >
            <Container>
              {inclusion.map((item, index) => {
                return (
                  <Container className="mb-3" key={index}>
                    <Checkbox
                      checked={inAndExCriteriaForm.values[item.key]}
                      onChange={inAndExCriteriaForm.handleChange(item.key)}
                    >
                      <Text style={{ whiteSpace: "pre-wrap" }}>
                        {t(item.title)}
                      </Text>
                    </Checkbox>
                  </Container>
                );
              })}
            </Container>
          </Panel>
          <Panel
            header={
              <Text primary bold size="16px">
                Exclusion criteria (11)
              </Text>
            }
            key="2"
          >
            {exclusion.map((item, index) => {
              return (
                <Container className="mb-3" key={index}>
                  <Checkbox
                    checked={inAndExCriteriaForm.values[item.key]}
                    onChange={inAndExCriteriaForm.handleChange(item.key)}
                  >
                    <Text style={{ whiteSpace: "pre-wrap" }}>
                      {t(item.title)}
                    </Text>
                  </Checkbox>
                </Container>
              );
            })}
          </Panel>
        </CollapseCustom>
      </Container>
    </>
  );
};

export default ExclusionInclusionCriteria;
