import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Home";
import PatientTeleDetail from "../../components/PatientTeleDetail";

const PatientMonitoringDetail = (props) => {
  const dispatch = useDispatch();
  const userOma = useSelector((state) => state.appUserOma);
  const dataSource = [
    {
      id: "1",
      first_name: "Sitthi",
      last_name: "Thammawong",
      cId: "1100000010100",
      email: "sitthi@everapp.io",
      tel: "0917374500",
    },
  ];
  return (
    <Layout
      {...props}
      render={(props) => (
        <PatientTeleDetail
          dataSource={dataSource}
          {...props}
          style={{ width: "100%" }}
        />
      )}
    />
  );
};
export default PatientMonitoringDetail;
