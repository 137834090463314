import React from "react";
import { Modal } from "antd";
import Table from "../../bookingTable/table";
const Covid = (props) => {
  const { visible, setVisible, treatment, t } = props;

  return (
    <Modal
      width={1000}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Table
        loading={treatment.loading}
        dataSource={treatment.history.isSuccess ? treatment.history.data : []}
        isActive={false}
        t={t}
      />
    </Modal>
  );
};

export default Covid;
