import { FaPenSquare } from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";
import { Typography, Button } from "antd";
import { CardSummaryStyled, NoteBox } from "./styles";
const { Title } = Typography;

const PrescriptionNoteCard = (props) => {
  const { booking, setVisiblePrescriptionNoteModal, t } = props;
  return (
    <CardSummaryStyled
      title={<Title level={4}>{t("prescription")}</Title>}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setVisiblePrescriptionNoteModal(true)}
          className="d-flex justify-content-center align-items-center summary-button"
        >
          <p className="m-0 pl-2">{t("add_prescription_note")}</p>
        </Button>
      }
      style={{ height: "100%" }}
      bodyStyle={{ height: "30vh" }}
    >
      {booking.current.prescriptionNote ? (
        <NoteBox>
          <p>{booking.current.prescriptionNote}</p>
        </NoteBox>
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-column text-center h-100">
          <FaPenSquare style={{ fontSize: "36px", color: "#757575" }} />
          <p className="mt-2 mb-5">{t("not_found_note")}</p>
        </div>
      )}
    </CardSummaryStyled>
  );
};

export default PrescriptionNoteCard;
