import { Badge, Card } from "antd";
import styled from "styled-components";

export const BookingCard = styled(Card)`
  overflow: hidden;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%) !important;
  border-radius: 10px !important;
  cursor: pointer;
  &:hover {
    box-shadow: 0 !important;
    .arrow-hover {
      left: -15px;
    }
  }
  .ant-card-body {
    padding: 0;
    background-color: ${(props) => props.backgroundColor};
  }
`;

export const StatusBadge = styled(Badge)`
  .ant-badge-count {
    border-radius: 2px !important;
    background-color: ${(props) => props.bg || "#e0e0e0"};
  }
  margin-bottom: 0.5rem !important;
`;

export const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap !important;
`;

export const BoxImage = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`;
export const BoxDetail = styled.div`
  padding-top: 1rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  max-width: calc(100% - 70px);
`;

export const HoverArrow = styled.div`
  transition: all 0.3s ease-out;
  cursor: pointer;
  border-radius: 50% 0 0 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 107px;
  height: 107px;
  position: absolute;
  left: 15px;
  padding: 10px;
  font-size: 20px;
  background-color: #038fde;
  z-index: 3;
`;

export const BoxArrow = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BoxEllipsis = styled.div`
  cursor: pointer;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
`;

export const HeadTruncate = styled.h4`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Para = styled.p`
  color: #8c8c8c !important;
`;
