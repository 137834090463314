import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { HEADER_DEFAULT_FORMS } from "../constant";
import styles, { TableContainer, TitlePDFFile } from "../styles";
import CheckIcon from "../assets/CheckIcon";
import {
  CHECK_BOX_LIST_EXCLUSION,
  CHECK_BOX_LIST_INCLUSION,
} from "../../ClinicTrialParticipantRegister/constant";
import { isEmpty } from "lodash";
import moment from "moment";
export default function EligibilityCriteriaPreview(props) {
  const CHECK_BOX_EXCLUSION_SECTION_1 = CHECK_BOX_LIST_EXCLUSION.slice(
    0,
    CHECK_BOX_LIST_EXCLUSION.length - 2
  );
  const CHECK_BOX_EXCLUSION_SECTION_2 = CHECK_BOX_LIST_EXCLUSION.slice(
    CHECK_BOX_LIST_EXCLUSION.length - 2,
    CHECK_BOX_LIST_EXCLUSION.length
  );
  const { t, data } = props;
  return (
    <Document>
      <Page size="A4">
        <Text style={styles.header}>{t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <TitlePDFFile t={t} />
          <View style={styles.titleHeader}>
            <Text style={styles.dateSignedText}>
              {t("subjects_initials")}: {data.subjectsInitials || "-"}
            </Text>
            <Text style={styles.dateSignedText}>
              {t("crf_subject_id")}: {data.subjectId}
            </Text>
            <Text style={styles.timeText}>
              {t("crf_exam_date")}:{" "}
              {moment(data.createdAt).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={[styles.titleContainer, { marginBottom: 15 }]}>
            <Text style={styles.titleText}>
              {t("crf_eligibility_criteria")}
            </Text>
          </View>
          <View>
            <Text style={styles.textSubTitle}>
              {t("crf_inclusion_criteria")}
            </Text>
            <Text style={styles.textBold}>
              Patients who meet all of the following criteria are eligible for
              enrollment as study participants:
            </Text>
            <TableContainer style={{ marginBottom: 20 }}>
              <View style={styles.row}>
                <View style={styles.colItemFlex}></View>
                <View style={[styles.colItemWOFlex, { width: 50 }]}>
                  <Text style={styles.textBold}>Yes</Text>
                </View>
                <View style={[styles.colLastItemWOFlex, { width: 50 }]}>
                  <Text style={styles.textBold}>No</Text>
                </View>
              </View>
              {CHECK_BOX_LIST_INCLUSION.map((item, index) => {
                return (
                  <View key={item.key} style={styles.row}>
                    <View
                      style={[
                        styles.colItemFlex,
                        CHECK_BOX_LIST_INCLUSION.length - 1 === index && {
                          borderBottom: 0,
                        },
                      ]}
                    >
                      <Text
                        style={[
                          { paddingHorizontal: 10 },
                          styles.textDescription,
                        ]}
                      >
                        {t("crf_" + item.title)}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colItemWOFlex,
                        { width: 50, justifyContent: "center" },
                        CHECK_BOX_LIST_INCLUSION.length - 1 === index && {
                          borderBottom: 0,
                        },
                      ]}
                    >
                      <CheckIcon width={11} height={9} />
                    </View>
                    <View
                      style={[
                        styles.colLastItemWOFlex,
                        { width: 50, justifyContent: "center" },
                        CHECK_BOX_LIST_INCLUSION.length - 1 === index && {
                          borderBottom: 0,
                        },
                      ]}
                    ></View>
                  </View>
                );
              })}
            </TableContainer>
            <Text style={styles.textSubTitle}>
              {t("crf_exclusion_criteria")}
            </Text>
            <Text style={styles.textBold}>
              Patients who meet any of these criteria are not eligible for
              enrollment as study participants:
            </Text>
            <TableContainer style={{ marginBottom: 20 }}>
              <View style={styles.row}>
                <View style={styles.colItemFlex}></View>
                <View style={[styles.colItemWOFlex, { width: 50 }]}>
                  <Text style={styles.textBold}>Yes</Text>
                </View>
                <View style={[styles.colLastItemWOFlex, { width: 50 }]}>
                  <Text style={styles.textBold}>No</Text>
                </View>
              </View>
              {CHECK_BOX_EXCLUSION_SECTION_1.map((item, index) => {
                return (
                  <View key={item.key} style={styles.row}>
                    <View
                      style={[
                        styles.colItemFlex,
                        CHECK_BOX_EXCLUSION_SECTION_1.length - 1 === index && {
                          borderBottom: 0,
                        },
                      ]}
                    >
                      <Text
                        style={[
                          { paddingHorizontal: 10 },
                          styles.textDescription,
                        ]}
                      >
                        {t("crf_" + item.title)}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.colItemWOFlex,
                        { width: 50, justifyContent: "center" },
                        CHECK_BOX_EXCLUSION_SECTION_1.length - 1 === index && {
                          borderBottom: 0,
                        },
                      ]}
                    >
                      {data[item.key] && <CheckIcon width={11} height={9} />}
                    </View>
                    <View
                      style={[
                        styles.colLastItemWOFlex,
                        { width: 50, justifyContent: "center" },
                        CHECK_BOX_EXCLUSION_SECTION_1.length - 1 === index && {
                          borderBottom: 0,
                        },
                      ]}
                    >
                      {!data[item.key] && <CheckIcon width={11} height={9} />}
                    </View>
                  </View>
                );
              })}
            </TableContainer>
          </View>
        </View>
        <View style={[styles.paddingBody, styles.row]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/28/2022
          </Text>
        </View>
        <View style={styles.paddingFooter}>
          <Text style={styles.textDescriptionCenter}>{t("crf_page")} 1/2</Text>
        </View>
      </Page>
      <Page size="A4">
        <Text style={styles.header}>{t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <TitlePDFFile t={t} />
          <TableContainer>
            <View style={styles.row}>
              <View style={styles.colItemFlex}></View>
              <View style={[styles.colItemWOFlex, { width: 50 }]}>
                <Text style={styles.textBold}>Yes</Text>
              </View>
              <View style={[styles.colLastItemWOFlex, { width: 50 }]}>
                <Text style={styles.textBold}>No</Text>
              </View>
            </View>
            {CHECK_BOX_EXCLUSION_SECTION_2.map((item, index) => {
              return (
                <View key={item.key} style={styles.row}>
                  <View
                    style={[
                      styles.colItemFlex,
                      CHECK_BOX_EXCLUSION_SECTION_2.length - 1 === index && {
                        borderBottom: 0,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        { paddingHorizontal: 10 },
                        styles.textDescription,
                      ]}
                    >
                      {t("crf_" + item.title)}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.colItemWOFlex,
                      { width: 50, justifyContent: "center" },
                      CHECK_BOX_EXCLUSION_SECTION_2.length - 1 === index && {
                        borderBottom: 0,
                      },
                    ]}
                  >
                    {data[item.key] && <CheckIcon width={11} height={9} />}
                  </View>
                  <View
                    style={[
                      styles.colLastItemWOFlex,
                      { width: 50, justifyContent: "center" },
                      CHECK_BOX_EXCLUSION_SECTION_2.length - 1 === index && {
                        borderBottom: 0,
                      },
                    ]}
                  >
                    {!data[item.key] && <CheckIcon width={11} height={9} />}
                  </View>
                </View>
              );
            })}
          </TableContainer>
          <View style={{ marginTop: 20 }}>
            <View style={[styles.row, { marginBottom: 20 }]}>
              <View style={{ flex: 1 }}>
                <Text style={styles.textDescription}>
                  Form Completed by: {t("crf_pi_name_fixed")}
                </Text>
              </View>
              <View style={{ width: 150 }}>
                <Text style={styles.textDescription}>
                  {t("crf_date")}:{" "}
                  {data.sitePISignatureDate
                    ? moment(data.completedDate).format("DD/MM/YYYY")
                    : "-"}
                </Text>
              </View>
            </View>

            <View style={[styles.row, { alignItems: "center" }]}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={styles.textDescription}>Site PI Signature:</Text>
                {!isEmpty(data.sitePISignatureURL) ? (
                  <Image
                    src={{ uri: data.sitePISignatureURL }}
                    style={styles.signatureImg}
                  />
                ) : (
                  <View></View>
                )}
              </View>
              <View style={{ width: 150 }}>
                <Text style={styles.textDescription}>
                  {t("crf_date")}:{" "}
                  {data.sitePISignatureDate
                    ? moment(data.sitePISignatureDate).format("DD/MM/YYYY")
                    : "-"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.paddingBody, styles.row]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/28/2022
          </Text>
        </View>
        <View style={styles.paddingFooter}>
          <Text style={styles.textDescriptionCenter}>{t("crf_page")} 2/2</Text>
        </View>
      </Page>
    </Document>
  );
}
