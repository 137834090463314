import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { HEADER_DEFAULT_FORMS } from "../constant";
import styles, { TableContainer, TitlePDFFile } from "../styles";
import Checkbox from "../assets/Checkbox";
import CheckboxOutline from "../assets/CheckboxOutline";
import RadioboxOutline from "../assets/RadioOutline";
import RadioChecked from "../assets/RadioChecked";

import moment from "moment";
import { range } from "lodash";

export default function VitalSignMeasurementsPreview(props) {
  const { t, data } = props;
  return (
    <Document>
      <Page size="A4">
        <Text style={styles.header}>{t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <TitlePDFFile t={t} />
          <View style={styles.titleHeader}>
            <Text style={styles.dateSignedText}>
              {t("subjects_initials")}: {data.subjectsInitials || "-"}
            </Text>
            <Text style={styles.dateSignedText}>
              {t("crf_subject_id")}: {data.subjectId}
            </Text>
            <Text style={styles.timeText}>
              {t("crf_exam_date")}:{" "}
              {moment(data.createdAt).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={[styles.titleContainer, { marginBottom: 15 }]}>
            <Text style={styles.titleText}>{t("crf_vital_sign")}</Text>
          </View>
          <Text style={styles.textBold}>{t("crf_visit_number")}:</Text>
          <View style={[styles.row, { marginBottom: 8, marginTop: 4 }]}>
            <View style={styles.col}>
              {range(3).map((item) => (
                <View
                  key={item}
                  style={[
                    styles.row,
                    { alignItems: "center", marginBottom: 6 },
                  ]}
                >
                  {item + 1 === data.visit ? (
                    <Checkbox width={10} height={10} />
                  ) : (
                    <CheckboxOutline width={10} height={10} />
                  )}
                  <Text style={[styles.textDescription, { marginLeft: 8 }]}>
                    Visit {item + 1}
                  </Text>
                </View>
              ))}
            </View>
            <View style={styles.col}>
              {range(3).map((item) => (
                <View
                  key={item}
                  style={[
                    styles.row,
                    { alignItems: "center", marginBottom: 6 },
                  ]}
                >
                  {item + 4 === data.visit ? (
                    <Checkbox width={10} height={10} />
                  ) : (
                    <CheckboxOutline width={10} height={10} />
                  )}
                  <Text style={[styles.textDescription, { marginLeft: 8 }]}>
                    Visit {item + 4}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={[styles.row, { marginBottom: 20 }]}>
            {data.notPerform ? (
              <Checkbox width={10} height={10} />
            ) : (
              <CheckboxOutline width={10} height={10} />
            )}
            <Text style={[styles.textDescription, { marginLeft: 8 }]}>
              {t("crf_vital_sign_not_performed")}
            </Text>
          </View>
          <TableContainer style={{ marginBottom: 15, padding: 5 }}>
            <View style={styles.rowInputBox}>
              <View style={styles.rowTwoContainer}>
                <Text style={styles.textBold}>Height:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.height || "-"}
                  </Text>
                </View>
                <Text style={[styles.textDescription, { marginLeft: 5 }]}>
                  cm
                </Text>
              </View>
              <View style={styles.rowTwoContainer}>
                <Text style={styles.textBold}>Weight:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.weight || "-"}
                  </Text>
                </View>
                <Text style={[styles.textDescription, { marginLeft: 5 }]}>
                  kg
                </Text>
              </View>
            </View>
            <View style={styles.rowInputBox}>
              <View style={styles.rowTwoContainer}>
                {data.heightNotMeasured ? (
                  <Checkbox width={10} height={10} />
                ) : (
                  <CheckboxOutline width={10} height={10} />
                )}
                <Text style={[styles.textDescription, { marginLeft: 5 }]}>
                  Height not measured
                </Text>
              </View>
              <View style={styles.rowTwoContainer}>
                {data.weightNotMeasured ? (
                  <Checkbox width={10} height={10} />
                ) : (
                  <CheckboxOutline width={10} height={10} />
                )}
                <Text style={[styles.textDescription, { marginLeft: 5 }]}>
                  Weight not measured
                </Text>
              </View>
            </View>
          </TableContainer>
          <TableContainer style={{ padding: 5 }}>
            <View style={styles.rowInputBox}>
              <View
                style={[
                  styles.row,
                  {
                    flex: 2,
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={styles.textBold}>{t("crf_time")}:</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {moment(data.timeCreated).format("HH:mm")}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 3, marginLeft: 5 }}>
                <Text style={[styles.textDescription, { fontStyle: "italic" }]}>
                  {t("crf_of_24_format")}
                </Text>
              </View>
            </View>
            <InputAndCheckboxComponent
              data={data.temperature}
              checked={data.tempNotMeasured}
              dataType={t("crf_celcius")}
              checboxLabel={t("crf_temp_not_measured")}
              title={t("crf_temperature")}
            />
            <View style={[styles.row, { flexGrow: 1, marginVertical: 3 }]}>
              <View style={[styles.row, { alignItems: "center" }]}>
                <Text style={styles.textDescription}>
                  <Text style={styles.fontBold}>{t("crf_method")}: </Text>
                  {t("crf_check_one")}
                </Text>
              </View>
              <View style={[styles.row, { marginLeft: 6 }]}>
                <RadioComponent
                  checked={data.tempMethod == "oral"}
                  title={t("crf_oral")}
                />
                <RadioComponent
                  checked={data.tempMethod == "axillary"}
                  title={t("crf_axillary")}
                />
                <RadioComponent
                  checked={data.tempMethod == "tympanic"}
                  title={t("crf_tympanic")}
                />
              </View>
            </View>
            <InputAndCheckboxComponent
              data={data.rr}
              checked={data.rrNotMeasured}
              dataType={t("crf_breaths_min")}
              checboxLabel={t("crf_rr_not_measured")}
              title={t("crf_respiratory_rate")}
            />
            <InputAndCheckboxComponent
              data={data.heartRate}
              checked={data.heartRateNotMeasured}
              dataType={t("crf_beats_min")}
              checboxLabel={t("crf_hr_not_measured")}
              title={t("crf_heart_rate")}
            />
            <InputAndCheckboxComponent
              data={data.systolicBP}
              checked={data.bpNotNotMeasured}
              dataType={t("crf_mmhg")}
              checboxLabel={t("crf_bp_not_measured")}
              title={t("crf_systolic_blood_pressure")}
            />
            <View style={styles.rowInputBox}>
              <View
                style={[
                  styles.row,
                  {
                    flex: 5,
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={styles.textBold}>
                  {t("crf_diastolic_blood_pressure")}:
                </Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.diastolicBP || "-"}
                  </Text>
                </View>
                <Text style={[styles.textDescription, { marginLeft: 5 }]}>
                  {t("crf_mmhg")}
                </Text>
              </View>
              <View style={{ flex: 3, marginLeft: 15 }} />
            </View>
            <View style={[styles.row, { flexGrow: 1, marginVertical: 5 }]}>
              <View style={[styles.row, { alignItems: "center" }]}>
                <Text style={styles.textDescription}>
                  <Text style={styles.fontBold}>{t("crf_method")}: </Text>
                  {t("crf_check_one")}
                </Text>
              </View>
              <View style={[styles.row, { marginLeft: 6 }]}>
                <RadioComponent
                  checked={data.bpMethod === "manual"}
                  title={t("crf_manual")}
                />
                <RadioComponent
                  checked={data.bpMethod === "automated"}
                  title={t("crf_automated")}
                />
              </View>
            </View>
            <View style={[styles.row, { flexGrow: 1, marginVertical: 5 }]}>
              <View style={[styles.row, { alignItems: "center" }]}>
                <Text style={styles.textDescription}>
                  <Text style={styles.fontBold}>{t("crf_location")}: </Text>
                  {t("crf_check_one")}
                </Text>
              </View>
              <View style={[styles.row, { marginLeft: 6 }]}>
                <RadioComponent
                  checked={data.bpLocation === "left_arm"}
                  title={t("crf_left_arm")}
                />
                <RadioComponent
                  checked={data.bpLocation === "right_arm"}
                  title={t("crf_right_arm")}
                />
              </View>
            </View>
            <View style={[styles.row, { flexGrow: 1, marginVertical: 5 }]}>
              <View style={[styles.row, { alignItems: "center" }]}>
                <Text style={styles.textDescription}>
                  <Text style={styles.fontBold}>{t("crf_position")}: </Text>
                  {t("crf_check_one")}
                </Text>
              </View>
              <View style={[styles.row, { marginLeft: 6 }]}>
                <RadioComponent
                  checked={data.bpPosition === "sitting"}
                  title={t("crf_sitting")}
                />
                <RadioComponent
                  checked={data.bpPosition === "supine"}
                  title={t("crf_supine")}
                />
                <RadioComponent
                  checked={data.bpPosition === "standing"}
                  title={t("crf_standing")}
                />
              </View>
            </View>
          </TableContainer>
          <View style={[styles.row, { marginTop: 15 }]}>
            <Text style={[styles.textBold]}>{t("crf_aditional_notes")}:</Text>
            <Text style={[styles.textDescription, { marginLeft: 3, flex: 1 }]}>
              {data.comment || "-"}
            </Text>
          </View>
          <Text
            style={[
              styles.textDescription,
              { marginTop: 20, marginBottom: 10 },
            ]}
          >
            {t("crf_vital_obtained_by")}: {t("crf_pi_name_fixed")}
          </Text>
        </View>
        <View style={[styles.paddingBody, styles.row]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/30/2022
          </Text>
        </View>
        <View style={styles.paddingFooter}>
          <Text style={styles.textDescriptionCenter}>{t("crf_page")} 1/1</Text>
        </View>
      </Page>
    </Document>
  );
}

const RadioComponent = ({ checked, title }) => {
  return (
    <View style={[styles.row, { alignItems: "center", marginHorizontal: 6 }]}>
      {checked ? (
        <RadioChecked width={12} height={12} />
      ) : (
        <RadioboxOutline width={12} height={12} />
      )}
      <Text style={[styles.textDescription, { marginLeft: 6 }]}>{title}</Text>
    </View>
  );
};

const InputAndCheckboxComponent = ({
  title,
  data,
  checked,
  dataType,
  checboxLabel,
}) => {
  return (
    <View style={styles.rowInputBox}>
      <View
        style={[
          styles.row,
          {
            flex: 5,
            alignItems: "center",
          },
        ]}
      >
        <Text style={styles.textBold}>{title}:</Text>
        <View style={styles.textBoxContainer}>
          <Text style={[styles.textDescription, { marginLeft: 3 }]}>
            {data || "-"}
          </Text>
        </View>
        <Text style={[styles.textDescription, { marginLeft: 5 }]}>
          {dataType}
        </Text>
      </View>
      <View style={[styles.row, { flex: 3, marginLeft: 15 }]}>
        {checked ? (
          <Checkbox width={10} height={10} />
        ) : (
          <CheckboxOutline width={10} height={10} />
        )}
        <Text style={[styles.textDescription, { marginLeft: 8 }]}>
          {checboxLabel}
        </Text>
      </View>
    </View>
  );
};
