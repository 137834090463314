import React, { useState, useEffect } from "react";
import "./Chat.css";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { getGetStreamToken } from "../../api/chat";
import { StreamChat } from "stream-chat";
import {
  Chat,
  Channel,
  Window,
  MessageList,
  MessageInput,
  Thread,
} from "stream-chat-react";
import Loading from "../Loading";

const customStyles = {
  "--xl-p": "0.5rem",
};

const client = StreamChat.getInstance("f2ew7kc453em");
let channel;

function index(props) {
  const { userId } = props;
  const [isLoading, setIsloading] = useState(true);
  const appUserOma = useSelector((state) => state.appUserOma);
  const booking = useSelector((state) => state.booking);
  const role = appUserOma.information.roles[0].name;

  useEffect(async () => {
    if (client && client._user) {
      await checkClient(parseInt(client._user.id), userId);
    }
    if (
      appUserOma.isSuccess &&
      (appUserOma.practitioner || appUserOma.information)
    ) {
      await connectGetStream(client);
    }
  }, [appUserOma]);

  const getName = () => {
    let name = "Practitioner";
    let title = "";
    let firstName = "";
    let lastName = "";
    if (appUserOma.practitioner) {
      title = appUserOma.practitioner.title || "";
      firstName = appUserOma.practitioner.firstName || "";
      lastName = appUserOma.practitioner.lastName || "";
      name = `${title}${firstName} ${lastName}`;
    }
    return name;
  };

  const connectGetStream = async () => {
    const token = await getGetStreamToken(userId);
    await client.connectUser(
      {
        id: `${userId}`,
        name: getName(),
        image: getProfilePhoto(),
      },
      token
    );
    channel = client.channel("messaging", booking.current.id, {
      members: [`${userId}`],
    });
    setIsloading(false);
  };

  const checkClient = (cUserId, userId) => {
    if (cUserId !== userId) {
      client.disconnectUser();
    }
  };

  const getProfilePhoto = () => {
    if (role === "communityPharmacist") {
      return "";
    }
    const profilePhoto =
      appUserOma.practitioner &&
      appUserOma.practitioner.photos &&
      appUserOma.practitioner.photos.find(
        (photo) => photo.category === "avatar"
      );
    if (profilePhoto && profilePhoto.imageUrl) {
      return profilePhoto.imageUrl;
    } else {
      return "";
    }
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading ? (
        <Loading height="100%" />
      ) : (
        <Chat client={client} customStyles={customStyles}>
          <Channel channel={channel} style={{ width: "100%" }}>
            <Window>
              <MessageList />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      )}
    </div>
  );
}

export default index;
