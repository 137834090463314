import { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import { CircleButtonAlert, CircleButtonNormal } from "./styles";
import React from "react";
const AudioButton = (props) => {
  const { isMute, handleAudioButton } = props;
  const Icon = isMute ? AudioMutedOutlined : AudioOutlined;
  const Button = isMute ? CircleButtonAlert : CircleButtonNormal;
  return (
    <Button onClick={() => handleAudioButton(!isMute)}>
      <Icon />
    </Button>
  );
};

export default AudioButton;
