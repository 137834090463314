import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/SymptomGroups";

async function getSymptom() {
  const { data } = await http.get(apiEndpoint);
  return data;
}

async function getMedicationForOrgs(symptomId) {
  const { data } = await http.get(
    `${apiEndpoint}/${symptomId}/medicationForOrgs?filter[where][organizationId]=4`
  );
  return data;
}

export { getSymptom, getMedicationForOrgs };
