import { Tag, Button, Modal, Image, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Table from "../Table";
import Action from "./action";
import { STATUS } from "./constant";
import { useDispatch } from "react-redux";

const { Option } = Select;

const table = ({ isHistoryBooking, handleToSummary, handleTableChange, t }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [filterDataSource, setFilterDataSource] = useState(false);
  const [visibleInitialSymptom, setVisibleInitialSymptom] = useState(false);
  const [symptomNote, setSymptomNote] = useState(false);
  const [visibleNote, setVisibleNote] = useState(false);
  const [note, setNote] = useState(false);
  const [medications, setMedications] = useState([]);
  const [visibleMedication, setVisibleMedication] = useState(false);
  const booking = useSelector((state) => state.booking);
  const userOma = useSelector((state) => state.appUserOma);
  const hieData = useSelector((state) => state.userOma);
  const role =
    userOma && userOma.information && userOma.information.roles[0].name;
  const { data, loading } = booking;

  const checkAddress = (address, addressTypeId) => {
    if (!address) return "-";
    const addressInfo = address.find(
      (item) => item.addressTypeId == addressTypeId
    );
    return addressInfo;
  };

  const setColor = (status) => {
    if (status.includes("PENDING")) {
      return "orange";
    }
    if (
      status.includes("CONFIRM") ||
      status.includes("PAID") ||
      status.includes("COMPLETED") ||
      status.includes("SUCCESS")
    ) {
      return "green";
    }
    if (
      status.includes("DECLINE") ||
      status.includes("CANCEL") ||
      status.includes("ALERT")
    ) {
      return "red";
    }
    return "blue";
  };

  const NOTE_COLUMN = {
    title: t("note"),
    key: "note",
    width: 100,
    dataIndex: ["notes"],
    render: (text) =>
      text && (
        <Button
          onClick={() => {
            setVisibleNote(true);
            setNote(text);
          }}
        >
          Note
        </Button>
      ),
  };

  const INITIAL_SYMPTOM_COLUMN = {
    title: t("initial_symtoms"),
    key: "initialSymtoms",
    width: 150,
    render: (text) =>
      text.symptom && (
        <Button
          onClick={() => {
            setVisibleInitialSymptom(true);
            setSymptomNote(text.symptom);
          }}
        >
          {t("initial_symtoms")}
        </Button>
      ),
  };

  const COLUMNS = [
    {
      title: t("date_time"),
      key: "dateTime",
      width: 150,
      align: "center",
      fixed: "left",
      render: (record) =>
        moment(record.admitTime).format("DD/MM/YYYY, HH:mm:ss"),
      sorter: (a, b) => moment(a.admitTime).unix() - moment(b.admitTime).unix(),
      defaultSortOrder: "descend",
    },
    {
      title: t("full_name"),
      width: 250,
      align: "center",
      render: (record) => {
        const userInfo =
          record.patient && record.patient.userInformation
            ? record.patient.userInformation
            : null;
        return userInfo ? `${userInfo.firstname} ${userInfo.lastname}` : "-";
      },
    },
    {
      title: t("consultation_type"),
      width: 150,
      align: "center",
      dataIndex: "bookingCategory",
    },
    {
      title: t("consult_with"),
      key: "consultWith",
      width: 200,
      align: "center",
      render: (record) => {
        const practitionerInfo =
          record.practitionerAppUser &&
          record.practitionerAppUser.userInformation
            ? record.practitionerAppUser.userInformation
            : null;
        return practitionerInfo &&
          practitionerInfo.firstname &&
          practitionerInfo.lastname
          ? `${practitionerInfo.firstname} ${practitionerInfo.lastname}`
          : "-";
      },
    },
    isHistoryBooking ? { ...NOTE_COLUMN } : { ...INITIAL_SYMPTOM_COLUMN },
  ];

  const COLUMNS_WITH_STATUS = [
    {
      title: t("status"),
      dataIndex: "status",
      width: 100,
      align: "center",
      render: (text) => (
        <Tag color={setColor(text)}>{t(`${STATUS[role][text]}`)}</Tag>
      ),
    },
  ];

  const COLUMNS_WITH_ADDRESS = [
    {
      title: t("address"),
      key: "address",
      width: 300,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return `เลขที่ ${address.no || "-"} หมู่บ้าน ${
              address.village || "-"
            } ซอย ${address.soi || "-"} ${address.road || "ถนน -"}`;
          }
        }
        return "-";
      },
    },
    {
      title: t("sub_district"),
      key: "tambon",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return address.subDistrict;
          }
        }
        return "-";
      },
    },
    {
      title: t("district"),
      key: "amphur",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return address.district;
          }
        }
        return "-";
      },
    },
    {
      title: t("province"),
      key: "province",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return address.province;
          }
        }
        return "-";
      },
    },
    {
      title: t("postal_code"),
      key: "zipcode",
      width: 100,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return address.postalCode;
          }
        }
        return "-";
      },
    },
  ];

  const COLUMNS_WITH_ACTION = [
    {
      title: t("action"),
      key: "action",
      width: 190,
      fixed: "right",
      align: "center",
      render: (record) => (
        <Action
          t={t}
          status={record.status}
          id={record.id}
          handleToSummary={handleToSummary}
        />
      ),
    },
  ];

  const FILTER_COLUMNS = [
    ...COLUMNS,
    ...COLUMNS_WITH_ADDRESS,
    ...COLUMNS_WITH_STATUS,
    ...COLUMNS_WITH_ACTION,
  ];

  return (
    <div style={{ width: "100%" }}>
      <Table
        loading={loading}
        dataSource={filterDataSource || data}
        columns={FILTER_COLUMNS}
        scroll={{ x: "max-content", y: "45vh" }}
        pagination={{
          current: booking && booking.meta ? booking.meta.current : 1,
          pageSize: booking && booking.meta ? booking.meta.itemsPerPage : 10,
          total: booking && booking.meta ? booking.meta.totalItemCount : 10,
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
        }}
        onChange={handleTableChange}
      />
      <Modal
        title={t("data_from_hie")}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        {hieData.loading ? (
          t("loading")
        ) : (
          <div>
            {hieData.hie && hieData.hie.drugAllergies.length ? (
              <div>
                {hieData.hie.drugAllergies.map((drug, index) => (
                  <div>
                    {index === 0 && <div>{t("drug_allergy")}</div>}
                    <div>
                      {index + 1}: {drug.genericname}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              t("no_data_from_prescription")
            )}
            <div>
              {t("congenital_disease")}:{" "}
              {hieData.hie &&
                hieData.hie.condition &&
                hieData.hie.condition.ccPersistDisease}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        title={t("initial_symptom")}
        visible={visibleInitialSymptom}
        footer={null}
        onOk={() => setVisibleInitialSymptom(false)}
        onCancel={() => setVisibleInitialSymptom(false)}
      >
        <div>
          {symptomNote.imageUrl && <Image src={symptomNote.imageUrl} />}
        </div>
        <div>
          <h3 className="font-weight-bold">{t("description")}:</h3>
          {symptomNote.note && <div>{symptomNote.note}</div>}
        </div>
      </Modal>
      <Modal
        title={t("note")}
        visible={visibleNote}
        footer={null}
        onOk={() => setVisibleNote(false)}
        onCancel={() => setVisibleNote(false)}
      >
        <div>{note && <div>{note}</div>}</div>
      </Modal>
      <Modal
        title={t("prescription")}
        visible={visibleMedication}
        footer={null}
        onOk={() => setVisibleMedication(false)}
        onCancel={() => setVisibleMedication(false)}
      >
        <div>
          {medications.map((medication, index) => (
            <div>
              <div>
                {index + 1}. {t("ingredient")}: {medication.activeIngredient}
              </div>
              <div>
                {t("trade_name")}: {medication.tradeName}
              </div>
              <div>
                {t("dosage")}: {medication.dosageForm}
              </div>
              <div>
                {t("strength")}: {medication.strength}
              </div>
              <div>
                {t("amount")}: {medication.amount}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};
export default table;
