import styled from "styled-components";

export const NoteIconBox = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.95),
      rgba(255, 255, 255, 0.95)
    ),
    #00bae5;
  border-radius: 100px;
`;
