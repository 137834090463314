import moment from "moment";
import { omit, range, keysIn } from "lodash";

const pi_sig_URL =
  "https://firebasestorage.googleapis.com/v0/b/ever-oma.appspot.com/o/clinicTrial%2Fsignature%2FImage%20from%20iOS.jpg?alt=media&token=3c7aa6a9-a782-489b-b3ad-94183ad0fbb8";

export const formConvert = (current, record) => {
  return {
    day: record.type === "daily_assessment" ? record.day : "",
    id: current.id,
    subjectId: current.filtering?.subjectId,
    createdAt: record.createdAt,
    timeCreated: record?.timeCreated,
    formData: record.rawData,
    type: record.type,
  };
};

export const filterDataSubTable = (data, selected) => {
  let dataResv, allCheck;
  switch (selected.key) {
    case "daily_assessment":
      dataResv = data.followUpForm
        ?.filter((item) => item.period === "MORNING")
        .map((item) => {
          return {
            day: item.day,
            rawData: item.jsonObj?.schemaList,
            submitted: item.submitted,
            fileName: `Day ${item.day}`,
            createdAt: moment(data.filtering?.createdAt)
              .add(+item.day - 1, "day")
              .toISOString(),
            type: selected.key,
          };
        });
      return dataResv;
    case "product_feasibility":
      return data.followUpForm
        ?.filter(
          (item) =>
            item.period === "MORNING" &&
            item.submitted &&
            +item.day === 8 &&
            Array.isArray(item.jsonObj?.schemaList) &&
            item.jsonObj?.schemaList.length > 2
        )
        .map((item) => ({
          rawData: item.jsonObj?.schemaList[2],
          submitted: true,
          fileName: selected.key,
          createdAt: moment(data.filtering?.createdAt)
            .add(7, "day")
            .toISOString(),
          type: selected.key,
        }));
    case "adverse_log":
      allCheck = data.followUpForm
        ?.filter((item) => item.period === "MORNING")
        .every((item) => {
          return (
            item.jsonObj?.schemaList[3]?.schema?.adverse_events.value === "no"
          );
        });
      return [
        {
          rawData: { allAdverseEvent: allCheck },
          submitted: true,
          fileName: selected.key,
          createdAt: data.filtering?.createdAt,
          type: selected.key,
        },
      ];
    case "crf_nasal_sinus_exam":
      return (
        Array.isArray(data?.eligibilityAssessment) &&
        data.eligibilityAssessment
          .filter((item) => item.day === -1)
          .map((item) => {
            return {
              rawData: { ...item },
              createdAt: data.filtering?.createdAt,
              timeCreated: item.createdAt,
              submitted: true,
              fileName: selected.key,
              type: selected.key,
            };
          })
      );
    case "crf_follow_nasal_sinus_exam":
      return (
        Array.isArray(data?.eligibilityAssessment) &&
        data.eligibilityAssessment
          .filter((item) => item.day === 8)
          .map((item) => {
            return {
              rawData: { ...item },
              timeCreated: item.createdAt,
              createdAt: data.filtering?.createdAt,
              submitted: true,
              fileName: selected.key,
              type: selected.key,
            };
          })
      );
    case "end_of_study_exam":
      return (
        Array.isArray(data?.eligibilityAssessment) &&
        data.eligibilityAssessment
          .filter((item) => item.day === 14)
          .map((item) => {
            return {
              rawData: { ...item },
              timeCreated: item.createdAt,
              createdAt: data.filtering?.createdAt,
              submitted: true,
              fileName: selected.key,
              type: selected.key,
            };
          })
      );
    case "crf_demographics":
      return [
        {
          rawData: { ...data.generalInformation, ...data.address },
          fileName: selected.key,
          submitted: true,
          type: selected.key,
          createdAt: data.filtering?.createdAt,
        },
      ];
    case "crf_vital_sign":
      return (
        Array.isArray(data?.eligibilityAssessment) &&
        data.eligibilityAssessment
          .filter((item) => item.day === -1)
          .map((item) => {
            return {
              rawData: { ...item },
              createdAt: data.filtering?.createdAt,
              timeCreated: item?.createdAt,
              submitted: true,
              fileName: selected.key,
              type: selected.key,
            };
          })
      );
    case "crf_eligibility_criteria":
      return [
        {
          rawData: { ...data.inExclusionCriteria },
          createdAt: data.filtering?.createdAt,
          submitted: true,
          fileName: selected.key,
          type: selected.key,
        },
      ];
    case "crf_informed_consent":
    case "crf_serious_adverse_event":
      return [
        {
          rawData: {},
          createdAt: moment().toISOString(),
          submitted: true,
          fileName: selected.key,
          type: selected.key,
        },
      ];
    case "withdrawn_title":
      return [
        {
          rawData: {
            subjectDeviation: Array.isArray(data.subjectDeviation)
              ? data.subjectDeviation
              : [],
          },
          createdAt: moment().toISOString(),
          submitted: true,
          fileName: selected.key,
          type: selected.key,
        },
      ];
    default:
      return [];
  }
};

const addItemSubjectDeviation = (array = []) => {
  if (array.length !== 5) {
    return [
      ...array,
      ...range(5 - array.length).map((item) => {
        return {
          affectDataIntegrity: "",
          affectSubjectWillingness: "",
          category: "",
          createdAt: "",
          description: "",
          endDate: "",
          startDate: "",
          impactSubjectSafety: "",
          otherCategoryReason: "",
          piInitials: "",
          piInitialsDate: "",
          reported: null,
          reportedDate: null,
          sequence: array.length + item,
        };
      }),
    ];
  }
  return array;
};

const FIX_SUB_ID = [
  {
    subject_id: "0123",
    subject_initials: "PS",
    consent_date: "5/15/22",
  },
  {
    subject_id: "9230",
    subject_initials: "NC",
    consent_date: "5/12/22",
  },
  {
    subject_id: "0555",
    subject_initials: "SS",
    consent_date: "5/20/22",
  },
  {
    subject_id: "0444",
    subject_initials: "RK",
    consent_date: "5/12/22",
  },
  {
    subject_id: "0102",
    subject_initials: "PP",
    consent_date: "5/17/22",
  },
  {
    subject_id: "1234",
    subject_initials: "RS",
    consent_date: "5/12/22",
  },
  {
    subject_id: "5908",
    subject_initials: "MR",
    consent_date: "5/12/22",
  },
  {
    subject_id: "5123",
    subject_initials: "RS",
    consent_date: "5/12/22",
  },
  {
    subject_id: "6091",
    subject_initials: "VK",
    consent_date: "5/12/22",
  },
  {
    subject_id: "6789",
    subject_initials: "PH",
    consent_date: "5/12/22",
  },
  {
    subject_id: "6534",
    subject_initials: "CN",
    consent_date: "5/12/22",
  },
  {
    subject_id: "6222",
    subject_initials: "VA",
    consent_date: "5/17/22",
  },
  {
    subject_id: "6111",
    subject_initials: "PP",
    consent_date: "5/17/22",
  },
  {
    subject_id: "6345",
    subject_initials: "TH",
    consent_date: "5/17/22",
  },
  {
    subject_id: "6456",
    subject_initials: "KJ",
    consent_date: "5/12/22",
  },
  {
    subject_id: "6890",
    subject_initials: "AS",
    consent_date: "5/9/22",
  },
  {
    subject_id: "9456",
    subject_initials: "CM",
    consent_date: "5/9/22",
  },
  {
    subject_id: "9123",
    subject_initials: "SM",
    consent_date: "5/12/22",
  },
  {
    subject_id: "9643",
    subject_initials: "VS",
    consent_date: "5/9/22",
  },
  {
    subject_id: "4325",
    subject_initials: "KM",
    consent_date: "5/9/22",
  },
  {
    subject_id: "9875",
    subject_initials: "MP",
    consent_date: "5/9/22",
  },
  {
    subject_id: "9000",
    subject_initials: "LJ",
    consent_date: "5/9/22",
  },
  {
    subject_id: "4123",
    subject_initials: "KP",
    consent_date: "5/9/22",
  },
  {
    subject_id: "5768",
    subject_initials: "CP",
    consent_date: "5/9/22",
  },
  {
    subject_id: "4765",
    subject_initials: "SA",
    consent_date: "5/9/22",
  },
  {
    subject_id: "4888",
    subject_initials: "PF",
    consent_date: "5/9/22",
  },
  {
    subject_id: "3333",
    subject_initials: "CT",
    consent_date: "5/9/22",
  },
  {
    subject_id: "3478",
    subject_initials: "AA",
    consent_date: "5/9/22",
  },
  {
    subject_id: "4908",
    subject_initials: "ST",
    consent_date: "5/9/22",
  },
  {
    subject_id: "3890",
    subject_initials: "PD",
    consent_date: "5/9/22",
  },
  {
    subject_id: "1123",
    subject_initials: "SS",
    consent_date: "5/9/22",
  },
  {
    subject_id: "2311",
    subject_initials: "SC",
    consent_date: "5/9/22",
  },
  {
    subject_id: "1124",
    subject_initials: "YP",
    consent_date: "5/9/22",
  },
  {
    subject_id: "5633",
    subject_initials: "JT",
    consent_date: "5/9/22",
  },
  {
    subject_id: "0005",
    subject_initials: "NS",
    consent_date: "5/9/22",
  },
  {
    subject_id: "3432",
    subject_initials: "CP",
    consent_date: "5/9/22",
  },
];

export const manageFormHandler = (formData) => {
  let createdAt, sampleDateAt, data, systolicBP, diastolicBP, bloodPressure;
  const fixedData = FIX_SUB_ID.find(
    (item) => item.subject_id === formData?.subjectId
  );
  switch (formData?.type) {
    case "crf_demographics":
      createdAt = moment(formData?.createdAt).subtract(1, "day").toISOString();
      return {
        ...formData.formData,
        createdAt,
        phoneNumberMethod: "mobile",
        alterNatePhoneNumberMethod: "",
        alterNatePhoneNumber: "",
        pfMethodContact: "",
        emsContactName: "",
        emsAddress: "",
        emsUnit: "",
        emsAddressDetail: "",
        emsPostalCode: "",
        emsPhoneNumber: "",
        emsPhoneNumberMethod: "",
        emsAlterPhoneNumber: "",
        emsAlterPhoneNumberMethod: "",
        emsEmail: "",
        emsPfMethodContact: "",
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: fixedData?.subject_initials,
        formCompletedBy: "",
        formCompletedDate: moment().toISOString(),
        formNumber: "",
        consentDate: fixedData?.consent_date,
      };
    case "crf_vital_sign":
      data = formData.formData;
      createdAt = moment(formData?.createdAt).subtract(1, "day").toISOString();
      sampleDateAt = moment(formData?.createdAt)
        .subtract(2, "day")
        .toISOString();
      bloodPressure = data.bloodPressureRange
        ? data.bloodPressureRange
        : data.bloodPressure.toString?.().replace(".", "/");
      systolicBP = `${bloodPressure}`.split("/")[0];
      diastolicBP =
        `${bloodPressure}`.split("/").length > 1
          ? `${bloodPressure}`.split("/")[1]
          : "";
      return {
        ...data,
        type: formData.type,
        subjectId: formData?.subjectId,
        height: data.height,
        timeCreated: formData?.timeCreated,
        heightNotMeasured: false,
        weight: data.weight,
        weightNotMeasured: false,
        subjectsInitials: fixedData?.subject_initials,
        visit: 1,
        notPerform: false,
        temperature: "",
        tempNotMeasured: true,
        rrNotMeasured: false,
        heartRateNotMeasured: false,
        bpNotNotMeasured: false,
        tempMethod: "",
        systolicBP,
        diastolicBP,
        createdAt,
        sampleDateAt,
        bpMethod: "automated",
        bpLocation: "left_arm",
        bpPosition: "sitting",
        vitalSignObtainedBy: "",
        comment: "",
        formNumber: "",
      };
    case "crf_nasal_sinus_exam":
    case "crf_follow_nasal_sinus_exam":
      if (formData.type === "crf_nasal_sinus_exam") {
        createdAt = moment(formData?.createdAt)
          .subtract(1, "day")
          .toISOString();
        sampleDateAt = moment(formData?.createdAt)
          .subtract(2, "day")
          .toISOString();
      } else {
        createdAt = moment(formData?.createdAt).add(7, "day").toISOString();
        sampleDateAt = moment(formData?.createdAt).add(6, "day").toISOString();
      }
      return {
        ...formData.formData,
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: fixedData?.subject_initials,
        visit: formData.type === "crf_follow_nasal_sinus_exam" ? 2 : 1,
        notPerform: false,
        timeCreated: formData?.timeCreated,
        createdAt,
        sampleDateAt,
        comment: "",
        performedBy: "",
        formNumber: "",
      };
    case "crf_eligibility_criteria":
      createdAt = moment(formData?.createdAt).subtract(1, "day").toISOString();
      return {
        ...formData.formData,
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        completedBy: "",
        createdAt,
        completedDate: moment("6/14/2022").toISOString(),
        sitePISignatureDate: moment("6/14/2022").toISOString(),
        sitePISignatureURL: pi_sig_URL,
        subjectsInitials: fixedData?.subject_initials,
        formNumber: "",
      };
    case "daily_assessment":
      let snottObj = {},
        tnssObj = {};
      Array.isArray(formData.formData) &&
        formData.formData.map((item) => {
          const keys = keysIn(item.schema);
          if (keys.includes("snot_blownose")) {
            snottObj = item.schema;
          }
          if (keys.includes("tnss_itchynose")) {
            tnssObj = item.schema;
          }
        });
      return {
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: fixedData?.subject_initials,
        comment: "",
        formNumber: "",
        notPerformed: false,
        createdAt: formData.createdAt,
        TNSSData: tnssObj,
        affectedhealthchoice: snottObj.affectedhealthchoice ?? [],
        SNOTTData: omit(snottObj, "affectedhealthchoice"),
      };
    case "adverse_log":
      return {
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: fixedData?.subject_initials,
        allAdverseEvent: true,
        formNumber: "",
        page: 1,
        of: 1,
        dataList: range(5).map(() => ({
          aeNumner: "",
          dateReported: null,
          description: "",
          adverseEvent: "",
          startDate: null,
          endDate: null,
          ongoing: "",
          outcome: null,
          severityGrade: null,
          serious: "",
          aeTreatment: null,
          expected: "",
          studyAttribution: null,
          actionTaken: null,
          deviceAttribution: null,
          piInitials: "",
          dateofInitials: null,
        })),
      };
    case "product_feasibility":
      return {
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: fixedData?.subject_initials,
        productComplicated:
          formData.formData?.schema?.using_product_complicated?.value,
        comment: "",
        formNumber: "",
        createdAt: formData.createdAt,
      };
    case "end_of_study_exam":
      data = formData.formData;
      createdAt = moment(formData?.createdAt).add(13, "day").toISOString();
      sampleDateAt = moment(formData?.createdAt).add(12, "day").toISOString();
      bloodPressure = data.bloodPressureRange
        ? data.bloodPressureRange
        : data.bloodPressure.toString?.().replace(".", "/");
      systolicBP = `${bloodPressure}`.split("/")[0];
      diastolicBP =
        `${bloodPressure}`.split("/").length > 1
          ? `${bloodPressure}`.split("/")[1]
          : "";
      return {
        ...data,
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: fixedData?.subject_initials,
        visit: 3,
        notPerform: false,
        timeCreated: formData?.timeCreated,
        temperature: "",
        tempNotMeasured: true,
        rrNotMeasured: true,
        heartRateNotMeasured: true,
        bpNotNotMeasured: true,
        tempMethod: "",
        systolicBP,
        diastolicBP,
        createdAt,
        sampleDateAt,
        bpMethod: "",
        bpLocation: "",
        bpPosition: "",
        vitalSignObtainedBy: "",
        comment: "",
        performedBy: "",
        piPrintName: "",
        piSignatureURL: pi_sig_URL,
        piSignatureDate: moment("6/14/2022").toISOString(),
        formNumber: "",
      };
    case "crf_informed_consent":
      return {
        ...formData.formData,
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        formNumber: "",
        dateSigned: fixedData?.consent_date,
        timeSigned: formData.createdAt,
        consentRefused: false,
        subjectsInitials: fixedData?.subject_initials,
        docSigned: [
          {
            title: "VCT study 01 ICF NCI version 4.0 date 12 เมษายน 2565",
            versionDate: "4/12/2022",
            approvalDate: fixedData?.consent_date,
          },
          {
            title: "",
            versionDate: null,
            approvalDate: null,
          },
          {
            title: "",
            versionDate: null,
            approvalDate: null,
          },
        ],
        notApicable: true,
        consentReviewSbj: true,
        subjectSuffient: true,
        consentPrior: true,
        copyOfTheSigned: true,
        nameObtainConsent: "",
        comment: "",
        refusedTime: "",
        refusedComment: "",
      };
    default:
      return {};
  }
};
