import { call, put, takeEvery } from "redux-saga/effects";
import { MonitoringReport } from "../../api";
import * as type from "../types";

function* getMonitoringReportByUserId({ appUserId }) {
  try {
    const result = yield call(
      MonitoringReport.getMonitoringReportByUserId,
      appUserId
    );
    yield put({ type: type.GET_MONITORING_REPORT_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_MONITORING_REPORT_FAILED, error: e.message });
  }
}

function* monitoringReportSaga() {
  yield takeEvery(
    type.GET_MONITORING_REPORT_REQUESTED,
    getMonitoringReportByUserId
  );
}

export default monitoringReportSaga;
