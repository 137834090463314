import React from "react";
import { Typography, Button } from "antd";
import { Container, Wrapper, WrapperHeader, CompassIcon } from "./styles";
const { Paragraph, Title } = Typography;
import moment from "moment";
import PrescriptionOrderCard from "../PrescriptionOrderCard";
import ModalPrescription from "../Modal/PrescriptionModal/Prescription";

const PrescriptionOrder = (props) => {
  const {
    bookings,
    handleModal,
    visibleModalPrescription,
    setVisibleModalPrescription,
    visibleModalNote,
    setVisibleModalNote,
    handleJoinLobby,
    handleLogout,
    communityPharmacy,
    handleClickLocation,
    handleBackLocation,
    currentBooking,
    t,
  } = props;

  return (
    <Container scroll={bookings.length >= 4}>
      <WrapperHeader>
        <CompassIcon onClick={handleBackLocation} />
        <Button onClick={handleLogout}>{t("logout")}</Button>
      </WrapperHeader>
      <Wrapper>
        <Title level={4}>{t("near_you")}</Title>
        <Paragraph>
          {t("today")}, {moment().format("MMM D")}
        </Paragraph>
        {bookings.map((booking, index) => (
          <PrescriptionOrderCard
            t={t}
            handleClickLocation={handleClickLocation}
            key={index}
            booking={booking}
            handleModal={handleModal}
            visibleModalNote={visibleModalNote}
            setVisibleModalNote={setVisibleModalNote}
          />
        ))}
      </Wrapper>
      {visibleModalPrescription && (
        <ModalPrescription
          t={t}
          visible={visibleModalPrescription}
          setVisible={setVisibleModalPrescription}
          patient={
            currentBooking &&
            currentBooking.patient &&
            currentBooking.patient.userInformation
          }
          booking={currentBooking}
          handleVisibleNote={setVisibleModalNote}
          handleJoinLobby={handleJoinLobby}
          communityPharmacy={communityPharmacy}
        />
      )}
    </Container>
  );
};

export default PrescriptionOrder;
