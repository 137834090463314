import { call, put, takeEvery } from "redux-saga/effects";
import { UserOma } from "../../api";
import * as type from "../types";

function* getUserOma() {
  try {
    const result = yield call(UserOma.getUserOma);
    yield put({ type: type.GET_USER_OMA_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_USER_OMA_FAILED, error: e.message });
  }
}

function* getUserOmaByHie({ id }) {
  try {
    const result = yield call(UserOma.getUserOmaByHie, id);
    yield put({ type: type.GET_USER_OMA_BY_HIE_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_USER_OMA_BY_HIE_FAILED, error: e.message });
  }
}

function* getUserOmaById({ id }) {
  try {
    const result = yield call(UserOma.getUserOmaById, id);
    yield put({ type: type.GET_USER_OMA_ID_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_USER_OMA_ID_FAILED, error: e.message });
  }
}

function* updateUserOma(detail) {
  try {
    const result = yield call(UserOma.updateUserOma, detail);
    yield put({ type: type.UPDATE_USER_OMA_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.UPDATE_USER_OMA_FAILED, error: e.message });
  }
}

function* getUserOmaDiagnosis({ id }) {
  try {
    const result = yield call(UserOma.getUserOmaDiagnosis, id);
    yield put({ type: type.GET_USER_OMA_DIAGNOSIS_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_USER_OMA_DIAGNOSIS_FAILED, error: e.message });
  }
}

function* userOmaSaga() {
  yield takeEvery(type.GET_USER_OMA_REQUESTED, getUserOma);
  yield takeEvery(type.GET_USER_OMA_BY_HIE_REQUESTED, getUserOmaByHie);
  yield takeEvery(type.GET_USER_OMA_ID_REQUESTED, getUserOmaById);
  yield takeEvery(type.UPDATE_USER_OMA_REQUESTED, updateUserOma);
  yield takeEvery(type.GET_USER_OMA_DIAGNOSIS_REQUESTED, getUserOmaDiagnosis);
}

export default userOmaSaga;
