import React from "react";
import styled from "styled-components";
import useParticipantNetworkQualityLevel from "../../hooks/Video/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  margin: 1%;
  div {
    width: 2px;
    margin-right: 1px;
    &:not(:last-child): {
      border-right: none;
    }
  }
`;
const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];

export default function NetworkQualityLevel({ participant }) {
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  if (networkQualityLevel === null) return null;

  return (
    <Container>
      {BARS_ARRAY.map((level) => (
        <div
          key={level}
          style={{
            height: `${STEP * (level + 1)}px`,
            background:
              networkQualityLevel > level
                ? "white"
                : "rgba(255, 255, 255, 0.2)",
          }}
        />
      ))}
    </Container>
  );
}
