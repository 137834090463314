import React, { useEffect, useState } from "react";
import {
  GoogleMap as GMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";

const containerStyle = "100vh";

const GoogleMap = (props) => {
  const { origin, destination, zoom, height, width } = props;
  const [directions, setDirections] = useState(null);
  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);
  const [isDirectionFailed, setIsDirectionFailed] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBDLYwoxv5GtDACl-UE5M4aaIN77Q1MYno",
  });

  useEffect(() => {
    if (isLoaded) {
      findDirection();
    }
  }, [isLoaded]);

  const findDirection = () => {
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(response);
          setIsFinishInitialLoad(true);
        } else {
          setIsDirectionFailed(true);
          setIsFinishInitialLoad(true);
        }
      }
    );
  };

  return isFinishInitialLoad ? (
    <GMap
      mapContainerStyle={{
        minHeight: "100%",
        position: "relative",
        borderRadius: 20,
        height: height ?? "100%",
        width: width ?? "100%",
      }}
      options={{ disableDefaultUI: true }}
      center={origin}
      zoom={zoom ?? 15}
    >
      {isDirectionFailed ? (
        <Marker
          position={origin}
          icon={{
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          }}
        />
      ) : (
        <DirectionsRenderer directions={directions} />
      )}
    </GMap>
  ) : (
    <></>
  );
};

export default GoogleMap;
