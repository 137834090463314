import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import { ProfileImage } from "./styles";
import moment from "moment";
const patientInfo = ({ userInfo, isVideoCall }) => {
  const [hie, setHie] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.hie) {
      setHie(userInfo.hie);
    }
  }, [userInfo]);
  return (
    <Card className="mb-2">
      <Row gutter={[8, 8]}>
        <Col span={isVideoCall ? 12 : 24}>
          <ProfileImage
            src={
              userInfo &&
              userInfo.userInformation &&
              userInfo.userInformation.img
                ? userInfo.userInformation.img
                : "https://thumbs.dreamstime.com/b/default-avatar-photo-placeholder-profile-picture-default-avatar-photo-placeholder-profile-picture-eps-file-easy-to-edit-125707135.jpg"
            }
            alt="รูปภาพผู้ป่วย"
          />
        </Col>
        <Col span={isVideoCall ? 12 : 24}>
          <Row type="flex" className="mt-2">
            <Col span="8">ชื่อ</Col>
            <Col span="16">
              {`${
                (userInfo &&
                  userInfo.userInformation &&
                  userInfo.userInformation.firstname) ||
                "-"
              } ${
                (userInfo &&
                  userInfo.userInformation &&
                  userInfo.userInformation.lastname) ||
                "-"
              }`}
            </Col>
            <Col span="8">อายุ</Col>
            <Col span="16">{`${
              userInfo &&
              userInfo.userInformation &&
              userInfo.userInformation.birthDate
                ? moment().diff(userInfo.userInformation.birthDate, "years")
                : "-"
            } ปี`}</Col>
            <Col span="8">HN: </Col>
            <Col span="16">
              {(userInfo &&
                userInfo.userInformation &&
                userInfo.userInformation.hnId) ||
                "-"}
            </Col>
            <Col span="8">โรคประจำตัว: </Col>
            <Col span="16">{hie && hie.condition.ccPersistDisease}</Col>
            <Col span="8">แพ้ยา: </Col>
            <Col span="16">
              {hie && hie.drugAllergies && hie.drugAllergies.length
                ? hie.drugAllergies.map((drug, index) => {
                    return (
                      <div>
                        {index + 1}.) {drug.genericname}
                      </div>
                    );
                  })
                : "-"}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default patientInfo;
