import styled from "styled-components";
import { Steps, Card } from "antd";

export const DeliverDrugContainer = styled.div``;
export const DeliverDrugContent = styled.div`
  height: 100%;
`;

export const StepsContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SummarySteps = styled(Steps)`
  max-width: 800px;
  line-height: 0px;
  .ant-steps-item-container {
    color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #b5b5b5;
    top: -10px;
    height: 2px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #b5b5b5;
    top: -10px;
    height: 2px;
  }
`;

export const StepsCard = styled("div")`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #cecece;
  padding: 12px 10px;
  border-radius: 20px;
`;

export const DeliverWrapper = styled.div`
  padding: 20px;
  height: 115vh;
  position: relative;
`;

export const DeliverCardDetail = styled.div`
  position: absolute;
  width: 30vw;
  right: 0;
  top: 0;
  padding-top: 20px;
  padding-right: 40px;
`;

export const CardStyled = styled(Card)`
  &&& {
    margin-top: 20px;
    border-radius: 20px;
  }
`;

export const ChatContent = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding-bottom: 40px;
  padding-left: 40px;
`;
