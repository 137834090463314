import React from "react";
import BookingCard from "./BookingCard";
import { Container } from "./styles";
import { Spin } from "antd";

const BookingHistory = ({ booking, OmaPatient }) => {
  const diagnosis = OmaPatient.diagnosis;
  return (
    <Container>
      <BookingCard booking={booking} diagnosis={diagnosis} />
    </Container>
  );
};

export default BookingHistory;
