import { call, put, takeEvery } from "redux-saga/effects";
import { Treatment } from "../../api";
import * as type from "../types";

function* createBookingByTreatment(data) {
  try {
    const result = yield call(Treatment.createBookingByTreatment, data);
    yield put({ type: type.CREATE_BOOKING_BY_TREATMENT_SUCCESS, data: result });
  } catch (e) {
    yield put({
      type: type.CREATE_BOOKING_BY_TREATMENT_FAILED,
      error: e.message,
    });
  }
}

function* getBookingsByTreatmentId(data) {
  try {
    const result = yield call(
      Treatment.getBookingsByTreatmentId,
      data.treatmentId
    );
    yield put({
      type: type.GET_BOOKINGS_BY_TREATMENT_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_BOOKINGS_BY_TREATMENT_FAILED,
      error: e.message,
    });
  }
}
function* updateTreatment(data) {
  try {
    const result = yield call(Treatment.updateTreatment, data);
    yield put({
      type: type.UPDATE_TREATMENT_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_TREATMENT_FAILED,
      error: e.message,
    });
  }
}

function* treatmentSaga() {
  yield takeEvery(
    type.CREATE_BOOKING_BY_TREATMENT_REQUESTED,
    createBookingByTreatment
  );
  yield takeEvery(
    type.GET_BOOKINGS_BY_TREATMENT_REQUESTED,
    getBookingsByTreatmentId
  );
  yield takeEvery(type.UPDATE_TREATMENT_REQUESTED, updateTreatment);
}

export default treatmentSaga;
