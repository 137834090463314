import { Image, Input } from "antd";
import styled from "styled-components";
import TextBase from "../Text";

const { Search: SearchAnt } = Input;

export const Text = styled(TextBase)`
  font-weight: bold;
`;
export const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`;

export const ImageKYC = styled(Image)`
  cursor: pointer;
  width: 50%;
  height: 50%;
  object-fiw: contain;
`;

export const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`;
export const Container = styled.div`
  width: 100%;
  padding: 20px;
`;
