import TextBase from "../../Text";
import styled from "styled-components";
import { Modal } from "antd";

const ModalCustom = styled(Modal)`
  .ant-modal-header {
    background: #f3f4f6;
    border-radius: 10px 10px 0px 0px !important;
  }
  .ant-modal-content {
    border-radius: 10px !important;
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 0px;
  }
`;

const Text = styled(TextBase)`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size + "px"};
  margin: 0px;
`;

const Container = styled.div`
  overflow: hidden;
`;

export { ModalCustom, Text, Container };
