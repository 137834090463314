import * as type from "../types";

const initialState = {
  currentRoom: false,
  room: {
    scheduling: {
      rooms: [],
      isSuccess: false,
    },
    roundRobin: {
      covid: {
        rooms: [],
        isSuccess: false,
      },
      general: {
        rooms: [],
        isSuccess: false,
      },
      communityPharmacist: {
        rooms: [],
        isSuccess: false,
      },
      data: [],
      isSuccess: false,
      error: null,
    },
  },
  isSuccess: false,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_ROOM_TYPE["SCHEDULING"].REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        room: {
          ...state.room,
          scheduling: {
            rooms: [],
            isSuccess: false,
          },
        },
      };
    case type.GET_ROOM_TYPE["SCHEDULING"].SUCCESS:
      return {
        ...state,
        loading: false,
        room: {
          ...state.room,
          scheduling: {
            rooms: action.data,
            isSuccess: true,
          },
        },
      };
    case type.GET_ROOM_TYPE["SCHEDULING"].FAILED:
      return {
        ...state,
        loading: false,
        room: {
          ...state.room,
          scheduling: {
            rooms: [],
            isSuccess: false,
          },
        },
        error: action.error,
      };
    case type.GET_ROOM_BY_COUNTRY_AND_SPECIALTY_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            data: [],
            isSuccess: false,
            error: null,
          },
        },
      };
    case type.GET_ROOM_BY_COUNTRY_AND_SPECIALTY_SUCCESS:
      return {
        ...state,
        loading: false,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            data: action.data,
            isSuccess: true,
            error: null,
          },
        },
      };
    case type.GET_ROOM_BY_COUNTRY_AND_SPECIALTY_FAILED:
      return {
        ...state,
        loading: false,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            data: [],
            isSuccess: false,
            error: action.error,
          },
        },
      };
    case type.GET_ROOM_TYPE["COMMUNITY_PHARMACIST"].REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            communityPharmacist: {
              rooms: [],
              isSuccess: false,
            },
          },
        },
        isSuccess: false,
      };
    case type.GET_ROOM_TYPE["COMMUNITY_PHARMACIST"].SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            communityPharmacist: {
              rooms: action.data,
              isSuccess: true,
            },
          },
        },
      };
    case type.GET_ROOM_TYPE["COMMUNITY_PHARMACIST"].FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            communityPharmacist: {
              rooms: [],
              isSuccess: false,
            },
          },
        },
        isSuccess: false,
      };
    case type.GET_ROOM_TYPE["COVID"].REQUESTED:
      return {
        ...state,
        loading: true,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            covid: {
              rooms: [],
              isSuccess: false,
            },
          },
        },
        error: null,
      };
    case type.GET_ROOM_TYPE["COVID"].SUCCESS:
      return {
        ...state,
        loading: false,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            covid: {
              rooms: action.data,
              isSuccess: true,
            },
          },
        },
      };
    case type.GET_ROOM_TYPE["COVID"].FAILED:
      return {
        ...state,
        loading: true,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            covid: {
              rooms: [],
              isSuccess: false,
            },
          },
        },
        error: action.error,
      };
    case type.GET_ROOM_TYPE["GENERAL"].REQUESTED:
      return {
        ...state,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            general: {
              rooms: [],
              isSuccess: false,
            },
          },
        },
        loading: true,
        error: null,
      };
    case type.GET_ROOM_TYPE["GENERAL"].SUCCESS:
      return {
        ...state,
        loading: false,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            general: {
              rooms: action.data,
              isSuccess: true,
            },
          },
        },
      };
    case type.GET_ROOM_TYPE["GENERAL"].FAILED:
      return {
        ...state,
        loading: false,
        room: {
          ...state.room,
          roundRobin: {
            ...state.room.roundRobin,
            general: {
              rooms: [],
              isSuccess: false,
            },
          },
        },
      };
    case type.GET_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentRoom: action.data,
      };
    case type.UPDATE_ROOM_TYPE["SCHEDULING"].REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.UPDATE_ROOM_TYPE["SCHEDULING"].SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    case type.UPDATE_ROOM_TYPE["SCHEDULING"].FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    case type.UPDATE_ROOM_TYPE["COMMUNITY_PHARMACIST"].REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.UPDATE_ROOM_TYPE["COMMUNITY_PHARMACIST"].SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    case type.UPDATE_ROOM_TYPE["COMMUNITY_PHARMACIST"].FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    case type.UPDATE_ROOM_TYPE["COVID"].REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.UPDATE_ROOM_TYPE["COVID"].SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    case type.UPDATE_ROOM_TYPE["COVID"].FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    case type.UPDATE_ROOM_TYPE["GENERAL"].REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.UPDATE_ROOM_TYPE["GENERAL"].SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    case type.UPDATE_ROOM_TYPE["GENERAL"].FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    case type.GET_CURRENT_ROOM_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.GET_CURRENT_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentRoom: action.data,
        isSuccess: true,
      };
    case type.GET_CURRENT_ROOM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    default:
      return state;
  }
};
