import React from "react";

const Error = () => {
  return (
    <div>
      <h1 style={{ fontWeight: "bold" }}>
        เกิดข้อผิดพลาด! กรุณาลองอีกครั้งในภายหลัง
      </h1>
    </div>
  );
};

export default Error;
