import { Card } from "antd";
import styled from "styled-components";

export const SummaryCard = styled(Card)`
  border: none !important;
  border-radius: 20px !important;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  padding: 8px;
  width: 100%';
`;
