import React from "react";
import { Row, Col, Typography, Steps } from "antd";
import { SUMMARY_STEPS } from "./constant";
import {
  StepsContainer,
  SummarySteps,
  StepsCard,
  SummaryContainer,
  SummaryContent,
  CardStyled,
  ButtonStyled,
} from "./styles";
import SummaryNoteCard from "../Card/SummaryNote";
import PrescriptionNoteCard from "../Card/PrescriptionNote";
import SummaryPatientDetailCard from "../Card/SummaryPatientDetail";
import HistoryPatientDetailModal from "../Modal/HistoryPatientDetail";
import SummaryNoteModal from "../Modal/SummaryNote";
import SummaryPrescriptionNoteModal from "../Modal/SummaryPrescriptionNoteModal";
import { CloseCircleFilled } from "@ant-design/icons";

const { Title } = Typography;
const { Step } = Steps;

const Summary = (props) => {
  const {
    t,
    roomType,
    booking,
    userOma,
    color,
    setColor,
    note,
    setNote,
    prescriptionNote,
    setPrescriptionNote,
    role,
    drugTimeEvent,
    drugTimeFrequency,
    symptomGroupId,
    handleSelectMedicine,
    medicines,
    setMedicines,
    handleUpdatePrescriptionNote,
    visibleHistoryPatientDetailModal,
    setVisibleHistoryPatientDetailModal,
    visibleSummaryNoteModal,
    setVisibleSummaryNoteModal,
    visiblePrescriptionNoteModal,
    setVisiblePrescriptionNoteModal,
    handleUpdateBooking,
    handleRedirectToCommunityPharmacist,
    handleRedirectToDeliverDrug,
    handleRedirectToComplete,
    handleRedirectToDecline,
    handleCloseSymptomGroup,
    handleOkSymptomGroup,
    handleChangeSymptomGroup,
    visibleSymptomGroup,
    setVisibleSymptomGroup,
    deleteDrugFromPrescription,
    getDrugTimeFrequencyInfo,
    handleRedirectToDashboard,
    getDrugTimeEventInfo,
    symptomGroups,
    onSearch,
    medication,
  } = props;
  return (
    <SummaryContainer>
      <SummaryContent>
        <Row
          gutter={[24, 12]}
          justify="center"
          align="middle"
          className="p-5 pb-0"
        >
          <Col span={12} className="d-flex align-items-center">
            <Row>
              {role === "communityPharmacist" && (
                <Col className="mt-1 mr-1">
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    onClick={handleRedirectToDashboard}
                  />
                </Col>
              )}
              <Col>
                <Title className="mb-0" level={3}>
                  {t("summary_note")}
                </Title>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <StepsCard>
              <StepsContainer>
                <SummarySteps
                  size="small"
                  current={2}
                  className="d-flex align-items-center mr-4"
                >
                  {SUMMARY_STEPS.map((step, index) => (
                    <Step
                      key={index}
                      icon={step.icon}
                      title={t(`${step.title}`)}
                    />
                  ))}
                </SummarySteps>
              </StepsContainer>
            </StepsCard>
          </Col>
          <Col span={24}>
            <Row type="flex" gutter={[24, 24]} justify="space-between">
              <Col xs={12} sm={12} xl={8} style={{ maxWidth: "435px" }}>
                <SummaryPatientDetailCard
                  t={t}
                  patient={booking.current.patient}
                  userId={booking.current.practitionerAppUserId}
                  setVisibleHistoryPatientDetailModal={
                    setVisibleHistoryPatientDetailModal
                  }
                />
              </Col>
              <Col xs={12} sm={12} xl={8} style={{ maxWidth: "435px" }}>
                <Row>
                  <Col span={24}>
                    <SummaryNoteCard
                      t={t}
                      booking={booking}
                      setVisibleSummaryNoteModal={setVisibleSummaryNoteModal}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} xl={8} style={{ maxWidth: "435px" }}>
                <PrescriptionNoteCard
                  t={t}
                  booking={booking}
                  setVisiblePrescriptionNoteModal={
                    setVisiblePrescriptionNoteModal
                  }
                />
                {["doctor", "callCenter"].includes(role) ? (
                  <CardStyled title={t("action")}>
                    <h1>{t("please_recheck_all_details_before_complete")}</h1>
                    <div>
                      {t("if_there_is_a_problem_with_the_consultation")}
                    </div>
                    <div className="text-right">
                      <ButtonStyled
                        type="danger"
                        onClick={handleRedirectToDecline}
                      >
                        {t("cancel")}
                      </ButtonStyled>
                      <ButtonStyled
                        type="primary"
                        onClick={handleRedirectToComplete}
                      >
                        {t("complete")}
                      </ButtonStyled>
                    </div>
                  </CardStyled>
                ) : role === "communityPharmacist" ? (
                  <CardStyled title="Delivery">
                    <h1>{t("please_recheck_all_details_before_deliver")}</h1>
                    <div>{t("full_heath_record")}</div>
                    <div style={{ textAlign: "right" }}>
                      <ButtonStyled
                        type="danger"
                        style={{ marginRight: 20 }}
                        onClick={handleRedirectToDecline}
                      >
                        {t("cancel")}
                      </ButtonStyled>
                      <ButtonStyled
                        type="primary"
                        onClick={handleRedirectToDeliverDrug}
                      >
                        {t("go_to_deliver")}
                      </ButtonStyled>
                    </div>
                  </CardStyled>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <HistoryPatientDetailModal
          visible={visibleHistoryPatientDetailModal}
          setVisible={setVisibleHistoryPatientDetailModal}
          patient={userOma}
          userId={booking.current.practitionerAppUserId}
        />
        <SummaryPrescriptionNoteModal
          t={t}
          visible={visiblePrescriptionNoteModal}
          setVisible={setVisiblePrescriptionNoteModal}
          prescriptionNote={prescriptionNote}
          setPrescriptionNote={setPrescriptionNote}
          handleUpdatePrescriptionNote={handleUpdatePrescriptionNote}
        />
        <SummaryNoteModal
          t={t}
          visible={visibleSummaryNoteModal}
          setVisible={setVisibleSummaryNoteModal}
          note={note}
          setNote={setNote}
          color={color}
          setColor={setColor}
          roomType={roomType}
          handleUpdateBooking={handleUpdateBooking}
        />
      </SummaryContent>
    </SummaryContainer>
  );
};
export default Summary;
