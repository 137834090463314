import { call, put, takeEvery } from "redux-saga/effects";
import { Organization, Submission, AppUserOma } from "../../api";
import * as type from "../types";

function* getOrganizations({ filter }) {
  try {
    const result = yield call(Organization.getOrganizations, filter);
    yield put({ type: type.GET_ORGANIZATIONS_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_ORGANIZATIONS_FAILED, error: e.message });
  }
}

function* getUsersByOrganization({ id }) {
  try {
    const result = yield call(Organization.getUsersByOrganization, id);
    yield put({ type: type.GET_USERS_BY_ORGANIZATION_SUCCESS, data: result });
  } catch (e) {
    yield put({
      type: type.GET_USERS_BY_ORGANIZATION_FAILED,
      error: e.message,
    });
  }
}

function* getCommunityPharmaciesByOrganization({ id }) {
  try {
    const result = yield call(
      Organization.getCommunityPharmaciesByOrganization,
      id
    );
    yield put({
      type: type.GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_FAILED,
      error: e.message,
    });
  }
}

function* getOrganizationSubmissionList({ id }) {
  try {
    const listOrgId = yield call(AppUserOma.getOrganizationsUser, id);
    const result = yield call(
      Organization.getOrganizationSubmissions,
      Array.isArray(listOrgId) ? listOrgId : []
    );
    yield put({
      type: type.GET_ORGANIZATIONS_SUBMISION_LIST_SUCCESS,
      data: result,
      listUserOrg: listOrgId,
    });
  } catch (e) {
    yield put({
      type: type.GET_ORGANIZATIONS_SUBMISION_LIST_FAILED,
      error: e.message,
    });
  }
}

function* updateOrganizationSubmission(id, option) {
  try {
    const result = yield call(Submission.updateUserOrgSubmission, id, option);
    yield put({
      type: type.UPDATE_ORGANIZATIONS_SUBMISION_LIST_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_ORGANIZATIONS_SUBMISION_LIST_FAILED,
      error: e.message,
    });
  }
}

function* organizationSaga() {
  yield takeEvery(type.GET_ORGANIZATIONS_REQUESTED, getOrganizations);
  yield takeEvery(
    type.GET_USERS_BY_ORGANIZATION_REQUESTED,
    getUsersByOrganization
  );
  yield takeEvery(
    type.GET_ORGANIZATIONS_SUBMISION_LIST_REQUESTED,
    getOrganizationSubmissionList
  );
  yield takeEvery(
    type.GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_REQUESTED,
    getCommunityPharmaciesByOrganization
  );
  yield takeEvery(
    type.UPDATE_ORGANIZATIONS_SUBMISION_LIST_REQUESTED,
    updateOrganizationSubmission
  );
}

export default organizationSaga;
