import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/kycPatientImages";

async function getKycImages({ id }) {
  const { data } = await http.get(
    `${apiEndpoint}?filter[where][patientId]=${id}`
  );
  return data[0];
}

export { getKycImages };
