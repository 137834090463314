import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Card, Row, Col, Form, Input, Button } from "antd";
import Chart from "react-apexcharts";
import { HeartRateText } from "./styles";

const HeartRateGraph = (props) => {
  const { graphs, defaultHeartRates, t } = props;
  const { lines } = graphs;

  return (
    <Card className="mt-2" title={t("heart_rate_trend")}>
      <Row>
        <Col span={24} className="mb-2">
          <div>{t("heart_rate_threshold")}</div>
        </Col>

        <Col span={10}>
          {`${defaultHeartRates[0]} - ${defaultHeartRates[1]}`}
        </Col>
      </Row>
      <Chart
        options={lines.options}
        series={lines.series}
        type="line"
        width="550"
      />
    </Card>
  );
};

export default HeartRateGraph;
