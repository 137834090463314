import React from "react";
import { Space, Collapse } from "antd";
import {
  Container,
  Text,
  Icon,
  DropDownWidget,
  HeaderPanel,
  TextError,
} from "../styles";
import {
  GiHealthNormal,
  RiHeartPulseFill,
  AiFillFileText,
  GiMedicines,
  RiVirusLine,
  FaSyringe,
  RiTestTubeFill,
  GiStethoscope,
  RiHealthBookFill,
  ImImages,
} from "react-icons/all";
import {
  DemographicDataSection,
  VitalSignsSection,
  MedicalAndMedication,
  UnderlyingDiseases,
  VaccinesInfectionHistory,
  PreviousOrCurrentClinicalPraticipant,
  PhysicalExamination,
  NasopharyngealSwab,
  SinuscopyAndImages,
  CCPassTesting,
} from "../../ClinicTrialEligibilityWidgets";

import "../styles.css";

const { Panel } = Collapse;

const EligibilityAssessment = (props) => {
  const {
    t,
    eligibilityAssessmentForm,
    customRequest,
    getNameFromUrl,
    daySelection,
    currentRecord,
    dateList,
  } = props;

  const renderNormalMenu = (items) => {
    return (
      <Container
        style={{ minWidth: 250, backgroundColor: "white" }}
        className="shadow px-2 py-1"
      >
        {items.map((item) => {
          return (
            <a
              key={item.key}
              onClick={(e) => {
                e.preventDefault();
                eligibilityAssessmentForm.setFieldValue(
                  "day",
                  parseInt(item.label)
                );
              }}
            >
              <Text className="py-1 my-1" key={item.key}>
                {item.label}
              </Text>
            </a>
          );
        })}
      </Container>
    );
  };

  return (
    <>
      <Container>
        <Space align="center">
          <Icon component={GiHealthNormal} size="30px" color="#2BA796" />
          <Text primary className="ml-2" bold size="24px">
            {t("eligibility_assessment")}
          </Text>
        </Space>
      </Container>
      {daySelection && (
        <Container className="mt-3">
          <DropDownWidget
            bold
            minWidth="200px"
            title={"Select Day"}
            value={eligibilityAssessmentForm.values.day}
            placeholder="Select day"
            renderList={renderNormalMenu(dateList)}
          />
          {eligibilityAssessmentForm.errors.day && (
            <TextError>{eligibilityAssessmentForm.errors.day}</TextError>
          )}
        </Container>
      )}
      <Container className="mt-4">
        <Collapse expandIconPosition="right">
          <Panel
            header={
              <HeaderPanel
                iconComponent={AiFillFileText}
                title={t("demographic")}
              />
            }
            key="1"
          >
            <DemographicDataSection t={t} form={eligibilityAssessmentForm} />
          </Panel>
          <Panel
            header={
              <HeaderPanel
                iconComponent={RiHeartPulseFill}
                title={t("vital_signs")}
              />
            }
            key="2"
          >
            <VitalSignsSection t={t} form={eligibilityAssessmentForm} />
          </Panel>
          <Panel
            header={
              <HeaderPanel
                iconComponent={GiMedicines}
                title={t("medication_histories")}
              />
            }
            key="3"
          >
            <MedicalAndMedication t={t} form={eligibilityAssessmentForm} />
          </Panel>
          <Panel
            header={
              <HeaderPanel
                iconComponent={RiVirusLine}
                title={t("underlying_diseases")}
              />
            }
            key="4"
          >
            <UnderlyingDiseases t={t} form={eligibilityAssessmentForm} />
          </Panel>
          <Panel
            header={
              <HeaderPanel
                iconComponent={FaSyringe}
                title={t("infection_history")}
              />
            }
            key="5"
          >
            <VaccinesInfectionHistory
              t={t}
              getNameFromUrl={getNameFromUrl}
              customRequest={customRequest}
              currentRecord={currentRecord}
              form={eligibilityAssessmentForm}
            />
          </Panel>
          <Panel
            header={
              <HeaderPanel
                iconComponent={RiTestTubeFill}
                title={t("current_clinical_trial")}
              />
            }
            key="6"
          >
            <PreviousOrCurrentClinicalPraticipant
              t={t}
              form={eligibilityAssessmentForm}
            />
          </Panel>
          <Panel
            header={
              <HeaderPanel
                iconComponent={GiStethoscope}
                title={t("physical_examination")}
              />
            }
            key="7"
          >
            <PhysicalExamination t={t} form={eligibilityAssessmentForm} />
          </Panel>
          <Panel
            header={
              <HeaderPanel
                iconComponent={RiHealthBookFill}
                title={t("nasop_oropharyngeal_swab")}
              />
            }
            key="8"
          >
            <NasopharyngealSwab
              t={t}
              form={eligibilityAssessmentForm}
              currentRecord={currentRecord}
              customRequest={customRequest}
              getNameFromUrl={getNameFromUrl}
            />
          </Panel>
          <Panel
            header={
              <HeaderPanel
                iconComponent={ImImages}
                title={t("sinuscopy_images")}
              />
            }
            key="9"
          >
            <SinuscopyAndImages
              t={t}
              form={eligibilityAssessmentForm}
              customRequest={customRequest}
              getNameFromUrl={getNameFromUrl}
              currentRecord={currentRecord}
            />
          </Panel>
          <Panel
            header={
              <HeaderPanel iconComponent={RiVirusLine} title={t("cc_pass")} />
            }
            key="10"
          >
            <CCPassTesting t={t} form={eligibilityAssessmentForm} />
          </Panel>
        </Collapse>
      </Container>
    </>
  );
};

export default EligibilityAssessment;
