import React from "react";
import { Card, Row, Col } from "antd";
import Chart from "react-apexcharts";

const OxygenGraph = (props) => {
  const { graphs, defaultOxygen, t } = props;
  const { lines } = graphs;

  return (
    <Card className="mt-2" title={t("oxygen_concentration_trend")}>
      <Row>
        <Col span={24} className="mb-2">
          <div>{t("oxygen_concentration_threshold")}</div>
        </Col>

        <Col span={10}>{`${defaultOxygen[0]} - ${defaultOxygen[1]}`}</Col>
      </Row>
      <Chart
        options={lines.options}
        series={lines.series}
        type="line"
        width="550"
      />
    </Card>
  );
};

export default OxygenGraph;
