import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
const { TabPane } = Tabs;
import { Container } from "./styles";
import HBPM from "./HBPM";
import SMBG from "./SMBG";
import { MonitoringReport, Booking, UserOma } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import HeartRate from "./HeartRate";
import OxygenConcentration from "./OxygenConcentration";
import BookingHistory from "./BookingHistory";
import BMI from "./BMI";
import Temperature from "./Temperature";
import { useTranslation } from "react-i18next";

const MonitoringInfo = ({ appUserId, isVideoCall }) => {
  const { t } = useTranslation();
  const [reports, setReports] = useState(false);
  const [glucoses, setGlucoses] = useState(false);
  const [pressures, setPressures] = useState(false);
  const [heartRates, setHeartRates] = useState(false);
  const [temperature, setTemperature] = useState(false);
  const [oxygen, setOxygen] = useState(false);
  const [bmi, setBmi] = useState(false);
  const [defaultGlucoseValue, setDefaultGlucoseValue] = useState([70, 130]);
  const [defaultPressure, setDefaultPressure] = useState({
    above: [90, 140],
    below: [60, 90],
  });
  const [defaultHeartRateValue, setDefaultHeartRateValue] = useState([60, 100]);
  const [defaultTemperatureValue, setDefaultTemperatureValue] = useState([
    35.4, 37.4,
  ]);
  const [defaultOxygenValue, setDefaultOxygenValue] = useState([90, 95]);
  const [defaultBmiValue, setDefaultBmiValue] = useState([18.5, 22.99]);
  const dispatch = useDispatch();
  const OmaPatient = useSelector((state) => state.userOma);
  const monitoringReport = useSelector((state) => state.monitoringReport);
  const booking = useSelector((state) => state.booking);
  const userOma = useSelector((state) => state.appUserOma);
  const userId = userOma.information && userOma.information.id;

  useEffect(() => {
    fetchMonitoringReport();
  }, []);

  useEffect(() => {
    if (monitoringReport.data) {
      checkFilterReports();
    }
  }, [monitoringReport.data]);

  useEffect(() => {
    fetchUserOmaByHie();
  }, []);

  useEffect(() => {
    if (OmaPatient.user) {
      fetchDiagnosisData();
    }
  }, [OmaPatient.user]);

  const fetchUserOmaByHie = () => {
    dispatch(UserOma.getUserOmaByHie(appUserId));
  };

  const fetchDiagnosisData = () => {
    dispatch(UserOma.getUserOmaDiagnosis(OmaPatient.user[0].id));
  };
  const fetchMonitoringReport = () => {
    dispatch(MonitoringReport.getMonitoringReportByUserId(Number(appUserId)));
    setReports(monitoringReport.data ? monitoringReport.data : []);
  };

  const checkFilterReports = () => {
    const filterGlucose = monitoringReport.data.filter(
      (report) => report.monitoringTypeId === 3
    );
    const filterPressure = monitoringReport.data.filter(
      (report) => report.monitoringTypeId === 4
    );
    const filterBmi = monitoringReport.data.filter(
      (report) => report.monitoringTypeId === 5
    );
    const filterTemperature = monitoringReport.data.filter(
      (report) => report.monitoringTypeId === 6
    );
    const filterOxygen = monitoringReport.data.filter(
      (report) => report.monitoringTypeId === 7
    );
    const filterHeartRate = monitoringReport.data.filter(
      (report) => report.monitoringTypeId === 8
    );
    checkGlucoseValue(filterGlucose);
    checkPressureValue(filterPressure);
    checkHeartRateValue(filterHeartRate);
    checkOxygenValue(filterOxygen);
    checkBmiValue(filterBmi);
    checkTemperatureValue(filterTemperature);
  };

  const checkPressureValue = (reports) => {
    const objectValue = {
      data: {
        high: [],
        low: [],
        periods: [],
        reasons: [],
        dates: [],
      },
      arr: [],
    };
    objectValue.arr = reports.map((report) => {
      const { high, low, periods, reasons, dates } = objectValue.data;
      const pressureMeasurements = report.detail;
      high.push(pressureMeasurements.high);
      low.push(pressureMeasurements.low);
      periods.push(pressureMeasurements.period);
      reasons.push(pressureMeasurements.reason);
      dates.push(moment(pressureMeasurements.timeStamp).format("DD-MM-YYYY"));
      return {
        high: pressureMeasurements.high,
        low: pressureMeasurements.low,
        date: moment(pressureMeasurements.timeStamp).format("DD-MM-YYYY"),
      };
    });
    setPressures(objectValue);
  };

  const checkGlucoseValue = (reports) => {
    const objectValue = {
      data: {
        arr: [],
        periods: [],
        reasons: [],
        dates: [],
      },
      arr: [],
    };
    objectValue.arr = reports.map((report) => {
      const { glucose, period, reason, timeStamp } = report.detail;
      objectValue.data.arr.push(glucose);
      objectValue.data.periods.push(period);
      objectValue.data.reasons.push(reason);
      objectValue.data.dates.push(moment(timeStamp).format("DD-MM-YYYY"));
      return {
        value: glucose,
        date: moment(timeStamp).format("DD-MM-YYYY"),
      };
    });
    setGlucoses(objectValue);
  };

  const checkHeartRateValue = (reports) => {
    const objectValue = {
      data: {
        arr: [],
        dates: [],
      },
      arr: [],
    };
    objectValue.arr = reports.map((report) => {
      const heartMeasurements = report.detail;
      objectValue.data.arr.push(heartMeasurements.times);
      objectValue.data.dates.push(
        moment(heartMeasurements.timeStamp).format("DD-MM-YYYY")
      );
      return {
        value: heartMeasurements.times,
        date: moment(heartMeasurements.timeStamp).format("DD-MM-YYYY"),
      };
    });
    setHeartRates(objectValue);
  };
  const checkTemperatureValue = (reports) => {
    const objectValue = {
      data: {
        arr: [],
        dates: [],
      },
      arr: [],
    };
    objectValue.arr = reports.map((report) => {
      const temperatureMeasurements = report.detail;
      objectValue.data.arr.push(temperatureMeasurements.celsius);
      objectValue.data.dates.push(
        moment(temperatureMeasurements.timeStamp).format("DD-MM-YYYY")
      );
      return {
        value: temperatureMeasurements.celsius,
        date: moment(temperatureMeasurements.timeStamp).format("DD-MM-YYYY"),
      };
    });
    setTemperature(objectValue);
  };

  const checkOxygenValue = (reports) => {
    const objectValue = {
      data: {
        arr: [],
        dates: [],
      },
      arr: [],
    };
    objectValue.arr = reports.map((report) => {
      const o2Measurements = report.detail;
      objectValue.data.arr.push(o2Measurements.percent);
      objectValue.data.dates.push(
        moment(o2Measurements.timeStamp).format("DD-MM-YYYY")
      );
      return {
        value: o2Measurements.percent,
        date: moment(o2Measurements.timeStamp).format("DD-MM-YYYY"),
      };
    });
    setOxygen(objectValue);
  };

  const checkBmiValue = (reports) => {
    const objectValue = {
      data: {
        arr: [],
        dates: [],
      },
      arr: [],
    };
    objectValue.arr = reports.map((report) => {
      const weightMeasurements = report.detail;
      objectValue.data.arr.push(weightMeasurements.bmi);
      objectValue.data.dates.push(
        moment(weightMeasurements.timeStamp).format("DD-MM-YYYY")
      );
      return {
        value: weightMeasurements.bmi,
        date: moment(weightMeasurements.timeStamp).format("DD-MM-YYYY"),
      };
    });
    setBmi(objectValue);
  };

  const checkIsBetweenDate = (time, start, end) => {
    const formatMoment = "DD-MM-YYYY";
    const startDate = moment(start).subtract(1, "days");
    const endDate = moment(end).add(1, "days");
    const momentTime = moment(time).format(formatMoment);
    return moment(momentTime, formatMoment).isBetween(
      moment(startDate, formatMoment),
      moment(endDate, formatMoment)
    );
  };

  const filterReport = (start, end, monitoringTypeId) => {
    const filterReports = monitoringReport.data.filter((report) => {
      if (
        report.monitoringTypeId === monitoringTypeId &&
        report.recordedAt &&
        checkIsBetweenDate(report.recordedAt, start, end)
      ) {
        return report;
      }
    });
    switch (monitoringTypeId) {
      case 3:
        checkGlucoseValue(filterReports);
        break;
      case 4:
        checkPressureValue(filterReports);
        break;
      case 5:
        checkBmiValue(filterReports);
        break;
      case 6:
        checkTemperatureValue(filterReports);
        break;
      case 7:
        checkOxygenValue(filterReports);
        break;
      case 8:
        checkHeartRateValue(filterReports);
        break;

      default:
        break;
    }
  };

  return (
    <Container>
      <Tabs defaultActiveKey="smbg">
        <TabPane tab={t("smbg")} key="smbg">
          <SMBG
            t={t}
            reports={reports}
            glucoses={glucoses}
            defaultGlucoses={defaultGlucoseValue}
            filterReport={filterReport}
            isVideoCall={isVideoCall}
          />
        </TabPane>
        <TabPane tab={t("hbpm")} key="hbpm">
          <HBPM
            t={t}
            pressures={pressures}
            defaultAbove={defaultPressure.above}
            defaultBelow={defaultPressure.below}
            filterReport={filterReport}
            isVideoCall={isVideoCall}
          />
        </TabPane>
        <TabPane tab={t("heart_rate")} key="heartRate">
          <HeartRate
            t={t}
            reports={reports}
            heartRates={heartRates}
            defaultHeartRates={defaultHeartRateValue}
            filterReport={filterReport}
            isVideoCall={isVideoCall}
          />
        </TabPane>
        <TabPane tab={t("oxygen")} key="oxygen">
          <OxygenConcentration
            t={t}
            reports={reports}
            oxygen={oxygen}
            defaultOxygen={defaultOxygenValue}
            filterReport={filterReport}
            isVideoCall={isVideoCall}
          />
        </TabPane>
        <TabPane tab={t("temperature")} key="temperature">
          <Temperature
            t={t}
            reports={reports}
            temperature={temperature}
            defaultTemperature={defaultTemperatureValue}
            filterReport={filterReport}
            isVideoCall={isVideoCall}
          />
        </TabPane>
        <TabPane tab={t("bmi")} key="bmi">
          <BMI
            t={t}
            reports={reports}
            bmi={bmi}
            defaultBmi={defaultBmiValue}
            filterReport={filterReport}
            isVideoCall={isVideoCall}
          />
        </TabPane>
        <TabPane tab={t("history_booking")} key="BookingHistory">
          <BookingHistory booking={booking} OmaPatient={OmaPatient} />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default MonitoringInfo;
