import { View, Document, Page, Text, Image } from "@react-pdf/renderer";
import React from "react";
import { HEADER_DEFAULT_FORMS } from "../constant";
import CheckboxOutline from "../assets/CheckboxOutline";
import Checkbox from "../assets/Checkbox";
import styles, { TableContainer, TitlePDFFile } from "../styles";
import moment from "moment";

export default function AdverseLogPreview(props) {
  const { t, data } = props;
  return (
    <Document>
      <Page size="A4">
        <Text style={styles.header}>{t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <TitlePDFFile t={t} />
          <View style={styles.titleHeader}>
            <Text style={styles.dateSignedText}>
              {t("subjects_initials")}: {data.subjectsInitials || "-"}
            </Text>
            <Text style={styles.dateSignedText}>
              {t("crf_subject_id")}: {data.subjectId}
            </Text>
            <Text style={styles.timeText}>
              {t("crf_page")} {data.page} {t("crf_of")} {data.of}
            </Text>
          </View>
          <View style={styles.titleSpcBtwContainer}>
            <Text style={styles.titleText}>{t("adverse_log")}</Text>
            <View style={[styles.row, { alignItems: "center" }]}>
              <Text style={[styles.textDescription, { marginRight: 3 }]}>
                {t("crf_adverse_check_box")}
              </Text>
              {data.allAdverseEvent ? (
                <Checkbox width={10} height={10} />
              ) : (
                <CheckboxOutline width={10} height={10} />
              )}
            </View>
          </View>
          <Text style={[styles.textDescription, { marginBottom: 10 }]}>
            {t("crf_adverse_check_sub_title")}
          </Text>
          <TableContainer>
            <View style={[styles.row, { height: 70 }]}>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textDescriptionCenter}>#</Text>
              </View>
              <View style={[styles.colHeaderAdv, { width: 40 }]}>
                <Text
                  style={[styles.textVerticalAdv, { width: 25, bottom: 5 }]}
                >
                  {t("crf_date_reported")}
                </Text>
              </View>
              <View style={[styles.colItemTNSS, { justifyContent: "center" }]}>
                <Text style={styles.textDescriptionCenter}>
                  {t("crf_adverse_desp")}
                </Text>
              </View>
              <View style={[styles.colItemTNSS, { justifyContent: "center" }]}>
                <Text style={[styles.textDescription, styles.textCenter]}>
                  {t("crf_adverse_event")}
                </Text>
              </View>
              <View style={[styles.colHeaderAdv, { width: 40 }]}>
                <Text
                  style={[styles.textVerticalAdv, { width: 25, bottom: 5 }]}
                >
                  {t("crf_start_date")}
                </Text>
              </View>
              <View style={[styles.colHeaderAdv, { width: 40 }]}>
                <Text
                  style={[styles.textVerticalAdv, { width: 25, bottom: 5 }]}
                >
                  {t("crf_end_date")}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>
                  {t("crf_adverse_ongoing")}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>
                  {t("crf_adverse_outcome")}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>
                  {t("crf_severity_grade")}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>{t("crf_serious")}</Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>
                  {t("crf_ae_treatment")}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>{t("crf_expected")}</Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>
                  {t("crf_study_attribution")}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>
                  {t("crf_action_taken")}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>
                  {t("crf_device_attribution")}
                </Text>
              </View>
              <View style={styles.colHeaderAdv}>
                <Text style={styles.textVerticalAdv}>{t("pi_initials")}</Text>
              </View>
              <View
                style={[styles.colHeaderAdv, { borderRight: 0, width: 40 }]}
              >
                <Text style={[styles.textVerticalAdv, { width: 15 }]}>
                  {t("date_pi_initials")}
                </Text>
              </View>
            </View>
            {Array.isArray(data.dataList) &&
              data.dataList.map((item, index) => {
                const styleCol =
                  data.dataList.length - 1 === index
                    ? styles.colLastRowItemAdv
                    : styles.colItemAdv;
                return (
                  <View key={index} style={styles.row}>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>
                        {item.aeNumner}
                      </Text>
                    </View>
                    <View style={[styleCol, { width: 40 }]}>
                      <Text style={styles.textDescription}>
                        {moment(item.dateReported).isValid()
                          ? moment(item.dateReported).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                    <View
                      style={
                        data.dataList.length - 1 === index
                          ? styles.colItemLastRow
                          : styles.colItemTNSS
                      }
                    >
                      <Text style={styles.textDescription}>
                        {item.description}
                      </Text>
                    </View>
                    <View
                      style={
                        data.dataList.length - 1 === index
                          ? styles.colItemLastRow
                          : styles.colItemTNSS
                      }
                    >
                      <Text style={styles.textDescription}>
                        {item.adverseEvent}
                      </Text>
                    </View>
                    <View style={[styleCol, { width: 40 }]}>
                      <Text style={styles.textDescription}>
                        {moment(item.startDate).isValid()
                          ? moment(item.startDate).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                    <View style={[styleCol, { width: 40 }]}>
                      <Text style={styles.textDescription}>
                        {moment(item.endDate).isValid()
                          ? moment(item.endDate).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>{item.ongoing}</Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>{item.outcome}</Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>
                        {item.severityGrade}
                      </Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>{item.serious}</Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>
                        {item.aeTreatment}
                      </Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>
                        {item.expected}
                      </Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>
                        {item.studyAttribution}
                      </Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>
                        {item.actionTaken}
                      </Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>
                        {item.deviceAttribution}
                      </Text>
                    </View>
                    <View style={styleCol}>
                      <Text style={styles.textDescription}>
                        {item.piInitials}
                      </Text>
                    </View>
                    <View
                      style={[
                        styleCol,
                        {
                          borderRight: 0,
                          width: 40,
                        },
                      ]}
                    >
                      <Text style={styles.textDescription}>
                        {moment(item.dateofInitials).isValid()
                          ? moment(item.dateofInitials).format("DD/MM/YY")
                          : ""}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </TableContainer>
          <Text style={[styles.textDescription, { marginVertical: 10 }]}>
            {t("crf_adverse_footer")}
          </Text>
          <TableContainer>
            <View style={styles.row}>
              <View style={[styles.colItemTNSS, { justifyContent: "center" }]}>
                <View
                  style={{ flexDirection: "row", alignItems: "flex-start" }}
                >
                  <Text style={[styles.textBold, { lineHeight: 1 }]}>
                    {t("crf_adverse_outcome")}
                  </Text>
                  <Text
                    style={[styles.textBold, { fontSize: 4, lineHeight: 0.5 }]}
                  >
                    1
                  </Text>
                </View>
              </View>
              <View style={[styles.colItemTNSS, { justifyContent: "center" }]}>
                <View
                  style={{ flexDirection: "row", alignItems: "flex-start" }}
                >
                  <Text style={[styles.textBold, { lineHeight: 1 }]}>
                    {t("crf_severity_grade")}
                  </Text>
                  <Text
                    style={[styles.textBold, { fontSize: 4, lineHeight: 0.5 }]}
                  >
                    2
                  </Text>
                </View>
              </View>
              <View style={[styles.colItemTNSS, { justifyContent: "center" }]}>
                <View
                  style={{ flexDirection: "row", alignItems: "flex-start" }}
                >
                  <Text style={[styles.textBold, { lineHeight: 1 }]}>
                    {t("crf_ae_treatment")}
                  </Text>
                  <Text
                    style={[styles.textBold, { fontSize: 4, lineHeight: 0.5 }]}
                  >
                    3
                  </Text>
                </View>
              </View>
              <View style={styles.colItemTNSS}>
                <View
                  style={{ flexDirection: "row", alignItems: "flex-start" }}
                >
                  <Text style={[styles.textBold, { lineHeight: 1 }]}>
                    {t("crf_action_taken_study_intervention")}
                  </Text>
                  <Text
                    style={[styles.textBold, { fontSize: 4, lineHeight: 0.5 }]}
                  >
                    4
                  </Text>
                </View>
              </View>
              <View style={styles.colLastItem}>
                <View
                  style={{ flexDirection: "row", alignItems: "flex-start" }}
                >
                  <Text style={[styles.textBold, { lineHeight: 1 }]}>
                    {t("crf_attribution_relatedness")}
                  </Text>
                  <Text
                    style={[styles.textBold, { fontSize: 4, lineHeight: 0.5 }]}
                  >
                    5
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[styles.colItemLastRow, { alignItems: "flex-start" }]}
              >
                <Text style={styles.textDescription}>
                  {t("crf_adverse_fatal")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_not_recovered")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_recovered_sequelae")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_recovered_o_sequelae")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_recovering_resolving")}
                </Text>
              </View>
              <View
                style={[styles.colItemLastRow, { alignItems: "flex-start" }]}
              >
                <Text style={styles.textDescription}>
                  {t("crf_adverse_mild")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_moderate")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_severe")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_threatening")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_death")}
                </Text>
              </View>
              <View
                style={[styles.colItemLastRow, { alignItems: "flex-start" }]}
              >
                <Text style={styles.textDescription}>
                  {t("crf_adverse_none")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_medications")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_non_medications")}
                </Text>
              </View>
              <View
                style={[styles.colItemLastRow, { alignItems: "flex-start" }]}
              >
                <Text style={styles.textDescription}>
                  {t("crf_adverse_none")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_interrupted")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_discontinued")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_dose_reduced")}
                </Text>
                <Text style={styles.textDescription}>
                  {t("crf_dose_increased")}
                </Text>

                <Text style={styles.textDescription}>
                  {t("crf_not_applicable")}
                </Text>
              </View>
              <View
                style={[
                  styles.colLastItemLastRow,
                  { alignItems: "flex-start" },
                ]}
              >
                <Text style={styles.textDescription}>{t("crf_definite")}</Text>
                <Text style={styles.textDescription}>{t("crf_probable")}</Text>
                <Text style={styles.textDescription}>{t("crf_possible")}</Text>
                <Text style={styles.textDescription}>{t("crf_unlikely")}</Text>
                <Text style={styles.textDescription}>{t("crf_unrelated")}</Text>
                <Text style={styles.textDescription}>
                  {t("crf_adverse_not_applicable")}
                </Text>
              </View>
            </View>
          </TableContainer>
          <Text style={[styles.textDescription, { marginVertical: 7 }]}>
            {t("crf_look_up_corresponding_ae")}:
            <Text style={{ textDecoration: "underline" }}>
              {t("crf_look_up_corresponding_ae_url")}
            </Text>
          </Text>
        </View>
        <View style={[styles.paddingBody, styles.row, { marginBottom: 20 }]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/31/2022
          </Text>
        </View>
      </Page>
    </Document>
  );
}
