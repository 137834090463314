import React, { Component } from "react";
import styled from "styled-components";

const StyledText = styled.p`
  color: ${(props) => props.theme.primaryText};
  color: ${(props) => props.second && props.theme.secondaryText};
  ${(props) => props.bold && "font-weight: bold;"}
`;

export default class index extends Component {
  render() {
    return <StyledText {...this.props} />;
  }
}
