import * as type from "../types";
import { uniqBy, sortBy } from "lodash";

const initialState = {
  clinicTrialResults: {
    data: [],
    isSuccess: false,
    loading: false,
    error: null,
  },
  researchObjects: {
    data: [],
    isSuccess: false,
    loading: false,
    error: null,
  },
  researchPrograms: {
    data: [],
    isSuccess: false,
    loading: false,
    error: null,
  },
  registerForm: {
    currentId: null,
    data: {},
    submit: false,
    isSuccess: false,
    loading: false,
    error: null,
  },
  listAssessment: {
    data: [],
    isSuccess: false,
    loading: false,
    error: null,
  },
  deviationData: {
    data: [],
    isSuccess: false,
    loading: false,
    error: null,
  },
  particitpantList: {
    isSuccess: false,
    data: null,
    error: null,
    loading: false,
    submit: false,
  },
};

export default (state = initialState, action) => {
  let finalData = [];
  switch (action.type) {
    case type.GET_RESEARCH_PROGRAM_BY_ORG_REQUEST:
      return {
        ...state,
        researchPrograms: {
          ...state.researchPrograms,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.GET_RESEARCH_PROGRAM_BY_ORG_SUCCESS:
      return {
        ...state,
        researchPrograms: {
          ...state.researchPrograms,
          data: action.data,
          loading: false,
          isSuccess: true,
        },
      };
    case type.GET_RESEARCH_PROGRAM_BY_ORG_FAILED:
      return {
        ...state,
        researchPrograms: {
          ...state.researchPrograms,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST:
      return {
        ...state,
        clinicTrialResults: {
          ...state.clinicTrialResults,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_SUCCESS:
      return {
        ...state,
        clinicTrialResults: {
          ...state.clinicTrialResults,
          data: action.data,
          loading: false,
          isSuccess: true,
        },
      };
    case type.GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_FAILED:
      return {
        ...state,
        clinicTrialResults: {
          ...state.clinicTrialResults,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST:
      return {
        ...state,
        researchObjects: {
          ...state.researchObjects,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_SUCCESS:
      return {
        ...state,
        researchObjects: {
          ...state.researchObjects,
          data: action.data,
          loading: false,
          isSuccess: true,
        },
      };
    case type.GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_FAILED:
      return {
        ...state,
        researchObjects: {
          ...state.researchObjects,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.CREATE_CLINIC_TRIAL_CONSENT_FORM_REQUEST:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.CREATE_CLINIC_TRIAL_CONSENT_FORM_SUCCESS:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          data: { ...state.registerForm.data, ...action.data },
          isSuccess: true,
        },
      };
    case type.CREATE_CLINIC_TRIAL_CONSENT_FORM_FAILED:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };
    case type.CREATE_CLINIC_TRIAL_GENERAL_FORM_REQUEST:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.CREATE_CLINIC_TRIAL_GENERAL_FORM_SUCCESS:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          data: { ...state.registerForm.data, ...action.data },
          isSuccess: true,
        },
      };
    case type.CREATE_CLINIC_TRIAL_GENERAL_FORM_FAILED:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };

    case type.CREATE_CLINIC_TRIAL_CONTACT_FORM_REQUEST:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.CREATE_CLINIC_TRIAL_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          data: { ...state.registerForm.data, ...action.data },
          isSuccess: true,
        },
      };
    case type.CREATE_CLINIC_TRIAL_CONTACT_FORM_FAILED:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };
    case type.CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          data: { ...state.registerForm.data, ...action.data },
          isSuccess: true,
        },
      };
    case type.CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };
    case type.UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          data: { ...state.registerForm.data, ...action.data },
          isSuccess: true,
        },
      };
    case type.UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };
    case type.APPROVE_CLINIC_TRIAL_REQUEST:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.APPROVE_CLINIC_TRIAL_SUCCESS:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: true,
          loading: false,
          isSuccess: true,
        },
      };
    case type.APPROVE_CLINIC_TRIAL_FAILED:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };
    case type.APPROVE_CLINIC_TRIAL_MANUALLY_REQUEST:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.APPROVE_CLINIC_TRIAL_MANUALLY_SUCCESS:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: true,
          loading: false,
          isSuccess: true,
        },
      };
    case type.APPROVE_CLINIC_TRIAL_MANUALLY_FAILED:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };
    case type.DECLINE_CLINIC_TRIAL_REQUEST:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.DECLINE_CLINIC_TRIAL_SUCCESS:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          submit: true,
          loading: false,
          isSuccess: true,
        },
      };
    case type.DECLINE_CLINIC_TRIAL_FAILED:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };
    case type.UPDATE_RECRUITMENT_OBJECT_REQUEST:
      return {
        ...state,
        clinicTrialResults: {
          ...state.clinicTrialResults,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.UPDATE_RECRUITMENT_OBJECT_SUCCESS:
      return {
        ...state,
        clinicTrialResults: {
          ...state.clinicTrialResults,
          loading: false,
          isSuccess: true,
          data: state.clinicTrialResults.data.map((item) => {
            return item.id === action.data?.id ? action.data : item;
          }),
        },
      };
    case type.UPDATE_RECRUITMENT_OBJECT_FAILED:
      return {
        ...state,
        clinicTrialResults: {
          ...state.clinicTrialResults,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST:
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS:
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          data: state.listAssessment.data.map((item) => {
            return item.id === action.data?.id ? action.data : item;
          }),
          loading: false,
          isSuccess: true,
        },
      };
    case type.EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED:
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_REQUEST:
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_SUCCESS:
      if (Array.isArray(action.data)) {
        finalData = uniqBy(sortBy(action.data, "updatedAt").reverse(), "day");
        finalData = sortBy(finalData, "day");
      }
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          data: finalData,
          loading: false,
          isSuccess: true,
        },
      };
    case type.GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_FAILED:
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.ADD_NEW_CLINIC_ASSESSMENT_BY_ID_REQUEST:
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.ADD_NEW_CLINIC_ASSESSMENT_BY_ID_SUCCESS:
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          data: [...state.listAssessment.data, action.data],
          isSuccess: true,
          loading: false,
        },
      };
    case type.ADD_NEW_CLINIC_ASSESSMENT_BY_ID_FAILED:
      return {
        ...state,
        listAssessment: {
          ...state.listAssessment,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.GET_RESEARCH_OBJECTS_REQUESTED:
      return {
        ...state,
        particitpantList: {
          ...state.particitpantList,
          submit: false,
          loading: true,
          error: null,
          isSuccess: false,
        },
      };
    case type.GET_RESEARCH_OBJECTS_SUCCESS:
      return {
        ...state,
        particitpantList: {
          ...state.particitpantList,
          loading: false,
          isSuccess: true,
          data: action.data,
          error: null,
        },
      };
    case type.GET_RESEARCH_OBJECTS_FAILED:
      return {
        ...state,
        particitpantList: {
          ...state.particitpantList,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.CREATE_CLINIC_TRIAL_DEVIATIONS_REQUEST:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.CREATE_CLINIC_TRIAL_DEVIATIONS_SUCCESS:
      finalData = sortBy(
        [...state.deviationData.data, action.data],
        "sequence"
      );
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          data: finalData,
          isSuccess: true,
          loading: false,
        },
      };
    case type.CREATE_CLINIC_TRIAL_DEVIATIONS_FAILED:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.DELETE_DEVIATIONS_BY_ID_REQUEST:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.DELETE_DEVIATIONS_BY_ID_SUCCESS:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          data: state.deviationData.data.filter(
            (item) => item.id !== action.data
          ),
          isSuccess: true,
          loading: false,
        },
      };
    case type.DELETE_DEVIATIONS_BY_ID_FAILED:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.WITHDRAWN_CLINIC_TRIAL_REQUEST:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.WITHDRAWN_CLINIC_TRIAL_SUCCESS:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          submit: true,
          isSuccess: true,
          loading: false,
        },
      };
    case type.WITHDRAWN_CLINIC_TRIAL_FAILED:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          submit: false,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.GET_CLINIC_TRIAL_DEVIATIONS_REQUEST:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.GET_CLINIC_TRIAL_DEVIATIONS_SUCCESS:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          data: action.data,
          isSuccess: true,
          loading: false,
        },
      };
    case type.GET_CLINIC_TRIAL_DEVIATIONS_FAILED:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    case type.UPDATE_CLINIC_TRIAL_DEVIATIONS_REQUEST:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          submit: false,
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.UPDATE_CLINIC_TRIAL_DEVIATIONS_SUCCESS:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          data: state.deviationData.data.map((item) =>
            item.id === action.data?.id ? action.data : item
          ),
          isSuccess: true,
          loading: false,
        },
      };
    case type.UPDATE_CLINIC_TRIAL_DEVIATIONS_FAILED:
      return {
        ...state,
        deviationData: {
          ...state.deviationData,
          error: action.error,
          loading: false,
          isSuccess: false,
        },
      };
    default:
      return state;
  }
};
