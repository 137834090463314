import React from "react";
import { HEADER_DEFAULT_FORMS } from "../constant";
import { isNumberValidate, checkDateInvalid } from "../utils";
import EverLogo from "../../../images/EverLogo.png";
import {
  Container,
  Text,
  Image,
  Input,
  DatePicker,
  HeaderContainer,
  TextArea,
} from "../styles";
import { Row, Col, Space } from "antd";
import moment from "moment";

export default function ProductfeasibilityForm(props) {
  const { t, form = {}, editable } = props;

  return (
    <Container>
      <Text className="text-center" size="16px" color="#0000008C">
        {t(HEADER_DEFAULT_FORMS)}
      </Text>
      <Container className="px-5 mt-3 rounded border">
        <Image className="mt-4" src={EverLogo} />
        <Row gutter={[16, 16]} className="my-4" align="middle">
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("subjects_initials")}:
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.subjectsInitials}
                padding="2px"
                onChange={form.handleChange("subjectsInitials")}
                maxLength={3}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_subject_id")}:
              </Text>
              <Input
                disabled
                value={form.values?.subjectId}
                padding="2px"
                onChange={form.handleChange("subjectId")}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center" direction="horizontal">
              <Text bold color="grey">
                {t("crf_exam_date")}:
              </Text>
              <DatePicker
                disabled
                value={checkDateInvalid(form.values?.createdAt)}
                format="DD/MM/YYYY"
                onChange={(date) =>
                  form.setFieldValue("createdAt", moment(date).toISOString())
                }
                padding="2px"
                width="130px"
              />
            </Space>
          </Col>
        </Row>
        <HeaderContainer>
          <Text className="my-2 text-center" bold size="16px">
            {t("product_feasibility")}
          </Text>
        </HeaderContainer>
        <Container className="my-4">
          <Row align="middle">
            <Col span={3}>
              <Text bold color="grey">
                {t("crf_aditional_notes")}:
              </Text>
            </Col>
            <Col span={21}>
              <TextArea
                disabled={!editable}
                maxLength={300}
                value={form.values?.comment}
                className="h-auto"
                onChange={form.handleChange("comment")}
              />
            </Col>
          </Row>
          <Space className="mt-4" size={25}>
            <Space>
              <Text bold color="grey">
                {t("crf_form_number")}
              </Text>
              <Input
                disabled={!editable}
                value={form.values?.formNumber}
                padding="2px"
                width="60px"
                maxLength={2}
                onChange={(e) => {
                  if (isNumberValidate(e.target.value)) {
                    form.setFieldValue("formNumber", e.target.value);
                  }
                }}
              />
            </Space>
            <Text color="grey">{t("crf_version_date")}: 01/07/2022</Text>
          </Space>
        </Container>
      </Container>
    </Container>
  );
}
