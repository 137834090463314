import styled from "styled-components";

import { Card } from "antd";

export const RoomCardStyle = styled(Card)`
  height: 150px;

  cursor: pointer;
  .ant-card-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
