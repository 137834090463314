import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Questionaires } from "../../redux/actions";
import DashboardCovid from "../../components/DashboardCovid";
import Loading from "../../components/Loading";

import Layout from "../Home";
import { useTranslation } from "react-i18next";
import {
  COLOR_CODE,
  COLOR_INDEX,
  COLOR_CODE_NEWCASE,
  dataGraphCovid,
} from "./constant";
import moment from "moment";

const DashboardCovidContainer = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const questionaires = useSelector((state) => state.questionaires);

  const [HIFormList, setHIFormList] = useState([]);
  const [newCases, setNewCases] = useState([]);
  const [totalMapData, setTotalMapData] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [tableIndex, setTableIndex] = useState(0);
  const [totalNewCases, setTotalNewCases] = useState(0);
  const [totalRegsitered, setTotalRegsitered] = useState(0);

  useEffect(() => {
    dispatch(Questionaires.getAllRegisteredQuestionnaire());
    dispatch(Questionaires.getAllNewCasesQuestionnaire());
  }, []);

  useEffect(() => {
    if (questionaires.todayCases.isSuccess) {
      const data = questionaires.todayCases.data;
      if (Array.isArray(data)) {
        const dailyReport = data
          .filter((item) => item.questionnaireTypeId === 3)
          .map((item) => {
            const filterList = Array.isArray(item.questionAnswers.reports)
              ? item.questionAnswers.reports
              : [];
            return {
              totalLength: filterList.length,
              appUserId: item.appUserId,
              fkey: item.questionAnswers?.fkey,
              ...(filterList.length > 0
                ? item.questionAnswers.reports[filterList.length - 1]
                : {}),
            };
          });
        const filterData = data
          .filter(
            (item) =>
              (item.questionnaireTypeId === 2 &&
                moment(item.createdAt).isSame(moment(), "date")) ||
              item.questionnaireTypeId === 1
          )
          .map((item) => {
            return {
              createAt: item.updatedAt,
              result: item.result,
              typeId: item.questionnaireTypeId,
              ...(item.questionnaireTypeId === 1 ? item.questionAnswers : {}),
              ...(Array.isArray(item.questionAnswers.reports) &&
              item.questionAnswers.reports.length > 0
                ? item.questionAnswers.reports[0]
                : {}),
              dailyReport: dailyReport.find(
                (innerItem) => innerItem.fkey === item.id
              ),
            };
          });
        setTotalNewCases(filterData.length);
        setTotalMapData([...HIFormList, ...filterData]);
        setNewCases(filterData);
      }
    }
  }, [questionaires.todayCases]);

  useEffect(() => {
    if (questionaires.registeredCases.isSuccess) {
      const data = questionaires.registeredCases.data;
      if (Array.isArray(data)) {
        const dailyReport = data
          .filter((item) => item.questionnaireTypeId === 3)
          .map((item) => {
            const filterList = Array.isArray(item.questionAnswers.reports)
              ? item.questionAnswers.reports
              : [];
            return {
              totalLength: filterList.length,
              appUserId: item.appUserId,
              fkey: item.questionAnswers?.fkey,
              ...(filterList.length > 0
                ? item.questionAnswers.reports[filterList.length - 1]
                : {}),
            };
          });
        const HIList = data
          .filter(
            (item) =>
              item.questionnaireTypeId === 2 &&
              !moment(item.createdAt).isSame(moment(), "date") &&
              COLOR_CODE.includes(item.result)
          )
          .map((item) => {
            return {
              appUserId: item.appUserId,
              typeId: item.questionnaireTypeId,
              resultIndex: COLOR_INDEX[item.result] ?? 0,
              note: item.questionAnswers?.note,
              result: item.result,
              ...(Array.isArray(item.questionAnswers.reports) &&
              item.questionAnswers.reports.length > 0
                ? item.questionAnswers.reports[0]
                : {}),
              dailyReport: dailyReport.find(
                (innerItem) => innerItem.fkey === item.id
              ),
            };
          });
        setTotalRegsitered(HIList.length);
        setTotalMapData([...newCases, ...HIList]);
        setHIFormList(HIList);
      }
    }
  }, [questionaires.registeredCases]);

  if (questionaires.registeredCases.loading || questionaires.todayCases.loading)
    return <Loading />;
  return (
    <Layout
      {...props}
      render={(props) => (
        <DashboardCovid
          t={t}
          i18n={i18n}
          colorCode={COLOR_CODE_NEWCASE}
          questionaires={questionaires}
          HIFormList={HIFormList}
          newCases={newCases}
          totalMapData={totalMapData}
          totalRegsitered={totalRegsitered}
          totalNewCases={totalNewCases}
          setCurrentData={setCurrentData}
          currentData={currentData}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          tableIndex={tableIndex}
          setTableIndex={setTableIndex}
          dataGraphCovid={dataGraphCovid}
        />
      )}
    />
  );
};

export default DashboardCovidContainer;
