import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/practitionerSpecialties";

async function getpractitonerSpecialty({ roleId }) {
  const { data } = await http.get(
    `${apiEndpoint}?filter[where][roleId]=${roleId}`
  );
  return data;
}

export { getpractitonerSpecialty };
