import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Booking, Firebase, AppUserOma } from "../../redux/actions";
import PrescriptionOrder from "../../components/PrescriptionOrder";
import { hashHelper } from "../../common/hash";
import { useTranslation } from "react-i18next";
const PrescriptionOrderContainer = (props) => {
  const {
    bookings,
    history,
    communityPharmacy,
    handleClickLocation,
    handleBackLocation,
  } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.appUserOma);

  const [visibleModalPrescription, setVisibleModalPrescription] =
    useState(false);

  const [visibleModalNote, setVisibleModalNote] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);

  const handleModal = (bookingId, booking) => {
    dispatch(Booking.getBooking(bookingId));
    setVisibleModalPrescription(true);
    setCurrentBooking(booking);
  };

  const handleJoinLobby = (booking) => {
    if (
      user &&
      user.information &&
      user.information.id &&
      booking &&
      booking.id &&
      booking.patient &&
      booking.patient.id
    ) {
      const roomType = "COMMUNITY_PHARMACIST";

      dispatch(
        Firebase.updateRoom(
          roomType,
          user.information.id,
          booking.patient.id,
          {
            status: "COMMUNITY_PHARMACIST_JOIN",
          },
          "communityPharmacist"
        )
      );
      dispatch(
        Booking.updateBooking(booking.id, false, {
          practitionerAppUserId: user.information.id,
          practitionerId: user.practitioner.id,
        })
      );
      setVisibleModalPrescription(false);
      const hash = hashHelper(
        JSON.stringify({
          date: booking.date,
          bookingId: booking.id,
          roomType,
        })
      );
      history.push(`/rooms/${hash}`);
    }
  };

  const handleLogout = () => {
    history.push("/logout");
  };

  return (
    <PrescriptionOrder
      handleBackLocation={handleBackLocation}
      handleClickLocation={handleClickLocation}
      communityPharmacy={communityPharmacy}
      bookings={bookings}
      handleModal={handleModal}
      visibleModalPrescription={visibleModalPrescription}
      setVisibleModalPrescription={setVisibleModalPrescription}
      visibleModalNote={visibleModalNote}
      setVisibleModalNote={setVisibleModalNote}
      handleJoinLobby={handleJoinLobby}
      handleLogout={handleLogout}
      currentBooking={currentBooking}
      t={t}
    />
  );
};

export default PrescriptionOrderContainer;
