import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/roles";

async function getRole() {
  const { data } = await http.get(apiEndpoint);
  return data;
}

export { getRole };
