import React from "react"
import { Select, Input } from "antd"
const { Option } = Select

const index = ({ setSearchBy, setSearchText, onSearch, t }) => {
  return (
    <Input.Group compact style={{ maxWidth: 400, marginLeft: "auto" }}>
      <Select
        defaultValue="name"
        style={{ minWidth: "120px" }}
        onSelect={(e) => {
          setSearchBy(e)
        }}
      >
        <Option value="name">{t("search_by_name")}</Option>
        <Option value="cid">{t("search_by_cid")}</Option>
      </Select>
      <Input
        allowClear
        style={{ maxWidth: "250px" }}
        onChange={(e) => {
          setSearchText(e.target.value)
        }}
        onPressEnter={() => {
          onSearch()
        }}
      />
    </Input.Group>
  )
}

export default index
