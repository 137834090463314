import { call, put, takeEvery } from "redux-saga/effects";
import { KycImage } from "../../api";
import * as type from "../types";

function* getKycImages(id) {
  try {
    const result = yield call(KycImage.getKycImages, id);
    yield put({ type: type.GET_KYC_IMAGES_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_KYC_IMAGES_FAILED, error: e.message });
  }
}

function* userOmaSaga() {
  yield takeEvery(type.GET_KYC_IMAGES_REQUESTED, getKycImages);
}

export default userOmaSaga;
