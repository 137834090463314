import React, { useEffect, useState } from "react";
import { get, map, intersection } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ClinicTrialDocuments from "../../components/ClinicTrialDocuments";
import Layout from "../Home";
import { ClinicTrial } from "../../redux/actions";
import {
  EXPORT_DOCUMENT,
  FORM_COMPONENT,
  PREVIEW_COMPONENT,
  CRF_FORM_LIST,
  fileNameHandler,
} from "./constant";

export default function ClinicTrialDocumentList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clinicTrial = useSelector((state) => state.clinicTrial);
  const appUserOma = useSelector((state) => state.appUserOma);
  const organizationId = get(appUserOma.organization, "data[0].id", null);
  const [userPerm, setUserPerm] = useState({
    EXPORT_DOCUMENT: false,
  });
  const [visibleDocModal, setVisibleDocModal] = useState(false);
  const [visibleFormModal, setVisibleFormModal] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [submitData, setSubmitData] = useState({});

  useEffect(() => {
    if (Array.isArray(clinicTrial?.particitpantList?.data)) {
      const data = clinicTrial?.particitpantList?.data.filter(
        (item) => item.status !== "inactive"
      );
      setFilterData(data);
    }
  }, [clinicTrial?.particitpantList?.data]);

  useEffect(() => {
    const userRoles = map(get(appUserOma.information, "roles", []), "name");
    setUserPerm({
      EXPORT_DOCUMENT: intersection(EXPORT_DOCUMENT, userRoles).length > 0,
    });
  }, [appUserOma.information]);

  useEffect(() => {
    if (organizationId) {
      dispatch(ClinicTrial.getResearchObjectsByOrganization(organizationId));
    }
  }, [organizationId]);

  return (
    <Layout
      {...props}
      render={() => (
        <ClinicTrialDocuments
          t={t}
          formComponent={FORM_COMPONENT}
          previewComponent={PREVIEW_COMPONENT}
          crfFormList={CRF_FORM_LIST}
          fileNameHandler={fileNameHandler}
          clinicTrial={clinicTrial}
          organizationId={organizationId}
          userPerm={userPerm}
          visibleDocModal={visibleDocModal}
          setVisibleDocModal={setVisibleDocModal}
          visibleFormModal={visibleFormModal}
          setVisibleFormModal={setVisibleFormModal}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          submitData={submitData}
          setSubmitData={setSubmitData}
          filterData={filterData}
        />
      )}
    />
  );
}
