import React from "react";
import { Typography } from "antd";
import { RoomCardStyle } from "./styles";

const { Title, Text } = Typography;

const RoomCard = (props) => {
  const { name, count, navigate } = props;
  return (
    <RoomCardStyle onClick={navigate}>
      <Title
        level={4}
        style={{ margin: 0, textAlign: "center", color: "#00BAE5" }}
      >
        {name}
      </Title>
      <Text type="secondary">{count} Cases</Text>
    </RoomCardStyle>
  );
};

export default RoomCard;
