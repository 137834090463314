import React from "react";
import { Redirect, Route } from "react-router-dom";
import Intercom from "../Intercom";
import LobbyAlert from "../LobbyAlert";
const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const isLoggedIn = localStorage.getItem("token") || false;
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
        return Component ? (
          <>
            <LobbyAlert />
            <Intercom path={path} auth={isLoggedIn} />
            <Component {...props} />
          </>
        ) : (
          render(props)
        );
      }}
    />
  );
};

export default ProtectedRoute;
