import React from "react";
import { Container, Text, Icon, CardContainer, Button } from "./styles";
import { Space, Table, Row, Col, Badge } from "antd";
import { FiMoreHorizontal, FaRegFolder, FaFolder } from "react-icons/all";

import CtmsDocumentFormModal from "../Modal/CtmsDocumentFormModal";
import CtmsDocumentListModal from "../Modal/CtmsDocumentListModal";

export default function ClinicTrialDocuments(props) {
  const {
    t,
    clinicTrial,
    formComponent,
    previewComponent,
    constSubId,
    crfFormList,
    fileNameHandler,
    userPerm,
    visibleDocModal,
    setVisibleDocModal,
    visibleFormModal,
    setVisibleFormModal,
    selectedData,
    setSelectedData,
    submitData,
    setSubmitData,
    filterData,
  } = props;

  const COLUMNS = [
    {
      title: t("subject_id"),
      className: "pl-4",
      render: (record) => {
        const { filtering, status } = record;
        const IconComp =
          filtering.eligibilityStatus === "Active" ||
          filtering.eligibilityStatus === "Completed"
            ? FaFolder
            : FaRegFolder;
        return (
          <Container>
            <Space direction="horizontal" align="center">
              <Icon
                component={IconComp}
                onClick={() => {
                  setVisibleDocModal(true);
                  setSelectedData(record);
                }}
                size="26px"
                color="#00000059"
              />
              <Text size="14px">{filtering.subjectId || "-"} </Text>
              <Badge
                size="small"
                count={status}
                style={{
                  fontSize: 10,
                  borderColor:
                    status === "active"
                      ? "#52c41a"
                      : status === "withdrawn"
                      ? "#FF9800"
                      : status === "completed"
                      ? "#FFF"
                      : "#000000",
                  textTransform: "capitalize",
                  backgroundColor:
                    status === "active"
                      ? "#52c41a"
                      : status === "completed"
                      ? "#FF9800"
                      : status === "withdrawn"
                      ? "#FFF5E5"
                      : "#F9FAFA",
                  color:
                    status === "active" || status === "completed"
                      ? "#ffffff"
                      : status === "withdrawn"
                      ? "#FF9800"
                      : "#000000",
                }}
              />
            </Space>
          </Container>
        );
      },
    },
    {
      width: 50,
      render: (record) => {
        return (
          <Icon
            size="18px"
            onClick={() => {
              setVisibleDocModal(true);
              setSelectedData(record);
            }}
            component={FiMoreHorizontal}
            color="#00000059"
          />
        );
      },
    },
  ];

  return (
    <Container className="px-4 py-5 mb-5">
      <CardContainer minheight="90vh">
        <Row className="h-100">
          <Col sm={24} xs={24} md={10} lg={6} xl={4} className="border-right">
            <Container className="p-3">
              <Text bold size="16px" color="#00000073">
                {t("clinic_trial_document")}
              </Text>
            </Container>
            <Row>
              <MenuButton index={0} title={t("document_list")} tabIndex={0} />
            </Row>
          </Col>
          <Col sm={24} xs={24} md={14} lg={18} xl={20} className="h-100">
            <Table
              rowKey={(item) => item.id}
              columns={COLUMNS}
              dataSource={filterData}
            />
          </Col>
        </Row>
      </CardContainer>
      <CtmsDocumentListModal
        t={t}
        constSubId={constSubId}
        previewComponent={previewComponent}
        fileNameHandler={fileNameHandler}
        crfFormList={crfFormList}
        currentData={selectedData}
        visible={visibleDocModal}
        setVisible={setVisibleDocModal}
      />
    </Container>
  );
}

const MenuButton = ({ index, title, tabIndex, onClick }) => {
  return (
    <Button
      color={tabIndex === index ? "#F2FCFE" : "#FFF"}
      className="col-12 active"
      type="text"
      key={index}
      onClick={onClick}
    >
      <Text
        active={tabIndex === index}
        bold={tabIndex === index}
        className="text-left ml-3"
      >
        {title}
      </Text>
    </Button>
  );
};
