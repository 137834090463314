import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import Loading from "../../components/Loading";
import RegisterForm from "../../components/RegisterForm";
import Layout from "../Home";
import {
  Organization,
  PractitionerSpecialties,
  Role,
} from "../../redux/actions";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";

const Register = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedPractitionerId, setSelectedPractitionerId] = useState(false);
  const [roleId, setRoleId] = useState(false);
  const organization = useSelector((state) => state.organization);
  const appUserOma = useSelector((state) => state.appUserOma);

  const isLoading =
    !organization.data || organization.loading || appUserOma.loading;

  useEffect(() => {
    dispatch(Organization.getOrganizations());
    dispatch(Role.getRole());
  }, []);

  useEffect(() => {
    if (roleId) {
      dispatch(PractitionerSpecialties.getPractitionerSpecialties(roleId));
    }
  }, [roleId]);

  useEffect(() => {
    if (appUserOma.register) {
      if (appUserOma.error) {
        error();
      } else {
        success();
      }
    }
  }, [appUserOma]);

  const success = () => {
    message.success({
      content: "ลงทะเบียนสำเร็จ",
      style: {
        marginTop: "50vh",
      },
    });
  };
  const error = () => {
    message.error({
      content: "ลงทะเบียนล้มเหลว",
      style: {
        marginTop: "50vh",
      },
    });
  };

  return (
    <Layout
      {...props}
      render={(props) =>
        isLoading ? (
          <Loading />
        ) : (
          <Container>
            <RegisterForm
              setSelectedPractitionerId={(id) => setSelectedPractitionerId(id)}
              setRoleId={(id) => setRoleId(id)}
              t={t}
            />
          </Container>
        )
      }
    />
  );
};
export default Register;
