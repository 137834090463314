import "bootstrap/dist/css/bootstrap.min.css";

import React, { Component } from "react";
import {
  Redirect,
  Route,
  Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import BookingFromPatient from "../BookingFromPatient";
import SupportDashboardContainer from "../SupportDashboard";
import ClinicTrial from "../ClinicTrial";
import DoctorEarnings from "../DoctorEarnings";
import DoctorSetting from "../DoctorSetting";
import Lobby from "../Lobby";
import Login from "../Login";
import Logout from "../Logout";
import Register from "../Register";
import UserList from "../UserList";
import SubmissionList from "../SubmissionList";
import RoundRobinContainer from "../RoundRobin";
import DoctorLobbyContainer from "../DoctorLobby";

import PatientMonitoringDetail from "../PatientMonitoringDetail";
import DashboardMapContainer from "../DashboardMap";
import RoomContainer from "../Room";
import SummaryContainer from "../Summary";
import HistoryBookingContainer from "../HistoryBooking";
import DeliverDrugContainer from "../DeliverDrug";
import DashboardCovidContainer from "../DashboardCovid";
import EarningContainer from "../Earning";
import history from "./history";
import { IntercomProvider } from "react-use-intercom";
import "@stream-io/stream-chat-css/dist/css/index.css";
import ResearchObjectDetail from "../ResearchObjectDetail";
import ClinicTrialDocumentList from "../ClinicTrialDocumentList";

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

class App extends Component {
  render() {
    return (
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <Router history={history}>
          <Switch>
            <ProtectedRoute
              path="/doctor-lobby"
              exact
              component={DoctorLobbyContainer}
            />
            <ProtectedRoute
              path="/homepage"
              exact
              component={DoctorLobbyContainer}
            />

            <ProtectedRoute
              path="/dashboard-map"
              exact
              component={DashboardMapContainer}
            />
            <ProtectedRoute
              path="/clinic-trial"
              exact
              component={ClinicTrial}
            />
            <ProtectedRoute
              path="/clinic-trial/research-object/:id"
              exact
              component={ResearchObjectDetail}
            />
            <ProtectedRoute
              path="/clinic-document"
              exact
              component={ClinicTrialDocumentList}
            />
            <ProtectedRoute
              path="/lobby/telemedicine"
              exact
              component={Lobby}
            />
            <ProtectedRoute path="/round-robin/covid" exact component={Lobby} />
            <ProtectedRoute
              path="/round-robin/general"
              exact
              component={Lobby}
            />
            <ProtectedRoute path="/rooms/:id" exact component={RoomContainer} />
            <ProtectedRoute
              path="/summary/:id"
              exact
              component={SummaryContainer}
            />
            <ProtectedRoute
              path="/round-robin"
              exact
              component={RoundRobinContainer}
            />
            <ProtectedRoute
              path="/booking"
              exact
              component={BookingFromPatient}
            />
            <ProtectedRoute
              path="/support-dashboard"
              exact
              component={SupportDashboardContainer}
            />
            <ProtectedRoute
              path="/partner-earnings"
              exact
              component={DoctorEarnings}
            />
            <ProtectedRoute
              path="/history-booking"
              exact
              component={HistoryBookingContainer}
            />
            <ProtectedRoute
              path="/dashboard-covid"
              exact
              component={DashboardCovidContainer}
            />
            <ProtectedRoute
              path="/deliver-drug/:id"
              exact
              component={DeliverDrugContainer}
            />
            <ProtectedRoute
              path="/submissionlist"
              exact
              component={SubmissionList}
            />
            <ProtectedRoute path="/userlist" exact component={UserList} />
            <ProtectedRoute
              path="/telemonitoring/:id"
              exact
              component={PatientMonitoringDetail}
            />
            {/* <ProtectedRoute
              path="/earning"
              exact
              component={EarningContainer}
            /> */}
            <ProtectedRoute path="/settings" exact component={DoctorSetting} />

            <ProtectedRoute path="/register" exact component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Redirect to="/homepage" />
          </Switch>
        </Router>
      </IntercomProvider>
    );
  }
}

export default App;
