import { Card } from "antd";
import styled from "styled-components";

export const Container = styled(Card)`
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #f0f0f1;
  border-radius: 16px;
  .ant-card-extra {
    display: flex;
  }
  .ant-card-body {
    padding: 0px 24px 24px 24px;
  }
`;

export const WrapContent = styled.div`
  margin-top: 30px;
  h2 {
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #bdbdbd;
  }
  img {
    margin-top: 8px;
  }
  p {
    margin-top: 10px;
  }
`;

export const ProfileImage = styled.img`
  height: 93.18px;
  border-radius: 50%;
`;

export const ProfileImageCard = styled.img`
  height: 93.18px;
  width: 93.18px;
  border-radius: 50%;
`;

export const Cover = styled.div`
  background: linear-gradient(180deg, #6fb2dd 0%, #d1e6f4 100%);
  border-radius: 16px;
  height: 93px;
  width: 293px;
`;
