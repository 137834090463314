export const ROLES = {
  admin: "Admin",
  medicalCenterManager: "Medical Center Manager",
  everAssistant: "Ever Assistant",
  user: "User",
  email: "Email",
  emailAssistant: "Email Assistant",
  pharmacy: "เภสัชกร",
  doctor: "หมอ",
  callCenter: "คอลเซ็นเตอร์",
  nurse: "พยาบาล",
  internalService: "Internal Service",
};

export const FILTERROLES = ["callCenter", "pharmacy", "doctor", "nurse"];
