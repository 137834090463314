import * as type from "../types";

export function sendNotification(id, notification, data) {
  return {
    type: type.SEND_NOTIFICATION_REQUESTED,
    id,
    notification,
    detail: data,
  };
}
