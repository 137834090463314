import CryptoJS from "crypto-js";

export const hashHelper = (key) => {
  const hash = CryptoJS.AES.encrypt(
    key,
    process.env.REACT_APP_PRIVATE_KEY
  ).toString();
  return encodeURIComponent(hash);
};

export const decryptHelper = (key) => {
  const decodedKey = decodeURIComponent(key);
  const bytes = CryptoJS.AES.decrypt(
    decodedKey,
    process.env.REACT_APP_PRIVATE_KEY
  );
  return bytes.toString(CryptoJS.enc.Utf8);
};
