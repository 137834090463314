import http from '../services/httpServiceHealthCare';

const apiEndpoint = '/DrugFrequencies';

async function getDrugTimeFrequency() {
    const { data } = await http.get(`${apiEndpoint}`);
    return data;
}

export { getDrugTimeFrequency };
