import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Booking } from "../../redux/actions";
import Layout from "../Home";
import { useTranslation } from "react-i18next";
import Earning from "../../components/Earning";
import Loading from "../../components/Loading";
import jwtDecode from "jwt-decode";

const BookingFromPatient = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const appUserOma = useSelector((state) => state.appUserOma);
  const booking = useSelector((state) => state.booking);

  const [isFirstLanding, setIsFirstLanding] = useState(true);
  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);

  useEffect(() => {
    if (booking.history.isSuccess) {
      setIsFinishInitialLoad(true);
    }
  }, [booking]);

  useEffect(() => {
    if (isFirstLanding) {
      const userId = jwtDecode(localStorage.getItem("token")).id;
      dispatch(
        Booking.getBookingHistoryWithEarning(userId, 0, "DOCTOR_COMPLETED")
      );
      setIsFirstLanding(false);
    }
  }, [appUserOma]);

  return (
    <Layout
      {...props}
      render={(props) =>
        isFinishInitialLoad ? (
          <Earning t={t} booking={booking.history} />
        ) : (
          <Loading />
        )
      }
    />
  );
};
export default BookingFromPatient;
