import moment from "moment";

export const PENDING_STATUS = [
  "DOCTOR_CONFIRM",
  "DOCTOR_PENDING_NOTE",
  "PHARMACY_PENDING_NOTE",
  "COMMUNITY_PHARMACIST_PENDING_NOTE",
];

export const STATUS = {
  doctor: {
    DOCTOR_PENDING: "DOCTOR_PENDING",
    DOCTOR_CONFIRM: "DOCTOR_CONFIRM",
    DOCTOR_PENDING_NOTE: "DOCTOR_PENDING_NOTE",
    DOCTOR_COMPLETED: "DOCTOR_COMPLETED",
    DOCTOR_DECLINED: "DOCTOR_DECLINED",
  },
  pharmacy: {
    PHARMACY_PENDING: "PHARMACY_PENDING",
    PHARMACY_CONFIRM: "PHARMACY_CONFIRM",
    PHARMACY_PENDING_NOTE: "PHARMACY_PENDING_NOTE",
    PHARMACY_COMPLETED: "PHARMACY_COMPLETED",
    PHARMACY_DECLINED: "PHARMACY_DECLINED",
  },
  callCenter: {
    DOCTOR_PENDING: "DOCTOR_PENDING",
    DOCTOR_CONFIRM: "DOCTOR_CONFIRM",
    DOCTOR_PENDING_NOTE: "DOCTOR_PENDING_NOTE",
    DOCTOR_COMPLETED: "DOCTOR_COMPLETED",
    DOCTOR_DECLINED: "DOCTOR_DECLINED",
    PHARMACY_PENDING: "PHARMACY_PENDING",
    PHARMACY_CONFIRM: "PHARMACY_CONFIRM",
    PHARMACY_PENDING_NOTE: "PHARMACY_PENDING_NOTE",
    PHARMACY_COMPLETED: "PHARMACY_COMPLETED",
    PHARMACY_DECLINED: "PHARMACY_DECLINED",
  },
  deliveryTeam: {
    PHARMACY_COMPLETED: "PHARMACY_COMPLETED",
  },
};

export const ACTION_STATUS = {
  DOCTOR_PENDING: [
    {
      title: "are_you_sure_to_cancel_this_appointment",
      update: "DOCTOR_DECLINED",
      notification: "การนัดหมายของท่านถูกปฎิเสธ โปรดทำการนัดหมายใหม่อีกครั้ง",
      btnText: "cancel",
      style: "danger",
      roles: ["doctor", "callCenter"],
      notificationKey: "cancelBooking",
      isUpdate: false,
    },
    {
      title: "are_you_sure_to_confirm_this_appointment",
      update: "DOCTOR_CONFIRM",
      notification: "การนัดหมายของท่านได้รับการยืนยันเรียบร้อยแล้ว",
      btnText: "confirm",
      notificationKey: "confirmBooking",
      isUpdate: true,
    },
  ],
  DOCTOR_CONFIRM: [
    {
      title: "are_you_sure_to_go_to_the_summary_page_this_appointment",
      update: "DOCTOR_PENDING_NOTE",
      btnText: "complete",
      isUpdate: true,
    },
  ],
  DOCTOR_PENDING_NOTE: [
    {
      title: "are_you_sure_to_complete_this_appointment",
      update: "DOCTOR_COMPLETED",
      disabled: false,
      btnText: "complete",
      isUpdate: false,
    },
    {
      title: "are_you_sure_to_send_this_appointment_to_pharmacy",
      update: "DOCTOR_COMPLETED",
      disabled: true,
      btnText: "send_to_pharmacy",
      isUpdate: true,
    },
  ],
  PHARMACY_PENDING_NOTE: [
    {
      title: "are_you_sure_to_complete_this_appointment",
      update: "PHARMACY_COMPLETED",
      btnText: "complete",
      detail: { logisticStatus: "PENDING" },
      isUpdate: false,
    },
  ],
  COMMUNITY_PHARMACIST_PENDING_NOTE: [
    {
      title: "are_you_sure_to_complete_this_appointment",
      update: "COMMUNITY_PHARMACIST_COMPLETED",
      btnText: "complete",
      isUpdate: false,
    },
  ],
};

export const TELEMONITORING_REDIRECT = {
  btnText: "ประวัติสุขภาพ",
  pathName: (patientId) => {
    return `/telemonitoring/${encodeURIComponent(`patientId=${patientId}`)}`;
  },
  roles: ["DOCTOR"],
};

export const NOTIFICATION = {
  confirmBooking: (language = "en") => ({
    body: "Your appointment has been confirmed",
    title: "Alert",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
  cancelBooking: (language = "en") => ({
    body: "Your appointment was declined. Please book another appointment.",
    title: "Alert",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
};

export const NOTIFICATION_DATA = (key, admitTime) => {
  const returnData = {
    confirmBooking: {
      time: admitTime ? new Date(admitTime) : null,
      page: "Booking",
    },
    cancelBooking: {
      page: "TeleSymptom",
    },
  };
  return returnData[key];
};

export const TABS = [
  { title: "all_booking", key: "allBookings" },
  { title: "booking_today", filter: "today", key: "today" },
  {
    title: "upcoming_booking",
    filter: "greaterThan",
    key: "greaterThan",
  },
  {
    title: "past_booking",
    filter: "lessThan",
    key: "lessThan",
  },
  {
    title: "cancelled_booking",
    key: "problemReport",
    filter: {
      doctor: "DOCTOR_DECLINED",
      pharmacy: "PHARMACY_DECLINED",
    },
  },
];

export const LOGISTIC_STATUSES = [
  { label: "waiting_to_deliver", value: "PENDING" },
  { label: "going_to_delivery", value: "ON_GOING" },
  { label: "delivered", value: "COMPLETED" },
];
