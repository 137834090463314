import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Image, Button } from "antd";
import Loading from "../../Loading";
import Error from "../../Error/Error";

const Call = (props) => {
  const {
    visible,
    setVisible,
    title,
    setVisibleCovidModal,
    fetchBookingByTreatmentId,
    booking,
    roomType,
    handleAcceptCall,
    t,
  } = props;

  const [isFinishInitial, setIsFinishInitial] = useState(false);

  useEffect(() => {
    if (booking.isSuccess && booking.error === null) {
      setIsFinishInitial(true);
    }
  }, [booking]);

  return (
    <Modal
      title={title === "CALLING" ? t("calling") : "ผู้ป่วยกำลังอยู่ในห้อง"}
      width={700}
      visible={visible}
      footer={
        <Row justify="space-between">
          <Col>
            {roomType === "COVID" && booking.current.covidForm && (
              <Button type="primary" onClick={() => setVisibleCovidModal(true)}>
                {t("covid_form_button")}
              </Button>
            )}
            <Button type="primary" onClick={fetchBookingByTreatmentId}>
              {t("consultation_history")}
            </Button>
          </Col>
          <Col>
            <Button onClick={() => setVisible(false)}>
              {t("close_modal")}
            </Button>
            <Button type="primary" onClick={handleAcceptCall}>
              {t("answer_call")}
            </Button>
          </Col>
        </Row>
      }
      onCancel={() => setVisible(false)}
      okText={`${t("answer_call")}`}
      cancelText={`${t("close")}`}
    >
      {isFinishInitial ? (
        <>
          {booking.error === null && booking.current ? (
            <>
              {booking.current.symptom ? (
                <>
                  <p>
                    {t("symptom")}: {booking.current.symptom.note ?? "-"}
                  </p>
                  {booking.current.symptom.imageUrl ? (
                    <Image
                      src={booking.current.symptom.imageUrl}
                      style={{ height: "300px" }}
                    />
                  ) : null}
                </>
              ) : (
                booking.current.covidForm && (
                  <>
                    <p>{t("atk_pcr_pic")}</p>
                    {booking.current.covidForm?.imagePCRUrl ? (
                      <Image
                        src={booking.current.covidForm?.imagePCRUrl}
                        style={{ height: "300px" }}
                      />
                    ) : null}
                  </>
                )
              )}
            </>
          ) : (
            <Error />
          )}
        </>
      ) : (
        <Loading height="20vh" />
      )}
    </Modal>
  );
};

export default Call;
