import styled from "styled-components";
import { CompassOutlined } from "@ant-design/icons";

export const Container = styled.div`
  overflow-y: ${(props) => (props.scroll ? "scroll" : "hidden")};
  position: absolute;
  width: 30vw;
  background-color: rgb(250, 250, 250, 0.9);
  top: 0px;
  right: 0px;
  padding: 10px;
  height: 100%;
`;

export const Wrapper = styled.div`
  padding: 20px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
`;

export const CompassIcon = styled(CompassOutlined)`
  cursor: pointer;
  font-size: 18px;
  :hover {
    color: #27d4f2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;
