import { omit, range, keysIn } from "lodash";
import * as Yup from "yup";
import moment from "moment";

const addItemSubjectDeviation = (array = []) => {
  if (array.length !== 5) {
    return [
      ...array,
      ...range(5 - array.length).map((item) => {
        return {
          affectDataIntegrity: "",
          affectSubjectWillingness: "",
          category: "",
          createdAt: "",
          description: "",
          endDate: "",
          startDate: "",
          impactSubjectSafety: "",
          otherCategoryReason: "",
          piInitials: "",
          piInitialsDate: "",
          reported: null,
          reportedDate: null,
          sequence: array.length + item,
        };
      }),
    ];
  }
  return array;
};

const manageFormHandler = (formData) => {
  let createdAt, sampleDateAt, data, systolicBP, diastolicBP, bloodPressure;
  switch (formData?.type) {
    case "daily_assessment":
      let snottObj = {},
        tnssObj = {};
      Array.isArray(formData.formData) &&
        formData.formData.map((item) => {
          const keys = keysIn(item.schema);
          if (keys.includes("snot_blownose")) {
            snottObj = item.schema;
          }
          if (keys.includes("tnss_itchynose")) {
            tnssObj = item.schema;
          }
        });
      return {
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: "",
        comment: "",
        formNumber: "",
        notPerformed: false,
        createdAt: formData.createdAt,
        TNSSData: tnssObj,
        affectedhealthchoice: snottObj.affectedhealthchoice ?? [],
        SNOTTData: omit(snottObj, "affectedhealthchoice"),
      };
    case "product_feasibility":
      return {
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: "",
        productComplicated:
          formData.formData?.schema?.using_product_complicated?.value,
        comment: "",
        formNumber: "",
        createdAt: formData.createdAt,
      };
    case "adverse_log":
      return {
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: "",
        allAdverseEvent: formData.formData?.allAdverseEvent,
        formNumber: "",
        page: "",
        of: "",
        dataList: range(5).map(() => ({
          aeNumner: "",
          dateReported: null,
          description: "",
          adverseEvent: "",
          startDate: null,
          endDate: null,
          ongoing: "",
          outcome: null,
          severityGrade: null,
          serious: "",
          aeTreatment: null,
          expected: "",
          studyAttribution: null,
          actionTaken: null,
          deviceAttribution: null,
          piInitials: "",
          dateofInitials: null,
        })),
      };
    case "crf_nasal_sinus_exam":
    case "crf_follow_nasal_sinus_exam":
      if (formData.type === "crf_nasal_sinus_exam") {
        createdAt = moment(formData?.createdAt)
          .subtract(1, "day")
          .toISOString();
        sampleDateAt = moment(formData?.createdAt)
          .subtract(2, "day")
          .toISOString();
      } else {
        createdAt = moment(formData?.createdAt).add(7, "day").toISOString();
        sampleDateAt = moment(formData?.createdAt).add(6, "day").toISOString();
      }
      return {
        ...formData.formData,
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: "",
        visit: null,
        notPerform: false,
        timeCreated: formData?.timeCreated,
        createdAt,
        sampleDateAt,
        comment: "",
        performedBy: "",
        formNumber: "",
      };
    case "end_of_study_exam":
      data = formData.formData;
      createdAt = moment(formData?.createdAt).add(13, "day").toISOString();
      sampleDateAt = moment(formData?.createdAt).add(12, "day").toISOString();
      bloodPressure = data.bloodPressureRange
        ? data.bloodPressureRange
        : data.bloodPressure.toString?.().replace(".", "/");
      systolicBP = `${bloodPressure}`.split("/")[0];
      diastolicBP =
        `${bloodPressure}`.split("/").length > 1
          ? `${bloodPressure}`.split("/")[1]
          : "";
      return {
        ...data,
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: "",
        visit: null,
        notPerform: false,
        timeCreated: formData?.timeCreated,
        temperature: "",
        tempNotMeasured: false,
        rrNotMeasured: false,
        heartRateNotMeasured: false,
        bpNotNotMeasured: false,
        tempMethod: "",
        systolicBP,
        diastolicBP,
        createdAt,
        sampleDateAt,
        bpMethod: "",
        bpLocation: "",
        bpPosition: "",
        vitalSignObtainedBy: "",
        comment: "",
        performedBy: "",
        piPrintName: "",
        piSignatureURL: "",
        piSignatureDate: moment().toISOString(),
        formNumber: "",
      };
    case "crf_demographics":
      createdAt = moment(formData?.createdAt).subtract(1, "day").toISOString();
      return {
        ...formData.formData,
        createdAt,
        phoneNumberMethod: "",
        alterNatePhoneNumberMethod: "",
        alterNatePhoneNumber: "",
        pfMethodContact: "",
        emsContactName: "",
        emsAddress: "",
        emsUnit: "",
        emsAddressDetail: "",
        emsPostalCode: "",
        emsPhoneNumber: "",
        emsPhoneNumberMethod: "",
        emsAlterPhoneNumber: "",
        emsAlterPhoneNumberMethod: "",
        emsEmail: "",
        emsPfMethodContact: "",
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        subjectsInitials: "",
        formCompletedBy: "",
        formCompletedDate: moment().toISOString(),
        formNumber: "",
      };
    case "crf_vital_sign":
      data = formData.formData;
      createdAt = moment(formData?.createdAt).subtract(1, "day").toISOString();
      sampleDateAt = moment(formData?.createdAt)
        .subtract(2, "day")
        .toISOString();
      bloodPressure = data.bloodPressureRange
        ? data.bloodPressureRange
        : data.bloodPressure.toString?.().replace(".", "/");
      systolicBP = `${bloodPressure}`.split("/")[0];
      diastolicBP =
        `${bloodPressure}`.split("/").length > 1
          ? `${bloodPressure}`.split("/")[1]
          : "";
      return {
        ...data,
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        height: data.height,
        timeCreated: formData?.timeCreated,
        heightNotMeasured: false,
        weight: data.weight,
        weightNotMeasured: false,
        subjectsInitials: "",
        visit: null,
        notPerform: false,
        temperature: "",
        tempNotMeasured: false,
        rrNotMeasured: false,
        heartRateNotMeasured: false,
        bpNotNotMeasured: false,
        tempMethod: "",
        systolicBP,
        diastolicBP,
        createdAt,
        sampleDateAt,
        bpMethod: "",
        bpLocation: "",
        bpPosition: "",
        vitalSignObtainedBy: "",
        comment: "",
        formNumber: "",
      };
    case "crf_eligibility_criteria":
      createdAt = moment(formData?.createdAt).subtract(1, "day").toISOString();
      return {
        ...formData.formData,
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        completedBy: "",
        createdAt,
        completedDate: moment().toISOString(),
        sitePISignatureURL: "",
        sitePISignatureDate: moment().toISOString(),
        subjectsInitials: "",
        formNumber: "",
      };
    case "crf_informed_consent":
      return {
        ...formData.formData,
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        formNumber: "",
        dateSigned: "",
        timeSigned: "",
        consentRefused: false,
        docSigned: range(3).map(() => ({
          title: "",
          versionDate: null,
          approvalDate: null,
        })),
        notApicable: false,
        consentReviewSbj: false,
        subjectSuffient: false,
        consentPrior: false,
        copyOfTheSigned: false,
        nameObtainConsent: "",
        comment: "",
        refusedTime: "",
        refusedComment: "",
      };
    case "crf_serious_adverse_event":
      return {
        ...formData.formData,
        type: formData.type,
        piName: "",
        irbNumber: "",
        page: "",
        of: "",
        shortProtocolTitle: "",
        dataList: range(5).map(() => {
          return {
            subjectId: "",
            desp: "",
            adverseEvent: "",
            sae: "",
            startDate: "",
            endDate: "",
            reportedDate: "",
            grade: "",
            unexpected: "",
            attribution: "",
            outcome: "",
          };
        }),
        formNumber: "",
      };
    case "withdrawn_title":
      data = formData.formData;
      return {
        ...data,
        subjectDeviation:
          Array.isArray(data.subjectDeviation) &&
          data.subjectDeviation?.length !== 0
            ? addItemSubjectDeviation(data.subjectDeviation)
            : range(5).map((item) => {
                return {
                  affectDataIntegrity: "",
                  affectSubjectWillingness: "",
                  category: "",
                  createdAt: "",
                  description: "",
                  endDate: "",
                  startDate: "",
                  impactSubjectSafety: "",
                  otherCategoryReason: "",
                  piInitials: "",
                  piInitialsDate: "",
                  reported: null,
                  reportedDate: null,
                  sequence: item + 1,
                };
              }),
        type: formData.type,
        subjectId: formData?.subjectId ?? "",
        formNumber: "",
        subjectsInitials: "",
        page: "",
        of: "",
      };
    default:
      return {};
  }
};

const validateDecimal = Yup.number()
  .typeError("Temperature must be a number")
  .test(
    "is-decimal",
    "Please fill in value in one decumal number.",
    (value) => (value + "").match(/^(\d+)?([.]?\d{1})?$/) || !value
  );

const manageValidateHandler = (formType) => {
  switch (formType) {
    case "end_of_study_exam":
      return Yup.object().shape({
        piSignatureURL: Yup.string().required("Please enter PI signature"),
        temperature: validateDecimal,
      });
    case "crf_vital_sign":
      return Yup.object().shape({
        temperature: validateDecimal,
      });
    case "crf_demographics":
      return Yup.object().shape({
        emsPostalCode: Yup.string().min(5, "กรอกรหัสไปรษณีย์ให้ถูกต้อง"),
        emsEmail: Yup.string().email("กรุณากรอกอีเมลให้ถูกต้อง"),
      });
    default:
      return Yup.object().shape({});
  }
};

export { manageFormHandler, manageValidateHandler };
