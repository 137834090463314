import * as type from "../types";

export function loginAppUserOma(credential) {
  return {
    type: type.LOGIN_APP_USER_OMA_REQUESTED,
    credential,
  };
}

export function logout() {
  return {
    type: type.LOGOUT_REQUESTED,
  };
}

export function registerAppUserOma(credential) {
  return {
    type: type.CREATE_APP_USER_OMA_REQUESTED,
    credential,
  };
}

export function getUser(id) {
  return {
    type: type.GET_APP_USER_OMA_REQUESTED,
    id,
  };
}

export function getProfile(id) {
  return {
    type: type.GET_PROFILE_REQUESTED,
    id,
  };
}

export function updateProfile(id, profile) {
  return {
    type: type.UPDATE_PROFILE_REQUESTED,
    id,
    profile,
  };
}

export function addPhoto(id, photo) {
  return {
    type: type.ADD_PHOTO_REQUESTED,
    id,
    photo,
  };
}

export function updatePhoto(id, photo, fk) {
  return {
    type: type.UPDATE_PHOTO_REQUESTED,
    id,
    photo,
    fk,
  };
}

export function updateSpecialties(id, specialties) {
  return {
    type: type.UPDATE_SPECIALTIES_REQUESTED,
    id,
    specialties,
  };
}

export function deleteUser(id) {
  return {
    type: type.DELETE_APP_USER_OMA_REQUESTED,
    id,
  };
}

export function getOrganizationsUser(id) {
  return {
    type: type.GET_ORGANIZATIONS_BY_USER_REQUESTED,
    id,
  };
}
