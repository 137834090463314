import React from "react";
import { Table, Modal } from "antd";

const PrescriptionTable = (props) => {
  const { visible, setVisible, booking, t } = props;

  const columns = [
    {
      title: t("drug_name"),
      dataIndex: "genericName",
      width: "220px",
      align: "left",
    },
    {
      title: t("dose"),
      dataIndex: "dose",
      width: "100px",
      align: "center",
    },
    {
      title: t("unit"),
      dataIndex: ["drugUnit", "name"],
      width: "100px",
      align: "center",
    },
    {
      title: t("drug_routes"),
      dataIndex: ["drugRoute", "routeEn"],
      align: "center",
      width: "150px",
    },
    {
      title: t("drug_time_events"),
      dataIndex: ["drugTimeEvent", "nameEn"],
      align: "center",
      width: "250px",
    },
    {
      title: t("drug_time_frequency"),
      dataIndex: ["drugFrequency", "nameEn"],
      align: "center",
      width: "250px",
    },
    {
      title: t("total_amount"),
      dataIndex: "amount",
      align: "center",
      width: "100px",
    },
    {
      title: t("note"),
      dataIndex: "note",
      align: "center",
      width: "200px",
    },
  ];
  return (
    <Modal
      title={t("prescription")}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={null}
      width={1200}
    >
      <Table columns={columns} dataSource={booking.current.prescription} />
    </Modal>
  );
};

export default PrescriptionTable;
