import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification, message } from "antd";
import jwtDecode from "jwt-decode";
import { decryptHelper, hashHelper } from "../../common/hash";
import {
  Booking,
  AppUserOma,
  SymptomGroup,
  Firebase,
  UserFcmToken,
  Questionaires,
  DrugIngredientGroup,
  MedicationForOrganization,
  Treatment,
} from "../../redux/actions";
import { NOTIFICATION, NOTIFICATION_DATA } from "./constant";
import Summary from "../../components/Summary";
import Loading from "../../components/Loading";
import { Medication, DrugTimeEvent, DrugTimeFrequency } from "../../api";
import { useTranslation } from "react-i18next";

const SummaryContainer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { match, history } = props;

  const booking = useSelector((state) => state.booking);
  const appUserOma = useSelector((state) => state.appUserOma);
  const symptomGroup = useSelector((state) => state.symptomGroup);
  const questionaires = useSelector((state) => state.questionaires);
  const drugIngredientGroup = useSelector((state) => state.drugIngredientGroup);
  const medication = useSelector(
    (state) => state.medicationForOrganization.medication
  );

  const [roomType, setRoomType] = useState("");
  const [country, setCountry] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);
  const [
    visibleHistoryPatientDetailModal,
    setVisibleHistoryPatientDetailModal,
  ] = useState(false);
  const [visibleSummaryNoteModal, setVisibleSummaryNoteModal] = useState(false);
  const [visiblePrescriptionNoteModal, setVisiblePrescriptionNoteModal] =
    useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  const [color, setColor] = useState("");
  const [note, setNote] = useState("");
  const [prescriptionNote, setPrescriptionNote] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [drugTimeEvent, setDrugTimeEvent] = useState([]);
  const [drugTimeFrequency, setDrugTimeFrequency] = useState([]);
  const [symptomGroupId, setSymptomGroupId] = useState(null);
  const [visibleSymptomGroup, setVisibleSymptomGroup] = useState(false);
  const [search, setSearch] = useState("");
  const [symptomGroups, setSymptomGroups] = useState([]);

  useEffect(() => {
    initialData();
  }, [isFirstLoad]);

  useEffect(() => {
    if (drugIngredientGroup.drug.isSuccess) {
      let mapDataMedicines = drugIngredientGroup.drug.data.map((drug) => ({
        genericName: drug.drugIngredient.name,
        ...drug.medications[0],
      }));
      setMedicines(mapDataMedicines);
    }
  }, [drugIngredientGroup]);

  useEffect(() => {
    if (booking.isSuccess) {
      setRoomType(booking.current.bookingCategory);
      setNote(booking.current.notes);
      setMedicines([...booking.medication]);
      if (
        booking.current.bookingCategory === "covid" &&
        booking.current.covidForm?.color
      ) {
        setColor(booking.current.covidForm.color);
      }
    }
    if (
      symptomGroup.medication.isSuccess &&
      booking.medication.length === 0 &&
      booking.isSuccess
    ) {
      setMedicines([...symptomGroup.medication.data]);
    }
    if (symptomGroup.symptom.isSuccess) {
      setSymptomGroups([...symptomGroup.symptom.data]);
    }
  }, [booking, symptomGroup]);

  useEffect(() => {
    if (
      booking.isSuccess &&
      appUserOma.information &&
      appUserOma.information.roles &&
      appUserOma.isSuccess &&
      isFirstLoad
    ) {
      setIsFinishInitialLoad(true);
    }
  }, [booking, appUserOma, isFirstLoad]);

  useEffect(() => {
    dispatch(MedicationForOrganization.fetchMedications(search));
  }, [search]);

  useEffect(() => {
    getDrugTime();
  }, []);

  const onSearch = (value) => {
    setSearch(value);
    setSymptomGroups(() => {
      return symptomGroup.symptom.data.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });
    });
  };

  const getDrugTime = async () => {
    const drugEvent = await DrugTimeEvent.getDrugTimeEvent();
    const drugFrequency = await DrugTimeFrequency.getDrugTimeFrequency();
    setDrugTimeEvent(drugEvent);
    setDrugTimeFrequency(drugFrequency);
  };

  const addMedicinesToBooking = () => {
    const mapToSave = medicines.map((medicine) => ({
      ...medicine,
      createdAt: new Date(),
    }));
    dispatch(Booking.addBookingMedication(booking.current.id, mapToSave));
  };

  const deleteDrugFromPrescription = (bookingId, tpuCode) => {
    const medicines = booking.medication.filter(
      (item) => item.medication.tpuCode !== tpuCode
    );
    setMedicines((prev) =>
      prev.filter((item) => item.medication.tpuCode !== tpuCode)
    );
    dispatch(Booking.deleteMedicationItem(bookingId, medicines));
  };

  const handleCloseSymptomGroup = () => {
    setSymptomGroupId(null);
    setVisibleSymptomGroup(false);
  };

  const handleOkSymptomGroup = () => {
    addMedicinesToBooking();
    setVisibleSymptomGroup(false);
    setSymptomGroupId(null);
  };

  const handleSelectMedicine = (id) => {
    const findMedication = medication.data.find((item) => item.id === id);
    const unit = findMedication.unitType;
    const drugTimeEvent = DRUG_TIME_EVENTS[0];
    const drugRoutes = findMedication.medication.drugRoutes;
    const drugRoute = findMedication.medication.drugRoutes[0];
    const drugTimeFrequency = DRUG_FREQUENCIES[0];
    setMedicines((prev) => {
      const checkDuplicatedDrug = prev.filter((item) => item.id === id);
      if (checkDuplicatedDrug.length > 0) {
        message.error(t("medicine_cannot_be_repeated"));
        return prev;
      }
      return [
        ...prev,
        {
          ...findMedication,
          unit,
          drugTimeEvent,
          drugRoute,
          drugRoutes,
          drugTimeFrequency,
        },
      ];
    });
  };

  const handleChangeSymptomGroup = (id) => {
    setSymptomGroupId(id);
    dispatch(DrugIngredientGroup.getDrugIngredient(id));
  };

  const initialData = () => {
    const { roles, id } = jwtDecode(localStorage.getItem("token"));
    const unHash = JSON.parse(decryptHelper(match.params.id));
    const { bookingId, date, country, specialty } = unHash;
    dispatch(Booking.getBooking(bookingId));
    dispatch(AppUserOma.getUser(id));
    if (["doctor", "communityPharmacist"].includes(roles[0])) {
      dispatch(AppUserOma.getProfile(id));
    }
    dispatch(SymptomGroup.getSymptomGroup());
    setCountry(country);
    setSpecialty(specialty);
    setIsFirstLoad(true);
  };

  const getDrugTimeEventInfo = (code) => {
    const drugTimeInfo = drugTimeEvent.find((drug) => drug.code === code);
    return drugTimeInfo;
  };

  const getDrugTimeFrequencyInfo = (code) => {
    const drugTimeInfo = drugTimeFrequency.find((drug) => drug.code === code);
    return drugTimeInfo;
  };

  const handleOpenNotification = () => {
    notification.warning({
      message: t("enter_color_warning"),
      description: t("enter_color_warning_desp"),
      placement: "topRight",
    });
  };

  const updateColorInQuestionnaire = () => {
    if (booking.current?.covidForm?.id) {
      dispatch(
        Questionaires.updateQuestionaireById(booking.current.covidForm.id, {
          questionAnswers: {
            reports: [{ ...booking.current.covidForm, color, note }],
          },
          result: color,
        })
      );
    }
  };

  const handleUpdateBooking = () => {
    setIsFinishInitialLoad(false);
    if (roomType === "covid") {
      updateColorInQuestionnaire();
    }
    const data =
      roomType === "covid"
        ? { covidForm: { ...booking.current.covidForm, color }, notes: note }
        : { notes: note };
    dispatch(Booking.updateBooking(booking.current.id, true, { ...data }));
    setVisibleSummaryNoteModal(false);
    setIsFirstLoad(false);
  };

  const handleUpdatePrescriptionNote = () => {
    setIsFinishInitialLoad(false);
    const data = { prescriptionNote: prescriptionNote };
    dispatch(Booking.updateBooking(booking.current.id, true, { ...data }));
    setVisiblePrescriptionNoteModal(false);
    setIsFirstLoad(false);
  };

  const handleRedirect = () => {
    let path = "/homepage";
    if (appUserOma.information.roles[0].name === "callCenter") {
      path = "/support-dashboard";
    } else if (
      booking.current.bookingCategory === "general" &&
      booking.current.bookingType === "Telepharmacy"
    ) {
      path = "/dashboard-map";
    } else if (appUserOma.information.roles[0].name === "pharmacy") {
      path = "/round-robin/general";
    } else if (booking.current.bookingCategory === "scheduling") {
      path = "/lobby/telemedicine";
    }
    history.push(path);
  };

  const handleRedirectToDeliverDrug = () => {
    const unHash = JSON.parse(decryptHelper(match.params.id));
    const bookingIdHash = hashHelper(unHash.bookingId);
    history.push(`/deliver-drug/${bookingIdHash}`);
  };

  const handleRedirectToComplete = () => {
    if (
      roomType === "covid" &&
      !booking.current.covidForm?.color &&
      questionaires.updateData?.id !== booking.current.covidForm?.id
    ) {
      handleOpenNotification();
      setVisibleSummaryNoteModal(true);
      return;
    }
    const statusUpdate = "COMPLETED";
    let bookingCategory = booking.current.bookingCategory.toUpperCase();
    let roleUpperCase = appUserOma.information.roles[0].name.toUpperCase();
    if (appUserOma.information.roles[0].name === "callCenter") {
      if (booking.current.status === "DOCTOR_PENDING_NOTE") {
        roleUpperCase = "DOCTOR";
        bookingCategory = "DOCTOR";
      } else if (
        booking.current.status === "COMMUNITY_PHARMACIST_PENDING_NOTE"
      ) {
        roleUpperCase = "COMMUNITY_PHARMACIST";
        bookingCategory = "COMMUNITY_PHARMACIST";
      }
    }
    updateFirebaseStatus(roleUpperCase, bookingCategory, statusUpdate);
    updateBookingStatus(roleUpperCase, statusUpdate);
    sendNotification(statusUpdate);
    handleRedirect();
  };

  const handleRedirectToDashboard = () => {
    history.push("/dashboard-map");
  };

  const handleRedirectToCommunityPharmacist = () => {
    if (
      roomType === "covid" &&
      !booking.current.covidForm?.color &&
      questionaires.updateData?.id !== booking.current.covidForm?.id
    ) {
      handleOpenNotification();
      setVisibleSummaryNoteModal(true);
      return;
    }
    const statusUpdate = "COMPLETED";
    let bookingCategory = booking.current.bookingCategory.toUpperCase();
    let roleUpperCase = appUserOma.information.roles[0].name.toUpperCase();
    if (appUserOma.information.roles[0].name === "callCenter") {
      if (booking.current.status === "DOCTOR_PENDING_NOTE") {
        roleUpperCase = "DOCTOR";
        bookingCategory = "DOCTOR";
      } else if (
        booking.current.status === "COMMUNITY_PHARMACIST_PENDING_NOTE"
      ) {
        roleUpperCase = "COMMUNITY_PHARMACIST";
        bookingCategory = "COMMUNITY_PHARMACIST";
      }
    }
    updateFirebaseStatus(roleUpperCase, bookingCategory, statusUpdate);
    updateBookingStatus(roleUpperCase, statusUpdate);
    sendNotification(statusUpdate);
    const bookingData = {
      status: "COMMUNITY_PHARMACIST_PENDING",
      treatmentId: booking.current.treatmentId,
      patientId: booking.current.patientId,
      bookingCategory:
        booking.current && booking.current.bookingCategory
          ? booking.current.bookingCategory
          : null,
      prescription: medicines.map((medicine) => ({
        ...medicine,
        createdAt: new Date(),
      })),
    };
    dispatch(
      Treatment.createBookingByTreatment(
        booking.current.treatmentId,
        bookingData
      )
    );
    handleRedirect();
  };

  const handleRedirectToDecline = () => {
    const statusUpdate = "DECLINED";
    let bookingCategory = booking.current.bookingCategory.toUpperCase();
    let roleUpperCase = appUserOma.information.roles[0].name.toUpperCase();
    if (appUserOma.information.roles[0].name === "communityPharmacist") {
      roleUpperCase = "COMMUNITY_PHARMACIST";
      bookingCategory = "COMMUNITY_PHARMACIST";
    } else if (appUserOma.information.roles[0].name === "callCenter") {
      if (booking.current.status === "DOCTOR_PENDING_NOTE") {
        roleUpperCase = "DOCTOR";
        bookingCategory = "DOCTOR";
      } else if (
        booking.current.status === "COMMUNITY_PHARMACIST_PENDING_NOTE"
      ) {
        roleUpperCase = "COMMUNITY_PHARMACIST";
        bookingCategory = "COMMUNITY_PHARMACIST";
      }
    }
    updateFirebaseStatus(roleUpperCase, bookingCategory, statusUpdate);
    updateBookingStatus(roleUpperCase, statusUpdate);
    sendNotification(statusUpdate);
    handleRedirect();
  };

  const updateFirebaseStatus = (role, bookingCategory, statusUpdate) => {
    if (country && specialty) {
      dispatch(
        Firebase.updateRoomByCountryAndSpecialty(
          country,
          specialty,
          { status: `${role}_${statusUpdate}` },
          booking.current.patientId
        )
      );
    } else {
      dispatch(
        Firebase.updateRoom(
          bookingCategory,
          appUserOma.information.id,
          booking.current.patientId,
          {
            status: `${role}_${statusUpdate}`,
          },
          role
        )
      );
    }
  };

  const updateBookingStatus = (role, statusUpdate) => {
    dispatch(
      Booking.updateBooking(booking.current.id, true, {
        status: `${role}_${statusUpdate}`,
      })
    );
  };

  const sendNotification = (status) => {
    dispatch(
      UserFcmToken.sendNotification(
        booking.current.patientId,
        NOTIFICATION[status],
        NOTIFICATION_DATA(status, booking.current.admitTime)
      )
    );
  };

  return isFinishInitialLoad ? (
    <Summary
      booking={booking}
      roomType={roomType}
      userOma={booking.current.patient}
      color={color}
      setColor={setColor}
      role={appUserOma.information.roles[0].name}
      note={note}
      setNote={setNote}
      medicines={medicines}
      drugTimeEvent={drugTimeEvent}
      drugTimeFrequency={drugTimeFrequency}
      symptomGroupId={symptomGroupId}
      setMedicines={setMedicines}
      medication={medication}
      handleSelectMedicine={handleSelectMedicine}
      prescriptionNote={prescriptionNote}
      setPrescriptionNote={setPrescriptionNote}
      visibleHistoryPatientDetailModal={visibleHistoryPatientDetailModal}
      setVisibleHistoryPatientDetailModal={setVisibleHistoryPatientDetailModal}
      visibleSummaryNoteModal={visibleSummaryNoteModal}
      setVisibleSummaryNoteModal={setVisibleSummaryNoteModal}
      visiblePrescriptionNoteModal={visiblePrescriptionNoteModal}
      setVisiblePrescriptionNoteModal={setVisiblePrescriptionNoteModal}
      handleUpdateBooking={handleUpdateBooking}
      handleRedirect={handleRedirect}
      handleRedirectToDeliverDrug={handleRedirectToDeliverDrug}
      handleRedirectToDecline={handleRedirectToDecline}
      handleCloseSymptomGroup={handleCloseSymptomGroup}
      handleOkSymptomGroup={handleOkSymptomGroup}
      handleChangeSymptomGroup={handleChangeSymptomGroup}
      visibleSymptomGroup={visibleSymptomGroup}
      setVisibleSymptomGroup={setVisibleSymptomGroup}
      deleteDrugFromPrescription={deleteDrugFromPrescription}
      getDrugTimeFrequencyInfo={getDrugTimeFrequencyInfo}
      getDrugTimeEventInfo={getDrugTimeEventInfo}
      handleRedirectToComplete={handleRedirectToComplete}
      handleRedirectToCommunityPharmacist={handleRedirectToCommunityPharmacist}
      handleRedirectToDashboard={handleRedirectToDashboard}
      handleUpdatePrescriptionNote={handleUpdatePrescriptionNote}
      onSearch={onSearch}
      symptomGroups={symptomGroups}
      t={t}
    />
  ) : (
    <Loading />
  );
};

export default SummaryContainer;
