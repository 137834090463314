import styled from "styled-components";

export const CircleButtonAlert = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #da0063;
  color: #ffffff;
  cursor: pointer;
`;

export const CircleButtonNormal = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #000000;
  cursor: pointer;
`;
