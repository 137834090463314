import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import { Radio, Select } from "antd";
import { ErrorMessage, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import logo from "../../images/logo-evermed.png";
import { AppUserOma } from "../../redux/actions";
import Button from "../Button";
import { ROLES, FILTERROLES } from "./constant";
import { Container, ErrMsg, Item, Row } from "./styles";
import { TextInput } from "./TextInput";

const { Option } = Select;
const RadioGroup = Radio.Group;

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("กรุณากรอกชื่อ"),
  lastname: Yup.string().required("กรุณากรอกนามสกุล"),
  email: Yup.string().email().required("กรุณากรอกอีเมล"),
  password: Yup.string()
    .min(6, "กรุณากรอกรหัสผ่านอย่างน้อย 6 ตัวอักษร")
    .required("กรุณากรอกรหัสผ่าน"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "รหัสผ่านไม่ตรงกัน")
    .required("กรุณากรอกยืนยันรหัสผ่าน"),
  role: Yup.string().required("กรุณาเลือกหน้าที่"),
});

const RegisterForm = ({ setSelectedPractitionerId, setRoleId, t }) => {
  const dispatch = useDispatch();
  const practitionerType = useSelector(
    (state) => state.practitionerSpecialties
  );
  const appUserOma = useSelector((state) => state.appUserOma);
  const organization = useSelector((state) => state.organization);
  const roles = useSelector((state) => state.role.data);

  const filterRole = (value) => {
    let filter = roles.filter((data) => data.id === value);
    return filter[0].name;
  };

  return (
    <Container>
      <img src={logo} alt="logo" width={75} height={75} />
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
          specialtyId: "",
          role: "",
          firstname: "",
          lastname: "",
        }}
        onSubmit={(values) => {
          dispatch(
            AppUserOma.registerAppUserOma({
              ...values,
              registerChannel: "webPortal",
            })
          );
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { values, handleChange, handleSubmit, errors, setFieldValue } =
            props;
          const {
            email,
            password,
            confirmPassword,
            specialtyId,
            role,
            firstname,
            lastname,
          } = values;
          return (
            <form style={{ padding: 20, width: "100%" }}>
              <Row>
                <Item>
                  <h5>{t("first_name")}</h5>
                  <TextInput
                    placeholder={t("fill_first_name")}
                    name="firstname"
                    prefix={<UserOutlined />}
                    value={firstname}
                    onChange={handleChange}
                    required
                  />
                  <ErrorMessage component={ErrMsg} name="firstname" />
                </Item>
                <Item>
                  <h5>{t("last_name")}</h5>
                  <TextInput
                    placeholder={t("fill_last_name")}
                    name="lastname"
                    prefix={<UserOutlined />}
                    value={lastname}
                    onChange={handleChange}
                    required
                  />
                  <ErrorMessage component={ErrMsg} name="lastname" />
                </Item>
              </Row>
              <Item>
                <h5>{t("email")}</h5>
                <TextInput
                  placeholder={t("fill_email")}
                  name="email"
                  prefix={<UserOutlined />}
                  value={email}
                  onChange={handleChange}
                  required
                />
                <ErrorMessage component={ErrMsg} name="email" />
              </Item>
              <Item>
                <h5>{t("role")}</h5>
                <Select
                  style={{ width: "100%" }}
                  name="role"
                  value={role}
                  onChange={(value) => {
                    setRoleId(value);
                    setFieldValue("role", filterRole(value));
                  }}
                >
                  {roles &&
                    roles.map(
                      (role) =>
                        FILTERROLES.includes(role.name) && (
                          <Option key={role.id} value={role.id}>
                            {ROLES[role.name]}
                          </Option>
                        )
                    )}
                </Select>
                <ErrorMessage component={ErrMsg} name="role" />
              </Item>
              {role === "doctor" ? (
                <Item>
                  <h5>{t("specialty")}</h5>
                  <Select
                    style={{ width: "100%" }}
                    name="specialtyId"
                    value={specialtyId}
                    onChange={(value) => {
                      setSelectedPractitionerId(value);
                      setFieldValue("specialtyId", value);
                    }}
                  >
                    {practitionerType.data &&
                      practitionerType.data.map((type) => (
                        <Option key={type.id} value={type.id}>
                          {type.nameTh || "-"}
                        </Option>
                      ))}
                  </Select>
                  <ErrorMessage component={ErrMsg} name="specialtyId" />
                </Item>
              ) : null}
              <Row>
                <Item>
                  <h5>{t("password")}</h5>
                  <TextInput
                    type="password"
                    name="password"
                    placeholder={t("fill_password")}
                    prefix={<KeyOutlined />}
                    value={password}
                    required
                    onChange={handleChange}
                    autocomplete="new-password"
                  />
                  <ErrorMessage component={ErrMsg} name="password" />
                </Item>
                <Item>
                  <h5>{t("confirm_password")}</h5>
                  <TextInput
                    type="password"
                    placeholder={t("fill_confirm_password")}
                    name="confirmPassword"
                    prefix={<KeyOutlined />}
                    value={confirmPassword}
                    required
                    onChange={handleChange}
                  />
                  <ErrorMessage component={ErrMsg} name="confirmPassword" />
                </Item>
              </Row>
              <Item flex>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  primary
                  style={{ flex: 1 }}
                >
                  {t("register")}
                </Button>
              </Item>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default RegisterForm;
