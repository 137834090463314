import { call, put, takeEvery } from "redux-saga/effects";
import { Role } from "../../api";
import * as type from "../types";

function* getRole() {
  try {
    const result = yield call(Role.getRole);
    yield put({ type: type.GET_ROLE_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_ROLE_FAILED, error: e.message });
  }
}

function* roleSaga() {
  yield takeEvery(type.GET_ROLE_REQUESTED, getRole);
}

export default roleSaga;
