export const NOTIFICATION = {
  COMPLETED: (language = "en") => ({
    body: "The doctor has completed an assessment of the symptoms",
    title: "Alert",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
  DECLINED: (language = "en") => ({
    body: "Something went wrong, Please contact your doctor again",
    title: "Alert",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
};

export const NOTIFICATION_DATA = (key, admitTime) => {
  const returnData = {
    COMPLETED: {
      time: admitTime ? new Date(admitTime) : null,
      page: "Booking",
    },
    DECLINED: {
      page: "TeleSymptom",
    },
  };
  return returnData[key];
};
