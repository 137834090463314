import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/doctorAvailableTimes";

export async function getAvailableTimes(id) {
  const { data } = await http.get(`/Practitioners/${id}/timeSlots`);
  if (data && data.length) {
    const mapData = {
      ...data[0],
      time: data[0].time.map((time) => ({
        start: time[0],
        end: time[1],
      })),
    };
    return mapData;
  }
  return [];
}

export async function updateAvailableTime({ id, detail }) {
  if (detail.time) {
    const times = detail.time.map((t) => [t.start, t.end]);
    detail.time = times;
  }
  const { data } = await http.patch(`${apiEndpoint}/${id}`, detail);
  return data;
}
