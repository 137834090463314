import styled from "styled-components";

export const Container = styled.div`
  background-image: linear-gradient(180deg, #eaeff6 0%, #f3f5f9 50%);
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
