import React, { useEffect } from "react";
import { Tabs, Button, Card, Row, Col } from "antd";
import { ContentWrapper } from "./styles";
import MonitoringInfo from "./MonitoringInfo";
import { KycImage, UserOma } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import GeneralInfo from "./GeneralInfo";
const { TabPane } = Tabs;

const PatientTeleDetail = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const url = decodeURIComponent(history.location.pathname);
  const appUserId = url.split("=")[1];
  const userOma = useSelector((state) => state.userOma);
  useEffect(() => {
    if (appUserId) {
      dispatch(UserOma.getUserOmaById(appUserId));
      dispatch(UserOma.getUserOmaByHie(appUserId));
    }
  }, []);
  return (
    <ContentWrapper>
      <Row type="flex" gutter={[24, 24]}>
        <Col sm={24} md={6} lg={6}>
          <GeneralInfo userInfo={userOma} />
        </Col>
        <Col sm={24} md={18} lg={18}>
          <MonitoringInfo appUserId={appUserId} />
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default PatientTeleDetail;
