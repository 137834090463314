import * as type from "../types";

const initialState = {
  loading: false,
  error: null,
  data: null,
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SEND_NOTIFICATION_REQUESTED:
      return {
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.SEND_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        isSuccess: true,
        error: null,
      };
    case type.SEND_NOTIFICATION_FAILED:
      return {
        loading: false,
        error: action.error,
        isSuccess: false,
      };

    default:
      return state;
  }
};
