import firebase from "firebase/app";
import "firebase/database";
import constant from "../constant";

const config = {
  apiKey: "AIzaSyBtcXKD8TpQORWXQMpGhIwoK6OkBAkE3Dc",
  authDomain: "ivory-coast-d4d9f.firebaseapp.com",
  databaseURL:
    "https://ivory-coast-d4d9f-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ivory-coast-d4d9f",
  storageBucket: "ivory-coast-d4d9f.appspot.com",
  messagingSenderId: "323160068008",
  appId: "1:323160068008:web:0b1de8f75582f9bb2f579d",
  measurementId: "G-JGE9HDPS25",
};

export const initializeFirebase = () => {
  firebase.initializeApp(config);
  // return firebase.messaging()
};

export const askForPermissionToReceiveNotifications = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      localStorage.setItem(constant.PUSH_TOKEN, token);
      return null;
    }
  } catch (error) {
    throw error;
  }
};

export const getMessaging = () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    return messaging;
  }
};
