import {
  Container,
  TextArea,
  Text,
  DatePicker,
  Dragger,
  Icon,
} from "../styles";
import { Row, Space, Radio, Descriptions, Typography } from "antd";
import { IoMdCloudUpload } from "react-icons/all";
import moment from "moment";

const disabledDate = (current) => {
  return current && current > moment().endOf("day");
};

const NasopharyngealSwab = ({
  t,
  form,
  customRequest,
  currentRecord,
  getNameFromUrl,
  disabled,
}) => {
  const { Link } = Typography;

  if (disabled) {
    const {
      nasopharyngealStatus = "nothing",
      nasopharyngealDateOfSampleCollection,
      nasopharyngealFileUrl,
    } = form;
    return (
      <Container>
        <Descriptions
          bordered
          labelStyle={{ fontWeight: "bold" }}
          size="small"
          column={1}
        >
          <Descriptions.Item label={t("status")}>
            {t(nasopharyngealStatus)}
          </Descriptions.Item>
          {nasopharyngealDateOfSampleCollection && (
            <Descriptions.Item label={t("date")}>
              {moment(nasopharyngealDateOfSampleCollection).format(
                "DD MMMM YYYY"
              )}
            </Descriptions.Item>
          )}
        </Descriptions>
        {nasopharyngealFileUrl && (
          <Descriptions
            bordered
            labelStyle={{ fontWeight: "bold" }}
            size="small"
            column={1}
            layout="vertical"
            className="mt-2"
          >
            <Descriptions.Item label="File">
              <Link href={nasopharyngealFileUrl} target="_blank">
                {getNameFromUrl(nasopharyngealFileUrl)}
              </Link>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <Space align="center">
        <Radio.Group
          onChange={form.handleChange("nasopharyngealStatus")}
          value={form.values.nasopharyngealStatus}
        >
          <Radio value={"not_detected"}>{t("not_detected")}</Radio>
          <Radio value={"detected"}>{t("detected")}</Radio>
        </Radio.Group>
      </Space>
      <TextArea className="mt-3" />
      <Space direction="vertical" className="mt-3">
        <Text size="12px">{t("date_of_sample_collection")}:</Text>
        <DatePicker
          format="DD MMMM YYYY"
          minWidth="200px"
          disabledDate={disabledDate}
          defaultValue={
            form.values.nasopharyngealDateOfSampleCollection
              ? moment(form.values.nasopharyngealDateOfSampleCollection)
              : null
          }
          onChange={(date) => {
            form.setFieldValue(
              "nasopharyngealDateOfSampleCollection",
              moment(date).toISOString()
            );
          }}
        />
      </Space>
      <Dragger
        defaultFileList={
          form.values.nasopharyngealFileUrl
            ? [
                {
                  uid: "1",
                  name: getNameFromUrl(form.values.nasopharyngealFileUrl),
                  status: "done",
                  response: { url: form.values.nasopharyngealFileUrl },
                  url: form.values.nasopharyngealFileUrl,
                },
              ]
            : []
        }
        multiple={false}
        onChange={(info) => {
          const { status } = info.file;
          if (status === "done") {
            form.setFieldValue(
              "nasopharyngealFileUrl",
              info.file.response?.url
            );
          }
        }}
        onRemove={(_) => {
          form.setFieldValue("nasopharyngealFileUrl", "");
        }}
        maxCount={1}
        customRequest={(option) =>
          customRequest({
            ...option,
            key: currentRecord.researchId,
            pathFb: "nasopharyngealFile",
          })
        }
        className="mt-3 py-4"
      >
        <Row justify="center" align="middle">
          <Icon component={IoMdCloudUpload} size="24px" color="#2BA796" />
          <Text className="mx-2" primary>
            {t("drop_file_to_upload_or_click")}
          </Text>
        </Row>
      </Dragger>
    </Container>
  );
};

export default NasopharyngealSwab;
