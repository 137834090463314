import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/organizations";

async function getOrganizations(filter) {
  const { data } = await http.get(`${apiEndpoint}${filter}`);
  return data;
}

async function getUsersByOrganization(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/users`);
  return data;
}

async function getCommunityPharmaciesByOrganization(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/communityPhamacies`);
  return data;
}

async function getOrganizationSubmissions(listOrgId = []) {
  const listReq = listOrgId.map((item) => {
    return new Promise(async (resolve, reject) => {
      const { data } = await http.get(`${apiEndpoint}/${item.id}/submissions`);
      resolve(data);
    });
  });
  const result = await Promise.all(listReq);
  return result;
}

async function getResearchObjectsByOrganization(id) {
  const { data } = await http.get(
    `${apiEndpoint}/${id}/researchObjects?filter[where][status][neq]=in-progress`
  );
  return data;
}

async function getResearchProgramsByOrganization(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/researchPrograms`);
  return data;
}

async function getRecruitmentObjectsByOrgAndProgram(id, programId) {
  const { data } = await http.get(
    `${apiEndpoint}/${id}/recruitmentObjects?filter[where][researchProgramId]=${programId}`
  );
  return data;
}

async function getReserchObjectsByOrgAndProgram(id, programId) {
  const { data } = await http.get(
    `${apiEndpoint}/${id}/researchObjects?filter[where][researchProgramId]=${programId}`
  );
  return data;
}

async function getQuestionnaireByOrganization() {}

export {
  getOrganizations,
  getOrganizationSubmissions,
  getUsersByOrganization,
  getCommunityPharmaciesByOrganization,
  getResearchObjectsByOrganization,
  getResearchProgramsByOrganization,
  getRecruitmentObjectsByOrgAndProgram,
  getReserchObjectsByOrgAndProgram,
};
