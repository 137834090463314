import { Modal, Row, Col, Radio, Button, Input } from "antd";
import { FaPenSquare } from "react-icons/fa";
import { NoteIconBox } from "./styles";
import { COLORS } from "./constant";

const { TextArea } = Input;

const SummaryNoteModal = (props) => {
  const {
    visible,
    setVisible,
    color,
    setColor,
    note,
    setNote,
    handleUpdateBooking,
    roomType,
    t,
  } = props;
  return (
    <Modal
      visible={visible}
      style={{ borderRadius: "24px" }}
      onCancel={() => setVisible(false)}
      footer={
        <Row justify="space-between">
          {roomType === "covid" && (
            <Col>
              <Radio.Group
                options={COLORS}
                onChange={(e) => setColor(e.target.value)}
                value={color}
              />
            </Col>
          )}

          <Col>
            <Button onClick={() => setVisible(false)}>{t("cancel")}</Button>
            <Button type="primary" onClick={handleUpdateBooking}>
              {t("confirm")}
            </Button>
          </Col>
        </Row>
      }
      okText={t("confirm")}
      cancelText={t("cancel")}
    >
      <Row type="flex" justify="center">
        <Col className="d-flex align-items-center flex-column text-center">
          <NoteIconBox>
            <FaPenSquare
              style={{ fontSize: "36px", color: "#00BAE5" }}
              className="my-2"
            />
          </NoteIconBox>
          <h1 className="h3 font-weight-bold">{t("note")}</h1>
          <p>{t("description")}</p>
        </Col>
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={4}
        />
      </Row>
    </Modal>
  );
};

export default SummaryNoteModal;
