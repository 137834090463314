import React, { useEffect, useState } from "react";
import {
  Collapse,
  Col,
  Row,
  Space,
  Typography,
  message,
  Dropdown,
  Card,
  Button,
} from "antd";
import { Container, CardContainer, HeaderPanel, Icon } from "./styles";
import {
  RiHeartPulseFill,
  AiFillFileText,
  GiMedicines,
  RiVirusLine,
  FaSyringe,
  RiTestTubeFill,
  GiStethoscope,
  RiHealthBookFill,
  ImImages,
  FiMoreHorizontal,
  MdEdit,
} from "react-icons/all";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import { getResearchObjectsById } from "../../api/researchObjects";
import {
  DemographicDataSection,
  VitalSignsSection,
  MedicalAndMedication,
  UnderlyingDiseases,
  VaccinesInfectionHistory,
  PreviousOrCurrentClinicalPraticipant,
  PhysicalExamination,
  NasopharyngealSwab,
  SinuscopyAndImages,
  CCPassTesting,
} from "../ClinicTrialEligibilityWidgets";
import CtmsParticipantInfomationModal from "../Modal/CtmsParticipantInfomationModal";
import InventionTimeline from "./widgets/InventionTimeline";
import SchemaModal from "./widgets/SchemaModal";
import EligibilityAssessmentModal from "./widgets/EligibilityAssessmentModal";
import EditEligibilityModal from "./widgets/EditEligibilityModal";
import { useDispatch, useSelector } from "react-redux";
import { ClinicTrial as ClinicTrialAction } from "../../redux/actions";
import { get, intersection, map } from "lodash";
import {
  EDIT_ELIGIBILITY,
  COLUMNS_RECRUITMENT,
  COLUMNS_GENERAL_CONTACT,
  COLUMNS_IN_EX_CRITERIA,
  COLUMNS_ELIGIBILITY,
  COLUMNS_FOLLOW_UP,
} from "./constant";
import ReactExport from "react-data-export";
import MoreComponent from "./widgets/MoreComponent";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const widgetList = [
  DemographicDataSection,
  VitalSignsSection,
  MedicalAndMedication,
  UnderlyingDiseases,
  VaccinesInfectionHistory,
  PreviousOrCurrentClinicalPraticipant,
  PhysicalExamination,
  NasopharyngealSwab,
  SinuscopyAndImages,
  CCPassTesting,
];

const ResearchObjectDetail = (props) => {
  const {
    t,
    researchObjectData,
    formatClinicTrial,
    formatGeneralContact,
    formatInExCriteria,
    formatEligibility,
    formatFollowUp,
  } = props;
  const { id } = useParams();
  const clinicTrial = useSelector((state) => state.clinicTrial);
  const appUserOma = useSelector((state) => state.appUserOma);
  const dispatch = useDispatch();
  const [followUpForms, setFollowUpForms] = useState([]);
  const [isSchemaModalVisible, setIsSchemaModalVisible] = useState(false);
  const [isModalDetailVisible, setIsModalDetailVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentEditData, setCurrentEditData] = useState({});
  const [userPerm, setUserPerm] = useState({
    EDIT_ELIGIBILITY: false,
  });

  const recruitmentDataSet = [
    {
      columns: COLUMNS_RECRUITMENT,
      data: formatClinicTrial,
    },
  ];

  const generalContactDataSet = [
    {
      columns: COLUMNS_GENERAL_CONTACT,
      data: formatGeneralContact,
    },
  ];

  const inExCriteriaDataSet = [
    {
      columns: COLUMNS_IN_EX_CRITERIA,
      data: formatInExCriteria,
    },
  ];

  const eligibilityDataSet = [
    {
      columns: COLUMNS_ELIGIBILITY,
      data: formatEligibility,
    },
  ];

  const followUpDataSet = [
    {
      columns: COLUMNS_FOLLOW_UP,
      data: formatFollowUp,
    },
  ];

  const { Title, Link, Text } = Typography;
  const { Panel } = Collapse;

  const [isEligibilityModalVisible, setIsEligibilityModalVisible] =
    useState(false);

  // * NOTE check roles,permissions and init
  useEffect(() => {
    const userRoles = map(get(appUserOma.information, "roles", []), "name");
    setUserPerm({
      EDIT_ELIGIBILITY: intersection(EDIT_ELIGIBILITY, userRoles).length > 0,
    });
    getAssessmentObjectByIdHandler();
  }, []);

  useEffect(() => {
    if (clinicTrial.listAssessment.isSuccess && isEligibilityModalVisible) {
      setIsEligibilityModalVisible(false);
    }
    if (clinicTrial.listAssessment.error) {
      message.error(t("error_occurred"));
    }
  }, [clinicTrial.listAssessment]);
  const addNewAssessmentByIdHandler = (id, payload) => {
    dispatch(ClinicTrialAction.addNewEligibilityAssessment(id, payload));
  };

  const getAssessmentObjectByIdHandler = () => {
    dispatch(ClinicTrialAction.getAssessmentObjectById(id));
  };

  const onEigibilityAssessmentSubmit = (id, fk, payload) => {
    dispatch(ClinicTrialAction.editEligibilityForm(id, fk, payload));
  };

  const customRequest = async ({ file, onSuccess, onError, key, pathFb }) => {
    try {
      const fileUpload = file;
      const fileName = fileUpload.name;
      const storageRef = firebase
        .storage()
        .ref(`clinicTrial/${key}/${pathFb}/${Date.now()}/${fileName}`);
      await storageRef.put(fileUpload);
      const imageUrl = await storageRef.getDownloadURL();
      onSuccess({ url: imageUrl });
    } catch (e) {
      onError({ e });
      message.error(e.message);
    }
  };

  const getNameFromUrl = (url) => {
    try {
      return firebase.storage().refFromURL(url).name;
    } catch (e) {
      return url;
    }
  };

  const extraComp = (index, item) => {
    if (userPerm.EDIT_ELIGIBILITY) {
      return (
        <MoreComponent
          t={t}
          index={index}
          item={item}
          setCurrentEditData={setCurrentEditData}
          setIsEditModalVisible={setIsEditModalVisible}
          researchObjectData={researchObjectData}
        />
      );
    }
    return null;
  };

  return (
    <Container className="px-4 py-5 mb-5">
      <Row gutter={[14, 14]}>
        <Col span={5} xs={24} md={24} lg={5} className="gutter-row">
          <CardContainer
            title={t("participant_Info")}
            extra={
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalDetailVisible(true);
                }}
                href="#"
              >
                {t("see_more")}
              </a>
            }
          >
            <Title level={5}>
              {t("subject_id")} :
              <Text style={{ fontWeight: 300 }}>
                {researchObjectData.filtering?.subjectId}
              </Text>
            </Title>
          </CardContainer>
          <CardContainer title={t("document_list")}>
            <Row type="flex" style={{ alignItems: "center" }} gutter={10}>
              <Col span={24}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {researchObjectData.consentForm?.fileUrls?.map((item) => (
                    <>
                      <Link href={item} target="_blank">
                        {getNameFromUrl(item)}
                      </Link>
                    </>
                  ))}
                </Space>
              </Col>
            </Row>
          </CardContainer>
          <ExcelFile element={<Button>Export excel</Button>}>
            <ExcelSheet
              dataSet={recruitmentDataSet}
              name="1. Results of recruitment"
            />
            <ExcelSheet
              dataSet={generalContactDataSet}
              name="2. General and contact"
            />
            <ExcelSheet
              dataSet={inExCriteriaDataSet}
              name="3. In/ex criteria"
            />
            <ExcelSheet
              dataSet={eligibilityDataSet}
              name="4. 0,8,14 eligibility"
            />
            <ExcelSheet dataSet={followUpDataSet} name="5. 1-14 Follow up" />
          </ExcelFile>
        </Col>
        <Col span={13} xs={24} md={14} lg={13} className="gutter-row">
          <CardContainer
            title={t("activities")}
            extra={
              userPerm.EDIT_ELIGIBILITY ? (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEligibilityModalVisible(true);
                  }}
                  href="#"
                >
                  {t("add")}
                </a>
              ) : null
            }
            minheight="90vh"
          >
            {clinicTrial.listAssessment?.data?.map((form, index) => (
              <Collapse
                expandIconPosition="right"
                style={{ marginTop: 10 }}
                key={index}
              >
                <Panel
                  header={
                    <HeaderPanel
                      title={`${t("day")} - ${
                        form.day === -1 ? t("pre_screening") : form.day
                      }`}
                    />
                  }
                  key="1"
                >
                  <Collapse>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={AiFillFileText}
                          title={t("demographic")}
                        />
                      }
                      extra={extraComp(1, form)}
                      key="1"
                    >
                      <DemographicDataSection t={t} form={form} disabled />
                    </Panel>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={RiHeartPulseFill}
                          title={t("vital_signs")}
                        />
                      }
                      extra={extraComp(2, form)}
                      key="2"
                    >
                      <VitalSignsSection
                        t={t}
                        form={{ values: form }}
                        disabled
                      />
                    </Panel>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={GiMedicines}
                          title={t("medication_histories")}
                        />
                      }
                      extra={extraComp(3, form)}
                      key="3"
                    >
                      <MedicalAndMedication t={t} form={form} disabled />
                    </Panel>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={RiVirusLine}
                          title={t("underlying_diseases")}
                        />
                      }
                      extra={extraComp(4, form)}
                      key="4"
                    >
                      <UnderlyingDiseases t={t} form={form} disabled />
                    </Panel>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={FaSyringe}
                          title={t("infection_history")}
                        />
                      }
                      extra={extraComp(5, form)}
                      key="5"
                    >
                      <VaccinesInfectionHistory
                        t={t}
                        form={form}
                        getNameFromUrl={getNameFromUrl}
                        disabled
                      />
                    </Panel>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={RiTestTubeFill}
                          title={t("current_clinical_trial")}
                        />
                      }
                      extra={extraComp(6, form)}
                      key="6"
                    >
                      <PreviousOrCurrentClinicalPraticipant
                        t={t}
                        form={form}
                        disabled
                      />
                    </Panel>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={GiStethoscope}
                          title={t("physical_examination")}
                        />
                      }
                      extra={extraComp(7, form)}
                      key="7"
                    >
                      <PhysicalExamination t={t} form={form} disabled />
                    </Panel>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={RiHealthBookFill}
                          title={t("nasop_oropharyngeal_swab")}
                        />
                      }
                      extra={extraComp(8, form)}
                      key="8"
                    >
                      <NasopharyngealSwab
                        getNameFromUrl={getNameFromUrl}
                        t={t}
                        form={form}
                        disabled
                      />
                    </Panel>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={ImImages}
                          title={t("sinuscopy_images")}
                        />
                      }
                      extra={extraComp(9, form)}
                      key="9"
                    >
                      <SinuscopyAndImages
                        t={t}
                        form={{ values: form }}
                        disabled
                      />
                    </Panel>
                    <Panel
                      header={
                        <HeaderPanel
                          iconComponent={RiVirusLine}
                          title={t("cc_pass")}
                        />
                      }
                      extra={extraComp(10, form)}
                      key="10"
                    >
                      <CCPassTesting t={t} form={{ values: form }} disabled />
                    </Panel>
                  </Collapse>
                </Panel>
              </Collapse>
            ))}
          </CardContainer>
        </Col>
        <Col span={6} xs={24} md={10} lg={6}>
          <CardContainer
            title={t("intervention_timeline")}
            minheight="90vh"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.85)" }}
          >
            <InventionTimeline
              researchObjectData={researchObjectData}
              setFollowUpForms={setFollowUpForms}
              setIsSchemaModalVisible={setIsSchemaModalVisible}
              t={t}
            />
          </CardContainer>
        </Col>
      </Row>
      <SchemaModal
        t={t}
        followUpForms={followUpForms}
        isSchemaModalVisible={isSchemaModalVisible}
        setFollowUpForms={setFollowUpForms}
        setIsSchemaModalVisible={setIsSchemaModalVisible}
      />
      <EligibilityAssessmentModal
        t={t}
        researchObjectData={researchObjectData}
        customRequest={customRequest}
        getNameFromUrl={getNameFromUrl}
        clinicTrial={clinicTrial}
        isEligibilityModalVisible={isEligibilityModalVisible}
        setIsEligibilityModalVisible={setIsEligibilityModalVisible}
        addNewAssessmentByIdHandler={addNewAssessmentByIdHandler}
      />
      <CtmsParticipantInfomationModal
        t={t}
        visible={isModalDetailVisible}
        setVisible={setIsModalDetailVisible}
        data={researchObjectData.address}
        extraData={researchObjectData.generalInformation}
        subjectId={researchObjectData.filtering?.subjectId}
      />
      <EditEligibilityModal
        t={t}
        currentRecord={currentEditData}
        widgetList={widgetList}
        customRequest={customRequest}
        getNameFromUrl={getNameFromUrl}
        isEditModalVisible={isEditModalVisible}
        setIsEditModalVisible={setIsEditModalVisible}
        onEigibilityAssessmentSubmit={onEigibilityAssessmentSubmit}
      />
    </Container>
  );
};

export default ResearchObjectDetail;
