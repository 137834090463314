import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { useDispatch, useSelector } from "react-redux";
import { hiddenChatPath, practitionerRoles } from "./constant";
import jwtDecode from "jwt-decode";
const Intercom = ({ path, auth }) => {
  const user = useSelector((state) => state.appUserOma);
  const { boot, shutdown, hide, show, update } = useIntercom();
  useEffect(() => {
    const authInfo = jwtDecode(auth);
    if (
      practitionerRoles.includes(authInfo.roles[0]) &&
      user.practitioner &&
      user.information
    ) {
      const userId = `${user.practitioner.appUserId}`;
      const appUserName = `${user.practitioner.title}${user.practitioner.firstName}_${user.practitioner.lastName}`;
      boot({
        name: appUserName,
        customAttributes: {
          user_id: `${userId}_${appUserName}`,
          email: user.information.email || "",
        },
      });
    } else if (
      !practitionerRoles.includes(authInfo.roles[0]) &&
      user.information
    ) {
      const userId = `${user.information.id}`;
      const userInfo = user.information.userInformation;
      const appUserName =
        userInfo && userInfo.firstname && userInfo.lastname
          ? `${userInfo.firstname}_${userInfo.lastname}`
          : user.information.email;
      boot({
        name: appUserName,
        customAttributes: {
          user_id: `${userId}_${appUserName}`,
          email: user.information.email || "",
        },
      });
    }
    shutdown();
  }, [user, path]);

  return null;
};

export default Intercom;
