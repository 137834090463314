import React, { useEffect, useState } from "react";
import TemperatureValueCard from "./TemperatureValueCard";
import TemperatureTrendCard from "./TemperatureTrendCard";
import TemperatureGraphCard from "./TemperatureGraphCard";
import { Container } from "./styles";
import {
  TemperatureValue,
  TemperatureTrend,
  TemperatureGraph,
} from "../constant";
const Temperature = ({
  temperature,
  filterReport,
  defaultTemperature,
  isVideoCall,
  t,
}) => {
  const [values, setValues] = useState(TemperatureValue);
  const [trends, setTrends] = useState(TemperatureTrend);
  const [graphs, setGraphs] = useState(TemperatureGraph);

  useEffect(() => {
    if (
      temperature.data &&
      temperature.data.arr.length &&
      temperature.data.dates.length
    ) {
      checkTemperatureValue();
    } else {
      setValues(TemperatureValue);
      setTrends(TemperatureTrend);
      setGraphs(TemperatureGraph);
    }
  }, [temperature]);

  const findMin = (arr) => Math.min(...arr);
  const findMax = (arr) => Math.max(...arr);
  const findAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

  const checkTemperatureValue = () => {
    const { arr } = temperature.data;
    setValues({
      min: findMin(arr),
      max: findMax(arr),
      average: findAverage(arr),
    });
    checkTemperatureTrend();
  };

  const checkTemperatureTrend = () => {
    if (defaultTemperature) {
      const { arr } = temperature.data;
      let lowest = defaultTemperature[0];
      let highest = defaultTemperature[1];
      const trend = { ...TemperatureTrend };
      arr.map((value) => {
        if (Number(value) < Number(lowest)) {
          trend.low++;
        } else if (
          Number(value) >= Number(lowest) &&
          Number(value) <= Number(highest)
        ) {
          trend.mid++;
        } else if (Number(value) > Number(highest)) {
          trend.high++;
        }
      });
      setTrends((prev) => ({
        ...trend,
        pie: {
          ...prev.pie,
          datasets: [
            {
              ...prev.pie.datasets[0],
              data: [trend.mid, trend.low, trend.high],
            },
          ],
        },
      }));
      checkTemperatureGraph(lowest, highest);
    }
  };

  const checkTemperatureGraph = (lowest, highest) => {
    const { arr, dates } = temperature.data;
    setGraphs((prev) => ({
      defaultLow: lowest,
      defaultHigh: highest,
      lines: {
        options: {
          ...prev.lines.options,
          xaxis: {
            categories: dates,
          },
          annotations: {
            yaxis: [
              { ...prev.lines.options.annotations.yaxis[0], y: highest },
              {
                ...prev.lines.options.annotations.yaxis[1],
                y: lowest,
                y2: highest,
              },
              {
                ...prev.lines.options.annotations.yaxis[2],
                y: lowest,
              },
            ],
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="arrow_box" style="padding: 16px">
                      <p>Temperature Times: <b>${
                        series[seriesIndex][dataPointIndex]
                      }</b></p>
                      <p>Date: <b>${dates[dataPointIndex] || "-"}</b></p>
                      </div>`;
            },
          },
        },
        series: [
          {
            name: "Temperature Times",
            data: arr,
          },
        ],
      },
    }));
  };

  return (
    <Container>
      <TemperatureValueCard
        t={t}
        values={values}
        filterReport={(start, end) => filterReport(start, end, 6)}
      />
      <TemperatureTrendCard t={t} trends={trends} isVideoCall={isVideoCall} />
      <TemperatureGraphCard
        t={t}
        graphs={graphs}
        defaultTemperature={defaultTemperature}
      />
    </Container>
  );
};

export default Temperature;
