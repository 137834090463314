import http from "../services/httpServiceHealthCare";
const apiEndpoint = "/ResearchObjects";

async function getResearchObjectsByRecruitmentId(id) {
  const { data } = await http.get(
    `${apiEndpoint}?filter[where][recruitmentObjectId]=${id}`
  );
  return data;
}

async function createConsentData(id, payload) {
  const { data } = await http.post(`${apiEndpoint}/${id}/consent`, payload);
  return data;
}

async function createGeneralInfoData(id, payload) {
  const { data } = await http.post(`${apiEndpoint}/${id}/information`, payload);
  return data;
}

async function createContactInfoData(id, payload) {
  const { data } = await http.post(`${apiEndpoint}/${id}/contact`, payload);
  return data;
}

async function createEligibilityAssessmentData(id, payload) {
  const { data } = await http.post(`${apiEndpoint}/${id}/assessments`, payload);
  return data;
}

async function createCriteriaData(id, payload) {
  const { data } = await http.post(`${apiEndpoint}/${id}/criteria`, payload);
  return data;
}

async function createDeviationsById(id, payload) {
  const { data } = await http.post(`${apiEndpoint}/${id}/deviations`, payload);
  return data;
}

async function getDeviationsById(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/deviations`);
  return data;
}

async function updateDeviationsById(id, fk, payload) {
  const { data } = await http.put(
    `${apiEndpoint}/${id}/deviations/${fk}`,
    payload
  );
  return data;
}

async function deleteDeviationsById(id, fk) {
  const { data } = await http.delete(`${apiEndpoint}/${id}/deviations/${fk}`);
  return data;
}

async function getCriteriaDataById(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/criteria`);
  return data;
}

async function updateEligibilityAssessmentData(id, fk, payload) {
  const { data } = await http.put(
    `${apiEndpoint}/${id}/assessments/${fk}`,
    payload
  );
  return data;
}

async function approveResearchObject(id) {
  const { data } = await http.patch(`${apiEndpoint}/${id}/approve`);
  return data;
}

async function rejectResearchObject(id) {
  const { data } = await http.patch(`${apiEndpoint}/${id}/decline`);
  return data;
}

async function withdrawnResearchObject(id) {
  const { data } = await http.patch(`${apiEndpoint}/${id}/withdrawn`);
  return data;
}

async function getResearchObjectsById(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}`);
  return data;
}

async function updateResearchObjectById(id, payload) {
  const { data } = await http.patch(`${apiEndpoint}/${id}`, payload);
  return data;
}

async function getAssessmentsObjectsById(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/assessments`);
  return data;
}

async function approveClinicTrailWithSubjectId(id, payload) {
  const { data } = await http.patch(
    `${apiEndpoint}/${id}/approveWithSubjectId`,
    payload
  );
  return data;
}

export {
  getResearchObjectsByRecruitmentId,
  createConsentData,
  createGeneralInfoData,
  createContactInfoData,
  createEligibilityAssessmentData,
  updateEligibilityAssessmentData,
  createCriteriaData,
  getCriteriaDataById,
  createDeviationsById,
  getDeviationsById,
  updateDeviationsById,
  deleteDeviationsById,
  approveResearchObject,
  rejectResearchObject,
  withdrawnResearchObject,
  getResearchObjectsById,
  updateResearchObjectById,
  getAssessmentsObjectsById,
  approveClinicTrailWithSubjectId,
};
