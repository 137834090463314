import React from "react";
import { Divider, Row, Col, Table, Typography, Button } from "antd";
import { formatter } from "../../common/format";
import GoogleMapDirection from "../../components/GoogleMapDirection";
import moment from "moment";

const { Title, Paragraph } = Typography;

const Earning = (props) => {
  const { t, booking } = props;

  const columns = [
    {
      title: "#",
      dataIndex: "index",
    },
    {
      title: t("date"),
      key: "date",
      dataIndex: "admitTime",
      render: (text) => moment(text).format("DD/MM/YYYY"),
      width: 150,
    },
    {
      title: t("time"),
      key: "time",
      dataIndex: "admitTime",
      render: (text) => moment(text).format("hh:mm A"),
      width: 150,
    },
    {
      title: t("name"),
      key: "name",
      dataIndex: ["patient", "userInformation", "firstname"],
      width: 250,
    },
    {
      title: t("location"),
      key: "location",
      dataIndex: ["locationAddress", "address"],
    },
    {
      title: t("service"),
      key: "service",
      dataIndex: "bookingType",
      width: 200,
    },
    {
      title: t("amount"),
      key: "amount",
      dataIndex: "prescription",
      width: 200,
      render: (text) => {
        if (text) {
          const total = text.reduce(
            (acc, cur) =>
              acc + (Number(cur.amount) * Number(cur.unitPriceCents)) / 100,
            0
          );
          if (total >= 0) {
            return formatter.format(total);
          }
        }
        return formatter.format(0);
      },
    },
  ];

  return (
    <div className="mt-5" style={{ width: "100%" }}>
      <div className="px-5">
        <Paragraph>{t("total_earnings")}</Paragraph>
        <Title className="mt-1">
          {formatter.format(booking.earning.total)}
        </Title>
      </div>
      <Divider />
      <Row gutter={24} className="px-5">
        <Col>
          <Title level={5}>{formatter.format(booking.earning.daily)}</Title>
          <Paragraph>{t("daily_earning")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
        <Col>
          <Title level={5}>{formatter.format(booking.earning.weekly)}</Title>
          <Paragraph>{t("weekly_earning")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
        <Col>
          <Title level={5}>{formatter.format(booking.earning.monthly)}</Title>
          <Paragraph>{t("monthly_earning")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
        <Col>
          <Title level={5}>0%</Title>
          <Paragraph>{t("completed")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />

        <Col>
          <Title level={5}>0</Title>
          <Paragraph>{t("online_hours")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
        <Col>
          <Title level={5}>0%</Title>
          <Paragraph>{t("acceptance_rate")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />

        <Col>
          <Title level={5}>0</Title>
          <Paragraph>{t("cancellations")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
      </Row>
      <Divider />
      <Table
        columns={columns}
        loading={booking.loading}
        dataSource={booking.data}
        className="px-5"
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <GoogleMapDirection
                origin={{
                  lat: record.locationAddress.latitude,
                  lng: record.locationAddress.longitude,
                }}
                destination={{
                  lat: record.locationAddress.latitude,
                  lng: record.locationAddress.longitude,
                }}
                width={200}
                height={200}
                zoom={12}
              />
              <Button
                type="primary"
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${record.locationAddress.latitude},${record.locationAddress.longitude}`
                  )
                }
                style={{ marginTop: 20 }}
              >
                Google Map
              </Button>
            </div>
          ),
          rowExpandable: (record) =>
            record.locationAddress &&
            record.locationAddress.latitude &&
            record.locationAddress.longitude,
        }}
      />
    </div>
  );
};

export default Earning;
