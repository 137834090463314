import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../Home";
import { useDispatch } from "react-redux";
import { AppUserOma } from "../../redux/actions";
import Loading from "../../components/Loading";
import Dashboard from "../Dashboard";
import { useTranslation } from "react-i18next";

const DoctorSetting = (props) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const userOma = useSelector((state) => state.appUserOma);
  const [practitionerProfile, setPractitionerProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(false);

  useEffect(() => {
    if (!userOma.loading && userOma.isSuccess) {
      if (
        userOma.practitioner === null &&
        !["devliveryTeam", "communityPharmacist", "pharmacy"].includes(
          userOma.information.roles[0].name
        )
      ) {
        dispatch(AppUserOma.getProfile(userOma.information.id));
      } else if (userOma.practitioner !== null) {
        if (userOma.practitioner.photos.length > 0) {
          const avatar = userOma.practitioner.photos.find(
            (pic) => pic.category === "avatar"
          );
          setProfileImage(avatar.imageUrl);
        }
        setPractitionerProfile(userOma.practitioner);
      }
    }
  }, [userOma]);

  return (
    <Layout
      {...props}
      render={(props) =>
        userOma.loading ? (
          <Loading />
        ) : (
          <Dashboard
            practitionerProfile={practitionerProfile}
            profileImage={profileImage}
            t={t}
          />
        )
      }
    />
  );
};

export default DoctorSetting;
