export const FILTER_STATUS = {
  doctor: ["DOCTOR_PENDING"],
  pharmacy: ["PHARMACY_PENDING"],
  nurse: ["NURSE_PENDING"],
};

export const CALL_STATUS = {
  doctor: {
    DOCTOR_PENDING: {
      name: "CALLING",
      color: "rgb(82, 196, 26)",
    },
    DOCTOR_CALLING: {
      name: "IN ROOM",
      color: "#91d5ff",
    },
  },
  pharmacy: {
    PHARMACY_PENDING: {
      name: "CALLING",
      color: "rgb(82, 196, 26)",
    },
    PHARMACY_CALLING: {
      name: "IN ROOM",
      color: "#91d5ff",
    },
  },
  nurse: {
    NURSE_PENDING: {
      name: "CALLING",
      color: "rgb(82, 196, 26)",
    },
    NURSE_CALLING: {
      name: "IN ROOM",
      color: "#91d5ff",
    },
  },
};

export const ROOM_TYPES = {
  "/lobby/telemedicine": { type: "SCHEDULING" },
  "/round-robin/covid": { type: "COVID" },
  "/round-robin/general": { type: "GENERAL" },
};

export const JOIN_STATUS = {
  doctor: "DOCTOR_JOIN",
  pharmacy: "PHARMACY_JOIN",
  communityPharmacist: "COMMUNITY_PHARMACIST_JOIN",
};

export const PENDING_NOTE_STATUS = {
  doctor: "DOCTOR_PENDING_NOTE",
  pharmacy: "PHARMACY_PENDING_NOTE",
  communityPharmacist: "COMMUNITY_PHARMACIST_PENDING_NOTE",
};

export const COVID_CASES = ["Red", "Yellow", "Green"];

export const COLORS = [
  { label: "เขียว", value: "Green" },
  { label: "เหลือง", value: "Yellow" },
  { label: "แดง", value: "Red" },
];
