import { Modal } from "antd";
import MonitoringInfo from "../../PatientTeleDetail/MonitoringInfo";
import GeneralInfo from "../../PatientTeleDetail/GeneralInfo";

const HistoryPatientDetailModal = (props) => {
  const { visible, setVisible, patient, userId } = props;
  return (
    <Modal
      width={"50vw"}
      visible={visible}
      title="History"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      okText="ยืนยัน"
      cancelText="ยกเลิก"
    >
      <GeneralInfo userInfo={patient} isVideoCall />
      <MonitoringInfo appUserId={userId} isVideoCall />
    </Modal>
  );
};

export default HistoryPatientDetailModal;
