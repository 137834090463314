import styled from "styled-components";
import { Typography, Image, Card } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

export const ParagraphStyle = styled(Paragraph)`
  color: ${(props) => props.color ?? "#000000"} !important;
  font-size: ${(props) => props.size ?? 14}px;
  margin: 0px !important;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

export const ImageStyle = styled(Image)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
`;

export const ButtonPrescription = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  background-color: #f2f2f2;
  padding: 3px 15px 3px 15px;
  border-radius: 20px;
  cursor: pointer;
`;

export const CustomIcon = styled(CaretRightOutlined)`
  display: flex !important;
  align-items: center !important;
  color: ${(props) => props.color ?? "#000000"} !important;
`;

export const Content = styled.div`
  margin-top: 20px;
`;

export const CardContainer = styled(Card)`
  border-radius: 25px !important;
`;
