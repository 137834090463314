import * as type from "../types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_AVAILABLE_TIMES_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_AVAILABLE_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case type.GET_AVAILABLE_TIMES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case type.UPDATE_AVAILABLE_TIME_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.UPDATE_AVAILABLE_TIME_SUCCESS:
      return {
        loading: false,
        data: {
          ...action.data,
          time: action.data.time.map((t) => ({ start: t[0], end: t[1] })),
        },
      };
    case type.UPDATE_AVAILABLE_TIME_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
