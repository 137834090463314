import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

import {
  Firebase,
  AppUserOma,
  Booking,
  Treatment,
  UserFcmToken,
} from "../../redux/actions";
import {
  ROOM_TYPES,
  FILTER_STATUS,
  JOIN_STATUS,
  NOTIFICATION,
} from "./constant";
import { Container } from "./styles";
import { hashHelper } from "../../common/hash";
import Layout from "../Home";
import RoomList from "../../components/RoomList";
import Loading from "../../components/Loading";
import NotFound from "../../components/ExtraPage/NotFound";
import { useTranslation } from "react-i18next";

const Lobby = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = props.location;

  const firebase = useSelector((state) => state.firebase);
  const userOma = useSelector((state) => state.appUserOma);
  const booking = useSelector((state) => state.booking);
  const treatment = useSelector((state) => state.treatment);

  const [rooms, setRooms] = useState([]);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");

  const [isFinishInitial, setIsFinishInitial] = useState(false);

  const [visibleCallModal, setVisibleCallModal] = useState(false);
  const [visibleCovidModal, setVisibleCovidModal] = useState(false);
  const [visibleHistoryBookingModal, setVisibleHistoryBookingModal] =
    useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const roomType = ROOM_TYPES[pathname];

  useEffect(() => {
    const userId = jwtDecode(localStorage.getItem("token")).id;
    dispatch(AppUserOma.getUser(userId));
    dispatch(AppUserOma.getProfile(userId));
  }, [pathname]);

  useEffect(() => {
    if (userOma.isSuccess && userOma.information !== null) {
      dispatch(
        Firebase.getRooms(
          roomType.type,
          userOma.information.id,
          userOma.information.roles[0].name
        )
      );
    }
  }, [userOma]);

  useEffect(() => {
    if (
      userOma.isSuccess &&
      (roomType.type === "SCHEDULING"
        ? firebase.room[roomType.type.toLowerCase()].isSuccess
        : firebase.room.roundRobin[roomType.type.toLowerCase()].isSuccess)
    ) {
      initialRoom();
    }
  }, [userOma, firebase.room]);

  useEffect(() => {
    if (booking.isSuccess && firebase.isSuccess && isUpdate) {
      const hash = hashHelper(
        JSON.stringify({
          bookingId: booking.current.id,
          date,
          roomType: roomType.type,
        })
      );
      props.history.push(`/rooms/${hash}`);
    }
  }, [booking, firebase, isUpdate]);

  const initialRoom = () => {
    let filterRooms = [];
    const roomTypes =
      roomType.type === "SCHEDULING"
        ? firebase.room[roomType.type.toLowerCase()].rooms
        : firebase.room.roundRobin[roomType.type.toLowerCase()].rooms;
    if (roomTypes && Object.keys(roomTypes).length > 0) {
      for (let key in roomTypes) {
        if (
          FILTER_STATUS[userOma.information.roles[0].name].includes(
            roomTypes[key].status
          ) &&
          roomTypes[key].roomName.includes(userOma.information.roles[0].name)
        ) {
          filterRooms = [...filterRooms, roomTypes[key]];
        }
      }
    }
    setRooms(filterRooms);
    setIsFinishInitial(true);
  };

  const handleOpenCallModal = (name, room, date) => {
    setTitle(name);
    setVisibleCallModal(true);
    setDate(date);
    dispatch(Booking.getBooking(room.bookingId));
  };

  const fetchBookingByTreatmentId = () => {
    dispatch(Treatment.getBookingsByTreatmentId(booking.current.treatmentId));
    setVisibleHistoryBookingModal(true);
  };

  const handleAcceptCall = () => {
    setVisibleCallModal(false);
    setIsFinishInitial(false);
    dispatch(
      UserFcmToken.sendNotification(
        booking.current.patientId,
        NOTIFICATION["acceptCall"]
      )
    );
    dispatch(
      Booking.updateBooking(booking.current.id, false, {
        practitionerAppUserId: userOma.information.id,
        practitionerId: userOma.practitioner.id,
      })
    );
    dispatch(
      Firebase.updateRoom(
        roomType.type,
        userOma.information.id,
        booking.current.patientId,
        {
          status: JOIN_STATUS[userOma.information.roles[0].name],
        },
        userOma.information.roles[0].name
      )
    );

    setIsUpdate(true);
  };

  return (
    <Layout
      {...props}
      render={(props) =>
        isFinishInitial ? (
          <Container>
            {rooms.length > 0 ? (
              <RoomList
                rooms={rooms}
                roomType={roomType.type}
                booking={booking}
                role={userOma.information.roles[0].name}
                visibleCallModal={visibleCallModal}
                visibleCovidModal={visibleCovidModal}
                visibleHistoryBookingModal={visibleHistoryBookingModal}
                treatment={treatment}
                title={title}
                setVisibleCallModal={setVisibleCallModal}
                setVisibleCovidModal={setVisibleCovidModal}
                setVisibleHistoryBookingModal={setVisibleHistoryBookingModal}
                handleOpenCallModal={handleOpenCallModal}
                fetchBookingByTreatmentId={fetchBookingByTreatmentId}
                handleAcceptCall={handleAcceptCall}
                t={t}
              />
            ) : (
              <NotFound
                title={roomType.type}
                description={t("no_patient_has_called")}
              />
            )}
          </Container>
        ) : (
          <Loading />
        )
      }
    />
  );
};
export default Lobby;
