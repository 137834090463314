import React, { useEffect, useState } from "react";

import { Card, Row, Col, Modal, DatePicker, Button } from "antd";
import { HeartRateText } from "./styles";
import { t } from "i18next";
const { RangePicker } = DatePicker;

const HeartRateValue = (props) => {
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const { min, max, average } = props.values;
  const { filterReport } = props;

  const onDateChange = (value, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const handleOk = () => {
    filterReport(startDate, endDate);
    setVisible(false);
  };

  return (
    <>
      <Card
        title={
          <Row type="flex" justify="space-between">
            <Col>{t("heart_rate_per_min")}</Col>
            <Col>
              <Button type="primary" onClick={() => setVisible(true)}>
                {t("select_date_range")}
              </Button>
            </Col>
          </Row>
        }
      >
        <Row>
          <HeartRateText color="black" span={8}>
            {t("lowest")}
          </HeartRateText>
          <HeartRateText color="black" span={8}>
            {t("highest")}
          </HeartRateText>
          <HeartRateText color="black" span={8}>
            {t("average")}
          </HeartRateText>
          <HeartRateText color="blue" span={8}>
            {min}
          </HeartRateText>
          <HeartRateText color="red" span={8}>
            {max}
          </HeartRateText>
          <HeartRateText color="black" span={8}>
            {average}
          </HeartRateText>
        </Row>
      </Card>
      <Modal
        title={t("select_date_range")}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        okText={t("confirm")}
        cancelText={t("close")}
      >
        <RangePicker
          format="DD MMM YYYY"
          onChange={onDateChange}
          className="w-100"
        />
      </Modal>
    </>
  );
};

export default HeartRateValue;
