import React, { useState } from "react";
import { Row, Typography, Dropdown, Card, Button } from "antd";
import { Icon } from "../styles";
import { FiMoreHorizontal, MdEdit } from "react-icons/all";

export default function MoreComponent({
  t,
  index,
  item,
  setCurrentEditData,
  setIsEditModalVisible,
  researchObjectData,
}) {
  const [popVisible, setPopVisible] = useState(false);
  const { Text } = Typography;
  return (
    <Dropdown
      trigger="click"
      visible={popVisible}
      onVisibleChange={setPopVisible}
      overlay={
        <Card>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Button
              type="text"
              onClick={(event) => {
                event.stopPropagation();
                setIsEditModalVisible(true);
                setPopVisible(false);
              }}
            >
              <Row align="middle" className="pr-5">
                <Icon component={MdEdit} size="24px" color="#ADADAD" />
                <Text className="ml-2">{t("edit")}</Text>
              </Row>
            </Button>
          </div>
        </Card>
      }
    >
      <Icon
        onClick={(event) => {
          event.stopPropagation();
          setPopVisible(true);
          setCurrentEditData({
            componentIndex: Math.max(index - 1, 0),
            researchId: researchObjectData.id,
            ...item,
          });
        }}
        component={FiMoreHorizontal}
        size="20px"
        color="#2BA796"
      />
    </Dropdown>
  );
}
