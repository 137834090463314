import React from "react";
import Table from "../../../Table";
import moment from "moment";

export const DiagnosesTable = ({ diagnoses }) => {
  const COLUMNS = [
    {
      title: "วันที่ - เวลา",
      key: "dateTime",
      width: 100,
      align: "center",
      fixed: "left",
      render: (record) =>
        moment(record.visitDateTime).format("DD/MM/YYYY, HH:mm:ss"),
      sorter: (a, b) =>
        moment(a.visitDateTime).unix() - moment(b.visitDateTime).unix(),
      defaultSortOrder: "descend",
    },
    {
      title: "รหัสโรงพยาบาล",
      key: "hospCode",
      width: 100,
      align: "center",
      render: (record) => record.hospCode,
    },
    {
      title: "รหัสโรค",
      key: "icd10",
      width: 100,
      align: "center",
      render: (record) => record.icd10,
    },
    {
      title: "ชื่อโรค",
      key: "icd10Name",
      width: 200,
      render: (text) => text.icd10Name,
    },
    {
      title: "ดูแลโดย",
      key: "consultWith",
      width: 200,
      align: "center",
      render: (record) => record.doctorName,
    },
  ];

  return (
    <div>
      <Table dataSource={diagnoses} columns={COLUMNS} />
    </div>
  );
};
