import { Select } from "antd";
import React, { useEffect } from "react";
import AvailableTimes from "react-available-times";
import { useDispatch, useSelector } from "react-redux";
import { AvailableTime } from "../../redux/actions";
import Loading from "../Loading";
import { INSPECTION_PERIOD } from "./constant";
import { Container } from "./styles";

const Calendar = (props) => {
  const { t } = props;
  const dispatch = useDispatch();

  const availableTime = useSelector((state) => state.availableTime);
  const userOma = useSelector((state) => state.appUserOma);

  const { time, minute, id } = availableTime.data;

  useEffect(() => {
    if (
      userOma &&
      userOma.practitioner &&
      !userOma.loading &&
      userOma.practitioner.id
    ) {
      dispatch(AvailableTime.getAvailableTimes(userOma.practitioner.id));
    }
  }, [userOma]);

  return availableTime.loading ? (
    <Loading />
  ) : (
    <Container>
      <p>{t("treatment_period")}</p>
      <Select
        value={minute}
        onChange={(selectedMinute) =>
          dispatch(
            AvailableTime.updateAvailableTime(id, { minute: selectedMinute })
          )
        }
        style={{ width: 120 }}
      >
        {INSPECTION_PERIOD.map((inspection) => (
          <Option value={inspection.value}>
            {inspection.label} {t("minute")}
          </Option>
        ))}
      </Select>
      <AvailableTimes
        timeConvention="24h"
        height={600}
        weekStartsOn="monday"
        start={new Date()}
        initialSelections={time}
        recurring={true}
        onChange={(times) =>
          dispatch(AvailableTime.updateAvailableTime(id, { time: times }))
        }
      />
    </Container>
  );
};

export default Calendar;
