import { Input } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { ErrMsg } from "./styles";

export const TextInput = (props) => (
  <React.Fragment>
    <Input {...props} />
    {props.errors[props.name] && <ErrMsg>{props.errors[props.name]}</ErrMsg>}
  </React.Fragment>
);
TextInput.defaultProps = {
  errors: {},
};
TextInput.propTypes = {
  errors: PropTypes.object, //eslint-disable-line
};
