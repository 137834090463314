import { call, put, takeEvery } from "redux-saga/effects";
import { ResearchObjects } from "../../api";
import * as type from "../types";

function* getResearchObjectsById({ id }) {
  try {
    const result = yield call(ResearchObjects.getResearchObjectsById, id);
    yield put({
      type: type.GET_RESEARCH_OBJECT_BY_ID_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_RESEARCH_OBJECT_BY_ID_FAILED,
      error: e.message,
    });
  }
}

function* ResearchObjectSaga() {
  yield takeEvery(
    type.GET_RESEARCH_OBJECT_BY_ID_REQUESTED,
    getResearchObjectsById
  );
}

export default ResearchObjectSaga;
