import { Modal } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const EditEligibilityModal = ({
  t,
  widgetList,
  customRequest,
  currentRecord,
  getNameFromUrl,
  isEditModalVisible,
  setIsEditModalVisible,
  onEigibilityAssessmentSubmit,
}) => {
  const ChildrenComponent = widgetList[currentRecord?.componentIndex ?? 0];
  const wrapTwoDecimal = (value) => {
    return +(Math.floor(value * 100) / 100).toFixed(2);
  };
  const validateDecimal = Yup.number().test(
    "is-decimal",
    t("input_number"),
    (value) => (value + "").match(/^(\d+)?([.]?\d{1})?$/) || !value
  );
  const eligibilityAssessmentForm = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      day: currentRecord.day ?? 1,
      height: currentRecord.height ?? 0,
      weight: currentRecord.weight ?? 0,
      bmi: currentRecord.bmi ?? 0,
      bloodPressure: currentRecord.bloodPressure ?? 0,
      bloodPressureRange: currentRecord?.bloodPressureRange ?? "",
      heartRate: currentRecord.heartRate ?? 0,
      rr: currentRecord.rr ?? 0,
      pulseOx: currentRecord.pulseOx ?? 0,
      medicationHistories:
        Array.isArray(currentRecord?.medicationHistories) &&
        currentRecord?.medicationHistories.length > 0
          ? currentRecord?.medicationHistories
          : [
              {
                medicationName: "",
                date: "",
              },
            ],
      underlingDiseases:
        Array.isArray(currentRecord?.underlingDiseases) &&
        currentRecord?.underlingDiseases.length > 0
          ? currentRecord?.underlingDiseases
          : ["", ""],
      currentMedications:
        Array.isArray(currentRecord?.currentMedications) &&
        currentRecord?.currentMedications.length > 0
          ? currentRecord?.currentMedications
          : [""],
      vaccinneHistory:
        Array.isArray(currentRecord?.covidInfectionHistory?.vaccinneHistory) &&
        currentRecord?.covidInfectionHistory?.vaccinneHistory.length > 0
          ? currentRecord?.covidInfectionHistory?.vaccinneHistory
          : [
              {
                vaccineName: "",
                date: "",
                site: "",
              },
            ],
      covidInfectionHistory: {
        closeContact: currentRecord?.covidInfectionHistory?.closeContact ?? "",
        closeContactComment:
          currentRecord?.covidInfectionHistory?.closeContactComment ?? "",
        closeContactDate:
          currentRecord?.covidInfectionHistory?.closeContactDate ?? "",
        covidInfectionHistoryUrl:
          currentRecord?.covidInfectionHistory?.covidInfectionHistoryUrl ?? "",
        covidInfected:
          currentRecord?.covidInfectionHistory?.covidInfected ?? "",
        covidInfectedComment:
          currentRecord?.covidInfectionHistory?.covidInfectedComment ?? "",
        covidInfectedDate:
          currentRecord?.covidInfectionHistory?.covidInfectedDate ?? "",
      },
      clinicalTrialCheck: currentRecord?.clinicalTrialCheck ?? "",
      clinicalTrialStartDate:
        currentRecord?.clinicalTrialStartDate ?? moment().toISOString(),
      clinicalTrialEndDate: currentRecord?.clinicalTrialEndDate
        ? currentRecord?.clinicalTrialEndDate
        : "",
      clinicalTrialDescription: currentRecord?.clinicalTrialDescription ?? "",
      physicalExamination: currentRecord?.physicalExamination ?? "",
      nasopharyngealStatus:
        currentRecord?.nasopharyngealStatus ?? "not_detected",
      nasopharyngealDateOfSampleCollection:
        currentRecord?.nasopharyngealDateOfSampleCollection ?? "",
      nasopharyngealFileUrl: currentRecord?.nasopharyngealFileUrl ?? "",
      sinuscopyPolypsRight: currentRecord?.sinuscopyPolypsRight ?? 0,
      sinuscopyOedemaRight: currentRecord?.sinuscopyOedemaRight ?? 0,
      sinuscopyDischargeRight: currentRecord?.sinuscopyDischargeRight ?? 0,
      sinuscopyDischargeImageUrlRight:
        currentRecord?.sinuscopyDischargeImageUrlRight ?? "",
      sinuscopyPolypsLeft: currentRecord?.sinuscopyPolypsLeft ?? 0,
      sinuscopyOedemaLeft: currentRecord?.sinuscopyOedemaLeft ?? 0,
      sinuscopyDischargeLeft: currentRecord?.sinuscopyDischargeLeft ?? 0,
      sinuscopyDischargeImageUrlLeft:
        currentRecord?.sinuscopyDischargeImageUrlLeft ?? "",
      cPassWuhanBefore: currentRecord?.cPassWuhanBefore ?? 0,
      cPassWuhanAfter: currentRecord?.cPassWuhanAfter ?? 0,
      cPassOmicronBefore: currentRecord?.cPassOmicronBefore ?? 0,
      cPassOmicronAfter: currentRecord?.cPassOmicronAfter ?? 0,
      cPassDeltaBefore: currentRecord?.cPassDeltaBefore ?? 0,
      cPassDeltaAfter: currentRecord?.cPassDeltaAfter ?? 0,
    },
    validationSchema: Yup.object().shape({
      cPassWuhanBefore: validateDecimal,
      cPassWuhanAfter: validateDecimal,
      cPassOmicronBefore: validateDecimal,
      cPassOmicronAfter: validateDecimal,
      cPassDeltaBefore: validateDecimal,
      cPassDeltaAfter: validateDecimal,
    }),
    onSubmit: async (values) => {
      if (currentRecord.researchId) {
        onEigibilityAssessmentSubmit(
          currentRecord.researchId,
          currentRecord.id,
          {
            ...values,
            covidInfectionHistory: {
              vaccinneHistory: values.vaccinneHistory,
              ...values.covidInfectionHistory,
            },
            nasopharyngealDateOfSampleCollection:
              values.nasopharyngealDateOfSampleCollection || null,
            clinicalTrialEndDate: values.clinicalTrialEndDate || null,
            cPassWuhanBefore: wrapTwoDecimal(values.cPassWuhanBefore),
            cPassWuhanAfter: wrapTwoDecimal(values.cPassWuhanAfter),
            cPassOmicronBefore: wrapTwoDecimal(values.cPassOmicronBefore),
            cPassOmicronAfter: wrapTwoDecimal(values.cPassOmicronAfter),
            cPassDeltaBefore: wrapTwoDecimal(values.cPassDeltaBefore),
            cPassDeltaAfter: wrapTwoDecimal(values.cPassDeltaAfter),
          }
        );
      }
    },
  });
  return (
    <Modal
      title={t("edit_eligibility_assessment")}
      visible={isEditModalVisible}
      width={"80vw"}
      onCancel={() => {
        setIsEditModalVisible(false);
      }}
      okText="Submit"
      onOk={() => {
        eligibilityAssessmentForm.handleSubmit();
        setIsEditModalVisible(false);
      }}
    >
      <ChildrenComponent
        t={t}
        getNameFromUrl={getNameFromUrl}
        currentRecord={currentRecord}
        customRequest={customRequest}
        form={eligibilityAssessmentForm}
      />
    </Modal>
  );
};

export default EditEligibilityModal;
