export const REGISTER_NEW_PARTICIPANTS = [
  "principleInvestigation",
  "doctor",
  "nurse",
];
export const EDIT_RECRUIT_OBJECTS = ["principleInvestigation"];
export const STUDY_AND_WITHDRAWN = ["principleInvestigation"];
export const CAN_SUBMIT_PP = ["doctor", "principleInvestigation", "nurse"];
export const CAN_MANUAL_SUBMIT_PP = ["principleInvestigation"];

export const SORT_VALUE = {
  completed: 1,
  inactive: 2,
  withdrawn: 3,
  active: 4,
};
