import React from "react";
import { Row, Col } from "antd";
import { Container } from "./styles";

import RoomCard from "../Card/RoomCard";

const RoundRobin = (props) => {
  const { navigate, countRoomType, rooms } = props;

  return (
    <Container>
      <Row gutter={24}>
        {rooms.map((room, index) => (
          <Col span={6} key={index}>
            <RoomCard
              name={room.name}
              count={countRoomType[room.name.toLowerCase()]}
              navigate={() => navigate(room.path)}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default RoundRobin;
