import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Howl } from "howler";
import jwtDecode from "jwt-decode";
import { Button, notification } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { FILTER_STATUS, ROOM_TYPES } from "./constant";
import { concat } from "lodash";
import ALERT_SOUND from "../../sounds/lobby_alert.mp3";
import { useHistory } from "react-router-dom";
import { Menu } from "../../redux/actions";
import { useTranslation } from "react-i18next";

const LobbyAlert = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();

  const sound = new Howl({
    src: ALERT_SOUND,
    html5: true,
    volume: 0.5,
    preload: true,
    onplayerror: function () {
      sound.once("unlock", function () {
        sound.play();
      });
    },
  });
  const [rooms, setRooms] = useState({});
  const [alreadyAlertRooms, setAlreadyAlertRooms] = useState([]);

  const role = jwtDecode(localStorage.getItem("token")).roles[0];

  const firebase = useSelector((state) => state.firebase);
  const menu = useSelector((state) => state.menu);

  useEffect(() => {
    if (
      firebase.room &&
      JSON.stringify(firebase.room) !== JSON.stringify(rooms) &&
      ((role === "doctor" && menu.isSuccess) || role === "communityPharmacist")
    ) {
      setRooms(firebase.room);
      clearCompletedRoom(firebase.room);
      checkInLobbyRoom();
    }
  }, [firebase.room, menu]);

  const checkInLobbyRoom = async () => {
    let filterRooms = [];
    const firebaseRoom = await getDataFromRoom();
    if (
      firebaseRoom &&
      firebaseRoom.rooms &&
      (firebaseRoom.rooms.length > 0 ||
        Object.keys(firebaseRoom.rooms).length > 0)
    ) {
      filterRooms = filterStatusAndRole(
        FILTER_STATUS[role],
        role,
        firebaseRoom.rooms
      );
    }
    if (filterRooms.length > 0 && firebaseRoom.type !== "") {
      checkAlert(firebaseRoom.type, filterRooms);
    }
  };

  const getDataFromRoom = () => {
    let rooms = [];
    let type = "";
    if (firebase.room.roundRobin && firebase.room.roundRobin.isSuccess) {
      rooms = firebase.room.roundRobin.data;
      type = "general";
    } else if (firebase.room.scheduling && firebase.room.scheduling.isSuccess) {
      rooms = firebase.room.scheduling.rooms;
      type = "scheduling";
    } else if (
      firebase.room.roundRobin &&
      firebase.room.roundRobin.communityPharmacist.isSuccess
    ) {
      rooms = firebase.room.roundRobin.communityPharmacist.rooms;
      type = "communityPharmacist";
    }
    return { rooms, type };
  };

  const checkAlert = async (type, rooms) => {
    for (const room of rooms) {
      const isDuplicated = await isAlreadyAlert(room);
      if (!isDuplicated) {
        openNotification(type, room);
        setAlreadyAlertRooms(concat(alreadyAlertRooms, room));
      }
    }
  };

  const clearCompletedRoom = (rooms) => {
    const generalRoom =
      role === "doctor"
        ? rooms.roundRobin.data
        : rooms.roundRobin.communityPharmacist.rooms;
    if (alreadyAlertRooms && alreadyAlertRooms.length) {
      setAlreadyAlertRooms(generalRoom);
    }
  };

  const isAlreadyAlert = (room) => {
    const isDuplicate = alreadyAlertRooms.find(
      (alertRoom) =>
        alertRoom.bookingId === room.bookingId &&
        alertRoom.status === room.status
    );
    return isDuplicate;
  };

  const filterStatusAndRole = (status, role, rooms) => {
    let arrayRooms = [];
    let filterRole =
      role === "communityPharmacist"
        ? "COMMUNITY_PHARMACIST"
        : role.toUpperCase();
    if (rooms && rooms.length) {
      arrayRooms = rooms.filter(
        (room) => room.status === status && room.status.includes(filterRole)
      );
    } else if (rooms && Object.keys(rooms).length > 0) {
      const mapRoom = Object.keys(rooms).map((id) => rooms[id]);
      arrayRooms = mapRoom.filter(
        (room) => room.status === status && room.status.includes(filterRole)
      );
    }
    return arrayRooms;
  };

  const openNotification = (type, room) => {
    soundPlay();
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          redirectPage(type);
          notification.close(key);
        }}
      >
        {t("go_to_lobby")}
      </Button>
    );
    notification.open({
      message: `${t("patient")} ${room.name}`,
      description: `${t("room")} ${ROOM_TYPES[type].type}`,
      icon: <PhoneOutlined style={{ color: "#108ee9" }} />,
      duration: 900,
      btn,
      key,
      style: {
        cursor: "pointer",
      },
    });
  };

  const redirectPage = (type) => {
    let path = "";
    switch (type) {
      case "scheduling":
        path = "/lobby/telemedicine";
        break;
      case "communityPharmacist":
        path = "/dashboard-map";
        break;
      case "general":
        path = "homepage";
        break;
      case "covid":
        path = "/round-robin/covid";
        break;
      default:
        break;
    }
    if (type !== "communityPharmacist") {
      checkMenu(type);
    }
    history.push(path);
  };

  const soundPlay = () => {
    sound.play();
  };
  const soundStop = () => {
    sound.stop();
  };

  const checkMenu = (type) => {
    const { data } = menu;
    let menuData;
    switch (type) {
      case "scheduling":
        menuData = data.find((item) => {
          return item.value.includes("lobby/telemedicine");
        });
        break;
      case "general":
        menuData = data.find((item) => {
          return item.value.includes("homepage");
        });
        break;
      case "covid": {
        menuData = data.find((item) => {
          return item.value.includes("round-robin");
        });
        break;
      }
      default:
        menudata = data[0];
        break;
    }
    dispatch(Menu.setMenu(menuData));
  };

  return null;
};

export default LobbyAlert;
