import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { HEADER_DEFAULT_FORMS } from "../constant";
import styles, { TableContainer, TitlePDFFile } from "../styles";
import Checkbox from "../assets/Checkbox";
import CheckboxOutline from "../assets/CheckboxOutline";
import RadioboxOutline from "../assets/RadioOutline";
import RadioChecked from "../assets/RadioChecked";

import moment from "moment";
export default function InformedConsentPreview(props) {
  const { t, data } = props;

  return (
    <Document>
      <Page size="A4">
        <Text style={styles.header}>{t(HEADER_DEFAULT_FORMS)}</Text>
        <View style={[styles.body, styles.paddingBody]}>
          <View style={styles.subHeader}>
            <View
              style={[
                styles.row,
                {
                  flex: 1,
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  styles.textDescription,
                  { color: "grey", fontStyle: "italic", flex: 1 },
                ]}
              >
                ({t("crf_title")})
              </Text>
              <Text style={styles.textDescription}>
                Date Signed:{" "}
                {moment(data.dateSigned).format("DD/MM/YYYY") || "-"}
              </Text>
              <Text style={[styles.textDescription, { marginHorizontal: 15 }]}>
                {t("crf_time")}:{" "}
                {moment(data.timeSigned).format("HH:mm") || "-"}
              </Text>
            </View>
          </View>
          <View style={[styles.row, { marginBottom: 10 }]}>
            <Text style={[styles.dateSignedText, { flex: 1 }]}>
              {t("subjects_initials")}: {data.subjectsInitials || "-"}
            </Text>
            <Text style={[styles.dateSignedText, { flex: 2 }]}>
              {t("crf_subject_id")}: {data.subjectId}
            </Text>
          </View>
          <View style={styles.titleSpcBtwContainer}>
            <Text style={styles.titleText}>{t("crf_informed_consent")}</Text>
            <View style={[styles.row, { alignItems: "center" }]}>
              {data.consentRefused ? (
                <Checkbox width={10} height={10} />
              ) : (
                <CheckboxOutline width={10} height={10} />
              )}
              <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                Consent Refused
              </Text>
            </View>
          </View>
          <TableContainer>
            <View style={styles.row}>
              <View
                style={[
                  styles.colLastItem,
                  { alignItems: "flex-start", paddingLeft: 20 },
                ]}
              >
                <Text style={styles.textBold}>Documents(s) signed</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[styles.colItemFlex, { flex: 4, paddingLeft: 10 }]}
              ></View>
              <View style={styles.colItemTNSS}>
                <Text style={styles.textDescription}>Version Date</Text>
              </View>
              <View style={styles.colLastItem}>
                <Text style={styles.textDescription}>Approval Date</Text>
              </View>
            </View>
            {Array.isArray(data.docSigned) &&
              data.docSigned.map((item, index) => {
                return (
                  <View key={index} style={styles.row}>
                    <View
                      style={[styles.colItemFlex, { flex: 4, paddingLeft: 10 }]}
                    >
                      <Text style={styles.textDescription}>{item.title}</Text>
                    </View>
                    <View style={styles.colItemTNSS}>
                      <Text style={styles.textDescription}>
                        {item.versionDate
                          ? moment(item.versionDate).format("DD/MM/YYYY")
                          : "-"}
                      </Text>
                    </View>
                    <View style={[styles.colLastItem]}>
                      <Text style={styles.textDescription}>
                        {item.approvalDate
                          ? moment(item.approvalDate).format("DD/MM/YYYY")
                          : "-"}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </TableContainer>
          <View style={{ marginVertical: 15 }}>
            <CheckboxComponent
              checked={data.consentReviewSbj}
              title="Consent Form, and related study documents, was throughly reviewed with the subject."
            />
            <CheckboxComponent
              checked={data.subjectSuffient}
              title="Subject had sufficient time to review the documents and ask questions."
            />
            <CheckboxComponent
              checked={data.consentPrior}
              title="Informed consent prior to any study related procedures."
            />
            <CheckboxComponent
              checked={data.copyOfTheSigned}
              title="A copy of the signed documents has been given to the subject."
            />
          </View>
          <View style={{ marginBottom: 20 }}>
            <View
              style={[styles.row, { alignItems: "center", marginBottom: 20 }]}
            >
              <Text style={styles.textBold}>
                Name of person that obtained consent:
              </Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {t("crf_pi_name_fixed")}
                </Text>
              </View>
            </View>
            <View style={[styles.row, { alignItems: "center" }]}>
              <Text style={styles.textBold}>Comments:</Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {data.comment}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.titleContainer, { marginVertical: 15 }]}>
            <Text style={styles.titleText}>Informed Consent Refused</Text>
          </View>
          <View>
            <View
              style={[
                styles.row,
                { justifyContent: "space-between", marginBottom: 20 },
              ]}
            >
              <View style={[styles.row, { alignItems: "center", width: 150 }]}>
                <Text style={styles.textBold}>Time :</Text>
                <View style={styles.textBoxContainer}>
                  <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                    {data.refusedTime}
                  </Text>
                </View>
              </View>
              <View style={[styles.row, { alignItems: "center" }]}>
                {data.notApicable ? (
                  <Checkbox width={10} height={10} />
                ) : (
                  <CheckboxOutline width={10} height={10} />
                )}
                <Text style={[styles.textDescription, { marginLeft: 5 }]}>
                  Not Applicable
                </Text>
              </View>
            </View>
            <View
              style={[styles.row, { alignItems: "center", marginBottom: 15 }]}
            >
              <Text style={styles.textBold}>Comments:</Text>
              <View style={styles.textBoxContainer}>
                <Text style={[styles.textDescription, { marginLeft: 3 }]}>
                  {data.refusedComment}
                </Text>
              </View>
            </View>
            <View style={[styles.divider, { marginVertical: 10 }]} />
            <Text style={styles.textBold}>
              This form should be completed for each Informed Consent process
              (i.e. if Re-consented)
            </Text>
            <View style={[styles.divider, { marginVertical: 10 }]} />
          </View>
        </View>
        <View style={[styles.paddingBody, styles.row]}>
          <Text style={styles.dateSignedText}>
            {t("crf_form_number")} {data.formNumber || "-"}
          </Text>
          <Text style={styles.dateSignedText}>
            {t("crf_version_date")}: 01/28/2022
          </Text>
        </View>
        <View style={styles.paddingFooter}>
          <Text style={styles.textDescriptionCenter}>{t("crf_page")} 1/1</Text>
        </View>
      </Page>
    </Document>
  );
}

const CheckboxComponent = ({ title, checked }) => {
  return (
    <View style={{ marginBottom: 10 }}>
      <Text style={styles.textBold}>{title}</Text>
      <View style={[styles.row, { marginTop: 5 }]}>
        <View style={[styles.row, { alignItems: "center" }]}>
          {checked ? (
            <RadioChecked width={11} height={11} />
          ) : (
            <RadioboxOutline width={10} height={10} />
          )}
          <Text style={[styles.textDescription, { marginLeft: 6 }]}>Yes</Text>
        </View>
        <View style={[styles.row, { alignItems: "center", marginLeft: 10 }]}>
          {!checked ? (
            <RadioChecked width={11} height={11} />
          ) : (
            <RadioboxOutline width={10} height={10} />
          )}
          <Text style={[styles.textDescription, { marginLeft: 6 }]}>No</Text>
        </View>
      </View>
    </View>
  );
};
