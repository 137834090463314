import styled from "styled-components";
import TextBase from "../../Text";
import {
  Input as InputAnt,
  DatePicker as DatpickerAnt,
  Radio as RadioAnt,
} from "antd";
const Container = styled.div`
  width: 100%;
`;

const Text = styled(TextBase)`
  color: ${(props) => (props.primary ? "#2BA796" : props.color)};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const DatePicker = styled(DatpickerAnt)`
  &&.ant-picker-disabled {
    background-color: #fafafa;
    .ant-picker-input > input[disabled] {
      color: black;
    }
  }
  ,
  && {
    border-radius: 8px;
    padding: 8px;
    height: auto;
    min-width: ${(props) => props.minWidth};
    width: ${(props) => props.width};
    text-align: left;
  }
`;

const Input = styled(InputAnt)`
  &&&&.ant-input[disabled] {
    color: ${(props) => props.color};
    background-color: #fafafa;
  }
  && {
    border-radius: 8px;
    padding: 8px;
    min-width: ${(props) => props.minWidth};
    width: ${(props) => props.width};
    height: auto;
  }
`;

const Radio = styled(RadioAnt)`
  &&.ant-radio-wrapper {
    .ant-radio-disabled + span {
      color: black;
    }
  }
`;

export { Text, Container, Input, DatePicker, Radio };
