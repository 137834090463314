import { useEffect, useState } from "react";
import { Card, Col, Descriptions, Modal, Row, Space, Typography } from "antd";
import { CardContainer } from "../styles";
import moment from "moment";

const SchemaModal = ({
  t,
  followUpForms,
  isSchemaModalVisible,
  setIsSchemaModalVisible,
  setFollowUpForms,
}) => {
  const [renderData, setRenderData] = useState([]);
  useEffect(() => {
    const renderData = followUpForms?.filter((item) => item.submitted);
    setRenderData(renderData);
  }, [followUpForms]);
  return (
    <Modal
      title={t("daily_safety_assessment")}
      visible={isSchemaModalVisible}
      style={{ paddingBottom: 90, top: 30 }}
      width={"100vw"}
      onCancel={() => {
        setFollowUpForms([]);
        setIsSchemaModalVisible(false);
      }}
    >
      <CardContainer minheight="90vh">
        <Row type="flex" gutter={10}>
          <Col span={24} style={{ padding: 20 }}>
            {renderData.map((item) => (
              <Card
                title={item.period}
                style={{ width: "100%" }}
                key={item.day}
              >
                {item.jsonObj?.form?.subForm?.map((form) => {
                  const schemaList = item.jsonObj.schemaList.find(
                    (schema) => schema.stepFormId == form.stepFormId
                  );

                  return (
                    <Card.Grid
                      hoverable={false}
                      style={{ width: "50%" }}
                      key={form.stepFormId}
                    >
                      <DescriptionComponent
                        form={form}
                        schema={schemaList?.schema}
                      />
                    </Card.Grid>
                  );
                })}
              </Card>
            ))}
          </Col>
        </Row>
      </CardContainer>
    </Modal>
  );
};

export default SchemaModal;

const DescriptionComponent = (props) => {
  const { Text } = Typography;
  const { form, schema } = props;
  const schemaArr = [];
  Object.keys(schema).map(function (key) {
    schemaArr.push({ key: key, value: schema[key] });
  });
  return (
    <Descriptions title={form.headerName} bordered size="small" column={1}>
      {schemaArr.map((item) => {
        const value = item.value?.value;
        if (value === "" || !value || value === undefined) {
          return;
        }

        const field = form?.elements.find(
          (element) => element.field === item.key
        );

        let renderValue = <></>;
        switch (field.type) {
          case `text`:
          case `phone`:
          case `number`:
          case `password`:
            renderValue = <Text>{value}</Text>;
            break;
          case `radio`:
          case `select`:
            renderValue = (
              <Text>
                {field.items?.find((choice) => choice.key === value).label}
              </Text>
            );
            break;
          case `timepicker`:
            renderValue = <Text>{moment(value).format("LT")}</Text>;
            break;
          case `datpicker`:
            renderValue = <Text>{moment(value).format("L")}</Text>;
            break;
          case `datetimepicker`:
            renderValue = <Text>{moment(value).format("LLL")}</Text>;
            break;
          case `checkbox`:
          case `multiple-select`:
            renderValue = (
              <Space align="start" direction="vertical">
                {value.map((arrItem, index) => {
                  return (
                    <Text>
                      {`${index + 1}. ${
                        field.items?.find((choice) => choice.key === arrItem)
                          .label
                      }`}
                    </Text>
                  );
                })}
              </Space>
            );
            break;
          default:
            break;
        }

        return (
          <Descriptions.Item label={field.label}>
            {renderValue}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
};
