import { BookOutlined } from "@ant-design/icons";
import { Row, Modal, Tabs, Select } from "antd";
import React, { useState } from "react";
import { Container, HeaderWrapper, Text } from "./styles";
import { useSelector } from "react-redux";
import Table from "./table";
import SearchBox from "../SearchBox";
import { TABS } from "./constant";
const { Option } = Select;

const { TabPane } = Tabs;

const BookingTable = ({
  loading,
  history,
  filterData,
  onSearch,
  handleTableChange,
  setTab,
  searchBy,
  searchText,
  setSearchBy,
  setSearchText,
  t,
}) => {
  const user = useSelector((state) => state.appUserOma);
  const booking = useSelector((state) => state.booking);
  const [visible, setVisible] = useState(false);
  const role = user.information.roles[0].name;
  return (
    <Container>
      <HeaderWrapper>
        <Row align="middle">
          <BookOutlined />
          <Text bold style={{ paddingTop: 10 }}>
            {t("booking")}
          </Text>
        </Row>
        <SearchBox
          setSearchBy={setSearchBy}
          setSearchText={setSearchText}
          onSearch={onSearch}
          t={t}
        />
      </HeaderWrapper>
      <Tabs
        defaultActiveKey="1"
        onChange={(tabNumber) => {
          setTab(TABS[tabNumber - 1]);
        }}
      >
        {role === "deliveryTeam" ? (
          <Table
            t={t}
            loading={loading}
            history={history}
            setVisibleHistory={(condition) => setVisible(condition)}
            isActive={true}
            isHistoryBooking={false}
            title="นัดหมายทั้งหมด"
          />
        ) : (
          TABS.map((tab, index) => (
            <TabPane tab={t(`${tab.title}`)} key={index + 1}>
              <Table
                t={t}
                loading={loading}
                history={history}
                setVisibleHistory={(condition) => setVisible(condition)}
                isActive={true}
                isHistoryBooking={false}
                filterBy={tab.filter}
                title={tab.title}
                dataSource={booking.data}
                handleTableChange={handleTableChange}
              />
            </TabPane>
          ))
        )}
      </Tabs>
      <Modal
        width={1200}
        title="ประวัตินัดหมาย"
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <Table
          t={t}
          loading={booking.history.loading}
          dataSource={booking.history.data}
          history={history}
          isHistoryBooking={true}
          isActive={false}
        />
      </Modal>
    </Container>
  );
};
export default BookingTable;
