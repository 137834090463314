import styled from 'styled-components'

export const ErrMsg = styled.p`
  &&& {
    color: red;
  }
`;
export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const Item = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin: 0;
  }
  ${(props) => {
    if (props.flex) {
      return `
        display: flex;
        justify-content: space-between;
      `;
    }
    if (props.textRight) {
      return `
        text-align: right;
      `;
    }
    return "";
  }}
`;
export const Container = styled.div`
  width: 410px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 30px 0 0;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  .back {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  h5,
  h6 {
    color: ${(props) => props.theme.primaryText};
    font-weight: bold;
  }
  p {
    color: ${(props) => props.theme.primaryText};
  }
  .forgot-password {
    font-size: 0.8125rem;
    color: ${(props) => props.theme.primaryButton};
    text-decoration: underline;
  }
`;