import { Modal, Row, Col, Button, Input } from "antd";
import { FaPenSquare } from "react-icons/fa";
import { NoteIconBox } from "./styles";

const { TextArea } = Input;

const SummaryPrescriptionNoteModal = (props) => {
  const {
    visible,
    setVisible,
    prescriptionNote,
    setPrescriptionNote,
    handleUpdatePrescriptionNote,
    t,
  } = props;
  return (
    <Modal
      visible={visible}
      style={{ borderRadius: "24px" }}
      onCancel={() => setVisible(false)}
      footer={
        <Row justify="space-between">
          <Col>
            <Button onClick={() => setVisible(false)}>{t("cancel")}</Button>
            <Button type="primary" onClick={handleUpdatePrescriptionNote}>
              {t("confirm")}
            </Button>
          </Col>
        </Row>
      }
      okText={t("confirm")}
      cancelText={t("cancel")}
    >
      <Row type="flex" justify="center">
        <Col className="d-flex align-items-center flex-column text-center">
          <NoteIconBox>
            <FaPenSquare
              style={{ fontSize: "36px", color: "#00BAE5" }}
              className="my-2"
            />
          </NoteIconBox>
          <h1 className="h3 font-weight-bold">{t("note")}</h1>
          <p>{t("description")}</p>
        </Col>
        <TextArea
          value={prescriptionNote}
          onChange={(e) => setPrescriptionNote(e.target.value)}
          rows={4}
        />
      </Row>
    </Modal>
  );
};

export default SummaryPrescriptionNoteModal;
